/* Set the base colors for light themes */
/* Set the base colors for dark themes */
/* Include the core Angular Material styles */
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

/* Create a base theme without any color to set the density and typography */
.mat-mdc-option {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-body1-font-size, 0.875rem);
  line-height: var(--mdc-typography-body1-line-height, 1.5rem);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
}

.mat-mdc-card-title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-headline6-font-size, 0.875rem);
  line-height: var(--mdc-typography-headline6-line-height, 1.5rem);
  font-weight: var(--mdc-typography-headline6-font-weight, 400);
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, normal);
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: var(--mdc-typography-headline6-text-transform, none);
}

.mat-mdc-card-subtitle {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-subtitle2-font-size, 0.875rem);
  line-height: var(--mdc-typography-subtitle2-line-height, 1.25rem);
  font-weight: var(--mdc-typography-subtitle2-font-weight, 600);
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle2-text-transform, none);
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: theme("fontFamily.sans");
  --mdc-plain-tooltip-supporting-text-size: 0.75rem;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mdc-text-field__input {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
.mdc-text-field__affix {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-text-field--textarea .mdc-text-field__input {
  line-height: 1.5rem;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}

[dir=rtl] .mdc-floating-label--required, .mdc-floating-label--required[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: var(--mdc-typography-caption-line-height, 1rem);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mat-mdc-form-field,
.mat-mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-body1-font-size, 0.875rem);
  line-height: var(--mdc-typography-body1-line-height, 1.5rem);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: calc(0.875rem * var(--mat-mdc-form-field-floating-label-scale, 0.75));
}
.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 0.875rem;
}

.mat-mdc-select-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 1.5rem;
}

.mat-mdc-select {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-body1-font-size, 0.875rem);
  line-height: var(--mdc-typography-body1-line-height, 1.5rem);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-autocomplete-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 1.5rem;
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: theme("fontFamily.sans");
  --mdc-dialog-subhead-line-height: 1.5rem;
  --mdc-dialog-subhead-size: 0.875rem;
  --mdc-dialog-subhead-weight: 400;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-font: theme("fontFamily.sans");
  --mdc-dialog-supporting-text-line-height: 1.5rem;
  --mdc-dialog-supporting-text-size: 0.875rem;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-chip {
  height: 32px;
}

.mat-mdc-standard-chip {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: var(--mdc-typography-body2-line-height, 1.5rem);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: var(--mdc-typography-body2-line-height, 1.5rem);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.mat-mdc-radio-button .mdc-radio {
  padding: calc((40px - 20px) / 2);
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  top: calc(-1 * (40px - 20px) / 2);
  left: calc(-1 * (40px - 20px) / 2);
  width: 40px;
  height: 40px;
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control {
  top: calc((40px - 40px) / 2);
  right: calc((40px - 40px) / 2);
  left: calc((40px - 40px) / 2);
  width: 40px;
  height: 40px;
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: var(--mdc-typography-body2-line-height, 1.5rem);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.mat-mdc-slider {
  --mdc-slider-label-label-text-font: theme("fontFamily.sans");
  --mdc-slider-label-label-text-font-size: 0.875rem;
  --mdc-slider-label-label-text-line-height: 1.25rem;
  --mdc-slider-label-label-text-tracking: normal;
  --mdc-slider-label-label-text-weight: 600;
}

.mat-mdc-menu-content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 1.5rem;
}
.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-body1-font-size, 0.875rem);
  line-height: var(--mdc-typography-body1-line-height, 1.5rem);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-list-item.mdc-list-item--with-one-line {
  height: 48px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-radio.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-switch.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-image.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-video.mdc-list-item--with-one-line {
  height: 72px;
}
.mat-mdc-list-item.mdc-list-item--with-two-lines {
  height: 64px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines {
  height: 72px;
}
.mat-mdc-list-item.mdc-list-item--with-three-lines {
  height: 88px;
}

.mat-mdc-list-option-with-trailing-avatar.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-option-with-trailing-avatar.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 1.5rem;
}

.mdc-list-item__primary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}

.mdc-list-item__secondary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: var(--mdc-typography-body2-line-height, 1.5rem);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-list-item__overline-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-overline-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-overline-font-size, 0.75rem);
  line-height: var(--mdc-typography-overline-line-height, 2rem);
  font-weight: var(--mdc-typography-overline-font-weight, 500);
  letter-spacing: var(--mdc-typography-overline-letter-spacing, normal);
  text-decoration: var(--mdc-typography-overline-text-decoration, none);
  text-transform: var(--mdc-typography-overline-text-transform, none);
}

.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: var(--mdc-typography-caption-line-height, 1rem);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mdc-list-group__subheader {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}

.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: var(--mdc-typography-caption-line-height, 1rem);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}

@media all and (-ms-high-contrast: none) {
  .mat-mdc-list-option .mdc-list-item__start .mdc-checkbox .mdc-checkbox__focus-ring, .mat-mdc-list-option .mdc-list-item__end .mdc-checkbox .mdc-checkbox__focus-ring {
    display: none;
  }
}
.mat-mdc-list-item .mdc-list-item__primary-text {
  font: 400 0.875rem / 1.5rem theme("fontFamily.sans");
  letter-spacing: normal;
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

.mat-mdc-paginator-container {
  min-height: 56px;
}

.mat-mdc-paginator {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: var(--mdc-typography-caption-line-height, 1rem);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mat-mdc-paginator .mat-mdc-select-value {
  font-size: 0.75rem;
}

.mat-mdc-tab-header .mdc-tab {
  height: 48px;
}

.mdc-tab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-button-font-size, 0.875rem);
  line-height: var(--mdc-typography-button-line-height, 0.875rem);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-mdc-checkbox .mdc-checkbox {
  padding: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  margin: calc((var(--mdc-checkbox-touch-target-size, 40px) - 40px) / 2);
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  top: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  left: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control {
  top: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  right: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  left: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  width: var(--mdc-checkbox-touch-target-size, 40px);
  height: var(--mdc-checkbox-touch-target-size, 40px);
}

@media all and (-ms-high-contrast: none) {
  .mdc-checkbox .mdc-checkbox__focus-ring {
    display: none;
  }
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: var(--mdc-typography-body2-line-height, 1.5rem);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-button-font-size, 0.875rem);
  line-height: var(--mdc-typography-button-line-height, 0.875rem);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  width: 48px;
  height: 48px;
  padding: 12px;
}
.mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 48px;
  max-width: 48px;
}
.mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}

.mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-button-font-size, 0.875rem);
  line-height: var(--mdc-typography-button-line-height, 0.875rem);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-font: theme("fontFamily.sans");
  --mdc-snackbar-supporting-text-line-height: 1.5rem;
  --mdc-snackbar-supporting-text-size: 0.875rem;
  --mdc-snackbar-supporting-text-weight: 400;
}

.mat-mdc-table .mdc-data-table__row {
  height: 52px;
}
.mat-mdc-table .mdc-data-table__pagination {
  min-height: 52px;
}
.mat-mdc-table .mdc-data-table__header-row {
  height: 56px;
}

.mdc-data-table__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: var(--mdc-typography-body2-line-height, 1.5rem);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-data-table__cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: var(--mdc-typography-body2-line-height, 1.5rem);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-data-table__header-cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, theme("fontFamily.sans")));
  font-size: var(--mdc-typography-subtitle2-font-size, 0.875rem);
  line-height: var(--mdc-typography-subtitle2-line-height, 1.25rem);
  font-weight: var(--mdc-typography-subtitle2-font-weight, 600);
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle2-text-transform, none);
}

[dir=rtl] .mdc-data-table__pagination-button .mdc-button__icon, .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-bottom-sheet-container {
  font: 400 0.875rem / 1.5rem theme("fontFamily.sans");
  letter-spacing: normal;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 40px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 40px;
  transform: translate(-50%, -50%);
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 0.875rem;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-expansion-panel-header {
  font-family: theme("fontFamily.sans");
  font-size: 0.875rem;
  font-weight: 600;
}

.mat-expansion-panel-content {
  font: 400 0.875rem / 1.5rem theme("fontFamily.sans");
  letter-spacing: normal;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 0.875rem;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 0.75rem;
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 0.875rem;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 0.875rem;
}

.mat-step-label-selected {
  font-size: 0.875rem;
  font-weight: 400;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 400 0.875rem / 1.5rem theme("fontFamily.sans");
  letter-spacing: normal;
  margin: 0;
}

.mat-tree-node {
  min-height: 48px;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 0.875rem;
}

/* Loop through user themes and generate Angular Material themes */
/* Generate the palettes */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define a light & dark Angular Material theme with the generated palettes */
/* Merge the custom base colors with the generated themes */
/* Generate and encapsulate Angular Material themes */
.theme-default .light .mat-ripple-element,
.theme-default.light .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.theme-default .light .mat-mdc-option,
.theme-default.light .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-default .light .mat-mdc-option:hover:not(.mdc-list-item--disabled), .theme-default .light .mat-mdc-option:focus:not(.mdc-list-item--disabled), .theme-default .light .mat-mdc-option.mat-mdc-option-active, .theme-default .light .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled),
.theme-default.light .mat-mdc-option:hover:not(.mdc-list-item--disabled),
.theme-default.light .mat-mdc-option:focus:not(.mdc-list-item--disabled),
.theme-default.light .mat-mdc-option.mat-mdc-option-active,
.theme-default.light .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.theme-default .light .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-default.light .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #068d5b);
}
.theme-default .light .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-default.light .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #1e293b);
}
.theme-default .light .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-default.light .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mat-mdc-optgroup-label,
.theme-default.light .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-default .light .mat-pseudo-checkbox,
.theme-default.light .mat-pseudo-checkbox {
  color: #64748b;
}
.theme-default .light .mat-pseudo-checkbox::after,
.theme-default.light .mat-pseudo-checkbox::after {
  color: #f1f5f9;
}
.theme-default .light .mat-pseudo-checkbox-disabled,
.theme-default.light .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.theme-default .light .mat-primary .mat-pseudo-checkbox-checked,
.theme-default .light .mat-primary .mat-pseudo-checkbox-indeterminate,
.theme-default.light .mat-primary .mat-pseudo-checkbox-checked,
.theme-default.light .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #068d5b;
}
.theme-default .light .mat-pseudo-checkbox-checked,
.theme-default .light .mat-pseudo-checkbox-indeterminate,
.theme-default .light .mat-accent .mat-pseudo-checkbox-checked,
.theme-default .light .mat-accent .mat-pseudo-checkbox-indeterminate,
.theme-default.light .mat-pseudo-checkbox-checked,
.theme-default.light .mat-pseudo-checkbox-indeterminate,
.theme-default.light .mat-accent .mat-pseudo-checkbox-checked,
.theme-default.light .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #1e293b;
}
.theme-default .light .mat-warn .mat-pseudo-checkbox-checked,
.theme-default .light .mat-warn .mat-pseudo-checkbox-indeterminate,
.theme-default.light .mat-warn .mat-pseudo-checkbox-checked,
.theme-default.light .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #dc2626;
}
.theme-default .light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-default .light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled,
.theme-default.light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-default.light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.theme-default .light .mat-app-background, .theme-default .light.mat-app-background,
.theme-default.light .mat-app-background,
.theme-default.light.mat-app-background {
  background-color: #f1f5f9;
  color: #1e293b;
}
.theme-default .light .mat-elevation-z0, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-default.light .mat-elevation-z0,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z1, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-default.light .mat-elevation-z1,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z2, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-default.light .mat-elevation-z2,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z3, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-default.light .mat-elevation-z3,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z4, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-default.light .mat-elevation-z4,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z5, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-default.light .mat-elevation-z5,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z6, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-default.light .mat-elevation-z6,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z7, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-default.light .mat-elevation-z7,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z8, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-default.light .mat-elevation-z8,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z9, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-default.light .mat-elevation-z9,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z10, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-default.light .mat-elevation-z10,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z11, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-default.light .mat-elevation-z11,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z12, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-default.light .mat-elevation-z12,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z13, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-default.light .mat-elevation-z13,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z14, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-default.light .mat-elevation-z14,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z15, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-default.light .mat-elevation-z15,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z16, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-default.light .mat-elevation-z16,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z17, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-default.light .mat-elevation-z17,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z18, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-default.light .mat-elevation-z18,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z19, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-default.light .mat-elevation-z19,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z20, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-default.light .mat-elevation-z20,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z21, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-default.light .mat-elevation-z21,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z22, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-default.light .mat-elevation-z22,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z23, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-default.light .mat-elevation-z23,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z24, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-default.light .mat-elevation-z24,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-default .light .mat-mdc-card,
.theme-default.light .mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #fff;
}
.theme-default .light .mat-mdc-card-outlined,
.theme-default.light .mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #e0e0e0;
}
.theme-default .light .mat-mdc-card-subtitle,
.theme-default.light .mat-mdc-card-subtitle {
  color: #64748b;
}
.theme-default .light .mat-mdc-progress-bar,
.theme-default.light .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #068d5b;
}
.theme-default .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.theme-default.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(6, 141, 91, 0.25)'/%3E%3C/svg%3E");
}
.theme-default .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.theme-default.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(6, 141, 91, 0.25);
}
.theme-default .light .mat-mdc-progress-bar.mat-accent,
.theme-default.light .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
}
.theme-default .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.theme-default.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(30, 41, 59, 0.25)'/%3E%3C/svg%3E");
}
.theme-default .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.theme-default.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(30, 41, 59, 0.25);
}
.theme-default .light .mat-mdc-progress-bar.mat-warn,
.theme-default.light .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
}
.theme-default .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.theme-default.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(220, 38, 38, 0.25)'/%3E%3C/svg%3E");
}
.theme-default .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.theme-default.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(220, 38, 38, 0.25);
}
.theme-default .light .mat-mdc-tooltip,
.theme-default.light .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.theme-default .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-default.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.theme-default .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input,
.theme-default.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .theme-default .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder,
  .theme-default.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .theme-default .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder,
  .theme-default.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.theme-default .light .mdc-text-field .mdc-text-field__input,
.theme-default.light .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #068d5b);
}
.theme-default .light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text,
.theme-default.light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.theme-default .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.theme-default .light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter,
.theme-default.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.theme-default.light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.theme-default .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading,
.theme-default.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.theme-default .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-default.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.theme-default .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix,
.theme-default.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-default .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix,
.theme-default.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-default .light .mdc-text-field--filled .mdc-text-field__ripple::before,
.theme-default .light .mdc-text-field--filled .mdc-text-field__ripple::after,
.theme-default.light .mdc-text-field--filled .mdc-text-field__ripple::before,
.theme-default.light .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.theme-default .light .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .theme-default .light .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before,
.theme-default.light .mdc-text-field--filled:hover .mdc-text-field__ripple::before,
.theme-default.light .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.theme-default .light .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .theme-default .light .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before,
.theme-default.light .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before,
.theme-default.light .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.theme-default .light .mdc-text-field--filled:not(.mdc-text-field--disabled),
.theme-default.light .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.theme-default .light .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-default.light .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.theme-default .light .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-default.light .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.theme-default .light .mdc-text-field--filled .mdc-line-ripple::after,
.theme-default.light .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #068d5b);
}
.theme-default .light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-default .light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-default .light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-default.light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-default.light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-default.light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.theme-default .light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-default .light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-default .light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-default.light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-default.light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-default.light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.theme-default .light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default .light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default .light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-default.light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default.light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default.light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #068d5b);
}
[dir=rtl] .theme-default .light .mdc-text-field--outlined.mdc-text-field--with-leading-icon, [dir=rtl] .theme-default.light .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .theme-default .light .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl], .theme-default.light .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-default .light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, [dir=rtl] .theme-default.light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .theme-default .light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl], .theme-default.light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-default .light .mdc-text-field--outlined .mdc-text-field__ripple::before,
.theme-default .light .mdc-text-field--outlined .mdc-text-field__ripple::after,
.theme-default.light .mdc-text-field--outlined .mdc-text-field__ripple::before,
.theme-default.light .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .theme-default .light .mdc-text-field--with-leading-icon.mdc-text-field--outlined, [dir=rtl] .theme-default.light .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .theme-default .light .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl], .theme-default.light .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-default .light .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-default.light .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(6, 141, 91, 0.87);
}
.theme-default .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-default.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-default.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-default.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-default.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mdc-text-field--invalid .mdc-text-field__input,
.theme-default.light .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-default.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-default.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-default .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-default .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-default.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-default.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-default.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-default .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-default .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-default.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-default.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-default.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-default.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mdc-text-field--disabled .mdc-text-field__input,
.theme-default.light .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .theme-default .light .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  .theme-default.light .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .theme-default .light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  .theme-default.light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.theme-default .light .mdc-text-field--disabled .mdc-floating-label,
.theme-default.light .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.theme-default .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
.theme-default.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.theme-default .light .mdc-text-field--disabled .mdc-text-field-character-counter,
.theme-default .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
.theme-default.light .mdc-text-field--disabled .mdc-text-field-character-counter,
.theme-default.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.theme-default .light .mdc-text-field--disabled .mdc-text-field__icon--leading,
.theme-default.light .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.theme-default .light .mdc-text-field--disabled .mdc-text-field__icon--trailing,
.theme-default.light .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.theme-default .light .mdc-text-field--disabled .mdc-text-field__affix--prefix,
.theme-default.light .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-default .light .mdc-text-field--disabled .mdc-text-field__affix--suffix,
.theme-default.light .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-default .light .mdc-text-field--disabled .mdc-line-ripple::before,
.theme-default.light .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.theme-default .light .mdc-text-field--disabled .mdc-notched-outline__leading,
.theme-default .light .mdc-text-field--disabled .mdc-notched-outline__notch,
.theme-default .light .mdc-text-field--disabled .mdc-notched-outline__trailing,
.theme-default.light .mdc-text-field--disabled .mdc-notched-outline__leading,
.theme-default.light .mdc-text-field--disabled .mdc-notched-outline__notch,
.theme-default.light .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .light .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  .theme-default.light .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  .theme-default.light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .light .mdc-text-field--disabled .mdc-floating-label,
  .theme-default.light .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
  .theme-default.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .light .mdc-text-field--disabled .mdc-text-field-character-counter,
  .theme-default .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
  .theme-default.light .mdc-text-field--disabled .mdc-text-field-character-counter,
  .theme-default.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .light .mdc-text-field--disabled .mdc-text-field__icon--leading,
  .theme-default.light .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .light .mdc-text-field--disabled .mdc-text-field__icon--trailing,
  .theme-default.light .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .light .mdc-text-field--disabled .mdc-text-field__affix--prefix,
  .theme-default.light .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .light .mdc-text-field--disabled .mdc-text-field__affix--suffix,
  .theme-default.light .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .light .mdc-text-field--disabled .mdc-line-ripple::before,
  .theme-default.light .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .light .mdc-text-field--disabled .mdc-notched-outline__leading,
  .theme-default .light .mdc-text-field--disabled .mdc-notched-outline__notch,
  .theme-default .light .mdc-text-field--disabled .mdc-notched-outline__trailing,
  .theme-default.light .mdc-text-field--disabled .mdc-notched-outline__leading,
  .theme-default.light .mdc-text-field--disabled .mdc-notched-outline__notch,
  .theme-default.light .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.theme-default .light .mdc-text-field--disabled.mdc-text-field--filled,
.theme-default.light .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .theme-default .light .mdc-text-field--end-aligned .mdc-text-field__input, [dir=rtl] .theme-default.light .mdc-text-field--end-aligned .mdc-text-field__input, .theme-default .light .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl], .theme-default.light .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-default .light .mdc-floating-label--required, [dir=rtl] .theme-default.light .mdc-floating-label--required, .theme-default .light .mdc-floating-label--required[dir=rtl], .theme-default.light .mdc-floating-label--required[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-default .light .mat-mdc-form-field-error,
.theme-default.light .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mat-mdc-form-field-focus-overlay,
.theme-default.light .mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}
.theme-default .light .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.theme-default.light .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
.theme-default .light .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.theme-default.light .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}
.theme-default .light .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.theme-default.light .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.theme-default .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.theme-default.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(6, 141, 91, 0.87);
}
.theme-default .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.theme-default.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(30, 41, 59, 0.87);
}
.theme-default .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.theme-default.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(220, 38, 38, 0.87);
}
.theme-default .light .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.theme-default.light .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.theme-default .light .mat-mdc-form-field.mat-accent .mdc-text-field__input,
.theme-default.light .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-default .light .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-default.light .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-default .light .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-default.light .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(30, 41, 59, 0.87);
}
.theme-default .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-default.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-default.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-default.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-default.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input,
.theme-default.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-default.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-default.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-default .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-default .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-default.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-default.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-default.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-default .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-default .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-default.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-default.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-default.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-default.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default .light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default .light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-default.light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default.light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default.light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-default .light .mat-mdc-form-field.mat-warn .mdc-text-field__input,
.theme-default.light .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-default.light .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-default.light .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(220, 38, 38, 0.87);
}
.theme-default .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-default.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-default.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-default.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-default.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input,
.theme-default.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-default.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-default.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-default .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-default .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-default.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-default.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-default.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-default .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-default .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-default.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-default.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-default.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-default.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default .light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default .light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-default.light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default.light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default.light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .light .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.theme-default.light .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.theme-default .light [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.theme-default.light [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.theme-default .light .mdc-menu-surface,
.theme-default.light .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mdc-list-item__primary-text,
.theme-default.light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-default .light .mdc-list-item__secondary-text,
.theme-default.light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.theme-default .light .mdc-list-item__overline-text,
.theme-default.light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-default .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-default.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-default .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-default.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.theme-default .light .mdc-list-item__end,
.theme-default.light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-default .light .mdc-list-item--disabled .mdc-list-item__start,
.theme-default .light .mdc-list-item--disabled .mdc-list-item__content,
.theme-default .light .mdc-list-item--disabled .mdc-list-item__end,
.theme-default.light .mdc-list-item--disabled .mdc-list-item__start,
.theme-default.light .mdc-list-item--disabled .mdc-list-item__content,
.theme-default.light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-default .light .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-default.light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-default.light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-default.light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-default.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-default.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-default .light .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-default.light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-default.light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #068d5b);
}
.theme-default .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #068d5b);
}
.theme-default .light .mdc-deprecated-list-group__subheader,
.theme-default.light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-default .light .mdc-list-divider::after,
.theme-default.light .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-default .light .mdc-list-divider,
.theme-default.light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-mdc-select-value,
.theme-default.light .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.theme-default .light .mat-mdc-select-placeholder,
.theme-default.light .mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.theme-default .light .mat-mdc-select-disabled .mat-mdc-select-value,
.theme-default.light .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.theme-default .light .mat-mdc-select-arrow,
.theme-default.light .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.theme-default .light .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow,
.theme-default.light .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(6, 141, 91, 0.87);
}
.theme-default .light .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow,
.theme-default.light .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(30, 41, 59, 0.87);
}
.theme-default .light .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow,
.theme-default.light .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(220, 38, 38, 0.87);
}
.theme-default .light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow,
.theme-default.light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(220, 38, 38, 0.87);
}
.theme-default .light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow,
.theme-default.light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.theme-default .light .mdc-menu-surface,
.theme-default.light .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mdc-list-item__primary-text,
.theme-default.light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-default .light .mdc-list-item__secondary-text,
.theme-default.light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.theme-default .light .mdc-list-item__overline-text,
.theme-default.light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-default .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-default.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-default .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-default.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.theme-default .light .mdc-list-item__end,
.theme-default.light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-default .light .mdc-list-item--disabled .mdc-list-item__start,
.theme-default .light .mdc-list-item--disabled .mdc-list-item__content,
.theme-default .light .mdc-list-item--disabled .mdc-list-item__end,
.theme-default.light .mdc-list-item--disabled .mdc-list-item__start,
.theme-default.light .mdc-list-item--disabled .mdc-list-item__content,
.theme-default.light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-default .light .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-default.light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-default.light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-default.light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-default.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-default.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-default .light .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-default.light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-default.light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #068d5b);
}
.theme-default .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #068d5b);
}
.theme-default .light .mdc-deprecated-list-group__subheader,
.theme-default.light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-default .light .mdc-list-divider::after,
.theme-default.light .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-default .light .mdc-list-divider,
.theme-default.light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-mdc-dialog-container,
.theme-default.light .mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.theme-default .light .mat-mdc-standard-chip,
.theme-default.light .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.theme-default .light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .theme-default .light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted,
.theme-default.light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected,
.theme-default.light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #068d5b;
  --mdc-chip-elevated-disabled-container-color: #068d5b;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-default .light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .theme-default .light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted,
.theme-default.light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected,
.theme-default.light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-default .light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .theme-default .light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted,
.theme-default.light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected,
.theme-default.light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-default .light .mat-mdc-chip-focus-overlay,
.theme-default.light .mat-mdc-chip-focus-overlay {
  background: #000000;
}
.theme-default .light .mat-mdc-slide-toggle,
.theme-default.light .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.theme-default .light .mat-mdc-slide-toggle .mdc-form-field,
.theme-default.light .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-default .light .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.theme-default.light .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: #94a3b8;
}
.theme-default .light .mat-mdc-slide-toggle.mat-primary,
.theme-default.light .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #068d5b;
  --mdc-switch-selected-handle-color: #068d5b;
  --mdc-switch-selected-hover-state-layer-color: #068d5b;
  --mdc-switch-selected-pressed-state-layer-color: #068d5b;
  --mdc-switch-selected-focus-handle-color: #074d37;
  --mdc-switch-selected-hover-handle-color: #074d37;
  --mdc-switch-selected-pressed-handle-color: #074d37;
  --mdc-switch-selected-focus-track-color: #6fe6b0;
  --mdc-switch-selected-hover-track-color: #6fe6b0;
  --mdc-switch-selected-pressed-track-color: #6fe6b0;
  --mdc-switch-selected-track-color: #6fe6b0;
}
.theme-default .light .mat-mdc-slide-toggle.mat-accent,
.theme-default.light .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.theme-default .light .mat-mdc-slide-toggle.mat-warn,
.theme-default.light .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.theme-default .light .mat-mdc-radio-button,
.theme-default.light .mat-mdc-radio-button {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mat-mdc-radio-ripple-color: #000;
}
.theme-default .light .mat-mdc-radio-button .mdc-form-field,
.theme-default.light .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-default .light .mat-mdc-radio-button .mdc-radio--disabled + label,
.theme-default.light .mat-mdc-radio-button .mdc-radio--disabled + label {
  color: #94a3b8;
}
.theme-default .light .mat-mdc-radio-button.mat-primary,
.theme-default.light .mat-mdc-radio-button.mat-primary {
  --mdc-radio-selected-focus-icon-color: #068d5b;
  --mdc-radio-selected-hover-icon-color: #068d5b;
  --mdc-radio-selected-icon-color: #068d5b;
  --mdc-radio-selected-pressed-icon-color: #068d5b;
  --mat-mdc-radio-checked-ripple-color: #068d5b;
}
.theme-default .light .mat-mdc-radio-button.mat-accent,
.theme-default.light .mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-mdc-radio-checked-ripple-color: #1e293b;
}
.theme-default .light .mat-mdc-radio-button.mat-warn,
.theme-default.light .mat-mdc-radio-button.mat-warn {
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-mdc-radio-checked-ripple-color: #dc2626;
}
.theme-default .light .mat-mdc-slider,
.theme-default.light .mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.theme-default .light .mat-mdc-slider.mat-primary,
.theme-default.light .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #068d5b;
  --mdc-slider-focus-handle-color: #068d5b;
  --mdc-slider-hover-handle-color: #068d5b;
  --mdc-slider-active-track-color: #068d5b;
  --mdc-slider-inactive-track-color: #068d5b;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #068d5b;
  --mat-mdc-slider-ripple-color: #068d5b;
  --mat-mdc-slider-hover-ripple-color: rgba(6, 141, 91, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(6, 141, 91, 0.2);
}
.theme-default .light .mat-mdc-slider.mat-accent,
.theme-default.light .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mat-mdc-slider-ripple-color: #1e293b;
  --mat-mdc-slider-hover-ripple-color: rgba(30, 41, 59, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(30, 41, 59, 0.2);
}
.theme-default .light .mat-mdc-slider.mat-warn,
.theme-default.light .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mat-mdc-slider-ripple-color: #dc2626;
  --mat-mdc-slider-hover-ripple-color: rgba(220, 38, 38, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(220, 38, 38, 0.2);
}
.theme-default .light .mdc-menu-surface,
.theme-default.light .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mdc-list-item__primary-text,
.theme-default.light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-default .light .mdc-list-item__secondary-text,
.theme-default.light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.theme-default .light .mdc-list-item__overline-text,
.theme-default.light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-default .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-default.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-default .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-default.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.theme-default .light .mdc-list-item__end,
.theme-default.light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-default .light .mdc-list-item--disabled .mdc-list-item__start,
.theme-default .light .mdc-list-item--disabled .mdc-list-item__content,
.theme-default .light .mdc-list-item--disabled .mdc-list-item__end,
.theme-default.light .mdc-list-item--disabled .mdc-list-item__start,
.theme-default.light .mdc-list-item--disabled .mdc-list-item__content,
.theme-default.light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-default .light .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-default.light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-default.light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-default.light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-default.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-default.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-default .light .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-default.light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-default.light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #068d5b);
}
.theme-default .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #068d5b);
}
.theme-default .light .mdc-deprecated-list-group__subheader,
.theme-default.light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-default .light .mdc-list-divider::after,
.theme-default.light .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-default .light .mdc-list-divider,
.theme-default.light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-mdc-menu-item[disabled],
.theme-default .light .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.theme-default .light .mat-mdc-menu-item[disabled] .mat-icon-no-color,
.theme-default.light .mat-mdc-menu-item[disabled],
.theme-default.light .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.theme-default.light .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}
.theme-default .light .mat-mdc-menu-item .mat-icon-no-color,
.theme-default .light .mat-mdc-menu-submenu-icon,
.theme-default.light .mat-mdc-menu-item .mat-icon-no-color,
.theme-default.light .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-default .light .mat-mdc-menu-item:hover:not([disabled]),
.theme-default .light .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.theme-default .light .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-default .light .mat-mdc-menu-item-highlighted:not([disabled]),
.theme-default.light .mat-mdc-menu-item:hover:not([disabled]),
.theme-default.light .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.theme-default.light .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-default.light .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.theme-default .light .mat-mdc-list-item-interactive::before,
.theme-default.light .mat-mdc-list-item-interactive::before {
  background: black;
}
.theme-default .light .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before,
.theme-default.light .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.04;
}
.theme-default .light .mat-mdc-list-item-interactive.mdc-list-item--selected::before,
.theme-default.light .mat-mdc-list-item-interactive.mdc-list-item--selected::before {
  background: #068d5b;
  opacity: 0.08;
}
.theme-default .light .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before,
.theme-default.light .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.12;
}
.theme-default .light .mat-mdc-list-item-interactive:focus::before,
.theme-default.light .mat-mdc-list-item-interactive:focus::before {
  opacity: 0.12;
}
.theme-default .light .mdc-list-item__primary-text,
.theme-default.light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-default .light .mdc-list-item__secondary-text,
.theme-default.light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.theme-default .light .mdc-list-item__overline-text,
.theme-default.light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-default .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-default.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-default .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-default.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.theme-default .light .mdc-list-item__end,
.theme-default.light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-default .light .mdc-list-item--disabled .mdc-list-item__start,
.theme-default .light .mdc-list-item--disabled .mdc-list-item__content,
.theme-default .light .mdc-list-item--disabled .mdc-list-item__end,
.theme-default.light .mdc-list-item--disabled .mdc-list-item__start,
.theme-default.light .mdc-list-item--disabled .mdc-list-item__content,
.theme-default.light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-default .light .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-default.light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-default.light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-default.light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-default.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-default.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-default .light .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-default.light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-default.light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #068d5b);
}
.theme-default .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #068d5b);
}
.theme-default .light .mdc-deprecated-list-group__subheader,
.theme-default.light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-default .light .mdc-list-divider::after,
.theme-default.light .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-default .light .mdc-list-divider,
.theme-default.light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-mdc-list-option .mdc-list-item__start, .theme-default .light .mat-mdc-list-option .mdc-list-item__end,
.theme-default.light .mat-mdc-list-option .mdc-list-item__start,
.theme-default.light .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #068d5b;
  --mdc-checkbox-selected-hover-icon-color: #068d5b;
  --mdc-checkbox-selected-icon-color: #068d5b;
  --mdc-checkbox-selected-pressed-icon-color: #068d5b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-default .light .mat-mdc-list-option.mat-accent .mdc-list-item__start, .theme-default .light .mat-mdc-list-option.mat-accent .mdc-list-item__end,
.theme-default.light .mat-mdc-list-option.mat-accent .mdc-list-item__start,
.theme-default.light .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-default .light .mat-mdc-list-option.mat-warn .mdc-list-item__start, .theme-default .light .mat-mdc-list-option.mat-warn .mdc-list-item__end,
.theme-default.light .mat-mdc-list-option.mat-warn .mdc-list-item__start,
.theme-default.light .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-default .light .mat-mdc-paginator,
.theme-default.light .mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme-default .light .mat-mdc-paginator-icon,
.theme-default.light .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}
.theme-default .light .mat-mdc-paginator-decrement,
.theme-default .light .mat-mdc-paginator-increment,
.theme-default.light .mat-mdc-paginator-decrement,
.theme-default.light .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.theme-default .light .mat-mdc-paginator-first,
.theme-default .light .mat-mdc-paginator-last,
.theme-default.light .mat-mdc-paginator-first,
.theme-default.light .mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.theme-default .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.theme-default .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.theme-default .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.theme-default .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last,
.theme-default.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.theme-default.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.theme-default.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.theme-default.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon,
.theme-default.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-mdc-tab, .theme-default .light .mat-mdc-tab-link,
.theme-default.light .mat-mdc-tab,
.theme-default.light .mat-mdc-tab-link {
  background-color: transparent;
}
.theme-default .light .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label, .theme-default .light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.theme-default.light .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.theme-default.light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}
.theme-default .light .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-default .light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-default.light .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-default.light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #068d5b;
}
.theme-default .light .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-default .light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-default.light .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-default.light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #068d5b;
}
.theme-default .light .mdc-tab__ripple::before,
.theme-default .light .mat-mdc-tab .mat-ripple-element,
.theme-default .light .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-default .light .mat-mdc-tab-link .mat-ripple-element,
.theme-default.light .mdc-tab__ripple::before,
.theme-default.light .mat-mdc-tab .mat-ripple-element,
.theme-default.light .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-default.light .mat-mdc-tab-link .mat-ripple-element {
  background-color: #068d5b;
}
.theme-default .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-default .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-default .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-default .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-default.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-default.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-default.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-default.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #1e293b;
}
.theme-default .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-default .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-default .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-default .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-default.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-default.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-default.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-default.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #1e293b;
}
.theme-default .light .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.theme-default .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-default .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-default .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .theme-default .light .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.theme-default .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-default .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-default .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element,
.theme-default.light .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.theme-default.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-default.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-default.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element,
.theme-default.light .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.theme-default.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-default.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-default.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #1e293b;
}
.theme-default .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-default .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-default .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-default .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-default.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-default.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-default.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-default.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #dc2626;
}
.theme-default .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-default .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-default .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-default .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-default.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-default.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-default.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-default.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #dc2626;
}
.theme-default .light .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.theme-default .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-default .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-default .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .theme-default .light .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.theme-default .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-default .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-default .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element,
.theme-default.light .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.theme-default.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-default.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-default.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element,
.theme-default.light .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.theme-default.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-default.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-default.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #dc2626;
}
.theme-default .light .mat-mdc-tab-group.mat-background-primary, .theme-default .light .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-default.light .mat-mdc-tab-group.mat-background-primary,
.theme-default.light .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #068d5b;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-default .light .mat-mdc-tab-group.mat-background-accent, .theme-default .light .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-default.light .mat-mdc-tab-group.mat-background-accent,
.theme-default.light .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #1e293b;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-default .light .mat-mdc-tab-group.mat-background-warn, .theme-default .light .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-default.light .mat-mdc-tab-group.mat-background-warn,
.theme-default.light .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #dc2626;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-default .light .mat-mdc-tab-header-pagination-chevron,
.theme-default.light .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #000);
}
.theme-default .light .mat-mdc-checkbox .mdc-form-field,
.theme-default.light .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-default .light .mat-mdc-checkbox .mat-ripple-element,
.theme-default.light .mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.theme-default .light .mat-mdc-checkbox .mdc-checkbox__ripple,
.theme-default.light .mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #000;
}
.theme-default .light .mat-mdc-checkbox.mat-primary,
.theme-default.light .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #068d5b;
  --mdc-checkbox-selected-hover-icon-color: #068d5b;
  --mdc-checkbox-selected-icon-color: #068d5b;
  --mdc-checkbox-selected-pressed-icon-color: #068d5b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-default .light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-default.light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(6, 141, 91, 0.1);
}
.theme-default .light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-default.light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #068d5b;
}
.theme-default .light .mat-mdc-checkbox.mat-accent,
.theme-default.light .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-default .light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-default.light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(30, 41, 59, 0.1);
}
.theme-default .light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-default.light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #1e293b;
}
.theme-default .light .mat-mdc-checkbox.mat-warn,
.theme-default.light .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-default .light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-default.light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(220, 38, 38, 0.1);
}
.theme-default .light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-default.light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #dc2626;
}
.theme-default .light .mat-mdc-checkbox-disabled label,
.theme-default.light .mat-mdc-checkbox-disabled label {
  color: #94a3b8;
}
.theme-default .light .mat-mdc-button.mat-unthemed,
.theme-default.light .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.theme-default .light .mat-mdc-button.mat-primary,
.theme-default.light .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #068d5b;
}
.theme-default .light .mat-mdc-button.mat-accent,
.theme-default.light .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
}
.theme-default .light .mat-mdc-button.mat-warn,
.theme-default.light .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
}
.theme-default .light .mat-mdc-button[disabled][disabled],
.theme-default.light .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.theme-default .light .mat-mdc-unelevated-button.mat-unthemed,
.theme-default.light .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.theme-default .light .mat-mdc-unelevated-button.mat-primary,
.theme-default.light .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #068d5b;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-default .light .mat-mdc-unelevated-button.mat-accent,
.theme-default.light .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-default .light .mat-mdc-unelevated-button.mat-warn,
.theme-default.light .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-default .light .mat-mdc-unelevated-button[disabled][disabled],
.theme-default.light .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.theme-default .light .mat-mdc-raised-button.mat-unthemed,
.theme-default.light .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.theme-default .light .mat-mdc-raised-button.mat-primary,
.theme-default.light .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #068d5b;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-default .light .mat-mdc-raised-button.mat-accent,
.theme-default.light .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-default .light .mat-mdc-raised-button.mat-warn,
.theme-default.light .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-default .light .mat-mdc-raised-button[disabled][disabled],
.theme-default.light .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.theme-default .light .mat-mdc-outlined-button,
.theme-default.light .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-mdc-outlined-button.mat-unthemed,
.theme-default.light .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.theme-default .light .mat-mdc-outlined-button.mat-primary,
.theme-default.light .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #068d5b;
}
.theme-default .light .mat-mdc-outlined-button.mat-accent,
.theme-default.light .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
}
.theme-default .light .mat-mdc-outlined-button.mat-warn,
.theme-default.light .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
}
.theme-default .light .mat-mdc-outlined-button[disabled][disabled],
.theme-default.light .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-mdc-button, .theme-default .light .mat-mdc-outlined-button,
.theme-default.light .mat-mdc-button,
.theme-default.light .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-default .light .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .theme-default .light .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-default .light .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-default .light .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-default .light .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-default .light .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-default .light .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .theme-default .light .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-default .light .mat-mdc-button.mat-primary, .theme-default .light .mat-mdc-outlined-button.mat-primary,
.theme-default.light .mat-mdc-button.mat-primary,
.theme-default.light .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #068d5b;
  --mat-mdc-button-ripple-color: rgba(6, 141, 91, 0.1);
}
.theme-default .light .mat-mdc-button.mat-accent, .theme-default .light .mat-mdc-outlined-button.mat-accent,
.theme-default.light .mat-mdc-button.mat-accent,
.theme-default.light .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #1e293b;
  --mat-mdc-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-default .light .mat-mdc-button.mat-warn, .theme-default .light .mat-mdc-outlined-button.mat-warn,
.theme-default.light .mat-mdc-button.mat-warn,
.theme-default.light .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #dc2626;
  --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-default .light .mat-mdc-raised-button, .theme-default .light .mat-mdc-unelevated-button,
.theme-default.light .mat-mdc-raised-button,
.theme-default.light .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-default .light .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .theme-default .light .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-default .light .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-default .light .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-default .light .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-default .light .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-default .light .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .theme-default .light .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-default .light .mat-mdc-raised-button.mat-primary, .theme-default .light .mat-mdc-unelevated-button.mat-primary,
.theme-default.light .mat-mdc-raised-button.mat-primary,
.theme-default.light .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .light .mat-mdc-raised-button.mat-accent, .theme-default .light .mat-mdc-unelevated-button.mat-accent,
.theme-default.light .mat-mdc-raised-button.mat-accent,
.theme-default.light .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .light .mat-mdc-raised-button.mat-warn, .theme-default .light .mat-mdc-unelevated-button.mat-warn,
.theme-default.light .mat-mdc-raised-button.mat-warn,
.theme-default.light .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .light .mat-mdc-icon-button,
.theme-default.light .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-default .light .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-default .light .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-default .light .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-default .light .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-default .light .mat-mdc-icon-button.mat-primary,
.theme-default.light .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #068d5b;
  --mat-mdc-button-ripple-color: rgba(6, 141, 91, 0.1);
}
.theme-default .light .mat-mdc-icon-button.mat-accent,
.theme-default.light .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #1e293b;
  --mat-mdc-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-default .light .mat-mdc-icon-button.mat-warn,
.theme-default.light .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #dc2626;
  --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-default .light .mat-mdc-icon-button.mat-primary,
.theme-default.light .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #068d5b;
}
.theme-default .light .mat-mdc-icon-button.mat-accent,
.theme-default.light .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
}
.theme-default .light .mat-mdc-icon-button.mat-warn,
.theme-default.light .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
}
.theme-default .light .mat-mdc-icon-button[disabled][disabled],
.theme-default.light .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.theme-default .light .mat-mdc-fab, .theme-default .light .mat-mdc-mini-fab,
.theme-default.light .mat-mdc-fab,
.theme-default.light .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-default .light .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .theme-default .light .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-default .light .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-default .light .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-default .light .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-default .light .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-default .light .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .theme-default .light .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.theme-default.light .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-default .light .mat-mdc-fab.mat-primary, .theme-default .light .mat-mdc-mini-fab.mat-primary,
.theme-default.light .mat-mdc-fab.mat-primary,
.theme-default.light .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .light .mat-mdc-fab.mat-accent, .theme-default .light .mat-mdc-mini-fab.mat-accent,
.theme-default.light .mat-mdc-fab.mat-accent,
.theme-default.light .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .light .mat-mdc-fab.mat-warn, .theme-default .light .mat-mdc-mini-fab.mat-warn,
.theme-default.light .mat-mdc-fab.mat-warn,
.theme-default.light .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .light .mat-mdc-fab.mat-unthemed, .theme-default .light .mat-mdc-mini-fab.mat-unthemed,
.theme-default.light .mat-mdc-fab.mat-unthemed,
.theme-default.light .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.theme-default .light .mat-mdc-fab.mat-primary, .theme-default .light .mat-mdc-mini-fab.mat-primary,
.theme-default.light .mat-mdc-fab.mat-primary,
.theme-default.light .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #068d5b;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-default .light .mat-mdc-fab.mat-accent, .theme-default .light .mat-mdc-mini-fab.mat-accent,
.theme-default.light .mat-mdc-fab.mat-accent,
.theme-default.light .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-default .light .mat-mdc-fab.mat-warn, .theme-default .light .mat-mdc-mini-fab.mat-warn,
.theme-default.light .mat-mdc-fab.mat-warn,
.theme-default.light .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-default .light .mat-mdc-fab[disabled][disabled], .theme-default .light .mat-mdc-mini-fab[disabled][disabled],
.theme-default.light .mat-mdc-fab[disabled][disabled],
.theme-default.light .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.theme-default .light .mat-mdc-snack-bar-container,
.theme-default.light .mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #1e293b;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
}
.theme-default .light .mdc-data-table,
.theme-default.light .mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-default .light .mdc-data-table__row,
.theme-default.light .mdc-data-table__row {
  background-color: inherit;
}
.theme-default .light .mdc-data-table__header-cell,
.theme-default.light .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}
.theme-default .light .mdc-data-table__row--selected,
.theme-default.light .mdc-data-table__row--selected {
  background-color: rgba(6, 141, 91, 0.04);
}
.theme-default .light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.theme-default .light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.theme-default .light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing,
.theme-default.light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.theme-default.light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.theme-default.light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-default .light .mdc-data-table__cell,
.theme-default .light .mdc-data-table__header-cell,
.theme-default.light .mdc-data-table__cell,
.theme-default.light .mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.theme-default .light .mdc-data-table__pagination,
.theme-default.light .mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.theme-default .light .mdc-data-table__row:not(.mdc-data-table__row--selected):hover,
.theme-default.light .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.theme-default .light .mdc-data-table__header-cell,
.theme-default.light .mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}
.theme-default .light .mdc-data-table__pagination-total,
.theme-default .light .mdc-data-table__pagination-rows-per-page-label,
.theme-default .light .mdc-data-table__cell,
.theme-default.light .mdc-data-table__pagination-total,
.theme-default.light .mdc-data-table__pagination-rows-per-page-label,
.theme-default.light .mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}
[dir=rtl] .theme-default .light .mdc-data-table__pagination-button .mdc-button__icon, [dir=rtl] .theme-default.light .mdc-data-table__pagination-button .mdc-button__icon, .theme-default .light .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl], .theme-default.light .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-default .light .mat-mdc-table,
.theme-default.light .mat-mdc-table {
  background: white;
}
.theme-default .light .mat-mdc-progress-spinner,
.theme-default.light .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #068d5b;
}
.theme-default .light .mat-mdc-progress-spinner.mat-accent,
.theme-default.light .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-default .light .mat-mdc-progress-spinner.mat-warn,
.theme-default.light .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-default .light .mat-badge-content,
.theme-default.light .mat-badge-content {
  color: white;
  background: #068d5b;
}
.cdk-high-contrast-active .theme-default .light .mat-badge-content, .cdk-high-contrast-active .theme-default.light .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.theme-default .light .mat-badge-accent .mat-badge-content,
.theme-default.light .mat-badge-accent .mat-badge-content {
  background: #1e293b;
  color: white;
}
.theme-default .light .mat-badge-warn .mat-badge-content,
.theme-default.light .mat-badge-warn .mat-badge-content {
  color: white;
  background: #dc2626;
}
.theme-default .light .mat-badge-disabled .mat-badge-content,
.theme-default.light .mat-badge-disabled .mat-badge-content {
  background: #94a3b8;
  color: #94a3b8;
}
.theme-default .light .mat-bottom-sheet-container,
.theme-default.light .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: #1e293b;
}
.theme-default .light .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-default .light .mat-button-toggle-group:not([class*=mat-elevation-z]),
.theme-default.light .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-default.light .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-default .light .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]),
.theme-default.light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-default.light .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.theme-default .light .mat-button-toggle,
.theme-default.light .mat-button-toggle {
  color: #94a3b8;
}
.theme-default .light .mat-button-toggle .mat-button-toggle-focus-overlay,
.theme-default.light .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: #64748b;
}
.theme-default .light .mat-button-toggle-appearance-standard,
.theme-default.light .mat-button-toggle-appearance-standard {
  color: #1e293b;
  background: white;
}
.theme-default .light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay,
.theme-default.light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: #64748b;
}
.theme-default .light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
.theme-default.light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e2e8f0;
}
.theme-default .light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
.theme-default.light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e2e8f0;
}
.theme-default .light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle,
.theme-default.light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e2e8f0;
}
.theme-default .light .mat-button-toggle-checked,
.theme-default.light .mat-button-toggle-checked {
  background-color: #e2e8f0;
  color: #64748b;
}
.theme-default .light .mat-button-toggle-checked.mat-button-toggle-appearance-standard,
.theme-default.light .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: #1e293b;
}
.theme-default .light .mat-button-toggle-disabled,
.theme-default.light .mat-button-toggle-disabled {
  color: #94a3b8;
  background-color: #cbd5e1;
}
.theme-default .light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard,
.theme-default.light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.theme-default .light .mat-button-toggle-disabled.mat-button-toggle-checked,
.theme-default.light .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #e2e8f0;
}
.theme-default .light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-default .light .mat-button-toggle-group-appearance-standard,
.theme-default.light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-default.light .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e2e8f0;
}
.theme-default .light .mat-calendar-arrow,
.theme-default.light .mat-calendar-arrow {
  fill: #64748b;
}
.theme-default .light .mat-datepicker-toggle,
.theme-default .light .mat-datepicker-content .mat-calendar-next-button,
.theme-default .light .mat-datepicker-content .mat-calendar-previous-button,
.theme-default.light .mat-datepicker-toggle,
.theme-default.light .mat-datepicker-content .mat-calendar-next-button,
.theme-default.light .mat-datepicker-content .mat-calendar-previous-button {
  color: #64748b;
}
.theme-default .light .mat-calendar-table-header-divider::after,
.theme-default.light .mat-calendar-table-header-divider::after {
  background: #e2e8f0;
}
.theme-default .light .mat-calendar-table-header,
.theme-default .light .mat-calendar-body-label,
.theme-default.light .mat-calendar-table-header,
.theme-default.light .mat-calendar-body-label {
  color: #64748b;
}
.theme-default .light .mat-calendar-body-cell-content,
.theme-default .light .mat-date-range-input-separator,
.theme-default.light .mat-calendar-body-cell-content,
.theme-default.light .mat-date-range-input-separator {
  color: #1e293b;
  border-color: transparent;
}
.theme-default .light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-default.light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: #94a3b8;
}
.theme-default .light .mat-form-field-disabled .mat-date-range-input-separator,
.theme-default.light .mat-form-field-disabled .mat-date-range-input-separator {
  color: #94a3b8;
}
.theme-default .light .mat-calendar-body-in-preview,
.theme-default.light .mat-calendar-body-in-preview {
  color: #e2e8f0;
}
.theme-default .light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-default.light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #94a3b8;
}
.theme-default .light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-default.light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(148, 163, 184, 0.8);
}
.theme-default .light .mat-calendar-body-in-range::before,
.theme-default.light .mat-calendar-body-in-range::before {
  background: rgba(6, 141, 91, 0.2);
}
.theme-default .light .mat-calendar-body-comparison-identical,
.theme-default .light .mat-calendar-body-in-comparison-range::before,
.theme-default.light .mat-calendar-body-comparison-identical,
.theme-default.light .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-default .light .mat-calendar-body-comparison-bridge-start::before,
.theme-default .light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-default.light .mat-calendar-body-comparison-bridge-start::before,
.theme-default.light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(6, 141, 91, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-default .light .mat-calendar-body-comparison-bridge-end::before,
.theme-default .light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-default.light .mat-calendar-body-comparison-bridge-end::before,
.theme-default.light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(6, 141, 91, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-default .light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-default .light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-default.light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-default.light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-default .light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-default .light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-default.light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-default.light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-default .light .mat-calendar-body-selected,
.theme-default.light .mat-calendar-body-selected {
  background-color: #068d5b;
  color: white;
}
.theme-default .light .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-default.light .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(6, 141, 91, 0.4);
}
.theme-default .light .mat-calendar-body-today.mat-calendar-body-selected,
.theme-default.light .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-default .light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-default .light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-default.light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-default.light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(6, 141, 91, 0.3);
}
@media (hover: hover) {
  .theme-default .light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-default.light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(6, 141, 91, 0.3);
  }
}
.theme-default .light .mat-datepicker-content,
.theme-default.light .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: #1e293b;
}
.theme-default .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before,
.theme-default.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(30, 41, 59, 0.2);
}
.theme-default .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-default .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before,
.theme-default.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-default.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-default .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-default .light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-default.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-default.light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(30, 41, 59, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-default .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-default .light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-default.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-default.light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(30, 41, 59, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-default .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-default .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-default.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-default.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-default .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-default .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-default.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-default.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-default .light .mat-datepicker-content.mat-accent .mat-calendar-body-selected,
.theme-default.light .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #1e293b;
  color: white;
}
.theme-default .light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-default.light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(30, 41, 59, 0.4);
}
.theme-default .light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected,
.theme-default.light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-default .light .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-default .light .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-default.light .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-default.light .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(30, 41, 59, 0.3);
}
@media (hover: hover) {
  .theme-default .light .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-default.light .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(30, 41, 59, 0.3);
  }
}
.theme-default .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before,
.theme-default.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(220, 38, 38, 0.2);
}
.theme-default .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-default .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before,
.theme-default.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-default.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-default .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-default .light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-default.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-default.light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(220, 38, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-default .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-default .light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-default.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-default.light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(220, 38, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-default .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-default .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-default.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-default.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-default .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-default .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-default.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-default.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-default .light .mat-datepicker-content.mat-warn .mat-calendar-body-selected,
.theme-default.light .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #dc2626;
  color: white;
}
.theme-default .light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-default.light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(220, 38, 38, 0.4);
}
.theme-default .light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected,
.theme-default.light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-default .light .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-default .light .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-default.light .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-default.light .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(220, 38, 38, 0.3);
}
@media (hover: hover) {
  .theme-default .light .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-default.light .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(220, 38, 38, 0.3);
  }
}
.theme-default .light .mat-datepicker-content-touch,
.theme-default.light .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-datepicker-toggle-active,
.theme-default.light .mat-datepicker-toggle-active {
  color: #068d5b;
}
.theme-default .light .mat-datepicker-toggle-active.mat-accent,
.theme-default.light .mat-datepicker-toggle-active.mat-accent {
  color: #1e293b;
}
.theme-default .light .mat-datepicker-toggle-active.mat-warn,
.theme-default.light .mat-datepicker-toggle-active.mat-warn {
  color: #dc2626;
}
.theme-default .light .mat-date-range-input-inner[disabled],
.theme-default.light .mat-date-range-input-inner[disabled] {
  color: #94a3b8;
}
.theme-default .light .mat-divider,
.theme-default.light .mat-divider {
  border-top-color: #e2e8f0;
}
.theme-default .light .mat-divider-vertical,
.theme-default.light .mat-divider-vertical {
  border-right-color: #e2e8f0;
}
.theme-default .light .mat-expansion-panel,
.theme-default.light .mat-expansion-panel {
  background: white;
  color: #1e293b;
}
.theme-default .light .mat-expansion-panel:not([class*=mat-elevation-z]),
.theme-default.light .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-action-row,
.theme-default.light .mat-action-row {
  border-top-color: #e2e8f0;
}
.theme-default .light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .theme-default .light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .theme-default .light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]),
.theme-default.light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
.theme-default.light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
.theme-default.light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(148, 163, 184, 0.12);
}
@media (hover: none) {
  .theme-default .light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover,
  .theme-default.light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.theme-default .light .mat-expansion-panel-header-title,
.theme-default.light .mat-expansion-panel-header-title {
  color: #1e293b;
}
.theme-default .light .mat-expansion-panel-header-description,
.theme-default .light .mat-expansion-indicator::after,
.theme-default.light .mat-expansion-panel-header-description,
.theme-default.light .mat-expansion-indicator::after {
  color: #64748b;
}
.theme-default .light .mat-expansion-panel-header[aria-disabled=true],
.theme-default.light .mat-expansion-panel-header[aria-disabled=true] {
  color: #94a3b8;
}
.theme-default .light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-default .light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description,
.theme-default.light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-default.light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.theme-default .light .mat-icon.mat-primary,
.theme-default.light .mat-icon.mat-primary {
  color: #068d5b;
}
.theme-default .light .mat-icon.mat-accent,
.theme-default.light .mat-icon.mat-accent {
  color: #1e293b;
}
.theme-default .light .mat-icon.mat-warn,
.theme-default.light .mat-icon.mat-warn {
  color: #dc2626;
}
.theme-default .light .mat-drawer-container,
.theme-default.light .mat-drawer-container {
  background-color: #f1f5f9;
  color: #1e293b;
}
.theme-default .light .mat-drawer,
.theme-default.light .mat-drawer {
  background-color: white;
  color: #1e293b;
}
.theme-default .light .mat-drawer.mat-drawer-push,
.theme-default.light .mat-drawer.mat-drawer-push {
  background-color: white;
}
.theme-default .light .mat-drawer:not(.mat-drawer-side),
.theme-default.light .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-drawer-side,
.theme-default.light .mat-drawer-side {
  border-right: solid 1px #e2e8f0;
}
.theme-default .light .mat-drawer-side.mat-drawer-end,
.theme-default.light .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px #e2e8f0;
  border-right: none;
}
.theme-default .light [dir=rtl] .mat-drawer-side,
.theme-default.light [dir=rtl] .mat-drawer-side {
  border-left: solid 1px #e2e8f0;
  border-right: none;
}
.theme-default .light [dir=rtl] .mat-drawer-side.mat-drawer-end,
.theme-default.light [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px #e2e8f0;
}
.theme-default .light .mat-drawer-backdrop.mat-drawer-shown,
.theme-default.light .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.theme-default .light .mat-step-header.cdk-keyboard-focused, .theme-default .light .mat-step-header.cdk-program-focused, .theme-default .light .mat-step-header:hover:not([aria-disabled]), .theme-default .light .mat-step-header:hover[aria-disabled=false],
.theme-default.light .mat-step-header.cdk-keyboard-focused,
.theme-default.light .mat-step-header.cdk-program-focused,
.theme-default.light .mat-step-header:hover:not([aria-disabled]),
.theme-default.light .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(148, 163, 184, 0.12);
}
.theme-default .light .mat-step-header:hover[aria-disabled=true],
.theme-default.light .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .theme-default .light .mat-step-header:hover,
  .theme-default.light .mat-step-header:hover {
    background: none;
  }
}
.theme-default .light .mat-step-header .mat-step-label,
.theme-default .light .mat-step-header .mat-step-optional,
.theme-default.light .mat-step-header .mat-step-label,
.theme-default.light .mat-step-header .mat-step-optional {
  color: #64748b;
}
.theme-default .light .mat-step-header .mat-step-icon,
.theme-default.light .mat-step-header .mat-step-icon {
  background-color: #64748b;
  color: white;
}
.theme-default .light .mat-step-header .mat-step-icon-selected,
.theme-default .light .mat-step-header .mat-step-icon-state-done,
.theme-default .light .mat-step-header .mat-step-icon-state-edit,
.theme-default.light .mat-step-header .mat-step-icon-selected,
.theme-default.light .mat-step-header .mat-step-icon-state-done,
.theme-default.light .mat-step-header .mat-step-icon-state-edit {
  background-color: #068d5b;
  color: white;
}
.theme-default .light .mat-step-header.mat-accent .mat-step-icon,
.theme-default.light .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.theme-default .light .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-default .light .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-default .light .mat-step-header.mat-accent .mat-step-icon-state-edit,
.theme-default.light .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-default.light .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-default.light .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #1e293b;
  color: white;
}
.theme-default .light .mat-step-header.mat-warn .mat-step-icon,
.theme-default.light .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.theme-default .light .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-default .light .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-default .light .mat-step-header.mat-warn .mat-step-icon-state-edit,
.theme-default.light .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-default.light .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-default.light .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #dc2626;
  color: white;
}
.theme-default .light .mat-step-header .mat-step-icon-state-error,
.theme-default.light .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #dc2626;
}
.theme-default .light .mat-step-header .mat-step-label.mat-step-label-active,
.theme-default.light .mat-step-header .mat-step-label.mat-step-label-active {
  color: #1e293b;
}
.theme-default .light .mat-step-header .mat-step-label.mat-step-label-error,
.theme-default.light .mat-step-header .mat-step-label.mat-step-label-error {
  color: #dc2626;
}
.theme-default .light .mat-stepper-horizontal, .theme-default .light .mat-stepper-vertical,
.theme-default.light .mat-stepper-horizontal,
.theme-default.light .mat-stepper-vertical {
  background-color: white;
}
.theme-default .light .mat-stepper-vertical-line::before,
.theme-default.light .mat-stepper-vertical-line::before {
  border-left-color: #e2e8f0;
}
.theme-default .light .mat-horizontal-stepper-header::before,
.theme-default .light .mat-horizontal-stepper-header::after,
.theme-default .light .mat-stepper-horizontal-line,
.theme-default.light .mat-horizontal-stepper-header::before,
.theme-default.light .mat-horizontal-stepper-header::after,
.theme-default.light .mat-stepper-horizontal-line {
  border-top-color: #e2e8f0;
}
.theme-default .light .mat-sort-header-arrow,
.theme-default.light .mat-sort-header-arrow {
  color: #64748b;
}
.theme-default .light .mat-toolbar,
.theme-default.light .mat-toolbar {
  background: white;
  color: #1e293b;
}
.theme-default .light .mat-toolbar.mat-primary,
.theme-default.light .mat-toolbar.mat-primary {
  background: #068d5b;
  color: white;
}
.theme-default .light .mat-toolbar.mat-accent,
.theme-default.light .mat-toolbar.mat-accent {
  background: #1e293b;
  color: white;
}
.theme-default .light .mat-toolbar.mat-warn,
.theme-default.light .mat-toolbar.mat-warn {
  background: #dc2626;
  color: white;
}
.theme-default .light .mat-toolbar .mat-form-field-underline,
.theme-default .light .mat-toolbar .mat-form-field-ripple,
.theme-default .light .mat-toolbar .mat-focused .mat-form-field-ripple,
.theme-default.light .mat-toolbar .mat-form-field-underline,
.theme-default.light .mat-toolbar .mat-form-field-ripple,
.theme-default.light .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.theme-default .light .mat-toolbar .mat-form-field-label,
.theme-default .light .mat-toolbar .mat-focused .mat-form-field-label,
.theme-default .light .mat-toolbar .mat-select-value,
.theme-default .light .mat-toolbar .mat-select-arrow,
.theme-default .light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow,
.theme-default.light .mat-toolbar .mat-form-field-label,
.theme-default.light .mat-toolbar .mat-focused .mat-form-field-label,
.theme-default.light .mat-toolbar .mat-select-value,
.theme-default.light .mat-toolbar .mat-select-arrow,
.theme-default.light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.theme-default .light .mat-toolbar .mat-input-element,
.theme-default.light .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.theme-default .light .mat-tree,
.theme-default.light .mat-tree {
  background: white;
}
.theme-default .light .mat-tree-node,
.theme-default .light .mat-nested-tree-node,
.theme-default.light .mat-tree-node,
.theme-default.light .mat-nested-tree-node {
  color: #1e293b;
}

.theme-default .dark .mat-ripple-element,
.theme-default.dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-default .dark .mat-mdc-option,
.theme-default.dark .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-default .dark .mat-mdc-option:hover:not(.mdc-list-item--disabled), .theme-default .dark .mat-mdc-option:focus:not(.mdc-list-item--disabled), .theme-default .dark .mat-mdc-option.mat-mdc-option-active, .theme-default .dark .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled),
.theme-default.dark .mat-mdc-option:hover:not(.mdc-list-item--disabled),
.theme-default.dark .mat-mdc-option:focus:not(.mdc-list-item--disabled),
.theme-default.dark .mat-mdc-option.mat-mdc-option-active,
.theme-default.dark .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(255, 255, 255, 0.08);
}
.theme-default .dark .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-default.dark .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #068d5b);
}
.theme-default .dark .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-default.dark .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #1e293b);
}
.theme-default .dark .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-default.dark .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mat-mdc-optgroup-label,
.theme-default.dark .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-default .dark .mat-pseudo-checkbox,
.theme-default.dark .mat-pseudo-checkbox {
  color: #94a3b8;
}
.theme-default .dark .mat-pseudo-checkbox::after,
.theme-default.dark .mat-pseudo-checkbox::after {
  color: #0f172a;
}
.theme-default .dark .mat-pseudo-checkbox-disabled,
.theme-default.dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.theme-default .dark .mat-primary .mat-pseudo-checkbox-checked,
.theme-default .dark .mat-primary .mat-pseudo-checkbox-indeterminate,
.theme-default.dark .mat-primary .mat-pseudo-checkbox-checked,
.theme-default.dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #068d5b;
}
.theme-default .dark .mat-pseudo-checkbox-checked,
.theme-default .dark .mat-pseudo-checkbox-indeterminate,
.theme-default .dark .mat-accent .mat-pseudo-checkbox-checked,
.theme-default .dark .mat-accent .mat-pseudo-checkbox-indeterminate,
.theme-default.dark .mat-pseudo-checkbox-checked,
.theme-default.dark .mat-pseudo-checkbox-indeterminate,
.theme-default.dark .mat-accent .mat-pseudo-checkbox-checked,
.theme-default.dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #1e293b;
}
.theme-default .dark .mat-warn .mat-pseudo-checkbox-checked,
.theme-default .dark .mat-warn .mat-pseudo-checkbox-indeterminate,
.theme-default.dark .mat-warn .mat-pseudo-checkbox-checked,
.theme-default.dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #dc2626;
}
.theme-default .dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-default .dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled,
.theme-default.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-default.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.theme-default .dark .mat-app-background, .theme-default .dark.mat-app-background,
.theme-default.dark .mat-app-background,
.theme-default.dark.mat-app-background {
  background-color: #0f172a;
  color: white;
}
.theme-default .dark .mat-elevation-z0, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-default.dark .mat-elevation-z0,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z1, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-default.dark .mat-elevation-z1,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z2, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-default.dark .mat-elevation-z2,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z3, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-default.dark .mat-elevation-z3,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z4, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-default.dark .mat-elevation-z4,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z5, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-default.dark .mat-elevation-z5,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z6, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-default.dark .mat-elevation-z6,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z7, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-default.dark .mat-elevation-z7,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z8, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-default.dark .mat-elevation-z8,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z9, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-default.dark .mat-elevation-z9,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z10, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-default.dark .mat-elevation-z10,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z11, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-default.dark .mat-elevation-z11,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z12, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-default.dark .mat-elevation-z12,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z13, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-default.dark .mat-elevation-z13,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z14, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-default.dark .mat-elevation-z14,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z15, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-default.dark .mat-elevation-z15,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z16, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-default.dark .mat-elevation-z16,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z17, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-default.dark .mat-elevation-z17,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z18, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-default.dark .mat-elevation-z18,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z19, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-default.dark .mat-elevation-z19,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z20, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-default.dark .mat-elevation-z20,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z21, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-default.dark .mat-elevation-z21,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z22, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-default.dark .mat-elevation-z22,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z23, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-default.dark .mat-elevation-z23,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z24, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-default.dark .mat-elevation-z24,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-default .dark .mat-mdc-card,
.theme-default.dark .mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #1e293b;
}
.theme-default .dark .mat-mdc-card-outlined,
.theme-default.dark .mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #394353;
}
.theme-default .dark .mat-mdc-card-subtitle,
.theme-default.dark .mat-mdc-card-subtitle {
  color: #94a3b8;
}
.theme-default .dark .mat-mdc-progress-bar,
.theme-default.dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #068d5b;
}
.theme-default .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.theme-default.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(6, 141, 91, 0.25)'/%3E%3C/svg%3E");
}
.theme-default .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.theme-default.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(6, 141, 91, 0.25);
}
.theme-default .dark .mat-mdc-progress-bar.mat-accent,
.theme-default.dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
}
.theme-default .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.theme-default.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(30, 41, 59, 0.25)'/%3E%3C/svg%3E");
}
.theme-default .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.theme-default.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(30, 41, 59, 0.25);
}
.theme-default .dark .mat-mdc-progress-bar.mat-warn,
.theme-default.dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
}
.theme-default .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.theme-default.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(220, 38, 38, 0.25)'/%3E%3C/svg%3E");
}
.theme-default .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.theme-default.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(220, 38, 38, 0.25);
}
.theme-default .dark .mat-mdc-tooltip,
.theme-default.dark .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.theme-default .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-default.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 255, 255, 0.6);
}
.theme-default .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input,
.theme-default.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.87);
}
@media all {
  .theme-default .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder,
  .theme-default.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}
@media all {
  .theme-default .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder,
  .theme-default.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}
.theme-default .dark .mdc-text-field .mdc-text-field__input,
.theme-default.dark .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #068d5b);
}
.theme-default .dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text,
.theme-default.dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.theme-default .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.theme-default .dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter,
.theme-default.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.theme-default.dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.theme-default .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading,
.theme-default.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.theme-default .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-default.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.theme-default .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix,
.theme-default.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-default .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix,
.theme-default.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-default .dark .mdc-text-field--filled .mdc-text-field__ripple::before,
.theme-default .dark .mdc-text-field--filled .mdc-text-field__ripple::after,
.theme-default.dark .mdc-text-field--filled .mdc-text-field__ripple::before,
.theme-default.dark .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(255, 255, 255, 0.87));
}
.theme-default .dark .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .theme-default .dark .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before,
.theme-default.dark .mdc-text-field--filled:hover .mdc-text-field__ripple::before,
.theme-default.dark .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.theme-default .dark .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .theme-default .dark .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before,
.theme-default.dark .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before,
.theme-default.dark .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.theme-default .dark .mdc-text-field--filled:not(.mdc-text-field--disabled),
.theme-default.dark .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #273243;
}
.theme-default .dark .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-default.dark .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.42);
}
.theme-default .dark .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-default.dark .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.87);
}
.theme-default .dark .mdc-text-field--filled .mdc-line-ripple::after,
.theme-default.dark .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #068d5b);
}
.theme-default .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-default .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-default .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-default.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-default.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-default.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.38);
}
.theme-default .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-default .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-default .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-default.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-default.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-default.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.87);
}
.theme-default .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-default.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #068d5b);
}
[dir=rtl] .theme-default .dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon, [dir=rtl] .theme-default.dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .theme-default .dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl], .theme-default.dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-default .dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, [dir=rtl] .theme-default.dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .theme-default .dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl], .theme-default.dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-default .dark .mdc-text-field--outlined .mdc-text-field__ripple::before,
.theme-default .dark .mdc-text-field--outlined .mdc-text-field__ripple::after,
.theme-default.dark .mdc-text-field--outlined .mdc-text-field__ripple::before,
.theme-default.dark .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .theme-default .dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined, [dir=rtl] .theme-default.dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .theme-default .dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl], .theme-default.dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-default .dark .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-default.dark .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(6, 141, 91, 0.87);
}
.theme-default .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-default.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-default.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-default.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-default.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mdc-text-field--invalid .mdc-text-field__input,
.theme-default.dark .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-default.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-default.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-default .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-default .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-default.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-default.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-default.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-default .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-default .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-default.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-default.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-default.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-default.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mdc-text-field--disabled .mdc-text-field__input,
.theme-default.dark .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.38);
}
@media all {
  .theme-default .dark .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  .theme-default.dark .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.38);
  }
}
@media all {
  .theme-default .dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  .theme-default.dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.38);
  }
}
.theme-default .dark .mdc-text-field--disabled .mdc-floating-label,
.theme-default.dark .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(255, 255, 255, 0.38);
}
.theme-default .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
.theme-default.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.theme-default .dark .mdc-text-field--disabled .mdc-text-field-character-counter,
.theme-default .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
.theme-default.dark .mdc-text-field--disabled .mdc-text-field-character-counter,
.theme-default.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.theme-default .dark .mdc-text-field--disabled .mdc-text-field__icon--leading,
.theme-default.dark .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.theme-default .dark .mdc-text-field--disabled .mdc-text-field__icon--trailing,
.theme-default.dark .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.theme-default .dark .mdc-text-field--disabled .mdc-text-field__affix--prefix,
.theme-default.dark .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-default .dark .mdc-text-field--disabled .mdc-text-field__affix--suffix,
.theme-default.dark .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-default .dark .mdc-text-field--disabled .mdc-line-ripple::before,
.theme-default.dark .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.06);
}
.theme-default .dark .mdc-text-field--disabled .mdc-notched-outline__leading,
.theme-default .dark .mdc-text-field--disabled .mdc-notched-outline__notch,
.theme-default .dark .mdc-text-field--disabled .mdc-notched-outline__trailing,
.theme-default.dark .mdc-text-field--disabled .mdc-notched-outline__leading,
.theme-default.dark .mdc-text-field--disabled .mdc-notched-outline__notch,
.theme-default.dark .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .dark .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  .theme-default.dark .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  .theme-default.dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .dark .mdc-text-field--disabled .mdc-floating-label,
  .theme-default.dark .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
  .theme-default.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .dark .mdc-text-field--disabled .mdc-text-field-character-counter,
  .theme-default .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
  .theme-default.dark .mdc-text-field--disabled .mdc-text-field-character-counter,
  .theme-default.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .dark .mdc-text-field--disabled .mdc-text-field__icon--leading,
  .theme-default.dark .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .dark .mdc-text-field--disabled .mdc-text-field__icon--trailing,
  .theme-default.dark .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .dark .mdc-text-field--disabled .mdc-text-field__affix--prefix,
  .theme-default.dark .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .dark .mdc-text-field--disabled .mdc-text-field__affix--suffix,
  .theme-default.dark .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .dark .mdc-text-field--disabled .mdc-line-ripple::before,
  .theme-default.dark .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .dark .mdc-text-field--disabled .mdc-notched-outline__leading,
  .theme-default .dark .mdc-text-field--disabled .mdc-notched-outline__notch,
  .theme-default .dark .mdc-text-field--disabled .mdc-notched-outline__trailing,
  .theme-default.dark .mdc-text-field--disabled .mdc-notched-outline__leading,
  .theme-default.dark .mdc-text-field--disabled .mdc-notched-outline__notch,
  .theme-default.dark .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.theme-default .dark .mdc-text-field--disabled.mdc-text-field--filled,
.theme-default.dark .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #232d3f;
}
[dir=rtl] .theme-default .dark .mdc-text-field--end-aligned .mdc-text-field__input, [dir=rtl] .theme-default.dark .mdc-text-field--end-aligned .mdc-text-field__input, .theme-default .dark .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl], .theme-default.dark .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-default .dark .mdc-floating-label--required, [dir=rtl] .theme-default.dark .mdc-floating-label--required, .theme-default .dark .mdc-floating-label--required[dir=rtl], .theme-default.dark .mdc-floating-label--required[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-default .dark .mat-mdc-form-field-error,
.theme-default.dark .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mat-mdc-form-field-focus-overlay,
.theme-default.dark .mat-mdc-form-field-focus-overlay {
  background-color: rgba(255, 255, 255, 0.87);
}
.theme-default .dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.theme-default.dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.08;
}
.theme-default .dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.theme-default.dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.24;
}
.theme-default .dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option,
.theme-default.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.theme-default .dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled,
.theme-default.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.theme-default .dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.theme-default.dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.54);
}
.theme-default .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.theme-default.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(6, 141, 91, 0.87);
}
.theme-default .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.theme-default.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(30, 41, 59, 0.87);
}
.theme-default .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.theme-default.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(220, 38, 38, 0.87);
}
.theme-default .dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.theme-default.dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.38);
}
.theme-default .dark .mat-mdc-form-field.mat-accent .mdc-text-field__input,
.theme-default.dark .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-default .dark .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-default.dark .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-default .dark .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-default.dark .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(30, 41, 59, 0.87);
}
.theme-default .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-default.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-default.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-default.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-default.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input,
.theme-default.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-default.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-default.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-default .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-default .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-default.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-default.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-default.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-default .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-default .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-default.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-default.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-default.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-default.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default .dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default .dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-default.dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default.dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default.dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-default .dark .mat-mdc-form-field.mat-warn .mdc-text-field__input,
.theme-default.dark .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-default.dark .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-default.dark .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(220, 38, 38, 0.87);
}
.theme-default .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-default.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-default.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-default.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-default.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input,
.theme-default.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-default.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-default.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-default .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-default .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-default.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-default.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-default.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-default .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-default .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-default.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-default.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-default.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-default.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default .dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default .dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-default.dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default.dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default.dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-default .dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.theme-default.dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.theme-default .dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.theme-default.dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.theme-default .dark .mdc-menu-surface,
.theme-default.dark .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #1e293b);
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mdc-list-item__primary-text,
.theme-default.dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-default .dark .mdc-list-item__secondary-text,
.theme-default.dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.theme-default .dark .mdc-list-item__overline-text,
.theme-default.dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-default .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-default.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-default .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-default.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.theme-default .dark .mdc-list-item__end,
.theme-default.dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-default .dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-default .dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-default .dark .mdc-list-item--disabled .mdc-list-item__end,
.theme-default.dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-default.dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-default.dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-default .dark .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-default.dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-default.dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-default.dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-default.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-default.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-default .dark .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-default.dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-default.dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #068d5b);
}
.theme-default .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #068d5b);
}
.theme-default .dark .mdc-deprecated-list-group__subheader,
.theme-default.dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-default .dark .mdc-list-divider::after,
.theme-default.dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-default .dark .mdc-list-divider,
.theme-default.dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-default .dark .mat-mdc-select-value,
.theme-default.dark .mat-mdc-select-value {
  color: rgba(255, 255, 255, 0.87);
}
.theme-default .dark .mat-mdc-select-placeholder,
.theme-default.dark .mat-mdc-select-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.theme-default .dark .mat-mdc-select-disabled .mat-mdc-select-value,
.theme-default.dark .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(255, 255, 255, 0.38);
}
.theme-default .dark .mat-mdc-select-arrow,
.theme-default.dark .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.54);
}
.theme-default .dark .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow,
.theme-default.dark .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(6, 141, 91, 0.87);
}
.theme-default .dark .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow,
.theme-default.dark .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(30, 41, 59, 0.87);
}
.theme-default .dark .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow,
.theme-default.dark .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(220, 38, 38, 0.87);
}
.theme-default .dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow,
.theme-default.dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(220, 38, 38, 0.87);
}
.theme-default .dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow,
.theme-default.dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.38);
}
.theme-default .dark .mdc-menu-surface,
.theme-default.dark .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #1e293b);
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mdc-list-item__primary-text,
.theme-default.dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-default .dark .mdc-list-item__secondary-text,
.theme-default.dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.theme-default .dark .mdc-list-item__overline-text,
.theme-default.dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-default .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-default.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-default .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-default.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.theme-default .dark .mdc-list-item__end,
.theme-default.dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-default .dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-default .dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-default .dark .mdc-list-item--disabled .mdc-list-item__end,
.theme-default.dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-default.dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-default.dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-default .dark .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-default.dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-default.dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-default.dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-default.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-default.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-default .dark .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-default.dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-default.dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #068d5b);
}
.theme-default .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #068d5b);
}
.theme-default .dark .mdc-deprecated-list-group__subheader,
.theme-default.dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-default .dark .mdc-list-divider::after,
.theme-default.dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-default .dark .mdc-list-divider,
.theme-default.dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-default .dark .mat-mdc-dialog-container,
.theme-default.dark .mat-mdc-dialog-container {
  --mdc-dialog-container-color: #1e293b;
  --mdc-dialog-with-divider-divider-color: rgba(255, 255, 255, 0.12);
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
}
.theme-default .dark .mat-mdc-standard-chip,
.theme-default.dark .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #394353;
  --mdc-chip-elevated-disabled-container-color: #394353;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
}
.theme-default .dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .theme-default .dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted,
.theme-default.dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected,
.theme-default.dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #068d5b;
  --mdc-chip-elevated-disabled-container-color: #068d5b;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-default .dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .theme-default .dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted,
.theme-default.dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected,
.theme-default.dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-default .dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .theme-default .dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted,
.theme-default.dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected,
.theme-default.dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-default .dark .mat-mdc-chip-focus-overlay,
.theme-default.dark .mat-mdc-chip-focus-overlay {
  background: #FFFFFF;
}
.theme-default .dark .mat-mdc-slide-toggle,
.theme-default.dark .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-unselected-icon-color: #212121;
}
.theme-default .dark .mat-mdc-slide-toggle .mdc-form-field,
.theme-default.dark .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-default .dark .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.theme-default.dark .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: #475569;
}
.theme-default .dark .mat-mdc-slide-toggle.mat-primary,
.theme-default.dark .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #6fe6b0;
  --mdc-switch-selected-handle-color: #6fe6b0;
  --mdc-switch-selected-hover-state-layer-color: #6fe6b0;
  --mdc-switch-selected-pressed-state-layer-color: #6fe6b0;
  --mdc-switch-selected-focus-handle-color: #a8f2cc;
  --mdc-switch-selected-hover-handle-color: #a8f2cc;
  --mdc-switch-selected-pressed-handle-color: #a8f2cc;
  --mdc-switch-selected-focus-track-color: #068d5b;
  --mdc-switch-selected-hover-track-color: #068d5b;
  --mdc-switch-selected-pressed-track-color: #068d5b;
  --mdc-switch-selected-track-color: #068d5b;
}
.theme-default .dark .mat-mdc-slide-toggle.mat-accent,
.theme-default.dark .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #cbd5e1;
  --mdc-switch-selected-handle-color: #cbd5e1;
  --mdc-switch-selected-hover-state-layer-color: #cbd5e1;
  --mdc-switch-selected-pressed-state-layer-color: #cbd5e1;
  --mdc-switch-selected-focus-handle-color: #e2e8f0;
  --mdc-switch-selected-hover-handle-color: #e2e8f0;
  --mdc-switch-selected-pressed-handle-color: #e2e8f0;
  --mdc-switch-selected-focus-track-color: #475569;
  --mdc-switch-selected-hover-track-color: #475569;
  --mdc-switch-selected-pressed-track-color: #475569;
  --mdc-switch-selected-track-color: #475569;
}
.theme-default .dark .mat-mdc-slide-toggle.mat-warn,
.theme-default.dark .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #fca5a5;
  --mdc-switch-selected-handle-color: #fca5a5;
  --mdc-switch-selected-hover-state-layer-color: #fca5a5;
  --mdc-switch-selected-pressed-state-layer-color: #fca5a5;
  --mdc-switch-selected-focus-handle-color: #fecaca;
  --mdc-switch-selected-hover-handle-color: #fecaca;
  --mdc-switch-selected-pressed-handle-color: #fecaca;
  --mdc-switch-selected-focus-track-color: #dc2626;
  --mdc-switch-selected-hover-track-color: #dc2626;
  --mdc-switch-selected-pressed-track-color: #dc2626;
  --mdc-switch-selected-track-color: #dc2626;
}
.theme-default .dark .mat-mdc-radio-button,
.theme-default.dark .mat-mdc-radio-button {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mat-mdc-radio-ripple-color: #fff;
}
.theme-default .dark .mat-mdc-radio-button .mdc-form-field,
.theme-default.dark .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-default .dark .mat-mdc-radio-button .mdc-radio--disabled + label,
.theme-default.dark .mat-mdc-radio-button .mdc-radio--disabled + label {
  color: #475569;
}
.theme-default .dark .mat-mdc-radio-button.mat-primary,
.theme-default.dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-selected-focus-icon-color: #068d5b;
  --mdc-radio-selected-hover-icon-color: #068d5b;
  --mdc-radio-selected-icon-color: #068d5b;
  --mdc-radio-selected-pressed-icon-color: #068d5b;
  --mat-mdc-radio-checked-ripple-color: #068d5b;
}
.theme-default .dark .mat-mdc-radio-button.mat-accent,
.theme-default.dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-mdc-radio-checked-ripple-color: #1e293b;
}
.theme-default .dark .mat-mdc-radio-button.mat-warn,
.theme-default.dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-mdc-radio-checked-ripple-color: #dc2626;
}
.theme-default .dark .mat-mdc-slider,
.theme-default.dark .mat-mdc-slider {
  --mdc-slider-label-container-color: white;
  --mdc-slider-label-label-text-color: black;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mat-mdc-slider-value-indicator-opacity: 0.9;
}
.theme-default .dark .mat-mdc-slider.mat-primary,
.theme-default.dark .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #068d5b;
  --mdc-slider-focus-handle-color: #068d5b;
  --mdc-slider-hover-handle-color: #068d5b;
  --mdc-slider-active-track-color: #068d5b;
  --mdc-slider-inactive-track-color: #068d5b;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #068d5b;
  --mat-mdc-slider-ripple-color: #068d5b;
  --mat-mdc-slider-hover-ripple-color: rgba(6, 141, 91, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(6, 141, 91, 0.2);
}
.theme-default .dark .mat-mdc-slider.mat-accent,
.theme-default.dark .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mat-mdc-slider-ripple-color: #1e293b;
  --mat-mdc-slider-hover-ripple-color: rgba(30, 41, 59, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(30, 41, 59, 0.2);
}
.theme-default .dark .mat-mdc-slider.mat-warn,
.theme-default.dark .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mat-mdc-slider-ripple-color: #dc2626;
  --mat-mdc-slider-hover-ripple-color: rgba(220, 38, 38, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(220, 38, 38, 0.2);
}
.theme-default .dark .mdc-menu-surface,
.theme-default.dark .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #1e293b);
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mdc-list-item__primary-text,
.theme-default.dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-default .dark .mdc-list-item__secondary-text,
.theme-default.dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.theme-default .dark .mdc-list-item__overline-text,
.theme-default.dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-default .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-default.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-default .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-default.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.theme-default .dark .mdc-list-item__end,
.theme-default.dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-default .dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-default .dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-default .dark .mdc-list-item--disabled .mdc-list-item__end,
.theme-default.dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-default.dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-default.dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-default .dark .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-default.dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-default.dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-default.dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-default.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-default.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-default .dark .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-default.dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-default.dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #068d5b);
}
.theme-default .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #068d5b);
}
.theme-default .dark .mdc-deprecated-list-group__subheader,
.theme-default.dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-default .dark .mdc-list-divider::after,
.theme-default.dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-default .dark .mdc-list-divider,
.theme-default.dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-default .dark .mat-mdc-menu-item[disabled],
.theme-default .dark .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.theme-default .dark .mat-mdc-menu-item[disabled] .mat-icon-no-color,
.theme-default.dark .mat-mdc-menu-item[disabled],
.theme-default.dark .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.theme-default.dark .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(255, 255, 255, 0.5));
}
.theme-default .dark .mat-mdc-menu-item .mat-icon-no-color,
.theme-default .dark .mat-mdc-menu-submenu-icon,
.theme-default.dark .mat-mdc-menu-item .mat-icon-no-color,
.theme-default.dark .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-default .dark .mat-mdc-menu-item:hover:not([disabled]),
.theme-default .dark .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.theme-default .dark .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-default .dark .mat-mdc-menu-item-highlighted:not([disabled]),
.theme-default.dark .mat-mdc-menu-item:hover:not([disabled]),
.theme-default.dark .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.theme-default.dark .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-default.dark .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.08);
}
.theme-default .dark .mat-mdc-list-item-interactive::before,
.theme-default.dark .mat-mdc-list-item-interactive::before {
  background: white;
}
.theme-default .dark .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before,
.theme-default.dark .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.08;
}
.theme-default .dark .mat-mdc-list-item-interactive.mdc-list-item--selected::before,
.theme-default.dark .mat-mdc-list-item-interactive.mdc-list-item--selected::before {
  background: #068d5b;
  opacity: 0.08;
}
.theme-default .dark .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before,
.theme-default.dark .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.12;
}
.theme-default .dark .mat-mdc-list-item-interactive:focus::before,
.theme-default.dark .mat-mdc-list-item-interactive:focus::before {
  opacity: 0.24;
}
.theme-default .dark .mdc-list-item__primary-text,
.theme-default.dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-default .dark .mdc-list-item__secondary-text,
.theme-default.dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.theme-default .dark .mdc-list-item__overline-text,
.theme-default.dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-default .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-default.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-default .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-default.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.theme-default .dark .mdc-list-item__end,
.theme-default.dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-default .dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-default .dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-default .dark .mdc-list-item--disabled .mdc-list-item__end,
.theme-default.dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-default.dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-default.dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-default .dark .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-default.dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-default.dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-default.dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-default.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-default.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-default .dark .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-default.dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-default.dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #068d5b);
}
.theme-default .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #068d5b);
}
.theme-default .dark .mdc-deprecated-list-group__subheader,
.theme-default.dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-default .dark .mdc-list-divider::after,
.theme-default.dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-default .dark .mdc-list-divider,
.theme-default.dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-default .dark .mat-mdc-list-option .mdc-list-item__start, .theme-default .dark .mat-mdc-list-option .mdc-list-item__end,
.theme-default.dark .mat-mdc-list-option .mdc-list-item__start,
.theme-default.dark .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #068d5b;
  --mdc-checkbox-selected-hover-icon-color: #068d5b;
  --mdc-checkbox-selected-icon-color: #068d5b;
  --mdc-checkbox-selected-pressed-icon-color: #068d5b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-default .dark .mat-mdc-list-option.mat-accent .mdc-list-item__start, .theme-default .dark .mat-mdc-list-option.mat-accent .mdc-list-item__end,
.theme-default.dark .mat-mdc-list-option.mat-accent .mdc-list-item__start,
.theme-default.dark .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-default .dark .mat-mdc-list-option.mat-warn .mdc-list-item__start, .theme-default .dark .mat-mdc-list-option.mat-warn .mdc-list-item__end,
.theme-default.dark .mat-mdc-list-option.mat-warn .mdc-list-item__start,
.theme-default.dark .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-default .dark .mat-mdc-paginator,
.theme-default.dark .mat-mdc-paginator {
  background: #1e293b;
  color: rgba(255, 255, 255, 0.87);
}
.theme-default .dark .mat-mdc-paginator-icon,
.theme-default.dark .mat-mdc-paginator-icon {
  fill: rgba(255, 255, 255, 0.54);
}
.theme-default .dark .mat-mdc-paginator-decrement,
.theme-default .dark .mat-mdc-paginator-increment,
.theme-default.dark .mat-mdc-paginator-decrement,
.theme-default.dark .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(255, 255, 255, 0.54);
  border-right: 2px solid rgba(255, 255, 255, 0.54);
}
.theme-default .dark .mat-mdc-paginator-first,
.theme-default .dark .mat-mdc-paginator-last,
.theme-default.dark .mat-mdc-paginator-first,
.theme-default.dark .mat-mdc-paginator-last {
  border-top: 2px solid rgba(255, 255, 255, 0.54);
}
.theme-default .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.theme-default .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.theme-default .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.theme-default .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last,
.theme-default.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.theme-default.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.theme-default.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.theme-default.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-default .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon,
.theme-default.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(255, 255, 255, 0.12);
}
.theme-default .dark .mat-mdc-tab, .theme-default .dark .mat-mdc-tab-link,
.theme-default.dark .mat-mdc-tab,
.theme-default.dark .mat-mdc-tab-link {
  background-color: transparent;
}
.theme-default .dark .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label, .theme-default .dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.theme-default.dark .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.theme-default.dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  color: rgba(255, 255, 255, 0.6);
}
.theme-default .dark .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-default .dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-default.dark .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-default.dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #068d5b;
}
.theme-default .dark .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-default .dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-default.dark .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-default.dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #068d5b;
}
.theme-default .dark .mdc-tab__ripple::before,
.theme-default .dark .mat-mdc-tab .mat-ripple-element,
.theme-default .dark .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-default .dark .mat-mdc-tab-link .mat-ripple-element,
.theme-default.dark .mdc-tab__ripple::before,
.theme-default.dark .mat-mdc-tab .mat-ripple-element,
.theme-default.dark .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-default.dark .mat-mdc-tab-link .mat-ripple-element {
  background-color: #068d5b;
}
.theme-default .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-default .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-default .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-default .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-default.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-default.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-default.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-default.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #1e293b;
}
.theme-default .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-default .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-default .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-default .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-default.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-default.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-default.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-default.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #1e293b;
}
.theme-default .dark .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.theme-default .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-default .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-default .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .theme-default .dark .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.theme-default .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-default .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-default .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element,
.theme-default.dark .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.theme-default.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-default.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-default.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element,
.theme-default.dark .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.theme-default.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-default.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-default.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #1e293b;
}
.theme-default .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-default .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-default .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-default .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-default.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-default.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-default.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-default.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #dc2626;
}
.theme-default .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-default .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-default .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-default .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-default.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-default.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-default.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-default.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #dc2626;
}
.theme-default .dark .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.theme-default .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-default .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-default .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .theme-default .dark .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.theme-default .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-default .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-default .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element,
.theme-default.dark .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.theme-default.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-default.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-default.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element,
.theme-default.dark .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.theme-default.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-default.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-default.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #dc2626;
}
.theme-default .dark .mat-mdc-tab-group.mat-background-primary, .theme-default .dark .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-default.dark .mat-mdc-tab-group.mat-background-primary,
.theme-default.dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #068d5b;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-default .dark .mat-mdc-tab-group.mat-background-accent, .theme-default .dark .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-default.dark .mat-mdc-tab-group.mat-background-accent,
.theme-default.dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #1e293b;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-default .dark .mat-mdc-tab-group.mat-background-warn, .theme-default .dark .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-default.dark .mat-mdc-tab-group.mat-background-warn,
.theme-default.dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #dc2626;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-default .dark .mat-mdc-tab-header-pagination-chevron,
.theme-default.dark .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #fff);
}
.theme-default .dark .mat-mdc-checkbox .mdc-form-field,
.theme-default.dark .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-default .dark .mat-mdc-checkbox .mat-ripple-element,
.theme-default.dark .mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-default .dark .mat-mdc-checkbox .mdc-checkbox__ripple,
.theme-default.dark .mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #fff;
}
.theme-default .dark .mat-mdc-checkbox.mat-primary,
.theme-default.dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #068d5b;
  --mdc-checkbox-selected-hover-icon-color: #068d5b;
  --mdc-checkbox-selected-icon-color: #068d5b;
  --mdc-checkbox-selected-pressed-icon-color: #068d5b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-default .dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-default.dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(6, 141, 91, 0.1);
}
.theme-default .dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-default.dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #068d5b;
}
.theme-default .dark .mat-mdc-checkbox.mat-accent,
.theme-default.dark .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-default .dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-default.dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(30, 41, 59, 0.1);
}
.theme-default .dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-default.dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #1e293b;
}
.theme-default .dark .mat-mdc-checkbox.mat-warn,
.theme-default.dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-default .dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-default.dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(220, 38, 38, 0.1);
}
.theme-default .dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-default.dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #dc2626;
}
.theme-default .dark .mat-mdc-checkbox-disabled label,
.theme-default.dark .mat-mdc-checkbox-disabled label {
  color: #475569;
}
.theme-default .dark .mat-mdc-button.mat-unthemed,
.theme-default.dark .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #fff;
}
.theme-default .dark .mat-mdc-button.mat-primary,
.theme-default.dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #068d5b;
}
.theme-default .dark .mat-mdc-button.mat-accent,
.theme-default.dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
}
.theme-default .dark .mat-mdc-button.mat-warn,
.theme-default.dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
}
.theme-default .dark .mat-mdc-button[disabled][disabled],
.theme-default.dark .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-text-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.theme-default .dark .mat-mdc-unelevated-button.mat-unthemed,
.theme-default.dark .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-default .dark .mat-mdc-unelevated-button.mat-primary,
.theme-default.dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #068d5b;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-default .dark .mat-mdc-unelevated-button.mat-accent,
.theme-default.dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-default .dark .mat-mdc-unelevated-button.mat-warn,
.theme-default.dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-default .dark .mat-mdc-unelevated-button[disabled][disabled],
.theme-default.dark .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-filled-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.theme-default .dark .mat-mdc-raised-button.mat-unthemed,
.theme-default.dark .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-default .dark .mat-mdc-raised-button.mat-primary,
.theme-default.dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #068d5b;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-default .dark .mat-mdc-raised-button.mat-accent,
.theme-default.dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-default .dark .mat-mdc-raised-button.mat-warn,
.theme-default.dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-default .dark .mat-mdc-raised-button[disabled][disabled],
.theme-default.dark .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-elevation: 0;
}
.theme-default .dark .mat-mdc-outlined-button,
.theme-default.dark .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.theme-default .dark .mat-mdc-outlined-button.mat-unthemed,
.theme-default.dark .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #fff;
}
.theme-default .dark .mat-mdc-outlined-button.mat-primary,
.theme-default.dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #068d5b;
}
.theme-default .dark .mat-mdc-outlined-button.mat-accent,
.theme-default.dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
}
.theme-default .dark .mat-mdc-outlined-button.mat-warn,
.theme-default.dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
}
.theme-default .dark .mat-mdc-outlined-button[disabled][disabled],
.theme-default.dark .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
}
.theme-default .dark .mat-mdc-button, .theme-default .dark .mat-mdc-outlined-button,
.theme-default.dark .mat-mdc-button,
.theme-default.dark .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .theme-default .dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-default .dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-default .dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-default .dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-default .dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-default .dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .theme-default .dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-default .dark .mat-mdc-button.mat-primary, .theme-default .dark .mat-mdc-outlined-button.mat-primary,
.theme-default.dark .mat-mdc-button.mat-primary,
.theme-default.dark .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #068d5b;
  --mat-mdc-button-ripple-color: rgba(6, 141, 91, 0.1);
}
.theme-default .dark .mat-mdc-button.mat-accent, .theme-default .dark .mat-mdc-outlined-button.mat-accent,
.theme-default.dark .mat-mdc-button.mat-accent,
.theme-default.dark .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #1e293b;
  --mat-mdc-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-default .dark .mat-mdc-button.mat-warn, .theme-default .dark .mat-mdc-outlined-button.mat-warn,
.theme-default.dark .mat-mdc-button.mat-warn,
.theme-default.dark .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #dc2626;
  --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-default .dark .mat-mdc-raised-button, .theme-default .dark .mat-mdc-unelevated-button,
.theme-default.dark .mat-mdc-raised-button,
.theme-default.dark .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .theme-default .dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-default .dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-default .dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-default .dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-default .dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-default .dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .theme-default .dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-default .dark .mat-mdc-raised-button.mat-primary, .theme-default .dark .mat-mdc-unelevated-button.mat-primary,
.theme-default.dark .mat-mdc-raised-button.mat-primary,
.theme-default.dark .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .dark .mat-mdc-raised-button.mat-accent, .theme-default .dark .mat-mdc-unelevated-button.mat-accent,
.theme-default.dark .mat-mdc-raised-button.mat-accent,
.theme-default.dark .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .dark .mat-mdc-raised-button.mat-warn, .theme-default .dark .mat-mdc-unelevated-button.mat-warn,
.theme-default.dark .mat-mdc-raised-button.mat-warn,
.theme-default.dark .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .dark .mat-mdc-icon-button,
.theme-default.dark .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-default .dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-default .dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-default .dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-default .dark .mat-mdc-icon-button.mat-primary,
.theme-default.dark .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #068d5b;
  --mat-mdc-button-ripple-color: rgba(6, 141, 91, 0.1);
}
.theme-default .dark .mat-mdc-icon-button.mat-accent,
.theme-default.dark .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #1e293b;
  --mat-mdc-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-default .dark .mat-mdc-icon-button.mat-warn,
.theme-default.dark .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #dc2626;
  --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-default .dark .mat-mdc-icon-button.mat-primary,
.theme-default.dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #068d5b;
}
.theme-default .dark .mat-mdc-icon-button.mat-accent,
.theme-default.dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
}
.theme-default .dark .mat-mdc-icon-button.mat-warn,
.theme-default.dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
}
.theme-default .dark .mat-mdc-icon-button[disabled][disabled],
.theme-default.dark .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
}
.theme-default .dark .mat-mdc-fab, .theme-default .dark .mat-mdc-mini-fab,
.theme-default.dark .mat-mdc-fab,
.theme-default.dark .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .theme-default .dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-default .dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-default .dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-default .dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-default .dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-default .dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .theme-default .dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.theme-default.dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-default .dark .mat-mdc-fab.mat-primary, .theme-default .dark .mat-mdc-mini-fab.mat-primary,
.theme-default.dark .mat-mdc-fab.mat-primary,
.theme-default.dark .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .dark .mat-mdc-fab.mat-accent, .theme-default .dark .mat-mdc-mini-fab.mat-accent,
.theme-default.dark .mat-mdc-fab.mat-accent,
.theme-default.dark .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .dark .mat-mdc-fab.mat-warn, .theme-default .dark .mat-mdc-mini-fab.mat-warn,
.theme-default.dark .mat-mdc-fab.mat-warn,
.theme-default.dark .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .dark .mat-mdc-fab.mat-unthemed, .theme-default .dark .mat-mdc-mini-fab.mat-unthemed,
.theme-default.dark .mat-mdc-fab.mat-unthemed,
.theme-default.dark .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-default .dark .mat-mdc-fab.mat-primary, .theme-default .dark .mat-mdc-mini-fab.mat-primary,
.theme-default.dark .mat-mdc-fab.mat-primary,
.theme-default.dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #068d5b;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-default .dark .mat-mdc-fab.mat-accent, .theme-default .dark .mat-mdc-mini-fab.mat-accent,
.theme-default.dark .mat-mdc-fab.mat-accent,
.theme-default.dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-default .dark .mat-mdc-fab.mat-warn, .theme-default .dark .mat-mdc-mini-fab.mat-warn,
.theme-default.dark .mat-mdc-fab.mat-warn,
.theme-default.dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-default .dark .mat-mdc-fab[disabled][disabled], .theme-default .dark .mat-mdc-mini-fab[disabled][disabled],
.theme-default.dark .mat-mdc-fab[disabled][disabled],
.theme-default.dark .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(255, 255, 255, 0.12);
  --mdc-fab-icon-color: rgba(255, 255, 255, 0.5);
  --mat-mdc-fab-color: rgba(255, 255, 255, 0.5);
}
.theme-default .dark .mat-mdc-snack-bar-container,
.theme-default.dark .mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: currentColor;
  --mdc-snackbar-container-color: #d2d4d8;
  --mdc-snackbar-supporting-text-color: rgba(30, 41, 59, 0.87);
}
.theme-default .dark .mdc-data-table,
.theme-default.dark .mdc-data-table {
  background-color: var(--mdc-theme-surface, #1e293b);
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-default .dark .mdc-data-table__row,
.theme-default.dark .mdc-data-table__row {
  background-color: inherit;
}
.theme-default .dark .mdc-data-table__header-cell,
.theme-default.dark .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #1e293b);
}
.theme-default .dark .mdc-data-table__row--selected,
.theme-default.dark .mdc-data-table__row--selected {
  background-color: rgba(6, 141, 91, 0.04);
}
.theme-default .dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.theme-default .dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.theme-default .dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing,
.theme-default.dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.theme-default.dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.theme-default.dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-default .dark .mdc-data-table__cell,
.theme-default .dark .mdc-data-table__header-cell,
.theme-default.dark .mdc-data-table__cell,
.theme-default.dark .mdc-data-table__header-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.theme-default .dark .mdc-data-table__pagination,
.theme-default.dark .mdc-data-table__pagination {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-default .dark .mdc-data-table__row:not(.mdc-data-table__row--selected):hover,
.theme-default.dark .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(255, 255, 255, 0.04);
}
.theme-default .dark .mdc-data-table__header-cell,
.theme-default.dark .mdc-data-table__header-cell {
  color: rgba(255, 255, 255, 0.87);
}
.theme-default .dark .mdc-data-table__pagination-total,
.theme-default .dark .mdc-data-table__pagination-rows-per-page-label,
.theme-default .dark .mdc-data-table__cell,
.theme-default.dark .mdc-data-table__pagination-total,
.theme-default.dark .mdc-data-table__pagination-rows-per-page-label,
.theme-default.dark .mdc-data-table__cell {
  color: rgba(255, 255, 255, 0.87);
}
[dir=rtl] .theme-default .dark .mdc-data-table__pagination-button .mdc-button__icon, [dir=rtl] .theme-default.dark .mdc-data-table__pagination-button .mdc-button__icon, .theme-default .dark .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl], .theme-default.dark .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-default .dark .mat-mdc-table,
.theme-default.dark .mat-mdc-table {
  background: #1e293b;
}
.theme-default .dark .mat-mdc-progress-spinner,
.theme-default.dark .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #068d5b;
}
.theme-default .dark .mat-mdc-progress-spinner.mat-accent,
.theme-default.dark .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-default .dark .mat-mdc-progress-spinner.mat-warn,
.theme-default.dark .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-default .dark .mat-badge-content,
.theme-default.dark .mat-badge-content {
  color: white;
  background: #068d5b;
}
.cdk-high-contrast-active .theme-default .dark .mat-badge-content, .cdk-high-contrast-active .theme-default.dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.theme-default .dark .mat-badge-accent .mat-badge-content,
.theme-default.dark .mat-badge-accent .mat-badge-content {
  background: #1e293b;
  color: white;
}
.theme-default .dark .mat-badge-warn .mat-badge-content,
.theme-default.dark .mat-badge-warn .mat-badge-content {
  color: white;
  background: #dc2626;
}
.theme-default .dark .mat-badge-disabled .mat-badge-content,
.theme-default.dark .mat-badge-disabled .mat-badge-content {
  background: #1e293b;
  color: #475569;
}
.theme-default .dark .mat-bottom-sheet-container,
.theme-default.dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #1e293b;
  color: white;
}
.theme-default .dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-default .dark .mat-button-toggle-group:not([class*=mat-elevation-z]),
.theme-default.dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-default.dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-default .dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]),
.theme-default.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-default.dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.theme-default .dark .mat-button-toggle,
.theme-default.dark .mat-button-toggle {
  color: #64748b;
}
.theme-default .dark .mat-button-toggle .mat-button-toggle-focus-overlay,
.theme-default.dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: #e2e8f0;
}
.theme-default .dark .mat-button-toggle-appearance-standard,
.theme-default.dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #1e293b;
}
.theme-default .dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay,
.theme-default.dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: #e2e8f0;
}
.theme-default .dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
.theme-default.dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #374152;
}
.theme-default .dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
.theme-default.dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #374152;
}
.theme-default .dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle,
.theme-default.dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #374152;
}
.theme-default .dark .mat-button-toggle-checked,
.theme-default.dark .mat-button-toggle-checked {
  background-color: rgba(255, 255, 255, 0.05);
  color: #94a3b8;
}
.theme-default .dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard,
.theme-default.dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.theme-default .dark .mat-button-toggle-disabled,
.theme-default.dark .mat-button-toggle-disabled {
  color: #1e293b;
  background-color: #0f172a;
}
.theme-default .dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard,
.theme-default.dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #1e293b;
}
.theme-default .dark .mat-button-toggle-disabled.mat-button-toggle-checked,
.theme-default.dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #1e293b;
}
.theme-default .dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-default .dark .mat-button-toggle-group-appearance-standard,
.theme-default.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-default.dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #374152;
}
.theme-default .dark .mat-calendar-arrow,
.theme-default.dark .mat-calendar-arrow {
  fill: #f1f5f9;
}
.theme-default .dark .mat-datepicker-toggle,
.theme-default .dark .mat-datepicker-content .mat-calendar-next-button,
.theme-default .dark .mat-datepicker-content .mat-calendar-previous-button,
.theme-default.dark .mat-datepicker-toggle,
.theme-default.dark .mat-datepicker-content .mat-calendar-next-button,
.theme-default.dark .mat-datepicker-content .mat-calendar-previous-button {
  color: #f1f5f9;
}
.theme-default .dark .mat-calendar-table-header-divider::after,
.theme-default.dark .mat-calendar-table-header-divider::after {
  background: rgba(241, 245, 249, 0.12);
}
.theme-default .dark .mat-calendar-table-header,
.theme-default .dark .mat-calendar-body-label,
.theme-default.dark .mat-calendar-table-header,
.theme-default.dark .mat-calendar-body-label {
  color: #94a3b8;
}
.theme-default .dark .mat-calendar-body-cell-content,
.theme-default .dark .mat-date-range-input-separator,
.theme-default.dark .mat-calendar-body-cell-content,
.theme-default.dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.theme-default .dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-default.dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: #475569;
}
.theme-default .dark .mat-form-field-disabled .mat-date-range-input-separator,
.theme-default.dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: #475569;
}
.theme-default .dark .mat-calendar-body-in-preview,
.theme-default.dark .mat-calendar-body-in-preview {
  color: rgba(241, 245, 249, 0.24);
}
.theme-default .dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-default.dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #64748b;
}
.theme-default .dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-default.dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(100, 116, 139, 0.8);
}
.theme-default .dark .mat-calendar-body-in-range::before,
.theme-default.dark .mat-calendar-body-in-range::before {
  background: rgba(6, 141, 91, 0.2);
}
.theme-default .dark .mat-calendar-body-comparison-identical,
.theme-default .dark .mat-calendar-body-in-comparison-range::before,
.theme-default.dark .mat-calendar-body-comparison-identical,
.theme-default.dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-default .dark .mat-calendar-body-comparison-bridge-start::before,
.theme-default .dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-default.dark .mat-calendar-body-comparison-bridge-start::before,
.theme-default.dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(6, 141, 91, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-default .dark .mat-calendar-body-comparison-bridge-end::before,
.theme-default .dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-default.dark .mat-calendar-body-comparison-bridge-end::before,
.theme-default.dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(6, 141, 91, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-default .dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-default .dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-default.dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-default.dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-default .dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-default .dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-default.dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-default.dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-default .dark .mat-calendar-body-selected,
.theme-default.dark .mat-calendar-body-selected {
  background-color: #068d5b;
  color: white;
}
.theme-default .dark .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-default.dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(6, 141, 91, 0.4);
}
.theme-default .dark .mat-calendar-body-today.mat-calendar-body-selected,
.theme-default.dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-default .dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-default .dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-default.dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-default.dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(6, 141, 91, 0.3);
}
@media (hover: hover) {
  .theme-default .dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-default.dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(6, 141, 91, 0.3);
  }
}
.theme-default .dark .mat-datepicker-content,
.theme-default.dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #1e293b;
  color: white;
}
.theme-default .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before,
.theme-default.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(30, 41, 59, 0.2);
}
.theme-default .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-default .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before,
.theme-default.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-default.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-default .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-default .dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-default.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-default.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(30, 41, 59, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-default .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-default .dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-default.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-default.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(30, 41, 59, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-default .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-default .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-default.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-default.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-default .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-default .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-default.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-default.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-default .dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected,
.theme-default.dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #1e293b;
  color: white;
}
.theme-default .dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-default.dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(30, 41, 59, 0.4);
}
.theme-default .dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected,
.theme-default.dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-default .dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-default .dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-default.dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-default.dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(30, 41, 59, 0.3);
}
@media (hover: hover) {
  .theme-default .dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-default.dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(30, 41, 59, 0.3);
  }
}
.theme-default .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before,
.theme-default.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(220, 38, 38, 0.2);
}
.theme-default .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-default .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before,
.theme-default.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-default.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-default .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-default .dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-default.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-default.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(220, 38, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-default .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-default .dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-default.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-default.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(220, 38, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-default .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-default .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-default.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-default.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-default .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-default .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-default.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-default.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-default .dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected,
.theme-default.dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #dc2626;
  color: white;
}
.theme-default .dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-default.dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(220, 38, 38, 0.4);
}
.theme-default .dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected,
.theme-default.dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-default .dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-default .dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-default.dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-default.dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(220, 38, 38, 0.3);
}
@media (hover: hover) {
  .theme-default .dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-default.dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(220, 38, 38, 0.3);
  }
}
.theme-default .dark .mat-datepicker-content-touch,
.theme-default.dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-datepicker-toggle-active,
.theme-default.dark .mat-datepicker-toggle-active {
  color: #068d5b;
}
.theme-default .dark .mat-datepicker-toggle-active.mat-accent,
.theme-default.dark .mat-datepicker-toggle-active.mat-accent {
  color: #1e293b;
}
.theme-default .dark .mat-datepicker-toggle-active.mat-warn,
.theme-default.dark .mat-datepicker-toggle-active.mat-warn {
  color: #dc2626;
}
.theme-default .dark .mat-date-range-input-inner[disabled],
.theme-default.dark .mat-date-range-input-inner[disabled] {
  color: #475569;
}
.theme-default .dark .mat-divider,
.theme-default.dark .mat-divider {
  border-top-color: rgba(241, 245, 249, 0.12);
}
.theme-default .dark .mat-divider-vertical,
.theme-default.dark .mat-divider-vertical {
  border-right-color: rgba(241, 245, 249, 0.12);
}
.theme-default .dark .mat-expansion-panel,
.theme-default.dark .mat-expansion-panel {
  background: #1e293b;
  color: white;
}
.theme-default .dark .mat-expansion-panel:not([class*=mat-elevation-z]),
.theme-default.dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-action-row,
.theme-default.dark .mat-action-row {
  border-top-color: rgba(241, 245, 249, 0.12);
}
.theme-default .dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .theme-default .dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .theme-default .dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]),
.theme-default.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
.theme-default.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
.theme-default.dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.05);
}
@media (hover: none) {
  .theme-default .dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover,
  .theme-default.dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #1e293b;
  }
}
.theme-default .dark .mat-expansion-panel-header-title,
.theme-default.dark .mat-expansion-panel-header-title {
  color: white;
}
.theme-default .dark .mat-expansion-panel-header-description,
.theme-default .dark .mat-expansion-indicator::after,
.theme-default.dark .mat-expansion-panel-header-description,
.theme-default.dark .mat-expansion-indicator::after {
  color: #94a3b8;
}
.theme-default .dark .mat-expansion-panel-header[aria-disabled=true],
.theme-default.dark .mat-expansion-panel-header[aria-disabled=true] {
  color: #1e293b;
}
.theme-default .dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-default .dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description,
.theme-default.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-default.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.theme-default .dark .mat-icon.mat-primary,
.theme-default.dark .mat-icon.mat-primary {
  color: #068d5b;
}
.theme-default .dark .mat-icon.mat-accent,
.theme-default.dark .mat-icon.mat-accent {
  color: #1e293b;
}
.theme-default .dark .mat-icon.mat-warn,
.theme-default.dark .mat-icon.mat-warn {
  color: #dc2626;
}
.theme-default .dark .mat-drawer-container,
.theme-default.dark .mat-drawer-container {
  background-color: #0f172a;
  color: white;
}
.theme-default .dark .mat-drawer,
.theme-default.dark .mat-drawer {
  background-color: #1e293b;
  color: white;
}
.theme-default .dark .mat-drawer.mat-drawer-push,
.theme-default.dark .mat-drawer.mat-drawer-push {
  background-color: #1e293b;
}
.theme-default .dark .mat-drawer:not(.mat-drawer-side),
.theme-default.dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-drawer-side,
.theme-default.dark .mat-drawer-side {
  border-right: solid 1px rgba(241, 245, 249, 0.12);
}
.theme-default .dark .mat-drawer-side.mat-drawer-end,
.theme-default.dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(241, 245, 249, 0.12);
  border-right: none;
}
.theme-default .dark [dir=rtl] .mat-drawer-side,
.theme-default.dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(241, 245, 249, 0.12);
  border-right: none;
}
.theme-default .dark [dir=rtl] .mat-drawer-side.mat-drawer-end,
.theme-default.dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(241, 245, 249, 0.12);
}
.theme-default .dark .mat-drawer-backdrop.mat-drawer-shown,
.theme-default.dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(225, 214, 196, 0.6);
}
.theme-default .dark .mat-step-header.cdk-keyboard-focused, .theme-default .dark .mat-step-header.cdk-program-focused, .theme-default .dark .mat-step-header:hover:not([aria-disabled]), .theme-default .dark .mat-step-header:hover[aria-disabled=false],
.theme-default.dark .mat-step-header.cdk-keyboard-focused,
.theme-default.dark .mat-step-header.cdk-program-focused,
.theme-default.dark .mat-step-header:hover:not([aria-disabled]),
.theme-default.dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.05);
}
.theme-default .dark .mat-step-header:hover[aria-disabled=true],
.theme-default.dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .theme-default .dark .mat-step-header:hover,
  .theme-default.dark .mat-step-header:hover {
    background: none;
  }
}
.theme-default .dark .mat-step-header .mat-step-label,
.theme-default .dark .mat-step-header .mat-step-optional,
.theme-default.dark .mat-step-header .mat-step-label,
.theme-default.dark .mat-step-header .mat-step-optional {
  color: #94a3b8;
}
.theme-default .dark .mat-step-header .mat-step-icon,
.theme-default.dark .mat-step-header .mat-step-icon {
  background-color: #94a3b8;
  color: white;
}
.theme-default .dark .mat-step-header .mat-step-icon-selected,
.theme-default .dark .mat-step-header .mat-step-icon-state-done,
.theme-default .dark .mat-step-header .mat-step-icon-state-edit,
.theme-default.dark .mat-step-header .mat-step-icon-selected,
.theme-default.dark .mat-step-header .mat-step-icon-state-done,
.theme-default.dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #068d5b;
  color: white;
}
.theme-default .dark .mat-step-header.mat-accent .mat-step-icon,
.theme-default.dark .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.theme-default .dark .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-default .dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-default .dark .mat-step-header.mat-accent .mat-step-icon-state-edit,
.theme-default.dark .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-default.dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-default.dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #1e293b;
  color: white;
}
.theme-default .dark .mat-step-header.mat-warn .mat-step-icon,
.theme-default.dark .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.theme-default .dark .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-default .dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-default .dark .mat-step-header.mat-warn .mat-step-icon-state-edit,
.theme-default.dark .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-default.dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-default.dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #dc2626;
  color: white;
}
.theme-default .dark .mat-step-header .mat-step-icon-state-error,
.theme-default.dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #dc2626;
}
.theme-default .dark .mat-step-header .mat-step-label.mat-step-label-active,
.theme-default.dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.theme-default .dark .mat-step-header .mat-step-label.mat-step-label-error,
.theme-default.dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #dc2626;
}
.theme-default .dark .mat-stepper-horizontal, .theme-default .dark .mat-stepper-vertical,
.theme-default.dark .mat-stepper-horizontal,
.theme-default.dark .mat-stepper-vertical {
  background-color: #1e293b;
}
.theme-default .dark .mat-stepper-vertical-line::before,
.theme-default.dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(241, 245, 249, 0.12);
}
.theme-default .dark .mat-horizontal-stepper-header::before,
.theme-default .dark .mat-horizontal-stepper-header::after,
.theme-default .dark .mat-stepper-horizontal-line,
.theme-default.dark .mat-horizontal-stepper-header::before,
.theme-default.dark .mat-horizontal-stepper-header::after,
.theme-default.dark .mat-stepper-horizontal-line {
  border-top-color: rgba(241, 245, 249, 0.12);
}
.theme-default .dark .mat-sort-header-arrow,
.theme-default.dark .mat-sort-header-arrow {
  color: #94a3b8;
}
.theme-default .dark .mat-toolbar,
.theme-default.dark .mat-toolbar {
  background: #0f172a;
  color: white;
}
.theme-default .dark .mat-toolbar.mat-primary,
.theme-default.dark .mat-toolbar.mat-primary {
  background: #068d5b;
  color: white;
}
.theme-default .dark .mat-toolbar.mat-accent,
.theme-default.dark .mat-toolbar.mat-accent {
  background: #1e293b;
  color: white;
}
.theme-default .dark .mat-toolbar.mat-warn,
.theme-default.dark .mat-toolbar.mat-warn {
  background: #dc2626;
  color: white;
}
.theme-default .dark .mat-toolbar .mat-form-field-underline,
.theme-default .dark .mat-toolbar .mat-form-field-ripple,
.theme-default .dark .mat-toolbar .mat-focused .mat-form-field-ripple,
.theme-default.dark .mat-toolbar .mat-form-field-underline,
.theme-default.dark .mat-toolbar .mat-form-field-ripple,
.theme-default.dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.theme-default .dark .mat-toolbar .mat-form-field-label,
.theme-default .dark .mat-toolbar .mat-focused .mat-form-field-label,
.theme-default .dark .mat-toolbar .mat-select-value,
.theme-default .dark .mat-toolbar .mat-select-arrow,
.theme-default .dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow,
.theme-default.dark .mat-toolbar .mat-form-field-label,
.theme-default.dark .mat-toolbar .mat-focused .mat-form-field-label,
.theme-default.dark .mat-toolbar .mat-select-value,
.theme-default.dark .mat-toolbar .mat-select-arrow,
.theme-default.dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.theme-default .dark .mat-toolbar .mat-input-element,
.theme-default.dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.theme-default .dark .mat-tree,
.theme-default.dark .mat-tree {
  background: #1e293b;
}
.theme-default .dark .mat-tree-node,
.theme-default .dark .mat-nested-tree-node,
.theme-default.dark .mat-tree-node,
.theme-default.dark .mat-nested-tree-node {
  color: white;
}

/* Generate the palettes */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define a light & dark Angular Material theme with the generated palettes */
/* Merge the custom base colors with the generated themes */
/* Generate and encapsulate Angular Material themes */
.theme-brand .light .mat-ripple-element,
.theme-brand.light .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.theme-brand .light .mat-mdc-option,
.theme-brand.light .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-brand .light .mat-mdc-option:hover:not(.mdc-list-item--disabled), .theme-brand .light .mat-mdc-option:focus:not(.mdc-list-item--disabled), .theme-brand .light .mat-mdc-option.mat-mdc-option-active, .theme-brand .light .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled),
.theme-brand.light .mat-mdc-option:hover:not(.mdc-list-item--disabled),
.theme-brand.light .mat-mdc-option:focus:not(.mdc-list-item--disabled),
.theme-brand.light .mat-mdc-option.mat-mdc-option-active,
.theme-brand.light .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.theme-brand .light .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-brand.light .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #2196f3);
}
.theme-brand .light .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-brand.light .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #1e293b);
}
.theme-brand .light .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-brand.light .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mat-mdc-optgroup-label,
.theme-brand.light .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-brand .light .mat-pseudo-checkbox,
.theme-brand.light .mat-pseudo-checkbox {
  color: #64748b;
}
.theme-brand .light .mat-pseudo-checkbox::after,
.theme-brand.light .mat-pseudo-checkbox::after {
  color: #f1f5f9;
}
.theme-brand .light .mat-pseudo-checkbox-disabled,
.theme-brand.light .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.theme-brand .light .mat-primary .mat-pseudo-checkbox-checked,
.theme-brand .light .mat-primary .mat-pseudo-checkbox-indeterminate,
.theme-brand.light .mat-primary .mat-pseudo-checkbox-checked,
.theme-brand.light .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #2196f3;
}
.theme-brand .light .mat-pseudo-checkbox-checked,
.theme-brand .light .mat-pseudo-checkbox-indeterminate,
.theme-brand .light .mat-accent .mat-pseudo-checkbox-checked,
.theme-brand .light .mat-accent .mat-pseudo-checkbox-indeterminate,
.theme-brand.light .mat-pseudo-checkbox-checked,
.theme-brand.light .mat-pseudo-checkbox-indeterminate,
.theme-brand.light .mat-accent .mat-pseudo-checkbox-checked,
.theme-brand.light .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #1e293b;
}
.theme-brand .light .mat-warn .mat-pseudo-checkbox-checked,
.theme-brand .light .mat-warn .mat-pseudo-checkbox-indeterminate,
.theme-brand.light .mat-warn .mat-pseudo-checkbox-checked,
.theme-brand.light .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #dc2626;
}
.theme-brand .light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-brand .light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled,
.theme-brand.light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-brand.light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.theme-brand .light .mat-app-background, .theme-brand .light.mat-app-background,
.theme-brand.light .mat-app-background,
.theme-brand.light.mat-app-background {
  background-color: #f1f5f9;
  color: #1e293b;
}
.theme-brand .light .mat-elevation-z0, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-brand.light .mat-elevation-z0,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z1, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-brand.light .mat-elevation-z1,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z2, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-brand.light .mat-elevation-z2,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z3, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-brand.light .mat-elevation-z3,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z4, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-brand.light .mat-elevation-z4,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z5, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-brand.light .mat-elevation-z5,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z6, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-brand.light .mat-elevation-z6,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z7, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-brand.light .mat-elevation-z7,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z8, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-brand.light .mat-elevation-z8,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z9, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-brand.light .mat-elevation-z9,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z10, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-brand.light .mat-elevation-z10,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z11, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-brand.light .mat-elevation-z11,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z12, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-brand.light .mat-elevation-z12,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z13, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-brand.light .mat-elevation-z13,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z14, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-brand.light .mat-elevation-z14,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z15, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-brand.light .mat-elevation-z15,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z16, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-brand.light .mat-elevation-z16,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z17, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-brand.light .mat-elevation-z17,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z18, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-brand.light .mat-elevation-z18,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z19, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-brand.light .mat-elevation-z19,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z20, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-brand.light .mat-elevation-z20,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z21, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-brand.light .mat-elevation-z21,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z22, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-brand.light .mat-elevation-z22,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z23, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-brand.light .mat-elevation-z23,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z24, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-brand.light .mat-elevation-z24,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-brand .light .mat-mdc-card,
.theme-brand.light .mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #fff;
}
.theme-brand .light .mat-mdc-card-outlined,
.theme-brand.light .mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #e0e0e0;
}
.theme-brand .light .mat-mdc-card-subtitle,
.theme-brand.light .mat-mdc-card-subtitle {
  color: #64748b;
}
.theme-brand .light .mat-mdc-progress-bar,
.theme-brand.light .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #2196f3;
}
.theme-brand .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.theme-brand.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(33, 150, 243, 0.25)'/%3E%3C/svg%3E");
}
.theme-brand .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.theme-brand.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(33, 150, 243, 0.25);
}
.theme-brand .light .mat-mdc-progress-bar.mat-accent,
.theme-brand.light .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
}
.theme-brand .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.theme-brand.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(30, 41, 59, 0.25)'/%3E%3C/svg%3E");
}
.theme-brand .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.theme-brand.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(30, 41, 59, 0.25);
}
.theme-brand .light .mat-mdc-progress-bar.mat-warn,
.theme-brand.light .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
}
.theme-brand .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.theme-brand.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(220, 38, 38, 0.25)'/%3E%3C/svg%3E");
}
.theme-brand .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.theme-brand.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(220, 38, 38, 0.25);
}
.theme-brand .light .mat-mdc-tooltip,
.theme-brand.light .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.theme-brand .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-brand.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.theme-brand .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input,
.theme-brand.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .theme-brand .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder,
  .theme-brand.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .theme-brand .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder,
  .theme-brand.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.theme-brand .light .mdc-text-field .mdc-text-field__input,
.theme-brand.light .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #2196f3);
}
.theme-brand .light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text,
.theme-brand.light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.theme-brand .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.theme-brand .light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter,
.theme-brand.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.theme-brand.light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.theme-brand .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading,
.theme-brand.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.theme-brand .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-brand.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.theme-brand .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix,
.theme-brand.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-brand .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix,
.theme-brand.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-brand .light .mdc-text-field--filled .mdc-text-field__ripple::before,
.theme-brand .light .mdc-text-field--filled .mdc-text-field__ripple::after,
.theme-brand.light .mdc-text-field--filled .mdc-text-field__ripple::before,
.theme-brand.light .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.theme-brand .light .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .theme-brand .light .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before,
.theme-brand.light .mdc-text-field--filled:hover .mdc-text-field__ripple::before,
.theme-brand.light .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.theme-brand .light .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .theme-brand .light .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before,
.theme-brand.light .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before,
.theme-brand.light .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.theme-brand .light .mdc-text-field--filled:not(.mdc-text-field--disabled),
.theme-brand.light .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.theme-brand .light .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-brand.light .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.theme-brand .light .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-brand.light .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.theme-brand .light .mdc-text-field--filled .mdc-line-ripple::after,
.theme-brand.light .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #2196f3);
}
.theme-brand .light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-brand .light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-brand .light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-brand.light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-brand.light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-brand.light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.theme-brand .light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-brand .light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-brand .light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-brand.light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-brand.light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-brand.light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.theme-brand .light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-brand .light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-brand .light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-brand.light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-brand.light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-brand.light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #2196f3);
}
[dir=rtl] .theme-brand .light .mdc-text-field--outlined.mdc-text-field--with-leading-icon, [dir=rtl] .theme-brand.light .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .theme-brand .light .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl], .theme-brand.light .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-brand .light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, [dir=rtl] .theme-brand.light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .theme-brand .light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl], .theme-brand.light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-brand .light .mdc-text-field--outlined .mdc-text-field__ripple::before,
.theme-brand .light .mdc-text-field--outlined .mdc-text-field__ripple::after,
.theme-brand.light .mdc-text-field--outlined .mdc-text-field__ripple::before,
.theme-brand.light .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .theme-brand .light .mdc-text-field--with-leading-icon.mdc-text-field--outlined, [dir=rtl] .theme-brand.light .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .theme-brand .light .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl], .theme-brand.light .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-brand .light .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-brand.light .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(33, 150, 243, 0.87);
}
.theme-brand .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-brand.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-brand.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-brand.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-brand.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mdc-text-field--invalid .mdc-text-field__input,
.theme-brand.light .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-brand.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-brand.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-brand .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-brand .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-brand.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-brand.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-brand.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-brand .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-brand .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-brand.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-brand.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-brand.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-brand .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-brand .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-brand.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-brand.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-brand.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mdc-text-field--disabled .mdc-text-field__input,
.theme-brand.light .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .theme-brand .light .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  .theme-brand.light .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .theme-brand .light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  .theme-brand.light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.theme-brand .light .mdc-text-field--disabled .mdc-floating-label,
.theme-brand.light .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.theme-brand .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
.theme-brand.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.theme-brand .light .mdc-text-field--disabled .mdc-text-field-character-counter,
.theme-brand .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
.theme-brand.light .mdc-text-field--disabled .mdc-text-field-character-counter,
.theme-brand.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.theme-brand .light .mdc-text-field--disabled .mdc-text-field__icon--leading,
.theme-brand.light .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.theme-brand .light .mdc-text-field--disabled .mdc-text-field__icon--trailing,
.theme-brand.light .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.theme-brand .light .mdc-text-field--disabled .mdc-text-field__affix--prefix,
.theme-brand.light .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-brand .light .mdc-text-field--disabled .mdc-text-field__affix--suffix,
.theme-brand.light .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-brand .light .mdc-text-field--disabled .mdc-line-ripple::before,
.theme-brand.light .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.theme-brand .light .mdc-text-field--disabled .mdc-notched-outline__leading,
.theme-brand .light .mdc-text-field--disabled .mdc-notched-outline__notch,
.theme-brand .light .mdc-text-field--disabled .mdc-notched-outline__trailing,
.theme-brand.light .mdc-text-field--disabled .mdc-notched-outline__leading,
.theme-brand.light .mdc-text-field--disabled .mdc-notched-outline__notch,
.theme-brand.light .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-brand .light .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  .theme-brand.light .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-brand .light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  .theme-brand.light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-brand .light .mdc-text-field--disabled .mdc-floating-label,
  .theme-brand.light .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-brand .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
  .theme-brand.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-brand .light .mdc-text-field--disabled .mdc-text-field-character-counter,
  .theme-brand .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
  .theme-brand.light .mdc-text-field--disabled .mdc-text-field-character-counter,
  .theme-brand.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-brand .light .mdc-text-field--disabled .mdc-text-field__icon--leading,
  .theme-brand.light .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-brand .light .mdc-text-field--disabled .mdc-text-field__icon--trailing,
  .theme-brand.light .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-brand .light .mdc-text-field--disabled .mdc-text-field__affix--prefix,
  .theme-brand.light .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-brand .light .mdc-text-field--disabled .mdc-text-field__affix--suffix,
  .theme-brand.light .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-brand .light .mdc-text-field--disabled .mdc-line-ripple::before,
  .theme-brand.light .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-brand .light .mdc-text-field--disabled .mdc-notched-outline__leading,
  .theme-brand .light .mdc-text-field--disabled .mdc-notched-outline__notch,
  .theme-brand .light .mdc-text-field--disabled .mdc-notched-outline__trailing,
  .theme-brand.light .mdc-text-field--disabled .mdc-notched-outline__leading,
  .theme-brand.light .mdc-text-field--disabled .mdc-notched-outline__notch,
  .theme-brand.light .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.theme-brand .light .mdc-text-field--disabled.mdc-text-field--filled,
.theme-brand.light .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .theme-brand .light .mdc-text-field--end-aligned .mdc-text-field__input, [dir=rtl] .theme-brand.light .mdc-text-field--end-aligned .mdc-text-field__input, .theme-brand .light .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl], .theme-brand.light .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-brand .light .mdc-floating-label--required, [dir=rtl] .theme-brand.light .mdc-floating-label--required, .theme-brand .light .mdc-floating-label--required[dir=rtl], .theme-brand.light .mdc-floating-label--required[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-brand .light .mat-mdc-form-field-error,
.theme-brand.light .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mat-mdc-form-field-focus-overlay,
.theme-brand.light .mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}
.theme-brand .light .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.theme-brand.light .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
.theme-brand .light .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.theme-brand.light .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}
.theme-brand .light .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.theme-brand.light .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.theme-brand .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.theme-brand.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(33, 150, 243, 0.87);
}
.theme-brand .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.theme-brand.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(30, 41, 59, 0.87);
}
.theme-brand .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.theme-brand.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(220, 38, 38, 0.87);
}
.theme-brand .light .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.theme-brand.light .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.theme-brand .light .mat-mdc-form-field.mat-accent .mdc-text-field__input,
.theme-brand.light .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-brand .light .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-brand.light .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-brand .light .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-brand.light .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(30, 41, 59, 0.87);
}
.theme-brand .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-brand.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-brand.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-brand.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-brand.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input,
.theme-brand.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-brand.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-brand.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-brand .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-brand .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-brand.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-brand.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-brand.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-brand .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-brand .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-brand.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-brand.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-brand.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-brand .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-brand .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-brand.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-brand.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-brand.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-brand .light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-brand .light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-brand.light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-brand.light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-brand.light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-brand .light .mat-mdc-form-field.mat-warn .mdc-text-field__input,
.theme-brand.light .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-brand.light .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-brand.light .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(220, 38, 38, 0.87);
}
.theme-brand .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-brand.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-brand.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-brand.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-brand.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input,
.theme-brand.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-brand.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-brand.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-brand .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-brand .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-brand.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-brand.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-brand.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-brand .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-brand .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-brand.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-brand.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-brand.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-brand .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-brand .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-brand.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-brand.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-brand.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-brand .light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-brand .light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-brand.light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-brand.light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-brand.light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .light .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.theme-brand.light .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.theme-brand .light [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.theme-brand.light [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.theme-brand .light .mdc-menu-surface,
.theme-brand.light .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mdc-list-item__primary-text,
.theme-brand.light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-brand .light .mdc-list-item__secondary-text,
.theme-brand.light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.theme-brand .light .mdc-list-item__overline-text,
.theme-brand.light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-brand .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-brand.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-brand .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-brand.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.theme-brand .light .mdc-list-item__end,
.theme-brand.light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-brand .light .mdc-list-item--disabled .mdc-list-item__start,
.theme-brand .light .mdc-list-item--disabled .mdc-list-item__content,
.theme-brand .light .mdc-list-item--disabled .mdc-list-item__end,
.theme-brand.light .mdc-list-item--disabled .mdc-list-item__start,
.theme-brand.light .mdc-list-item--disabled .mdc-list-item__content,
.theme-brand.light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-brand .light .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-brand.light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-brand.light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-brand.light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-brand.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-brand.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-brand .light .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-brand.light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-brand.light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #2196f3);
}
.theme-brand .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #2196f3);
}
.theme-brand .light .mdc-deprecated-list-group__subheader,
.theme-brand.light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-brand .light .mdc-list-divider::after,
.theme-brand.light .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-brand .light .mdc-list-divider,
.theme-brand.light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-mdc-select-value,
.theme-brand.light .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.theme-brand .light .mat-mdc-select-placeholder,
.theme-brand.light .mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.theme-brand .light .mat-mdc-select-disabled .mat-mdc-select-value,
.theme-brand.light .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.theme-brand .light .mat-mdc-select-arrow,
.theme-brand.light .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.theme-brand .light .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow,
.theme-brand.light .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(33, 150, 243, 0.87);
}
.theme-brand .light .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow,
.theme-brand.light .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(30, 41, 59, 0.87);
}
.theme-brand .light .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow,
.theme-brand.light .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(220, 38, 38, 0.87);
}
.theme-brand .light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow,
.theme-brand.light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(220, 38, 38, 0.87);
}
.theme-brand .light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow,
.theme-brand.light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.theme-brand .light .mdc-menu-surface,
.theme-brand.light .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mdc-list-item__primary-text,
.theme-brand.light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-brand .light .mdc-list-item__secondary-text,
.theme-brand.light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.theme-brand .light .mdc-list-item__overline-text,
.theme-brand.light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-brand .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-brand.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-brand .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-brand.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.theme-brand .light .mdc-list-item__end,
.theme-brand.light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-brand .light .mdc-list-item--disabled .mdc-list-item__start,
.theme-brand .light .mdc-list-item--disabled .mdc-list-item__content,
.theme-brand .light .mdc-list-item--disabled .mdc-list-item__end,
.theme-brand.light .mdc-list-item--disabled .mdc-list-item__start,
.theme-brand.light .mdc-list-item--disabled .mdc-list-item__content,
.theme-brand.light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-brand .light .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-brand.light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-brand.light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-brand.light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-brand.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-brand.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-brand .light .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-brand.light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-brand.light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #2196f3);
}
.theme-brand .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #2196f3);
}
.theme-brand .light .mdc-deprecated-list-group__subheader,
.theme-brand.light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-brand .light .mdc-list-divider::after,
.theme-brand.light .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-brand .light .mdc-list-divider,
.theme-brand.light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-mdc-dialog-container,
.theme-brand.light .mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.theme-brand .light .mat-mdc-standard-chip,
.theme-brand.light .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.theme-brand .light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .theme-brand .light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted,
.theme-brand.light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected,
.theme-brand.light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #2196f3;
  --mdc-chip-elevated-disabled-container-color: #2196f3;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-brand .light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .theme-brand .light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted,
.theme-brand.light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected,
.theme-brand.light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-brand .light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .theme-brand .light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted,
.theme-brand.light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected,
.theme-brand.light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-brand .light .mat-mdc-chip-focus-overlay,
.theme-brand.light .mat-mdc-chip-focus-overlay {
  background: #000000;
}
.theme-brand .light .mat-mdc-slide-toggle,
.theme-brand.light .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.theme-brand .light .mat-mdc-slide-toggle .mdc-form-field,
.theme-brand.light .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-brand .light .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.theme-brand.light .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: #94a3b8;
}
.theme-brand .light .mat-mdc-slide-toggle.mat-primary,
.theme-brand.light .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #1e88dc;
  --mdc-switch-selected-handle-color: #1e88dc;
  --mdc-switch-selected-hover-state-layer-color: #1e88dc;
  --mdc-switch-selected-pressed-state-layer-color: #1e88dc;
  --mdc-switch-selected-focus-handle-color: #125183;
  --mdc-switch-selected-hover-handle-color: #125183;
  --mdc-switch-selected-pressed-handle-color: #125183;
  --mdc-switch-selected-focus-track-color: #a1c6f8;
  --mdc-switch-selected-hover-track-color: #a1c6f8;
  --mdc-switch-selected-pressed-track-color: #a1c6f8;
  --mdc-switch-selected-track-color: #a1c6f8;
}
.theme-brand .light .mat-mdc-slide-toggle.mat-accent,
.theme-brand.light .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.theme-brand .light .mat-mdc-slide-toggle.mat-warn,
.theme-brand.light .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.theme-brand .light .mat-mdc-radio-button,
.theme-brand.light .mat-mdc-radio-button {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mat-mdc-radio-ripple-color: #000;
}
.theme-brand .light .mat-mdc-radio-button .mdc-form-field,
.theme-brand.light .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-brand .light .mat-mdc-radio-button .mdc-radio--disabled + label,
.theme-brand.light .mat-mdc-radio-button .mdc-radio--disabled + label {
  color: #94a3b8;
}
.theme-brand .light .mat-mdc-radio-button.mat-primary,
.theme-brand.light .mat-mdc-radio-button.mat-primary {
  --mdc-radio-selected-focus-icon-color: #2196f3;
  --mdc-radio-selected-hover-icon-color: #2196f3;
  --mdc-radio-selected-icon-color: #2196f3;
  --mdc-radio-selected-pressed-icon-color: #2196f3;
  --mat-mdc-radio-checked-ripple-color: #2196f3;
}
.theme-brand .light .mat-mdc-radio-button.mat-accent,
.theme-brand.light .mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-mdc-radio-checked-ripple-color: #1e293b;
}
.theme-brand .light .mat-mdc-radio-button.mat-warn,
.theme-brand.light .mat-mdc-radio-button.mat-warn {
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-mdc-radio-checked-ripple-color: #dc2626;
}
.theme-brand .light .mat-mdc-slider,
.theme-brand.light .mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.theme-brand .light .mat-mdc-slider.mat-primary,
.theme-brand.light .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #2196f3;
  --mdc-slider-focus-handle-color: #2196f3;
  --mdc-slider-hover-handle-color: #2196f3;
  --mdc-slider-active-track-color: #2196f3;
  --mdc-slider-inactive-track-color: #2196f3;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #2196f3;
  --mat-mdc-slider-ripple-color: #2196f3;
  --mat-mdc-slider-hover-ripple-color: rgba(33, 150, 243, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(33, 150, 243, 0.2);
}
.theme-brand .light .mat-mdc-slider.mat-accent,
.theme-brand.light .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mat-mdc-slider-ripple-color: #1e293b;
  --mat-mdc-slider-hover-ripple-color: rgba(30, 41, 59, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(30, 41, 59, 0.2);
}
.theme-brand .light .mat-mdc-slider.mat-warn,
.theme-brand.light .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mat-mdc-slider-ripple-color: #dc2626;
  --mat-mdc-slider-hover-ripple-color: rgba(220, 38, 38, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(220, 38, 38, 0.2);
}
.theme-brand .light .mdc-menu-surface,
.theme-brand.light .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mdc-list-item__primary-text,
.theme-brand.light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-brand .light .mdc-list-item__secondary-text,
.theme-brand.light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.theme-brand .light .mdc-list-item__overline-text,
.theme-brand.light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-brand .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-brand.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-brand .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-brand.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.theme-brand .light .mdc-list-item__end,
.theme-brand.light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-brand .light .mdc-list-item--disabled .mdc-list-item__start,
.theme-brand .light .mdc-list-item--disabled .mdc-list-item__content,
.theme-brand .light .mdc-list-item--disabled .mdc-list-item__end,
.theme-brand.light .mdc-list-item--disabled .mdc-list-item__start,
.theme-brand.light .mdc-list-item--disabled .mdc-list-item__content,
.theme-brand.light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-brand .light .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-brand.light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-brand.light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-brand.light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-brand.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-brand.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-brand .light .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-brand.light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-brand.light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #2196f3);
}
.theme-brand .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #2196f3);
}
.theme-brand .light .mdc-deprecated-list-group__subheader,
.theme-brand.light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-brand .light .mdc-list-divider::after,
.theme-brand.light .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-brand .light .mdc-list-divider,
.theme-brand.light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-mdc-menu-item[disabled],
.theme-brand .light .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.theme-brand .light .mat-mdc-menu-item[disabled] .mat-icon-no-color,
.theme-brand.light .mat-mdc-menu-item[disabled],
.theme-brand.light .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.theme-brand.light .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}
.theme-brand .light .mat-mdc-menu-item .mat-icon-no-color,
.theme-brand .light .mat-mdc-menu-submenu-icon,
.theme-brand.light .mat-mdc-menu-item .mat-icon-no-color,
.theme-brand.light .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-brand .light .mat-mdc-menu-item:hover:not([disabled]),
.theme-brand .light .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.theme-brand .light .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-brand .light .mat-mdc-menu-item-highlighted:not([disabled]),
.theme-brand.light .mat-mdc-menu-item:hover:not([disabled]),
.theme-brand.light .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.theme-brand.light .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-brand.light .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.theme-brand .light .mat-mdc-list-item-interactive::before,
.theme-brand.light .mat-mdc-list-item-interactive::before {
  background: black;
}
.theme-brand .light .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before,
.theme-brand.light .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.04;
}
.theme-brand .light .mat-mdc-list-item-interactive.mdc-list-item--selected::before,
.theme-brand.light .mat-mdc-list-item-interactive.mdc-list-item--selected::before {
  background: #2196f3;
  opacity: 0.08;
}
.theme-brand .light .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before,
.theme-brand.light .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.12;
}
.theme-brand .light .mat-mdc-list-item-interactive:focus::before,
.theme-brand.light .mat-mdc-list-item-interactive:focus::before {
  opacity: 0.12;
}
.theme-brand .light .mdc-list-item__primary-text,
.theme-brand.light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-brand .light .mdc-list-item__secondary-text,
.theme-brand.light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.theme-brand .light .mdc-list-item__overline-text,
.theme-brand.light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-brand .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-brand.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-brand .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-brand.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.theme-brand .light .mdc-list-item__end,
.theme-brand.light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-brand .light .mdc-list-item--disabled .mdc-list-item__start,
.theme-brand .light .mdc-list-item--disabled .mdc-list-item__content,
.theme-brand .light .mdc-list-item--disabled .mdc-list-item__end,
.theme-brand.light .mdc-list-item--disabled .mdc-list-item__start,
.theme-brand.light .mdc-list-item--disabled .mdc-list-item__content,
.theme-brand.light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-brand .light .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-brand.light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-brand.light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-brand.light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-brand.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-brand.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-brand .light .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-brand.light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-brand.light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #2196f3);
}
.theme-brand .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #2196f3);
}
.theme-brand .light .mdc-deprecated-list-group__subheader,
.theme-brand.light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-brand .light .mdc-list-divider::after,
.theme-brand.light .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-brand .light .mdc-list-divider,
.theme-brand.light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-mdc-list-option .mdc-list-item__start, .theme-brand .light .mat-mdc-list-option .mdc-list-item__end,
.theme-brand.light .mat-mdc-list-option .mdc-list-item__start,
.theme-brand.light .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #2196f3;
  --mdc-checkbox-selected-hover-icon-color: #2196f3;
  --mdc-checkbox-selected-icon-color: #2196f3;
  --mdc-checkbox-selected-pressed-icon-color: #2196f3;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-brand .light .mat-mdc-list-option.mat-accent .mdc-list-item__start, .theme-brand .light .mat-mdc-list-option.mat-accent .mdc-list-item__end,
.theme-brand.light .mat-mdc-list-option.mat-accent .mdc-list-item__start,
.theme-brand.light .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-brand .light .mat-mdc-list-option.mat-warn .mdc-list-item__start, .theme-brand .light .mat-mdc-list-option.mat-warn .mdc-list-item__end,
.theme-brand.light .mat-mdc-list-option.mat-warn .mdc-list-item__start,
.theme-brand.light .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-brand .light .mat-mdc-paginator,
.theme-brand.light .mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme-brand .light .mat-mdc-paginator-icon,
.theme-brand.light .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}
.theme-brand .light .mat-mdc-paginator-decrement,
.theme-brand .light .mat-mdc-paginator-increment,
.theme-brand.light .mat-mdc-paginator-decrement,
.theme-brand.light .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.theme-brand .light .mat-mdc-paginator-first,
.theme-brand .light .mat-mdc-paginator-last,
.theme-brand.light .mat-mdc-paginator-first,
.theme-brand.light .mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.theme-brand .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.theme-brand .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.theme-brand .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.theme-brand .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last,
.theme-brand.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.theme-brand.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.theme-brand.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.theme-brand.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon,
.theme-brand.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-mdc-tab, .theme-brand .light .mat-mdc-tab-link,
.theme-brand.light .mat-mdc-tab,
.theme-brand.light .mat-mdc-tab-link {
  background-color: transparent;
}
.theme-brand .light .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label, .theme-brand .light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.theme-brand.light .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.theme-brand.light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}
.theme-brand .light .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-brand .light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-brand.light .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-brand.light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #2196f3;
}
.theme-brand .light .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-brand .light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-brand.light .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-brand.light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #2196f3;
}
.theme-brand .light .mdc-tab__ripple::before,
.theme-brand .light .mat-mdc-tab .mat-ripple-element,
.theme-brand .light .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-brand .light .mat-mdc-tab-link .mat-ripple-element,
.theme-brand.light .mdc-tab__ripple::before,
.theme-brand.light .mat-mdc-tab .mat-ripple-element,
.theme-brand.light .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-brand.light .mat-mdc-tab-link .mat-ripple-element {
  background-color: #2196f3;
}
.theme-brand .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-brand .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-brand .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-brand .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-brand.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-brand.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-brand.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-brand.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #1e293b;
}
.theme-brand .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-brand .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-brand .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-brand .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-brand.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-brand.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-brand.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-brand.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #1e293b;
}
.theme-brand .light .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.theme-brand .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-brand .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-brand .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .theme-brand .light .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.theme-brand .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-brand .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-brand .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element,
.theme-brand.light .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.theme-brand.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-brand.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-brand.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element,
.theme-brand.light .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.theme-brand.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-brand.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-brand.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #1e293b;
}
.theme-brand .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-brand .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-brand .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-brand .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-brand.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-brand.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-brand.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-brand.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #dc2626;
}
.theme-brand .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-brand .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-brand .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-brand .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-brand.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-brand.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-brand.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-brand.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #dc2626;
}
.theme-brand .light .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.theme-brand .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-brand .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-brand .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .theme-brand .light .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.theme-brand .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-brand .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-brand .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element,
.theme-brand.light .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.theme-brand.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-brand.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-brand.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element,
.theme-brand.light .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.theme-brand.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-brand.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-brand.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #dc2626;
}
.theme-brand .light .mat-mdc-tab-group.mat-background-primary, .theme-brand .light .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-brand.light .mat-mdc-tab-group.mat-background-primary,
.theme-brand.light .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #2196f3;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-brand .light .mat-mdc-tab-group.mat-background-accent, .theme-brand .light .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-brand.light .mat-mdc-tab-group.mat-background-accent,
.theme-brand.light .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #1e293b;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-brand .light .mat-mdc-tab-group.mat-background-warn, .theme-brand .light .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-brand.light .mat-mdc-tab-group.mat-background-warn,
.theme-brand.light .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #dc2626;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-brand .light .mat-mdc-tab-header-pagination-chevron,
.theme-brand.light .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #000);
}
.theme-brand .light .mat-mdc-checkbox .mdc-form-field,
.theme-brand.light .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-brand .light .mat-mdc-checkbox .mat-ripple-element,
.theme-brand.light .mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.theme-brand .light .mat-mdc-checkbox .mdc-checkbox__ripple,
.theme-brand.light .mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #000;
}
.theme-brand .light .mat-mdc-checkbox.mat-primary,
.theme-brand.light .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #2196f3;
  --mdc-checkbox-selected-hover-icon-color: #2196f3;
  --mdc-checkbox-selected-icon-color: #2196f3;
  --mdc-checkbox-selected-pressed-icon-color: #2196f3;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-brand .light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-brand.light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(33, 150, 243, 0.1);
}
.theme-brand .light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-brand.light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #2196f3;
}
.theme-brand .light .mat-mdc-checkbox.mat-accent,
.theme-brand.light .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-brand .light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-brand.light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(30, 41, 59, 0.1);
}
.theme-brand .light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-brand.light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #1e293b;
}
.theme-brand .light .mat-mdc-checkbox.mat-warn,
.theme-brand.light .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-brand .light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-brand.light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(220, 38, 38, 0.1);
}
.theme-brand .light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-brand.light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #dc2626;
}
.theme-brand .light .mat-mdc-checkbox-disabled label,
.theme-brand.light .mat-mdc-checkbox-disabled label {
  color: #94a3b8;
}
.theme-brand .light .mat-mdc-button.mat-unthemed,
.theme-brand.light .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.theme-brand .light .mat-mdc-button.mat-primary,
.theme-brand.light .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #2196f3;
}
.theme-brand .light .mat-mdc-button.mat-accent,
.theme-brand.light .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
}
.theme-brand .light .mat-mdc-button.mat-warn,
.theme-brand.light .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
}
.theme-brand .light .mat-mdc-button[disabled][disabled],
.theme-brand.light .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.theme-brand .light .mat-mdc-unelevated-button.mat-unthemed,
.theme-brand.light .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.theme-brand .light .mat-mdc-unelevated-button.mat-primary,
.theme-brand.light .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #2196f3;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-brand .light .mat-mdc-unelevated-button.mat-accent,
.theme-brand.light .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-brand .light .mat-mdc-unelevated-button.mat-warn,
.theme-brand.light .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-brand .light .mat-mdc-unelevated-button[disabled][disabled],
.theme-brand.light .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.theme-brand .light .mat-mdc-raised-button.mat-unthemed,
.theme-brand.light .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.theme-brand .light .mat-mdc-raised-button.mat-primary,
.theme-brand.light .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #2196f3;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-brand .light .mat-mdc-raised-button.mat-accent,
.theme-brand.light .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-brand .light .mat-mdc-raised-button.mat-warn,
.theme-brand.light .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-brand .light .mat-mdc-raised-button[disabled][disabled],
.theme-brand.light .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.theme-brand .light .mat-mdc-outlined-button,
.theme-brand.light .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-mdc-outlined-button.mat-unthemed,
.theme-brand.light .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.theme-brand .light .mat-mdc-outlined-button.mat-primary,
.theme-brand.light .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #2196f3;
}
.theme-brand .light .mat-mdc-outlined-button.mat-accent,
.theme-brand.light .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
}
.theme-brand .light .mat-mdc-outlined-button.mat-warn,
.theme-brand.light .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
}
.theme-brand .light .mat-mdc-outlined-button[disabled][disabled],
.theme-brand.light .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-mdc-button, .theme-brand .light .mat-mdc-outlined-button,
.theme-brand.light .mat-mdc-button,
.theme-brand.light .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-brand .light .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .theme-brand .light .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-brand .light .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-brand .light .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-brand .light .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-brand .light .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-brand .light .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .theme-brand .light .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-brand .light .mat-mdc-button.mat-primary, .theme-brand .light .mat-mdc-outlined-button.mat-primary,
.theme-brand.light .mat-mdc-button.mat-primary,
.theme-brand.light .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #2196f3;
  --mat-mdc-button-ripple-color: rgba(33, 150, 243, 0.1);
}
.theme-brand .light .mat-mdc-button.mat-accent, .theme-brand .light .mat-mdc-outlined-button.mat-accent,
.theme-brand.light .mat-mdc-button.mat-accent,
.theme-brand.light .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #1e293b;
  --mat-mdc-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-brand .light .mat-mdc-button.mat-warn, .theme-brand .light .mat-mdc-outlined-button.mat-warn,
.theme-brand.light .mat-mdc-button.mat-warn,
.theme-brand.light .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #dc2626;
  --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-brand .light .mat-mdc-raised-button, .theme-brand .light .mat-mdc-unelevated-button,
.theme-brand.light .mat-mdc-raised-button,
.theme-brand.light .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-brand .light .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .theme-brand .light .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-brand .light .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-brand .light .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-brand .light .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-brand .light .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-brand .light .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .theme-brand .light .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-brand .light .mat-mdc-raised-button.mat-primary, .theme-brand .light .mat-mdc-unelevated-button.mat-primary,
.theme-brand.light .mat-mdc-raised-button.mat-primary,
.theme-brand.light .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .light .mat-mdc-raised-button.mat-accent, .theme-brand .light .mat-mdc-unelevated-button.mat-accent,
.theme-brand.light .mat-mdc-raised-button.mat-accent,
.theme-brand.light .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .light .mat-mdc-raised-button.mat-warn, .theme-brand .light .mat-mdc-unelevated-button.mat-warn,
.theme-brand.light .mat-mdc-raised-button.mat-warn,
.theme-brand.light .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .light .mat-mdc-icon-button,
.theme-brand.light .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-brand .light .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-brand .light .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-brand .light .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-brand .light .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-brand .light .mat-mdc-icon-button.mat-primary,
.theme-brand.light .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #2196f3;
  --mat-mdc-button-ripple-color: rgba(33, 150, 243, 0.1);
}
.theme-brand .light .mat-mdc-icon-button.mat-accent,
.theme-brand.light .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #1e293b;
  --mat-mdc-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-brand .light .mat-mdc-icon-button.mat-warn,
.theme-brand.light .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #dc2626;
  --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-brand .light .mat-mdc-icon-button.mat-primary,
.theme-brand.light .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #2196f3;
}
.theme-brand .light .mat-mdc-icon-button.mat-accent,
.theme-brand.light .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
}
.theme-brand .light .mat-mdc-icon-button.mat-warn,
.theme-brand.light .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
}
.theme-brand .light .mat-mdc-icon-button[disabled][disabled],
.theme-brand.light .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.theme-brand .light .mat-mdc-fab, .theme-brand .light .mat-mdc-mini-fab,
.theme-brand.light .mat-mdc-fab,
.theme-brand.light .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-brand .light .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .theme-brand .light .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-brand .light .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-brand .light .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-brand .light .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-brand .light .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-brand .light .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .theme-brand .light .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.theme-brand.light .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-brand .light .mat-mdc-fab.mat-primary, .theme-brand .light .mat-mdc-mini-fab.mat-primary,
.theme-brand.light .mat-mdc-fab.mat-primary,
.theme-brand.light .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .light .mat-mdc-fab.mat-accent, .theme-brand .light .mat-mdc-mini-fab.mat-accent,
.theme-brand.light .mat-mdc-fab.mat-accent,
.theme-brand.light .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .light .mat-mdc-fab.mat-warn, .theme-brand .light .mat-mdc-mini-fab.mat-warn,
.theme-brand.light .mat-mdc-fab.mat-warn,
.theme-brand.light .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .light .mat-mdc-fab.mat-unthemed, .theme-brand .light .mat-mdc-mini-fab.mat-unthemed,
.theme-brand.light .mat-mdc-fab.mat-unthemed,
.theme-brand.light .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.theme-brand .light .mat-mdc-fab.mat-primary, .theme-brand .light .mat-mdc-mini-fab.mat-primary,
.theme-brand.light .mat-mdc-fab.mat-primary,
.theme-brand.light .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #2196f3;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-brand .light .mat-mdc-fab.mat-accent, .theme-brand .light .mat-mdc-mini-fab.mat-accent,
.theme-brand.light .mat-mdc-fab.mat-accent,
.theme-brand.light .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-brand .light .mat-mdc-fab.mat-warn, .theme-brand .light .mat-mdc-mini-fab.mat-warn,
.theme-brand.light .mat-mdc-fab.mat-warn,
.theme-brand.light .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-brand .light .mat-mdc-fab[disabled][disabled], .theme-brand .light .mat-mdc-mini-fab[disabled][disabled],
.theme-brand.light .mat-mdc-fab[disabled][disabled],
.theme-brand.light .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.theme-brand .light .mat-mdc-snack-bar-container,
.theme-brand.light .mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #1e293b;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
}
.theme-brand .light .mdc-data-table,
.theme-brand.light .mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mdc-data-table__row,
.theme-brand.light .mdc-data-table__row {
  background-color: inherit;
}
.theme-brand .light .mdc-data-table__header-cell,
.theme-brand.light .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}
.theme-brand .light .mdc-data-table__row--selected,
.theme-brand.light .mdc-data-table__row--selected {
  background-color: rgba(33, 150, 243, 0.04);
}
.theme-brand .light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.theme-brand .light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.theme-brand .light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing,
.theme-brand.light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.theme-brand.light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.theme-brand.light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mdc-data-table__cell,
.theme-brand .light .mdc-data-table__header-cell,
.theme-brand.light .mdc-data-table__cell,
.theme-brand.light .mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mdc-data-table__pagination,
.theme-brand.light .mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mdc-data-table__row:not(.mdc-data-table__row--selected):hover,
.theme-brand.light .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.theme-brand .light .mdc-data-table__header-cell,
.theme-brand.light .mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}
.theme-brand .light .mdc-data-table__pagination-total,
.theme-brand .light .mdc-data-table__pagination-rows-per-page-label,
.theme-brand .light .mdc-data-table__cell,
.theme-brand.light .mdc-data-table__pagination-total,
.theme-brand.light .mdc-data-table__pagination-rows-per-page-label,
.theme-brand.light .mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}
[dir=rtl] .theme-brand .light .mdc-data-table__pagination-button .mdc-button__icon, [dir=rtl] .theme-brand.light .mdc-data-table__pagination-button .mdc-button__icon, .theme-brand .light .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl], .theme-brand.light .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-brand .light .mat-mdc-table,
.theme-brand.light .mat-mdc-table {
  background: white;
}
.theme-brand .light .mat-mdc-progress-spinner,
.theme-brand.light .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #2196f3;
}
.theme-brand .light .mat-mdc-progress-spinner.mat-accent,
.theme-brand.light .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-brand .light .mat-mdc-progress-spinner.mat-warn,
.theme-brand.light .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-brand .light .mat-badge-content,
.theme-brand.light .mat-badge-content {
  color: white;
  background: #2196f3;
}
.cdk-high-contrast-active .theme-brand .light .mat-badge-content, .cdk-high-contrast-active .theme-brand.light .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.theme-brand .light .mat-badge-accent .mat-badge-content,
.theme-brand.light .mat-badge-accent .mat-badge-content {
  background: #1e293b;
  color: white;
}
.theme-brand .light .mat-badge-warn .mat-badge-content,
.theme-brand.light .mat-badge-warn .mat-badge-content {
  color: white;
  background: #dc2626;
}
.theme-brand .light .mat-badge-disabled .mat-badge-content,
.theme-brand.light .mat-badge-disabled .mat-badge-content {
  background: #94a3b8;
  color: #94a3b8;
}
.theme-brand .light .mat-bottom-sheet-container,
.theme-brand.light .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: #1e293b;
}
.theme-brand .light .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-brand .light .mat-button-toggle-group:not([class*=mat-elevation-z]),
.theme-brand.light .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-brand.light .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-brand .light .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]),
.theme-brand.light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-brand.light .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.theme-brand .light .mat-button-toggle,
.theme-brand.light .mat-button-toggle {
  color: #94a3b8;
}
.theme-brand .light .mat-button-toggle .mat-button-toggle-focus-overlay,
.theme-brand.light .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: #64748b;
}
.theme-brand .light .mat-button-toggle-appearance-standard,
.theme-brand.light .mat-button-toggle-appearance-standard {
  color: #1e293b;
  background: white;
}
.theme-brand .light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay,
.theme-brand.light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: #64748b;
}
.theme-brand .light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
.theme-brand.light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e2e8f0;
}
.theme-brand .light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
.theme-brand.light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e2e8f0;
}
.theme-brand .light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle,
.theme-brand.light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e2e8f0;
}
.theme-brand .light .mat-button-toggle-checked,
.theme-brand.light .mat-button-toggle-checked {
  background-color: #e2e8f0;
  color: #64748b;
}
.theme-brand .light .mat-button-toggle-checked.mat-button-toggle-appearance-standard,
.theme-brand.light .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: #1e293b;
}
.theme-brand .light .mat-button-toggle-disabled,
.theme-brand.light .mat-button-toggle-disabled {
  color: #94a3b8;
  background-color: #cbd5e1;
}
.theme-brand .light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard,
.theme-brand.light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.theme-brand .light .mat-button-toggle-disabled.mat-button-toggle-checked,
.theme-brand.light .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #e2e8f0;
}
.theme-brand .light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-brand .light .mat-button-toggle-group-appearance-standard,
.theme-brand.light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-brand.light .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e2e8f0;
}
.theme-brand .light .mat-calendar-arrow,
.theme-brand.light .mat-calendar-arrow {
  fill: #64748b;
}
.theme-brand .light .mat-datepicker-toggle,
.theme-brand .light .mat-datepicker-content .mat-calendar-next-button,
.theme-brand .light .mat-datepicker-content .mat-calendar-previous-button,
.theme-brand.light .mat-datepicker-toggle,
.theme-brand.light .mat-datepicker-content .mat-calendar-next-button,
.theme-brand.light .mat-datepicker-content .mat-calendar-previous-button {
  color: #64748b;
}
.theme-brand .light .mat-calendar-table-header-divider::after,
.theme-brand.light .mat-calendar-table-header-divider::after {
  background: #e2e8f0;
}
.theme-brand .light .mat-calendar-table-header,
.theme-brand .light .mat-calendar-body-label,
.theme-brand.light .mat-calendar-table-header,
.theme-brand.light .mat-calendar-body-label {
  color: #64748b;
}
.theme-brand .light .mat-calendar-body-cell-content,
.theme-brand .light .mat-date-range-input-separator,
.theme-brand.light .mat-calendar-body-cell-content,
.theme-brand.light .mat-date-range-input-separator {
  color: #1e293b;
  border-color: transparent;
}
.theme-brand .light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-brand.light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: #94a3b8;
}
.theme-brand .light .mat-form-field-disabled .mat-date-range-input-separator,
.theme-brand.light .mat-form-field-disabled .mat-date-range-input-separator {
  color: #94a3b8;
}
.theme-brand .light .mat-calendar-body-in-preview,
.theme-brand.light .mat-calendar-body-in-preview {
  color: #e2e8f0;
}
.theme-brand .light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-brand.light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #94a3b8;
}
.theme-brand .light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-brand.light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(148, 163, 184, 0.8);
}
.theme-brand .light .mat-calendar-body-in-range::before,
.theme-brand.light .mat-calendar-body-in-range::before {
  background: rgba(33, 150, 243, 0.2);
}
.theme-brand .light .mat-calendar-body-comparison-identical,
.theme-brand .light .mat-calendar-body-in-comparison-range::before,
.theme-brand.light .mat-calendar-body-comparison-identical,
.theme-brand.light .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-brand .light .mat-calendar-body-comparison-bridge-start::before,
.theme-brand .light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-brand.light .mat-calendar-body-comparison-bridge-start::before,
.theme-brand.light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(33, 150, 243, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-brand .light .mat-calendar-body-comparison-bridge-end::before,
.theme-brand .light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-brand.light .mat-calendar-body-comparison-bridge-end::before,
.theme-brand.light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(33, 150, 243, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-brand .light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-brand .light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-brand.light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-brand.light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-brand .light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-brand .light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-brand.light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-brand.light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-brand .light .mat-calendar-body-selected,
.theme-brand.light .mat-calendar-body-selected {
  background-color: #2196f3;
  color: white;
}
.theme-brand .light .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-brand.light .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(33, 150, 243, 0.4);
}
.theme-brand .light .mat-calendar-body-today.mat-calendar-body-selected,
.theme-brand.light .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-brand .light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-brand .light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-brand.light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-brand.light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(33, 150, 243, 0.3);
}
@media (hover: hover) {
  .theme-brand .light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-brand.light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(33, 150, 243, 0.3);
  }
}
.theme-brand .light .mat-datepicker-content,
.theme-brand.light .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: #1e293b;
}
.theme-brand .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before,
.theme-brand.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(30, 41, 59, 0.2);
}
.theme-brand .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-brand .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before,
.theme-brand.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-brand.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-brand .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-brand .light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-brand.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-brand.light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(30, 41, 59, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-brand .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-brand .light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-brand.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-brand.light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(30, 41, 59, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-brand .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-brand .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-brand.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-brand.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-brand .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-brand .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-brand.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-brand.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-brand .light .mat-datepicker-content.mat-accent .mat-calendar-body-selected,
.theme-brand.light .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #1e293b;
  color: white;
}
.theme-brand .light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-brand.light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(30, 41, 59, 0.4);
}
.theme-brand .light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected,
.theme-brand.light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-brand .light .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-brand .light .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-brand.light .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-brand.light .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(30, 41, 59, 0.3);
}
@media (hover: hover) {
  .theme-brand .light .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-brand.light .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(30, 41, 59, 0.3);
  }
}
.theme-brand .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before,
.theme-brand.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(220, 38, 38, 0.2);
}
.theme-brand .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-brand .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before,
.theme-brand.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-brand.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-brand .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-brand .light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-brand.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-brand.light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(220, 38, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-brand .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-brand .light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-brand.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-brand.light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(220, 38, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-brand .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-brand .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-brand.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-brand.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-brand .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-brand .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-brand.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-brand.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-brand .light .mat-datepicker-content.mat-warn .mat-calendar-body-selected,
.theme-brand.light .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #dc2626;
  color: white;
}
.theme-brand .light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-brand.light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(220, 38, 38, 0.4);
}
.theme-brand .light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected,
.theme-brand.light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-brand .light .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-brand .light .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-brand.light .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-brand.light .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(220, 38, 38, 0.3);
}
@media (hover: hover) {
  .theme-brand .light .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-brand.light .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(220, 38, 38, 0.3);
  }
}
.theme-brand .light .mat-datepicker-content-touch,
.theme-brand.light .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-datepicker-toggle-active,
.theme-brand.light .mat-datepicker-toggle-active {
  color: #2196f3;
}
.theme-brand .light .mat-datepicker-toggle-active.mat-accent,
.theme-brand.light .mat-datepicker-toggle-active.mat-accent {
  color: #1e293b;
}
.theme-brand .light .mat-datepicker-toggle-active.mat-warn,
.theme-brand.light .mat-datepicker-toggle-active.mat-warn {
  color: #dc2626;
}
.theme-brand .light .mat-date-range-input-inner[disabled],
.theme-brand.light .mat-date-range-input-inner[disabled] {
  color: #94a3b8;
}
.theme-brand .light .mat-divider,
.theme-brand.light .mat-divider {
  border-top-color: #e2e8f0;
}
.theme-brand .light .mat-divider-vertical,
.theme-brand.light .mat-divider-vertical {
  border-right-color: #e2e8f0;
}
.theme-brand .light .mat-expansion-panel,
.theme-brand.light .mat-expansion-panel {
  background: white;
  color: #1e293b;
}
.theme-brand .light .mat-expansion-panel:not([class*=mat-elevation-z]),
.theme-brand.light .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-action-row,
.theme-brand.light .mat-action-row {
  border-top-color: #e2e8f0;
}
.theme-brand .light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .theme-brand .light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .theme-brand .light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]),
.theme-brand.light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
.theme-brand.light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
.theme-brand.light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(148, 163, 184, 0.12);
}
@media (hover: none) {
  .theme-brand .light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover,
  .theme-brand.light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.theme-brand .light .mat-expansion-panel-header-title,
.theme-brand.light .mat-expansion-panel-header-title {
  color: #1e293b;
}
.theme-brand .light .mat-expansion-panel-header-description,
.theme-brand .light .mat-expansion-indicator::after,
.theme-brand.light .mat-expansion-panel-header-description,
.theme-brand.light .mat-expansion-indicator::after {
  color: #64748b;
}
.theme-brand .light .mat-expansion-panel-header[aria-disabled=true],
.theme-brand.light .mat-expansion-panel-header[aria-disabled=true] {
  color: #94a3b8;
}
.theme-brand .light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-brand .light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description,
.theme-brand.light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-brand.light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.theme-brand .light .mat-icon.mat-primary,
.theme-brand.light .mat-icon.mat-primary {
  color: #2196f3;
}
.theme-brand .light .mat-icon.mat-accent,
.theme-brand.light .mat-icon.mat-accent {
  color: #1e293b;
}
.theme-brand .light .mat-icon.mat-warn,
.theme-brand.light .mat-icon.mat-warn {
  color: #dc2626;
}
.theme-brand .light .mat-drawer-container,
.theme-brand.light .mat-drawer-container {
  background-color: #f1f5f9;
  color: #1e293b;
}
.theme-brand .light .mat-drawer,
.theme-brand.light .mat-drawer {
  background-color: white;
  color: #1e293b;
}
.theme-brand .light .mat-drawer.mat-drawer-push,
.theme-brand.light .mat-drawer.mat-drawer-push {
  background-color: white;
}
.theme-brand .light .mat-drawer:not(.mat-drawer-side),
.theme-brand.light .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-drawer-side,
.theme-brand.light .mat-drawer-side {
  border-right: solid 1px #e2e8f0;
}
.theme-brand .light .mat-drawer-side.mat-drawer-end,
.theme-brand.light .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px #e2e8f0;
  border-right: none;
}
.theme-brand .light [dir=rtl] .mat-drawer-side,
.theme-brand.light [dir=rtl] .mat-drawer-side {
  border-left: solid 1px #e2e8f0;
  border-right: none;
}
.theme-brand .light [dir=rtl] .mat-drawer-side.mat-drawer-end,
.theme-brand.light [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px #e2e8f0;
}
.theme-brand .light .mat-drawer-backdrop.mat-drawer-shown,
.theme-brand.light .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.theme-brand .light .mat-step-header.cdk-keyboard-focused, .theme-brand .light .mat-step-header.cdk-program-focused, .theme-brand .light .mat-step-header:hover:not([aria-disabled]), .theme-brand .light .mat-step-header:hover[aria-disabled=false],
.theme-brand.light .mat-step-header.cdk-keyboard-focused,
.theme-brand.light .mat-step-header.cdk-program-focused,
.theme-brand.light .mat-step-header:hover:not([aria-disabled]),
.theme-brand.light .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(148, 163, 184, 0.12);
}
.theme-brand .light .mat-step-header:hover[aria-disabled=true],
.theme-brand.light .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .theme-brand .light .mat-step-header:hover,
  .theme-brand.light .mat-step-header:hover {
    background: none;
  }
}
.theme-brand .light .mat-step-header .mat-step-label,
.theme-brand .light .mat-step-header .mat-step-optional,
.theme-brand.light .mat-step-header .mat-step-label,
.theme-brand.light .mat-step-header .mat-step-optional {
  color: #64748b;
}
.theme-brand .light .mat-step-header .mat-step-icon,
.theme-brand.light .mat-step-header .mat-step-icon {
  background-color: #64748b;
  color: white;
}
.theme-brand .light .mat-step-header .mat-step-icon-selected,
.theme-brand .light .mat-step-header .mat-step-icon-state-done,
.theme-brand .light .mat-step-header .mat-step-icon-state-edit,
.theme-brand.light .mat-step-header .mat-step-icon-selected,
.theme-brand.light .mat-step-header .mat-step-icon-state-done,
.theme-brand.light .mat-step-header .mat-step-icon-state-edit {
  background-color: #2196f3;
  color: white;
}
.theme-brand .light .mat-step-header.mat-accent .mat-step-icon,
.theme-brand.light .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.theme-brand .light .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-brand .light .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-brand .light .mat-step-header.mat-accent .mat-step-icon-state-edit,
.theme-brand.light .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-brand.light .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-brand.light .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #1e293b;
  color: white;
}
.theme-brand .light .mat-step-header.mat-warn .mat-step-icon,
.theme-brand.light .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.theme-brand .light .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-brand .light .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-brand .light .mat-step-header.mat-warn .mat-step-icon-state-edit,
.theme-brand.light .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-brand.light .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-brand.light .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #dc2626;
  color: white;
}
.theme-brand .light .mat-step-header .mat-step-icon-state-error,
.theme-brand.light .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #dc2626;
}
.theme-brand .light .mat-step-header .mat-step-label.mat-step-label-active,
.theme-brand.light .mat-step-header .mat-step-label.mat-step-label-active {
  color: #1e293b;
}
.theme-brand .light .mat-step-header .mat-step-label.mat-step-label-error,
.theme-brand.light .mat-step-header .mat-step-label.mat-step-label-error {
  color: #dc2626;
}
.theme-brand .light .mat-stepper-horizontal, .theme-brand .light .mat-stepper-vertical,
.theme-brand.light .mat-stepper-horizontal,
.theme-brand.light .mat-stepper-vertical {
  background-color: white;
}
.theme-brand .light .mat-stepper-vertical-line::before,
.theme-brand.light .mat-stepper-vertical-line::before {
  border-left-color: #e2e8f0;
}
.theme-brand .light .mat-horizontal-stepper-header::before,
.theme-brand .light .mat-horizontal-stepper-header::after,
.theme-brand .light .mat-stepper-horizontal-line,
.theme-brand.light .mat-horizontal-stepper-header::before,
.theme-brand.light .mat-horizontal-stepper-header::after,
.theme-brand.light .mat-stepper-horizontal-line {
  border-top-color: #e2e8f0;
}
.theme-brand .light .mat-sort-header-arrow,
.theme-brand.light .mat-sort-header-arrow {
  color: #64748b;
}
.theme-brand .light .mat-toolbar,
.theme-brand.light .mat-toolbar {
  background: white;
  color: #1e293b;
}
.theme-brand .light .mat-toolbar.mat-primary,
.theme-brand.light .mat-toolbar.mat-primary {
  background: #2196f3;
  color: white;
}
.theme-brand .light .mat-toolbar.mat-accent,
.theme-brand.light .mat-toolbar.mat-accent {
  background: #1e293b;
  color: white;
}
.theme-brand .light .mat-toolbar.mat-warn,
.theme-brand.light .mat-toolbar.mat-warn {
  background: #dc2626;
  color: white;
}
.theme-brand .light .mat-toolbar .mat-form-field-underline,
.theme-brand .light .mat-toolbar .mat-form-field-ripple,
.theme-brand .light .mat-toolbar .mat-focused .mat-form-field-ripple,
.theme-brand.light .mat-toolbar .mat-form-field-underline,
.theme-brand.light .mat-toolbar .mat-form-field-ripple,
.theme-brand.light .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.theme-brand .light .mat-toolbar .mat-form-field-label,
.theme-brand .light .mat-toolbar .mat-focused .mat-form-field-label,
.theme-brand .light .mat-toolbar .mat-select-value,
.theme-brand .light .mat-toolbar .mat-select-arrow,
.theme-brand .light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow,
.theme-brand.light .mat-toolbar .mat-form-field-label,
.theme-brand.light .mat-toolbar .mat-focused .mat-form-field-label,
.theme-brand.light .mat-toolbar .mat-select-value,
.theme-brand.light .mat-toolbar .mat-select-arrow,
.theme-brand.light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.theme-brand .light .mat-toolbar .mat-input-element,
.theme-brand.light .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.theme-brand .light .mat-tree,
.theme-brand.light .mat-tree {
  background: white;
}
.theme-brand .light .mat-tree-node,
.theme-brand .light .mat-nested-tree-node,
.theme-brand.light .mat-tree-node,
.theme-brand.light .mat-nested-tree-node {
  color: #1e293b;
}

.theme-brand .dark .mat-ripple-element,
.theme-brand.dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .dark .mat-mdc-option,
.theme-brand.dark .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-brand .dark .mat-mdc-option:hover:not(.mdc-list-item--disabled), .theme-brand .dark .mat-mdc-option:focus:not(.mdc-list-item--disabled), .theme-brand .dark .mat-mdc-option.mat-mdc-option-active, .theme-brand .dark .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled),
.theme-brand.dark .mat-mdc-option:hover:not(.mdc-list-item--disabled),
.theme-brand.dark .mat-mdc-option:focus:not(.mdc-list-item--disabled),
.theme-brand.dark .mat-mdc-option.mat-mdc-option-active,
.theme-brand.dark .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(255, 255, 255, 0.08);
}
.theme-brand .dark .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-brand.dark .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #2196f3);
}
.theme-brand .dark .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-brand.dark .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #1e293b);
}
.theme-brand .dark .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-brand.dark .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mat-mdc-optgroup-label,
.theme-brand.dark .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-brand .dark .mat-pseudo-checkbox,
.theme-brand.dark .mat-pseudo-checkbox {
  color: #94a3b8;
}
.theme-brand .dark .mat-pseudo-checkbox::after,
.theme-brand.dark .mat-pseudo-checkbox::after {
  color: #0f172a;
}
.theme-brand .dark .mat-pseudo-checkbox-disabled,
.theme-brand.dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.theme-brand .dark .mat-primary .mat-pseudo-checkbox-checked,
.theme-brand .dark .mat-primary .mat-pseudo-checkbox-indeterminate,
.theme-brand.dark .mat-primary .mat-pseudo-checkbox-checked,
.theme-brand.dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #2196f3;
}
.theme-brand .dark .mat-pseudo-checkbox-checked,
.theme-brand .dark .mat-pseudo-checkbox-indeterminate,
.theme-brand .dark .mat-accent .mat-pseudo-checkbox-checked,
.theme-brand .dark .mat-accent .mat-pseudo-checkbox-indeterminate,
.theme-brand.dark .mat-pseudo-checkbox-checked,
.theme-brand.dark .mat-pseudo-checkbox-indeterminate,
.theme-brand.dark .mat-accent .mat-pseudo-checkbox-checked,
.theme-brand.dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #1e293b;
}
.theme-brand .dark .mat-warn .mat-pseudo-checkbox-checked,
.theme-brand .dark .mat-warn .mat-pseudo-checkbox-indeterminate,
.theme-brand.dark .mat-warn .mat-pseudo-checkbox-checked,
.theme-brand.dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #dc2626;
}
.theme-brand .dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-brand .dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled,
.theme-brand.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-brand.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.theme-brand .dark .mat-app-background, .theme-brand .dark.mat-app-background,
.theme-brand.dark .mat-app-background,
.theme-brand.dark.mat-app-background {
  background-color: #0f172a;
  color: white;
}
.theme-brand .dark .mat-elevation-z0, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-brand.dark .mat-elevation-z0,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z1, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-brand.dark .mat-elevation-z1,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z2, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-brand.dark .mat-elevation-z2,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z3, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-brand.dark .mat-elevation-z3,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z4, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-brand.dark .mat-elevation-z4,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z5, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-brand.dark .mat-elevation-z5,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z6, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-brand.dark .mat-elevation-z6,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z7, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-brand.dark .mat-elevation-z7,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z8, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-brand.dark .mat-elevation-z8,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z9, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-brand.dark .mat-elevation-z9,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z10, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-brand.dark .mat-elevation-z10,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z11, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-brand.dark .mat-elevation-z11,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z12, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-brand.dark .mat-elevation-z12,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z13, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-brand.dark .mat-elevation-z13,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z14, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-brand.dark .mat-elevation-z14,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z15, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-brand.dark .mat-elevation-z15,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z16, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-brand.dark .mat-elevation-z16,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z17, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-brand.dark .mat-elevation-z17,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z18, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-brand.dark .mat-elevation-z18,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z19, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-brand.dark .mat-elevation-z19,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z20, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-brand.dark .mat-elevation-z20,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z21, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-brand.dark .mat-elevation-z21,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z22, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-brand.dark .mat-elevation-z22,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z23, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-brand.dark .mat-elevation-z23,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z24, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-brand.dark .mat-elevation-z24,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-brand .dark .mat-mdc-card,
.theme-brand.dark .mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #1e293b;
}
.theme-brand .dark .mat-mdc-card-outlined,
.theme-brand.dark .mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #394353;
}
.theme-brand .dark .mat-mdc-card-subtitle,
.theme-brand.dark .mat-mdc-card-subtitle {
  color: #94a3b8;
}
.theme-brand .dark .mat-mdc-progress-bar,
.theme-brand.dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #2196f3;
}
.theme-brand .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.theme-brand.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(33, 150, 243, 0.25)'/%3E%3C/svg%3E");
}
.theme-brand .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.theme-brand.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(33, 150, 243, 0.25);
}
.theme-brand .dark .mat-mdc-progress-bar.mat-accent,
.theme-brand.dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
}
.theme-brand .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.theme-brand.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(30, 41, 59, 0.25)'/%3E%3C/svg%3E");
}
.theme-brand .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.theme-brand.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(30, 41, 59, 0.25);
}
.theme-brand .dark .mat-mdc-progress-bar.mat-warn,
.theme-brand.dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
}
.theme-brand .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.theme-brand.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(220, 38, 38, 0.25)'/%3E%3C/svg%3E");
}
.theme-brand .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.theme-brand.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(220, 38, 38, 0.25);
}
.theme-brand .dark .mat-mdc-tooltip,
.theme-brand.dark .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.theme-brand .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-brand.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 255, 255, 0.6);
}
.theme-brand .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input,
.theme-brand.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.87);
}
@media all {
  .theme-brand .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder,
  .theme-brand.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}
@media all {
  .theme-brand .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder,
  .theme-brand.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}
.theme-brand .dark .mdc-text-field .mdc-text-field__input,
.theme-brand.dark .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #2196f3);
}
.theme-brand .dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text,
.theme-brand.dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.theme-brand .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.theme-brand .dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter,
.theme-brand.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.theme-brand.dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.theme-brand .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading,
.theme-brand.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.theme-brand .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-brand.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.theme-brand .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix,
.theme-brand.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-brand .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix,
.theme-brand.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-brand .dark .mdc-text-field--filled .mdc-text-field__ripple::before,
.theme-brand .dark .mdc-text-field--filled .mdc-text-field__ripple::after,
.theme-brand.dark .mdc-text-field--filled .mdc-text-field__ripple::before,
.theme-brand.dark .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(255, 255, 255, 0.87));
}
.theme-brand .dark .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .theme-brand .dark .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before,
.theme-brand.dark .mdc-text-field--filled:hover .mdc-text-field__ripple::before,
.theme-brand.dark .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.theme-brand .dark .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .theme-brand .dark .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before,
.theme-brand.dark .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before,
.theme-brand.dark .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.theme-brand .dark .mdc-text-field--filled:not(.mdc-text-field--disabled),
.theme-brand.dark .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #273243;
}
.theme-brand .dark .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-brand.dark .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.42);
}
.theme-brand .dark .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-brand.dark .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.87);
}
.theme-brand .dark .mdc-text-field--filled .mdc-line-ripple::after,
.theme-brand.dark .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #2196f3);
}
.theme-brand .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-brand .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-brand .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-brand.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-brand.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-brand.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.38);
}
.theme-brand .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-brand .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-brand .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-brand.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-brand.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-brand.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.87);
}
.theme-brand .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-brand .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-brand .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-brand.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-brand.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-brand.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #2196f3);
}
[dir=rtl] .theme-brand .dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon, [dir=rtl] .theme-brand.dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .theme-brand .dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl], .theme-brand.dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-brand .dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, [dir=rtl] .theme-brand.dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .theme-brand .dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl], .theme-brand.dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-brand .dark .mdc-text-field--outlined .mdc-text-field__ripple::before,
.theme-brand .dark .mdc-text-field--outlined .mdc-text-field__ripple::after,
.theme-brand.dark .mdc-text-field--outlined .mdc-text-field__ripple::before,
.theme-brand.dark .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .theme-brand .dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined, [dir=rtl] .theme-brand.dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .theme-brand .dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl], .theme-brand.dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-brand .dark .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-brand.dark .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(33, 150, 243, 0.87);
}
.theme-brand .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-brand.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-brand.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-brand.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-brand.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mdc-text-field--invalid .mdc-text-field__input,
.theme-brand.dark .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-brand.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-brand.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-brand .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-brand .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-brand.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-brand.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-brand.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-brand .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-brand .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-brand.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-brand.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-brand.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-brand .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-brand .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-brand.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-brand.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-brand.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mdc-text-field--disabled .mdc-text-field__input,
.theme-brand.dark .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.38);
}
@media all {
  .theme-brand .dark .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  .theme-brand.dark .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.38);
  }
}
@media all {
  .theme-brand .dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  .theme-brand.dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.38);
  }
}
.theme-brand .dark .mdc-text-field--disabled .mdc-floating-label,
.theme-brand.dark .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(255, 255, 255, 0.38);
}
.theme-brand .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
.theme-brand.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.theme-brand .dark .mdc-text-field--disabled .mdc-text-field-character-counter,
.theme-brand .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
.theme-brand.dark .mdc-text-field--disabled .mdc-text-field-character-counter,
.theme-brand.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.theme-brand .dark .mdc-text-field--disabled .mdc-text-field__icon--leading,
.theme-brand.dark .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.theme-brand .dark .mdc-text-field--disabled .mdc-text-field__icon--trailing,
.theme-brand.dark .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.theme-brand .dark .mdc-text-field--disabled .mdc-text-field__affix--prefix,
.theme-brand.dark .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-brand .dark .mdc-text-field--disabled .mdc-text-field__affix--suffix,
.theme-brand.dark .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-brand .dark .mdc-text-field--disabled .mdc-line-ripple::before,
.theme-brand.dark .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.06);
}
.theme-brand .dark .mdc-text-field--disabled .mdc-notched-outline__leading,
.theme-brand .dark .mdc-text-field--disabled .mdc-notched-outline__notch,
.theme-brand .dark .mdc-text-field--disabled .mdc-notched-outline__trailing,
.theme-brand.dark .mdc-text-field--disabled .mdc-notched-outline__leading,
.theme-brand.dark .mdc-text-field--disabled .mdc-notched-outline__notch,
.theme-brand.dark .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-brand .dark .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  .theme-brand.dark .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-brand .dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  .theme-brand.dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-brand .dark .mdc-text-field--disabled .mdc-floating-label,
  .theme-brand.dark .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-brand .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
  .theme-brand.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-brand .dark .mdc-text-field--disabled .mdc-text-field-character-counter,
  .theme-brand .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
  .theme-brand.dark .mdc-text-field--disabled .mdc-text-field-character-counter,
  .theme-brand.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-brand .dark .mdc-text-field--disabled .mdc-text-field__icon--leading,
  .theme-brand.dark .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-brand .dark .mdc-text-field--disabled .mdc-text-field__icon--trailing,
  .theme-brand.dark .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-brand .dark .mdc-text-field--disabled .mdc-text-field__affix--prefix,
  .theme-brand.dark .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-brand .dark .mdc-text-field--disabled .mdc-text-field__affix--suffix,
  .theme-brand.dark .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-brand .dark .mdc-text-field--disabled .mdc-line-ripple::before,
  .theme-brand.dark .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-brand .dark .mdc-text-field--disabled .mdc-notched-outline__leading,
  .theme-brand .dark .mdc-text-field--disabled .mdc-notched-outline__notch,
  .theme-brand .dark .mdc-text-field--disabled .mdc-notched-outline__trailing,
  .theme-brand.dark .mdc-text-field--disabled .mdc-notched-outline__leading,
  .theme-brand.dark .mdc-text-field--disabled .mdc-notched-outline__notch,
  .theme-brand.dark .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.theme-brand .dark .mdc-text-field--disabled.mdc-text-field--filled,
.theme-brand.dark .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #232d3f;
}
[dir=rtl] .theme-brand .dark .mdc-text-field--end-aligned .mdc-text-field__input, [dir=rtl] .theme-brand.dark .mdc-text-field--end-aligned .mdc-text-field__input, .theme-brand .dark .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl], .theme-brand.dark .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-brand .dark .mdc-floating-label--required, [dir=rtl] .theme-brand.dark .mdc-floating-label--required, .theme-brand .dark .mdc-floating-label--required[dir=rtl], .theme-brand.dark .mdc-floating-label--required[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-brand .dark .mat-mdc-form-field-error,
.theme-brand.dark .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mat-mdc-form-field-focus-overlay,
.theme-brand.dark .mat-mdc-form-field-focus-overlay {
  background-color: rgba(255, 255, 255, 0.87);
}
.theme-brand .dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.theme-brand.dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.08;
}
.theme-brand .dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.theme-brand.dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.24;
}
.theme-brand .dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option,
.theme-brand.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.theme-brand .dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled,
.theme-brand.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.theme-brand .dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.theme-brand.dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.54);
}
.theme-brand .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.theme-brand.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(33, 150, 243, 0.87);
}
.theme-brand .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.theme-brand.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(30, 41, 59, 0.87);
}
.theme-brand .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.theme-brand.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(220, 38, 38, 0.87);
}
.theme-brand .dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.theme-brand.dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.38);
}
.theme-brand .dark .mat-mdc-form-field.mat-accent .mdc-text-field__input,
.theme-brand.dark .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-brand .dark .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-brand.dark .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-brand .dark .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-brand.dark .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(30, 41, 59, 0.87);
}
.theme-brand .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-brand.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-brand.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-brand.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-brand.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input,
.theme-brand.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-brand.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-brand.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-brand .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-brand .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-brand.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-brand.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-brand.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-brand .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-brand .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-brand.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-brand.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-brand.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-brand .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-brand .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-brand.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-brand.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-brand.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-brand .dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-brand .dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-brand.dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-brand.dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-brand.dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-brand .dark .mat-mdc-form-field.mat-warn .mdc-text-field__input,
.theme-brand.dark .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-brand.dark .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-brand.dark .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(220, 38, 38, 0.87);
}
.theme-brand .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-brand.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-brand.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-brand.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-brand.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input,
.theme-brand.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-brand.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-brand.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-brand .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-brand .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-brand.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-brand.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-brand.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-brand .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-brand .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-brand.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-brand.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-brand.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-brand .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-brand .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-brand.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-brand.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-brand.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-brand .dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-brand .dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-brand.dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-brand.dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-brand.dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-brand .dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.theme-brand.dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.theme-brand .dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.theme-brand.dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.theme-brand .dark .mdc-menu-surface,
.theme-brand.dark .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #1e293b);
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mdc-list-item__primary-text,
.theme-brand.dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-brand .dark .mdc-list-item__secondary-text,
.theme-brand.dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.theme-brand .dark .mdc-list-item__overline-text,
.theme-brand.dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-brand .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-brand.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-brand .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-brand.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.theme-brand .dark .mdc-list-item__end,
.theme-brand.dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-brand .dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-brand .dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-brand .dark .mdc-list-item--disabled .mdc-list-item__end,
.theme-brand.dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-brand.dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-brand.dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-brand .dark .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-brand.dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-brand.dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-brand.dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-brand.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-brand.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-brand .dark .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-brand.dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-brand.dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #2196f3);
}
.theme-brand .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #2196f3);
}
.theme-brand .dark .mdc-deprecated-list-group__subheader,
.theme-brand.dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-brand .dark .mdc-list-divider::after,
.theme-brand.dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-brand .dark .mdc-list-divider,
.theme-brand.dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-brand .dark .mat-mdc-select-value,
.theme-brand.dark .mat-mdc-select-value {
  color: rgba(255, 255, 255, 0.87);
}
.theme-brand .dark .mat-mdc-select-placeholder,
.theme-brand.dark .mat-mdc-select-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.theme-brand .dark .mat-mdc-select-disabled .mat-mdc-select-value,
.theme-brand.dark .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(255, 255, 255, 0.38);
}
.theme-brand .dark .mat-mdc-select-arrow,
.theme-brand.dark .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.54);
}
.theme-brand .dark .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow,
.theme-brand.dark .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(33, 150, 243, 0.87);
}
.theme-brand .dark .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow,
.theme-brand.dark .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(30, 41, 59, 0.87);
}
.theme-brand .dark .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow,
.theme-brand.dark .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(220, 38, 38, 0.87);
}
.theme-brand .dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow,
.theme-brand.dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(220, 38, 38, 0.87);
}
.theme-brand .dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow,
.theme-brand.dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.38);
}
.theme-brand .dark .mdc-menu-surface,
.theme-brand.dark .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #1e293b);
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mdc-list-item__primary-text,
.theme-brand.dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-brand .dark .mdc-list-item__secondary-text,
.theme-brand.dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.theme-brand .dark .mdc-list-item__overline-text,
.theme-brand.dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-brand .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-brand.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-brand .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-brand.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.theme-brand .dark .mdc-list-item__end,
.theme-brand.dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-brand .dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-brand .dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-brand .dark .mdc-list-item--disabled .mdc-list-item__end,
.theme-brand.dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-brand.dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-brand.dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-brand .dark .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-brand.dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-brand.dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-brand.dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-brand.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-brand.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-brand .dark .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-brand.dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-brand.dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #2196f3);
}
.theme-brand .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #2196f3);
}
.theme-brand .dark .mdc-deprecated-list-group__subheader,
.theme-brand.dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-brand .dark .mdc-list-divider::after,
.theme-brand.dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-brand .dark .mdc-list-divider,
.theme-brand.dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-brand .dark .mat-mdc-dialog-container,
.theme-brand.dark .mat-mdc-dialog-container {
  --mdc-dialog-container-color: #1e293b;
  --mdc-dialog-with-divider-divider-color: rgba(255, 255, 255, 0.12);
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
}
.theme-brand .dark .mat-mdc-standard-chip,
.theme-brand.dark .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #394353;
  --mdc-chip-elevated-disabled-container-color: #394353;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
}
.theme-brand .dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .theme-brand .dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted,
.theme-brand.dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected,
.theme-brand.dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #2196f3;
  --mdc-chip-elevated-disabled-container-color: #2196f3;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-brand .dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .theme-brand .dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted,
.theme-brand.dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected,
.theme-brand.dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-brand .dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .theme-brand .dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted,
.theme-brand.dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected,
.theme-brand.dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-brand .dark .mat-mdc-chip-focus-overlay,
.theme-brand.dark .mat-mdc-chip-focus-overlay {
  background: #FFFFFF;
}
.theme-brand .dark .mat-mdc-slide-toggle,
.theme-brand.dark .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-unselected-icon-color: #212121;
}
.theme-brand .dark .mat-mdc-slide-toggle .mdc-form-field,
.theme-brand.dark .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-brand .dark .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.theme-brand.dark .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: #475569;
}
.theme-brand .dark .mat-mdc-slide-toggle.mat-primary,
.theme-brand.dark .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #a1c6f8;
  --mdc-switch-selected-handle-color: #a1c6f8;
  --mdc-switch-selected-hover-state-layer-color: #a1c6f8;
  --mdc-switch-selected-pressed-state-layer-color: #a1c6f8;
  --mdc-switch-selected-focus-handle-color: #c4dafa;
  --mdc-switch-selected-hover-handle-color: #c4dafa;
  --mdc-switch-selected-pressed-handle-color: #c4dafa;
  --mdc-switch-selected-focus-track-color: #1e88dc;
  --mdc-switch-selected-hover-track-color: #1e88dc;
  --mdc-switch-selected-pressed-track-color: #1e88dc;
  --mdc-switch-selected-track-color: #1e88dc;
}
.theme-brand .dark .mat-mdc-slide-toggle.mat-accent,
.theme-brand.dark .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #cbd5e1;
  --mdc-switch-selected-handle-color: #cbd5e1;
  --mdc-switch-selected-hover-state-layer-color: #cbd5e1;
  --mdc-switch-selected-pressed-state-layer-color: #cbd5e1;
  --mdc-switch-selected-focus-handle-color: #e2e8f0;
  --mdc-switch-selected-hover-handle-color: #e2e8f0;
  --mdc-switch-selected-pressed-handle-color: #e2e8f0;
  --mdc-switch-selected-focus-track-color: #475569;
  --mdc-switch-selected-hover-track-color: #475569;
  --mdc-switch-selected-pressed-track-color: #475569;
  --mdc-switch-selected-track-color: #475569;
}
.theme-brand .dark .mat-mdc-slide-toggle.mat-warn,
.theme-brand.dark .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #fca5a5;
  --mdc-switch-selected-handle-color: #fca5a5;
  --mdc-switch-selected-hover-state-layer-color: #fca5a5;
  --mdc-switch-selected-pressed-state-layer-color: #fca5a5;
  --mdc-switch-selected-focus-handle-color: #fecaca;
  --mdc-switch-selected-hover-handle-color: #fecaca;
  --mdc-switch-selected-pressed-handle-color: #fecaca;
  --mdc-switch-selected-focus-track-color: #dc2626;
  --mdc-switch-selected-hover-track-color: #dc2626;
  --mdc-switch-selected-pressed-track-color: #dc2626;
  --mdc-switch-selected-track-color: #dc2626;
}
.theme-brand .dark .mat-mdc-radio-button,
.theme-brand.dark .mat-mdc-radio-button {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mat-mdc-radio-ripple-color: #fff;
}
.theme-brand .dark .mat-mdc-radio-button .mdc-form-field,
.theme-brand.dark .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-brand .dark .mat-mdc-radio-button .mdc-radio--disabled + label,
.theme-brand.dark .mat-mdc-radio-button .mdc-radio--disabled + label {
  color: #475569;
}
.theme-brand .dark .mat-mdc-radio-button.mat-primary,
.theme-brand.dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-selected-focus-icon-color: #2196f3;
  --mdc-radio-selected-hover-icon-color: #2196f3;
  --mdc-radio-selected-icon-color: #2196f3;
  --mdc-radio-selected-pressed-icon-color: #2196f3;
  --mat-mdc-radio-checked-ripple-color: #2196f3;
}
.theme-brand .dark .mat-mdc-radio-button.mat-accent,
.theme-brand.dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-mdc-radio-checked-ripple-color: #1e293b;
}
.theme-brand .dark .mat-mdc-radio-button.mat-warn,
.theme-brand.dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-mdc-radio-checked-ripple-color: #dc2626;
}
.theme-brand .dark .mat-mdc-slider,
.theme-brand.dark .mat-mdc-slider {
  --mdc-slider-label-container-color: white;
  --mdc-slider-label-label-text-color: black;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mat-mdc-slider-value-indicator-opacity: 0.9;
}
.theme-brand .dark .mat-mdc-slider.mat-primary,
.theme-brand.dark .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #2196f3;
  --mdc-slider-focus-handle-color: #2196f3;
  --mdc-slider-hover-handle-color: #2196f3;
  --mdc-slider-active-track-color: #2196f3;
  --mdc-slider-inactive-track-color: #2196f3;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #2196f3;
  --mat-mdc-slider-ripple-color: #2196f3;
  --mat-mdc-slider-hover-ripple-color: rgba(33, 150, 243, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(33, 150, 243, 0.2);
}
.theme-brand .dark .mat-mdc-slider.mat-accent,
.theme-brand.dark .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mat-mdc-slider-ripple-color: #1e293b;
  --mat-mdc-slider-hover-ripple-color: rgba(30, 41, 59, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(30, 41, 59, 0.2);
}
.theme-brand .dark .mat-mdc-slider.mat-warn,
.theme-brand.dark .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mat-mdc-slider-ripple-color: #dc2626;
  --mat-mdc-slider-hover-ripple-color: rgba(220, 38, 38, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(220, 38, 38, 0.2);
}
.theme-brand .dark .mdc-menu-surface,
.theme-brand.dark .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #1e293b);
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mdc-list-item__primary-text,
.theme-brand.dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-brand .dark .mdc-list-item__secondary-text,
.theme-brand.dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.theme-brand .dark .mdc-list-item__overline-text,
.theme-brand.dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-brand .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-brand.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-brand .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-brand.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.theme-brand .dark .mdc-list-item__end,
.theme-brand.dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-brand .dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-brand .dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-brand .dark .mdc-list-item--disabled .mdc-list-item__end,
.theme-brand.dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-brand.dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-brand.dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-brand .dark .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-brand.dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-brand.dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-brand.dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-brand.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-brand.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-brand .dark .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-brand.dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-brand.dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #2196f3);
}
.theme-brand .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #2196f3);
}
.theme-brand .dark .mdc-deprecated-list-group__subheader,
.theme-brand.dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-brand .dark .mdc-list-divider::after,
.theme-brand.dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-brand .dark .mdc-list-divider,
.theme-brand.dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-brand .dark .mat-mdc-menu-item[disabled],
.theme-brand .dark .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.theme-brand .dark .mat-mdc-menu-item[disabled] .mat-icon-no-color,
.theme-brand.dark .mat-mdc-menu-item[disabled],
.theme-brand.dark .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.theme-brand.dark .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(255, 255, 255, 0.5));
}
.theme-brand .dark .mat-mdc-menu-item .mat-icon-no-color,
.theme-brand .dark .mat-mdc-menu-submenu-icon,
.theme-brand.dark .mat-mdc-menu-item .mat-icon-no-color,
.theme-brand.dark .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-brand .dark .mat-mdc-menu-item:hover:not([disabled]),
.theme-brand .dark .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.theme-brand .dark .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-brand .dark .mat-mdc-menu-item-highlighted:not([disabled]),
.theme-brand.dark .mat-mdc-menu-item:hover:not([disabled]),
.theme-brand.dark .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.theme-brand.dark .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-brand.dark .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.08);
}
.theme-brand .dark .mat-mdc-list-item-interactive::before,
.theme-brand.dark .mat-mdc-list-item-interactive::before {
  background: white;
}
.theme-brand .dark .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before,
.theme-brand.dark .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.08;
}
.theme-brand .dark .mat-mdc-list-item-interactive.mdc-list-item--selected::before,
.theme-brand.dark .mat-mdc-list-item-interactive.mdc-list-item--selected::before {
  background: #2196f3;
  opacity: 0.08;
}
.theme-brand .dark .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before,
.theme-brand.dark .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.12;
}
.theme-brand .dark .mat-mdc-list-item-interactive:focus::before,
.theme-brand.dark .mat-mdc-list-item-interactive:focus::before {
  opacity: 0.24;
}
.theme-brand .dark .mdc-list-item__primary-text,
.theme-brand.dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-brand .dark .mdc-list-item__secondary-text,
.theme-brand.dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.theme-brand .dark .mdc-list-item__overline-text,
.theme-brand.dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-brand .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-brand.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-brand .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-brand.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.theme-brand .dark .mdc-list-item__end,
.theme-brand.dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-brand .dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-brand .dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-brand .dark .mdc-list-item--disabled .mdc-list-item__end,
.theme-brand.dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-brand.dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-brand.dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-brand .dark .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-brand.dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-brand.dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-brand.dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-brand.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-brand.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-brand .dark .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-brand.dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-brand.dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #2196f3);
}
.theme-brand .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #2196f3);
}
.theme-brand .dark .mdc-deprecated-list-group__subheader,
.theme-brand.dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-brand .dark .mdc-list-divider::after,
.theme-brand.dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-brand .dark .mdc-list-divider,
.theme-brand.dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-brand .dark .mat-mdc-list-option .mdc-list-item__start, .theme-brand .dark .mat-mdc-list-option .mdc-list-item__end,
.theme-brand.dark .mat-mdc-list-option .mdc-list-item__start,
.theme-brand.dark .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #2196f3;
  --mdc-checkbox-selected-hover-icon-color: #2196f3;
  --mdc-checkbox-selected-icon-color: #2196f3;
  --mdc-checkbox-selected-pressed-icon-color: #2196f3;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-brand .dark .mat-mdc-list-option.mat-accent .mdc-list-item__start, .theme-brand .dark .mat-mdc-list-option.mat-accent .mdc-list-item__end,
.theme-brand.dark .mat-mdc-list-option.mat-accent .mdc-list-item__start,
.theme-brand.dark .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-brand .dark .mat-mdc-list-option.mat-warn .mdc-list-item__start, .theme-brand .dark .mat-mdc-list-option.mat-warn .mdc-list-item__end,
.theme-brand.dark .mat-mdc-list-option.mat-warn .mdc-list-item__start,
.theme-brand.dark .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-brand .dark .mat-mdc-paginator,
.theme-brand.dark .mat-mdc-paginator {
  background: #1e293b;
  color: rgba(255, 255, 255, 0.87);
}
.theme-brand .dark .mat-mdc-paginator-icon,
.theme-brand.dark .mat-mdc-paginator-icon {
  fill: rgba(255, 255, 255, 0.54);
}
.theme-brand .dark .mat-mdc-paginator-decrement,
.theme-brand .dark .mat-mdc-paginator-increment,
.theme-brand.dark .mat-mdc-paginator-decrement,
.theme-brand.dark .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(255, 255, 255, 0.54);
  border-right: 2px solid rgba(255, 255, 255, 0.54);
}
.theme-brand .dark .mat-mdc-paginator-first,
.theme-brand .dark .mat-mdc-paginator-last,
.theme-brand.dark .mat-mdc-paginator-first,
.theme-brand.dark .mat-mdc-paginator-last {
  border-top: 2px solid rgba(255, 255, 255, 0.54);
}
.theme-brand .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.theme-brand .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.theme-brand .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.theme-brand .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last,
.theme-brand.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.theme-brand.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.theme-brand.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.theme-brand.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-brand .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon,
.theme-brand.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(255, 255, 255, 0.12);
}
.theme-brand .dark .mat-mdc-tab, .theme-brand .dark .mat-mdc-tab-link,
.theme-brand.dark .mat-mdc-tab,
.theme-brand.dark .mat-mdc-tab-link {
  background-color: transparent;
}
.theme-brand .dark .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label, .theme-brand .dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.theme-brand.dark .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.theme-brand.dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  color: rgba(255, 255, 255, 0.6);
}
.theme-brand .dark .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-brand .dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-brand.dark .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-brand.dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #2196f3;
}
.theme-brand .dark .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-brand .dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-brand.dark .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-brand.dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #2196f3;
}
.theme-brand .dark .mdc-tab__ripple::before,
.theme-brand .dark .mat-mdc-tab .mat-ripple-element,
.theme-brand .dark .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-brand .dark .mat-mdc-tab-link .mat-ripple-element,
.theme-brand.dark .mdc-tab__ripple::before,
.theme-brand.dark .mat-mdc-tab .mat-ripple-element,
.theme-brand.dark .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-brand.dark .mat-mdc-tab-link .mat-ripple-element {
  background-color: #2196f3;
}
.theme-brand .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-brand .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-brand .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-brand .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-brand.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-brand.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-brand.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-brand.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #1e293b;
}
.theme-brand .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-brand .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-brand .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-brand .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-brand.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-brand.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-brand.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-brand.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #1e293b;
}
.theme-brand .dark .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.theme-brand .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-brand .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-brand .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .theme-brand .dark .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.theme-brand .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-brand .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-brand .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element,
.theme-brand.dark .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.theme-brand.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-brand.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-brand.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element,
.theme-brand.dark .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.theme-brand.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-brand.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-brand.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #1e293b;
}
.theme-brand .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-brand .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-brand .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-brand .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-brand.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-brand.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-brand.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-brand.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #dc2626;
}
.theme-brand .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-brand .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-brand .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-brand .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-brand.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-brand.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-brand.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-brand.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #dc2626;
}
.theme-brand .dark .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.theme-brand .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-brand .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-brand .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .theme-brand .dark .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.theme-brand .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-brand .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-brand .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element,
.theme-brand.dark .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.theme-brand.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-brand.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-brand.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element,
.theme-brand.dark .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.theme-brand.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-brand.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-brand.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #dc2626;
}
.theme-brand .dark .mat-mdc-tab-group.mat-background-primary, .theme-brand .dark .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-brand.dark .mat-mdc-tab-group.mat-background-primary,
.theme-brand.dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #2196f3;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-brand .dark .mat-mdc-tab-group.mat-background-accent, .theme-brand .dark .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-brand.dark .mat-mdc-tab-group.mat-background-accent,
.theme-brand.dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #1e293b;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-brand .dark .mat-mdc-tab-group.mat-background-warn, .theme-brand .dark .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-brand.dark .mat-mdc-tab-group.mat-background-warn,
.theme-brand.dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #dc2626;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-brand .dark .mat-mdc-tab-header-pagination-chevron,
.theme-brand.dark .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #fff);
}
.theme-brand .dark .mat-mdc-checkbox .mdc-form-field,
.theme-brand.dark .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-brand .dark .mat-mdc-checkbox .mat-ripple-element,
.theme-brand.dark .mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .dark .mat-mdc-checkbox .mdc-checkbox__ripple,
.theme-brand.dark .mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #fff;
}
.theme-brand .dark .mat-mdc-checkbox.mat-primary,
.theme-brand.dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #2196f3;
  --mdc-checkbox-selected-hover-icon-color: #2196f3;
  --mdc-checkbox-selected-icon-color: #2196f3;
  --mdc-checkbox-selected-pressed-icon-color: #2196f3;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-brand .dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-brand.dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(33, 150, 243, 0.1);
}
.theme-brand .dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-brand.dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #2196f3;
}
.theme-brand .dark .mat-mdc-checkbox.mat-accent,
.theme-brand.dark .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-brand .dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-brand.dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(30, 41, 59, 0.1);
}
.theme-brand .dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-brand.dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #1e293b;
}
.theme-brand .dark .mat-mdc-checkbox.mat-warn,
.theme-brand.dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-brand .dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-brand.dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(220, 38, 38, 0.1);
}
.theme-brand .dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-brand.dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #dc2626;
}
.theme-brand .dark .mat-mdc-checkbox-disabled label,
.theme-brand.dark .mat-mdc-checkbox-disabled label {
  color: #475569;
}
.theme-brand .dark .mat-mdc-button.mat-unthemed,
.theme-brand.dark .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #fff;
}
.theme-brand .dark .mat-mdc-button.mat-primary,
.theme-brand.dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #2196f3;
}
.theme-brand .dark .mat-mdc-button.mat-accent,
.theme-brand.dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
}
.theme-brand .dark .mat-mdc-button.mat-warn,
.theme-brand.dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
}
.theme-brand .dark .mat-mdc-button[disabled][disabled],
.theme-brand.dark .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-text-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.theme-brand .dark .mat-mdc-unelevated-button.mat-unthemed,
.theme-brand.dark .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-brand .dark .mat-mdc-unelevated-button.mat-primary,
.theme-brand.dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #2196f3;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-brand .dark .mat-mdc-unelevated-button.mat-accent,
.theme-brand.dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-brand .dark .mat-mdc-unelevated-button.mat-warn,
.theme-brand.dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-brand .dark .mat-mdc-unelevated-button[disabled][disabled],
.theme-brand.dark .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-filled-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.theme-brand .dark .mat-mdc-raised-button.mat-unthemed,
.theme-brand.dark .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-brand .dark .mat-mdc-raised-button.mat-primary,
.theme-brand.dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #2196f3;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-brand .dark .mat-mdc-raised-button.mat-accent,
.theme-brand.dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-brand .dark .mat-mdc-raised-button.mat-warn,
.theme-brand.dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-brand .dark .mat-mdc-raised-button[disabled][disabled],
.theme-brand.dark .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-elevation: 0;
}
.theme-brand .dark .mat-mdc-outlined-button,
.theme-brand.dark .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.theme-brand .dark .mat-mdc-outlined-button.mat-unthemed,
.theme-brand.dark .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #fff;
}
.theme-brand .dark .mat-mdc-outlined-button.mat-primary,
.theme-brand.dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #2196f3;
}
.theme-brand .dark .mat-mdc-outlined-button.mat-accent,
.theme-brand.dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
}
.theme-brand .dark .mat-mdc-outlined-button.mat-warn,
.theme-brand.dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
}
.theme-brand .dark .mat-mdc-outlined-button[disabled][disabled],
.theme-brand.dark .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
}
.theme-brand .dark .mat-mdc-button, .theme-brand .dark .mat-mdc-outlined-button,
.theme-brand.dark .mat-mdc-button,
.theme-brand.dark .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .theme-brand .dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-brand .dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-brand .dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-brand .dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-brand .dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-brand .dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .theme-brand .dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-brand .dark .mat-mdc-button.mat-primary, .theme-brand .dark .mat-mdc-outlined-button.mat-primary,
.theme-brand.dark .mat-mdc-button.mat-primary,
.theme-brand.dark .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #2196f3;
  --mat-mdc-button-ripple-color: rgba(33, 150, 243, 0.1);
}
.theme-brand .dark .mat-mdc-button.mat-accent, .theme-brand .dark .mat-mdc-outlined-button.mat-accent,
.theme-brand.dark .mat-mdc-button.mat-accent,
.theme-brand.dark .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #1e293b;
  --mat-mdc-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-brand .dark .mat-mdc-button.mat-warn, .theme-brand .dark .mat-mdc-outlined-button.mat-warn,
.theme-brand.dark .mat-mdc-button.mat-warn,
.theme-brand.dark .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #dc2626;
  --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-brand .dark .mat-mdc-raised-button, .theme-brand .dark .mat-mdc-unelevated-button,
.theme-brand.dark .mat-mdc-raised-button,
.theme-brand.dark .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .theme-brand .dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-brand .dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-brand .dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-brand .dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-brand .dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-brand .dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .theme-brand .dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-brand .dark .mat-mdc-raised-button.mat-primary, .theme-brand .dark .mat-mdc-unelevated-button.mat-primary,
.theme-brand.dark .mat-mdc-raised-button.mat-primary,
.theme-brand.dark .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .dark .mat-mdc-raised-button.mat-accent, .theme-brand .dark .mat-mdc-unelevated-button.mat-accent,
.theme-brand.dark .mat-mdc-raised-button.mat-accent,
.theme-brand.dark .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .dark .mat-mdc-raised-button.mat-warn, .theme-brand .dark .mat-mdc-unelevated-button.mat-warn,
.theme-brand.dark .mat-mdc-raised-button.mat-warn,
.theme-brand.dark .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .dark .mat-mdc-icon-button,
.theme-brand.dark .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-brand .dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-brand .dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-brand .dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-brand .dark .mat-mdc-icon-button.mat-primary,
.theme-brand.dark .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #2196f3;
  --mat-mdc-button-ripple-color: rgba(33, 150, 243, 0.1);
}
.theme-brand .dark .mat-mdc-icon-button.mat-accent,
.theme-brand.dark .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #1e293b;
  --mat-mdc-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-brand .dark .mat-mdc-icon-button.mat-warn,
.theme-brand.dark .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #dc2626;
  --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-brand .dark .mat-mdc-icon-button.mat-primary,
.theme-brand.dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #2196f3;
}
.theme-brand .dark .mat-mdc-icon-button.mat-accent,
.theme-brand.dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
}
.theme-brand .dark .mat-mdc-icon-button.mat-warn,
.theme-brand.dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
}
.theme-brand .dark .mat-mdc-icon-button[disabled][disabled],
.theme-brand.dark .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
}
.theme-brand .dark .mat-mdc-fab, .theme-brand .dark .mat-mdc-mini-fab,
.theme-brand.dark .mat-mdc-fab,
.theme-brand.dark .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .theme-brand .dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-brand .dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-brand .dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-brand .dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-brand .dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-brand .dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .theme-brand .dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.theme-brand.dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-brand .dark .mat-mdc-fab.mat-primary, .theme-brand .dark .mat-mdc-mini-fab.mat-primary,
.theme-brand.dark .mat-mdc-fab.mat-primary,
.theme-brand.dark .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .dark .mat-mdc-fab.mat-accent, .theme-brand .dark .mat-mdc-mini-fab.mat-accent,
.theme-brand.dark .mat-mdc-fab.mat-accent,
.theme-brand.dark .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .dark .mat-mdc-fab.mat-warn, .theme-brand .dark .mat-mdc-mini-fab.mat-warn,
.theme-brand.dark .mat-mdc-fab.mat-warn,
.theme-brand.dark .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .dark .mat-mdc-fab.mat-unthemed, .theme-brand .dark .mat-mdc-mini-fab.mat-unthemed,
.theme-brand.dark .mat-mdc-fab.mat-unthemed,
.theme-brand.dark .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-brand .dark .mat-mdc-fab.mat-primary, .theme-brand .dark .mat-mdc-mini-fab.mat-primary,
.theme-brand.dark .mat-mdc-fab.mat-primary,
.theme-brand.dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #2196f3;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-brand .dark .mat-mdc-fab.mat-accent, .theme-brand .dark .mat-mdc-mini-fab.mat-accent,
.theme-brand.dark .mat-mdc-fab.mat-accent,
.theme-brand.dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-brand .dark .mat-mdc-fab.mat-warn, .theme-brand .dark .mat-mdc-mini-fab.mat-warn,
.theme-brand.dark .mat-mdc-fab.mat-warn,
.theme-brand.dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-brand .dark .mat-mdc-fab[disabled][disabled], .theme-brand .dark .mat-mdc-mini-fab[disabled][disabled],
.theme-brand.dark .mat-mdc-fab[disabled][disabled],
.theme-brand.dark .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(255, 255, 255, 0.12);
  --mdc-fab-icon-color: rgba(255, 255, 255, 0.5);
  --mat-mdc-fab-color: rgba(255, 255, 255, 0.5);
}
.theme-brand .dark .mat-mdc-snack-bar-container,
.theme-brand.dark .mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: currentColor;
  --mdc-snackbar-container-color: #d2d4d8;
  --mdc-snackbar-supporting-text-color: rgba(30, 41, 59, 0.87);
}
.theme-brand .dark .mdc-data-table,
.theme-brand.dark .mdc-data-table {
  background-color: var(--mdc-theme-surface, #1e293b);
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-brand .dark .mdc-data-table__row,
.theme-brand.dark .mdc-data-table__row {
  background-color: inherit;
}
.theme-brand .dark .mdc-data-table__header-cell,
.theme-brand.dark .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #1e293b);
}
.theme-brand .dark .mdc-data-table__row--selected,
.theme-brand.dark .mdc-data-table__row--selected {
  background-color: rgba(33, 150, 243, 0.04);
}
.theme-brand .dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.theme-brand .dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.theme-brand .dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing,
.theme-brand.dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.theme-brand.dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.theme-brand.dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-brand .dark .mdc-data-table__cell,
.theme-brand .dark .mdc-data-table__header-cell,
.theme-brand.dark .mdc-data-table__cell,
.theme-brand.dark .mdc-data-table__header-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.theme-brand .dark .mdc-data-table__pagination,
.theme-brand.dark .mdc-data-table__pagination {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-brand .dark .mdc-data-table__row:not(.mdc-data-table__row--selected):hover,
.theme-brand.dark .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(255, 255, 255, 0.04);
}
.theme-brand .dark .mdc-data-table__header-cell,
.theme-brand.dark .mdc-data-table__header-cell {
  color: rgba(255, 255, 255, 0.87);
}
.theme-brand .dark .mdc-data-table__pagination-total,
.theme-brand .dark .mdc-data-table__pagination-rows-per-page-label,
.theme-brand .dark .mdc-data-table__cell,
.theme-brand.dark .mdc-data-table__pagination-total,
.theme-brand.dark .mdc-data-table__pagination-rows-per-page-label,
.theme-brand.dark .mdc-data-table__cell {
  color: rgba(255, 255, 255, 0.87);
}
[dir=rtl] .theme-brand .dark .mdc-data-table__pagination-button .mdc-button__icon, [dir=rtl] .theme-brand.dark .mdc-data-table__pagination-button .mdc-button__icon, .theme-brand .dark .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl], .theme-brand.dark .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-brand .dark .mat-mdc-table,
.theme-brand.dark .mat-mdc-table {
  background: #1e293b;
}
.theme-brand .dark .mat-mdc-progress-spinner,
.theme-brand.dark .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #2196f3;
}
.theme-brand .dark .mat-mdc-progress-spinner.mat-accent,
.theme-brand.dark .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-brand .dark .mat-mdc-progress-spinner.mat-warn,
.theme-brand.dark .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-brand .dark .mat-badge-content,
.theme-brand.dark .mat-badge-content {
  color: white;
  background: #2196f3;
}
.cdk-high-contrast-active .theme-brand .dark .mat-badge-content, .cdk-high-contrast-active .theme-brand.dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.theme-brand .dark .mat-badge-accent .mat-badge-content,
.theme-brand.dark .mat-badge-accent .mat-badge-content {
  background: #1e293b;
  color: white;
}
.theme-brand .dark .mat-badge-warn .mat-badge-content,
.theme-brand.dark .mat-badge-warn .mat-badge-content {
  color: white;
  background: #dc2626;
}
.theme-brand .dark .mat-badge-disabled .mat-badge-content,
.theme-brand.dark .mat-badge-disabled .mat-badge-content {
  background: #1e293b;
  color: #475569;
}
.theme-brand .dark .mat-bottom-sheet-container,
.theme-brand.dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #1e293b;
  color: white;
}
.theme-brand .dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-brand .dark .mat-button-toggle-group:not([class*=mat-elevation-z]),
.theme-brand.dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-brand.dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-brand .dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]),
.theme-brand.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-brand.dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.theme-brand .dark .mat-button-toggle,
.theme-brand.dark .mat-button-toggle {
  color: #64748b;
}
.theme-brand .dark .mat-button-toggle .mat-button-toggle-focus-overlay,
.theme-brand.dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: #e2e8f0;
}
.theme-brand .dark .mat-button-toggle-appearance-standard,
.theme-brand.dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #1e293b;
}
.theme-brand .dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay,
.theme-brand.dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: #e2e8f0;
}
.theme-brand .dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
.theme-brand.dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #374152;
}
.theme-brand .dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
.theme-brand.dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #374152;
}
.theme-brand .dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle,
.theme-brand.dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #374152;
}
.theme-brand .dark .mat-button-toggle-checked,
.theme-brand.dark .mat-button-toggle-checked {
  background-color: rgba(255, 255, 255, 0.05);
  color: #94a3b8;
}
.theme-brand .dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard,
.theme-brand.dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.theme-brand .dark .mat-button-toggle-disabled,
.theme-brand.dark .mat-button-toggle-disabled {
  color: #1e293b;
  background-color: #0f172a;
}
.theme-brand .dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard,
.theme-brand.dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #1e293b;
}
.theme-brand .dark .mat-button-toggle-disabled.mat-button-toggle-checked,
.theme-brand.dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #1e293b;
}
.theme-brand .dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-brand .dark .mat-button-toggle-group-appearance-standard,
.theme-brand.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-brand.dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #374152;
}
.theme-brand .dark .mat-calendar-arrow,
.theme-brand.dark .mat-calendar-arrow {
  fill: #f1f5f9;
}
.theme-brand .dark .mat-datepicker-toggle,
.theme-brand .dark .mat-datepicker-content .mat-calendar-next-button,
.theme-brand .dark .mat-datepicker-content .mat-calendar-previous-button,
.theme-brand.dark .mat-datepicker-toggle,
.theme-brand.dark .mat-datepicker-content .mat-calendar-next-button,
.theme-brand.dark .mat-datepicker-content .mat-calendar-previous-button {
  color: #f1f5f9;
}
.theme-brand .dark .mat-calendar-table-header-divider::after,
.theme-brand.dark .mat-calendar-table-header-divider::after {
  background: rgba(241, 245, 249, 0.12);
}
.theme-brand .dark .mat-calendar-table-header,
.theme-brand .dark .mat-calendar-body-label,
.theme-brand.dark .mat-calendar-table-header,
.theme-brand.dark .mat-calendar-body-label {
  color: #94a3b8;
}
.theme-brand .dark .mat-calendar-body-cell-content,
.theme-brand .dark .mat-date-range-input-separator,
.theme-brand.dark .mat-calendar-body-cell-content,
.theme-brand.dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.theme-brand .dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-brand.dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: #475569;
}
.theme-brand .dark .mat-form-field-disabled .mat-date-range-input-separator,
.theme-brand.dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: #475569;
}
.theme-brand .dark .mat-calendar-body-in-preview,
.theme-brand.dark .mat-calendar-body-in-preview {
  color: rgba(241, 245, 249, 0.24);
}
.theme-brand .dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-brand.dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #64748b;
}
.theme-brand .dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-brand.dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(100, 116, 139, 0.8);
}
.theme-brand .dark .mat-calendar-body-in-range::before,
.theme-brand.dark .mat-calendar-body-in-range::before {
  background: rgba(33, 150, 243, 0.2);
}
.theme-brand .dark .mat-calendar-body-comparison-identical,
.theme-brand .dark .mat-calendar-body-in-comparison-range::before,
.theme-brand.dark .mat-calendar-body-comparison-identical,
.theme-brand.dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-brand .dark .mat-calendar-body-comparison-bridge-start::before,
.theme-brand .dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-brand.dark .mat-calendar-body-comparison-bridge-start::before,
.theme-brand.dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(33, 150, 243, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-brand .dark .mat-calendar-body-comparison-bridge-end::before,
.theme-brand .dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-brand.dark .mat-calendar-body-comparison-bridge-end::before,
.theme-brand.dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(33, 150, 243, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-brand .dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-brand .dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-brand.dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-brand.dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-brand .dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-brand .dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-brand.dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-brand.dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-brand .dark .mat-calendar-body-selected,
.theme-brand.dark .mat-calendar-body-selected {
  background-color: #2196f3;
  color: white;
}
.theme-brand .dark .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-brand.dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(33, 150, 243, 0.4);
}
.theme-brand .dark .mat-calendar-body-today.mat-calendar-body-selected,
.theme-brand.dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-brand .dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-brand .dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-brand.dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-brand.dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(33, 150, 243, 0.3);
}
@media (hover: hover) {
  .theme-brand .dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-brand.dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(33, 150, 243, 0.3);
  }
}
.theme-brand .dark .mat-datepicker-content,
.theme-brand.dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #1e293b;
  color: white;
}
.theme-brand .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before,
.theme-brand.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(30, 41, 59, 0.2);
}
.theme-brand .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-brand .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before,
.theme-brand.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-brand.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-brand .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-brand .dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-brand.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-brand.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(30, 41, 59, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-brand .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-brand .dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-brand.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-brand.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(30, 41, 59, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-brand .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-brand .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-brand.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-brand.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-brand .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-brand .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-brand.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-brand.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-brand .dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected,
.theme-brand.dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #1e293b;
  color: white;
}
.theme-brand .dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-brand.dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(30, 41, 59, 0.4);
}
.theme-brand .dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected,
.theme-brand.dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-brand .dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-brand .dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-brand.dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-brand.dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(30, 41, 59, 0.3);
}
@media (hover: hover) {
  .theme-brand .dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-brand.dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(30, 41, 59, 0.3);
  }
}
.theme-brand .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before,
.theme-brand.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(220, 38, 38, 0.2);
}
.theme-brand .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-brand .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before,
.theme-brand.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-brand.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-brand .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-brand .dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-brand.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-brand.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(220, 38, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-brand .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-brand .dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-brand.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-brand.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(220, 38, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-brand .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-brand .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-brand.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-brand.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-brand .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-brand .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-brand.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-brand.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-brand .dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected,
.theme-brand.dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #dc2626;
  color: white;
}
.theme-brand .dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-brand.dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(220, 38, 38, 0.4);
}
.theme-brand .dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected,
.theme-brand.dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-brand .dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-brand .dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-brand.dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-brand.dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(220, 38, 38, 0.3);
}
@media (hover: hover) {
  .theme-brand .dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-brand.dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(220, 38, 38, 0.3);
  }
}
.theme-brand .dark .mat-datepicker-content-touch,
.theme-brand.dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-datepicker-toggle-active,
.theme-brand.dark .mat-datepicker-toggle-active {
  color: #2196f3;
}
.theme-brand .dark .mat-datepicker-toggle-active.mat-accent,
.theme-brand.dark .mat-datepicker-toggle-active.mat-accent {
  color: #1e293b;
}
.theme-brand .dark .mat-datepicker-toggle-active.mat-warn,
.theme-brand.dark .mat-datepicker-toggle-active.mat-warn {
  color: #dc2626;
}
.theme-brand .dark .mat-date-range-input-inner[disabled],
.theme-brand.dark .mat-date-range-input-inner[disabled] {
  color: #475569;
}
.theme-brand .dark .mat-divider,
.theme-brand.dark .mat-divider {
  border-top-color: rgba(241, 245, 249, 0.12);
}
.theme-brand .dark .mat-divider-vertical,
.theme-brand.dark .mat-divider-vertical {
  border-right-color: rgba(241, 245, 249, 0.12);
}
.theme-brand .dark .mat-expansion-panel,
.theme-brand.dark .mat-expansion-panel {
  background: #1e293b;
  color: white;
}
.theme-brand .dark .mat-expansion-panel:not([class*=mat-elevation-z]),
.theme-brand.dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-action-row,
.theme-brand.dark .mat-action-row {
  border-top-color: rgba(241, 245, 249, 0.12);
}
.theme-brand .dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .theme-brand .dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .theme-brand .dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]),
.theme-brand.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
.theme-brand.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
.theme-brand.dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.05);
}
@media (hover: none) {
  .theme-brand .dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover,
  .theme-brand.dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #1e293b;
  }
}
.theme-brand .dark .mat-expansion-panel-header-title,
.theme-brand.dark .mat-expansion-panel-header-title {
  color: white;
}
.theme-brand .dark .mat-expansion-panel-header-description,
.theme-brand .dark .mat-expansion-indicator::after,
.theme-brand.dark .mat-expansion-panel-header-description,
.theme-brand.dark .mat-expansion-indicator::after {
  color: #94a3b8;
}
.theme-brand .dark .mat-expansion-panel-header[aria-disabled=true],
.theme-brand.dark .mat-expansion-panel-header[aria-disabled=true] {
  color: #1e293b;
}
.theme-brand .dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-brand .dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description,
.theme-brand.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-brand.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.theme-brand .dark .mat-icon.mat-primary,
.theme-brand.dark .mat-icon.mat-primary {
  color: #2196f3;
}
.theme-brand .dark .mat-icon.mat-accent,
.theme-brand.dark .mat-icon.mat-accent {
  color: #1e293b;
}
.theme-brand .dark .mat-icon.mat-warn,
.theme-brand.dark .mat-icon.mat-warn {
  color: #dc2626;
}
.theme-brand .dark .mat-drawer-container,
.theme-brand.dark .mat-drawer-container {
  background-color: #0f172a;
  color: white;
}
.theme-brand .dark .mat-drawer,
.theme-brand.dark .mat-drawer {
  background-color: #1e293b;
  color: white;
}
.theme-brand .dark .mat-drawer.mat-drawer-push,
.theme-brand.dark .mat-drawer.mat-drawer-push {
  background-color: #1e293b;
}
.theme-brand .dark .mat-drawer:not(.mat-drawer-side),
.theme-brand.dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-drawer-side,
.theme-brand.dark .mat-drawer-side {
  border-right: solid 1px rgba(241, 245, 249, 0.12);
}
.theme-brand .dark .mat-drawer-side.mat-drawer-end,
.theme-brand.dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(241, 245, 249, 0.12);
  border-right: none;
}
.theme-brand .dark [dir=rtl] .mat-drawer-side,
.theme-brand.dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(241, 245, 249, 0.12);
  border-right: none;
}
.theme-brand .dark [dir=rtl] .mat-drawer-side.mat-drawer-end,
.theme-brand.dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(241, 245, 249, 0.12);
}
.theme-brand .dark .mat-drawer-backdrop.mat-drawer-shown,
.theme-brand.dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(225, 214, 196, 0.6);
}
.theme-brand .dark .mat-step-header.cdk-keyboard-focused, .theme-brand .dark .mat-step-header.cdk-program-focused, .theme-brand .dark .mat-step-header:hover:not([aria-disabled]), .theme-brand .dark .mat-step-header:hover[aria-disabled=false],
.theme-brand.dark .mat-step-header.cdk-keyboard-focused,
.theme-brand.dark .mat-step-header.cdk-program-focused,
.theme-brand.dark .mat-step-header:hover:not([aria-disabled]),
.theme-brand.dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.05);
}
.theme-brand .dark .mat-step-header:hover[aria-disabled=true],
.theme-brand.dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .theme-brand .dark .mat-step-header:hover,
  .theme-brand.dark .mat-step-header:hover {
    background: none;
  }
}
.theme-brand .dark .mat-step-header .mat-step-label,
.theme-brand .dark .mat-step-header .mat-step-optional,
.theme-brand.dark .mat-step-header .mat-step-label,
.theme-brand.dark .mat-step-header .mat-step-optional {
  color: #94a3b8;
}
.theme-brand .dark .mat-step-header .mat-step-icon,
.theme-brand.dark .mat-step-header .mat-step-icon {
  background-color: #94a3b8;
  color: white;
}
.theme-brand .dark .mat-step-header .mat-step-icon-selected,
.theme-brand .dark .mat-step-header .mat-step-icon-state-done,
.theme-brand .dark .mat-step-header .mat-step-icon-state-edit,
.theme-brand.dark .mat-step-header .mat-step-icon-selected,
.theme-brand.dark .mat-step-header .mat-step-icon-state-done,
.theme-brand.dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #2196f3;
  color: white;
}
.theme-brand .dark .mat-step-header.mat-accent .mat-step-icon,
.theme-brand.dark .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.theme-brand .dark .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-brand .dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-brand .dark .mat-step-header.mat-accent .mat-step-icon-state-edit,
.theme-brand.dark .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-brand.dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-brand.dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #1e293b;
  color: white;
}
.theme-brand .dark .mat-step-header.mat-warn .mat-step-icon,
.theme-brand.dark .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.theme-brand .dark .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-brand .dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-brand .dark .mat-step-header.mat-warn .mat-step-icon-state-edit,
.theme-brand.dark .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-brand.dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-brand.dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #dc2626;
  color: white;
}
.theme-brand .dark .mat-step-header .mat-step-icon-state-error,
.theme-brand.dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #dc2626;
}
.theme-brand .dark .mat-step-header .mat-step-label.mat-step-label-active,
.theme-brand.dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.theme-brand .dark .mat-step-header .mat-step-label.mat-step-label-error,
.theme-brand.dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #dc2626;
}
.theme-brand .dark .mat-stepper-horizontal, .theme-brand .dark .mat-stepper-vertical,
.theme-brand.dark .mat-stepper-horizontal,
.theme-brand.dark .mat-stepper-vertical {
  background-color: #1e293b;
}
.theme-brand .dark .mat-stepper-vertical-line::before,
.theme-brand.dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(241, 245, 249, 0.12);
}
.theme-brand .dark .mat-horizontal-stepper-header::before,
.theme-brand .dark .mat-horizontal-stepper-header::after,
.theme-brand .dark .mat-stepper-horizontal-line,
.theme-brand.dark .mat-horizontal-stepper-header::before,
.theme-brand.dark .mat-horizontal-stepper-header::after,
.theme-brand.dark .mat-stepper-horizontal-line {
  border-top-color: rgba(241, 245, 249, 0.12);
}
.theme-brand .dark .mat-sort-header-arrow,
.theme-brand.dark .mat-sort-header-arrow {
  color: #94a3b8;
}
.theme-brand .dark .mat-toolbar,
.theme-brand.dark .mat-toolbar {
  background: #0f172a;
  color: white;
}
.theme-brand .dark .mat-toolbar.mat-primary,
.theme-brand.dark .mat-toolbar.mat-primary {
  background: #2196f3;
  color: white;
}
.theme-brand .dark .mat-toolbar.mat-accent,
.theme-brand.dark .mat-toolbar.mat-accent {
  background: #1e293b;
  color: white;
}
.theme-brand .dark .mat-toolbar.mat-warn,
.theme-brand.dark .mat-toolbar.mat-warn {
  background: #dc2626;
  color: white;
}
.theme-brand .dark .mat-toolbar .mat-form-field-underline,
.theme-brand .dark .mat-toolbar .mat-form-field-ripple,
.theme-brand .dark .mat-toolbar .mat-focused .mat-form-field-ripple,
.theme-brand.dark .mat-toolbar .mat-form-field-underline,
.theme-brand.dark .mat-toolbar .mat-form-field-ripple,
.theme-brand.dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.theme-brand .dark .mat-toolbar .mat-form-field-label,
.theme-brand .dark .mat-toolbar .mat-focused .mat-form-field-label,
.theme-brand .dark .mat-toolbar .mat-select-value,
.theme-brand .dark .mat-toolbar .mat-select-arrow,
.theme-brand .dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow,
.theme-brand.dark .mat-toolbar .mat-form-field-label,
.theme-brand.dark .mat-toolbar .mat-focused .mat-form-field-label,
.theme-brand.dark .mat-toolbar .mat-select-value,
.theme-brand.dark .mat-toolbar .mat-select-arrow,
.theme-brand.dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.theme-brand .dark .mat-toolbar .mat-input-element,
.theme-brand.dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.theme-brand .dark .mat-tree,
.theme-brand.dark .mat-tree {
  background: #1e293b;
}
.theme-brand .dark .mat-tree-node,
.theme-brand .dark .mat-nested-tree-node,
.theme-brand.dark .mat-tree-node,
.theme-brand.dark .mat-nested-tree-node {
  color: white;
}

/* Generate the palettes */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define a light & dark Angular Material theme with the generated palettes */
/* Merge the custom base colors with the generated themes */
/* Generate and encapsulate Angular Material themes */
.theme-teal .light .mat-ripple-element,
.theme-teal.light .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.theme-teal .light .mat-mdc-option,
.theme-teal.light .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-teal .light .mat-mdc-option:hover:not(.mdc-list-item--disabled), .theme-teal .light .mat-mdc-option:focus:not(.mdc-list-item--disabled), .theme-teal .light .mat-mdc-option.mat-mdc-option-active, .theme-teal .light .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled),
.theme-teal.light .mat-mdc-option:hover:not(.mdc-list-item--disabled),
.theme-teal.light .mat-mdc-option:focus:not(.mdc-list-item--disabled),
.theme-teal.light .mat-mdc-option.mat-mdc-option-active,
.theme-teal.light .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.theme-teal .light .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-teal.light .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #0d9488);
}
.theme-teal .light .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-teal.light .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #1e293b);
}
.theme-teal .light .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-teal.light .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mat-mdc-optgroup-label,
.theme-teal.light .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-teal .light .mat-pseudo-checkbox,
.theme-teal.light .mat-pseudo-checkbox {
  color: #64748b;
}
.theme-teal .light .mat-pseudo-checkbox::after,
.theme-teal.light .mat-pseudo-checkbox::after {
  color: #f1f5f9;
}
.theme-teal .light .mat-pseudo-checkbox-disabled,
.theme-teal.light .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.theme-teal .light .mat-primary .mat-pseudo-checkbox-checked,
.theme-teal .light .mat-primary .mat-pseudo-checkbox-indeterminate,
.theme-teal.light .mat-primary .mat-pseudo-checkbox-checked,
.theme-teal.light .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #0d9488;
}
.theme-teal .light .mat-pseudo-checkbox-checked,
.theme-teal .light .mat-pseudo-checkbox-indeterminate,
.theme-teal .light .mat-accent .mat-pseudo-checkbox-checked,
.theme-teal .light .mat-accent .mat-pseudo-checkbox-indeterminate,
.theme-teal.light .mat-pseudo-checkbox-checked,
.theme-teal.light .mat-pseudo-checkbox-indeterminate,
.theme-teal.light .mat-accent .mat-pseudo-checkbox-checked,
.theme-teal.light .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #1e293b;
}
.theme-teal .light .mat-warn .mat-pseudo-checkbox-checked,
.theme-teal .light .mat-warn .mat-pseudo-checkbox-indeterminate,
.theme-teal.light .mat-warn .mat-pseudo-checkbox-checked,
.theme-teal.light .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #dc2626;
}
.theme-teal .light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-teal .light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled,
.theme-teal.light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-teal.light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.theme-teal .light .mat-app-background, .theme-teal .light.mat-app-background,
.theme-teal.light .mat-app-background,
.theme-teal.light.mat-app-background {
  background-color: #f1f5f9;
  color: #1e293b;
}
.theme-teal .light .mat-elevation-z0, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-teal.light .mat-elevation-z0,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z1, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-teal.light .mat-elevation-z1,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z2, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-teal.light .mat-elevation-z2,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z3, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-teal.light .mat-elevation-z3,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z4, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-teal.light .mat-elevation-z4,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z5, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-teal.light .mat-elevation-z5,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z6, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-teal.light .mat-elevation-z6,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z7, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-teal.light .mat-elevation-z7,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z8, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-teal.light .mat-elevation-z8,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z9, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-teal.light .mat-elevation-z9,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z10, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-teal.light .mat-elevation-z10,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z11, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-teal.light .mat-elevation-z11,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z12, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-teal.light .mat-elevation-z12,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z13, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-teal.light .mat-elevation-z13,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z14, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-teal.light .mat-elevation-z14,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z15, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-teal.light .mat-elevation-z15,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z16, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-teal.light .mat-elevation-z16,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z17, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-teal.light .mat-elevation-z17,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z18, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-teal.light .mat-elevation-z18,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z19, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-teal.light .mat-elevation-z19,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z20, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-teal.light .mat-elevation-z20,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z21, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-teal.light .mat-elevation-z21,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z22, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-teal.light .mat-elevation-z22,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z23, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-teal.light .mat-elevation-z23,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z24, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-teal.light .mat-elevation-z24,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-teal .light .mat-mdc-card,
.theme-teal.light .mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #fff;
}
.theme-teal .light .mat-mdc-card-outlined,
.theme-teal.light .mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #e0e0e0;
}
.theme-teal .light .mat-mdc-card-subtitle,
.theme-teal.light .mat-mdc-card-subtitle {
  color: #64748b;
}
.theme-teal .light .mat-mdc-progress-bar,
.theme-teal.light .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #0d9488;
}
.theme-teal .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.theme-teal.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(13, 148, 136, 0.25)'/%3E%3C/svg%3E");
}
.theme-teal .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.theme-teal.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(13, 148, 136, 0.25);
}
.theme-teal .light .mat-mdc-progress-bar.mat-accent,
.theme-teal.light .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
}
.theme-teal .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.theme-teal.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(30, 41, 59, 0.25)'/%3E%3C/svg%3E");
}
.theme-teal .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.theme-teal.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(30, 41, 59, 0.25);
}
.theme-teal .light .mat-mdc-progress-bar.mat-warn,
.theme-teal.light .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
}
.theme-teal .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.theme-teal.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(220, 38, 38, 0.25)'/%3E%3C/svg%3E");
}
.theme-teal .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.theme-teal.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(220, 38, 38, 0.25);
}
.theme-teal .light .mat-mdc-tooltip,
.theme-teal.light .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.theme-teal .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-teal.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.theme-teal .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input,
.theme-teal.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .theme-teal .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder,
  .theme-teal.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .theme-teal .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder,
  .theme-teal.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.theme-teal .light .mdc-text-field .mdc-text-field__input,
.theme-teal.light .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #0d9488);
}
.theme-teal .light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text,
.theme-teal.light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.theme-teal .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.theme-teal .light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter,
.theme-teal.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.theme-teal.light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.theme-teal .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading,
.theme-teal.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.theme-teal .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-teal.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.theme-teal .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix,
.theme-teal.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-teal .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix,
.theme-teal.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-teal .light .mdc-text-field--filled .mdc-text-field__ripple::before,
.theme-teal .light .mdc-text-field--filled .mdc-text-field__ripple::after,
.theme-teal.light .mdc-text-field--filled .mdc-text-field__ripple::before,
.theme-teal.light .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.theme-teal .light .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .theme-teal .light .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before,
.theme-teal.light .mdc-text-field--filled:hover .mdc-text-field__ripple::before,
.theme-teal.light .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.theme-teal .light .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .theme-teal .light .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before,
.theme-teal.light .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before,
.theme-teal.light .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.theme-teal .light .mdc-text-field--filled:not(.mdc-text-field--disabled),
.theme-teal.light .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.theme-teal .light .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-teal.light .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.theme-teal .light .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-teal.light .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.theme-teal .light .mdc-text-field--filled .mdc-line-ripple::after,
.theme-teal.light .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #0d9488);
}
.theme-teal .light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-teal .light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-teal .light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-teal.light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-teal.light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-teal.light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.theme-teal .light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-teal .light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-teal .light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-teal.light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-teal.light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-teal.light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.theme-teal .light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-teal .light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-teal .light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-teal.light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-teal.light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-teal.light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #0d9488);
}
[dir=rtl] .theme-teal .light .mdc-text-field--outlined.mdc-text-field--with-leading-icon, [dir=rtl] .theme-teal.light .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .theme-teal .light .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl], .theme-teal.light .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-teal .light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, [dir=rtl] .theme-teal.light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .theme-teal .light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl], .theme-teal.light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-teal .light .mdc-text-field--outlined .mdc-text-field__ripple::before,
.theme-teal .light .mdc-text-field--outlined .mdc-text-field__ripple::after,
.theme-teal.light .mdc-text-field--outlined .mdc-text-field__ripple::before,
.theme-teal.light .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .theme-teal .light .mdc-text-field--with-leading-icon.mdc-text-field--outlined, [dir=rtl] .theme-teal.light .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .theme-teal .light .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl], .theme-teal.light .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-teal .light .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-teal.light .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(13, 148, 136, 0.87);
}
.theme-teal .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-teal.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-teal.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-teal.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-teal.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mdc-text-field--invalid .mdc-text-field__input,
.theme-teal.light .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-teal.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-teal.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-teal .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-teal .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-teal.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-teal.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-teal.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-teal .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-teal .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-teal.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-teal.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-teal.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-teal .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-teal .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-teal.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-teal.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-teal.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mdc-text-field--disabled .mdc-text-field__input,
.theme-teal.light .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .theme-teal .light .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  .theme-teal.light .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .theme-teal .light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  .theme-teal.light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.theme-teal .light .mdc-text-field--disabled .mdc-floating-label,
.theme-teal.light .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.theme-teal .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
.theme-teal.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.theme-teal .light .mdc-text-field--disabled .mdc-text-field-character-counter,
.theme-teal .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
.theme-teal.light .mdc-text-field--disabled .mdc-text-field-character-counter,
.theme-teal.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.theme-teal .light .mdc-text-field--disabled .mdc-text-field__icon--leading,
.theme-teal.light .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.theme-teal .light .mdc-text-field--disabled .mdc-text-field__icon--trailing,
.theme-teal.light .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.theme-teal .light .mdc-text-field--disabled .mdc-text-field__affix--prefix,
.theme-teal.light .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-teal .light .mdc-text-field--disabled .mdc-text-field__affix--suffix,
.theme-teal.light .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-teal .light .mdc-text-field--disabled .mdc-line-ripple::before,
.theme-teal.light .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.theme-teal .light .mdc-text-field--disabled .mdc-notched-outline__leading,
.theme-teal .light .mdc-text-field--disabled .mdc-notched-outline__notch,
.theme-teal .light .mdc-text-field--disabled .mdc-notched-outline__trailing,
.theme-teal.light .mdc-text-field--disabled .mdc-notched-outline__leading,
.theme-teal.light .mdc-text-field--disabled .mdc-notched-outline__notch,
.theme-teal.light .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-teal .light .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  .theme-teal.light .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-teal .light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  .theme-teal.light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-teal .light .mdc-text-field--disabled .mdc-floating-label,
  .theme-teal.light .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-teal .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
  .theme-teal.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-teal .light .mdc-text-field--disabled .mdc-text-field-character-counter,
  .theme-teal .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
  .theme-teal.light .mdc-text-field--disabled .mdc-text-field-character-counter,
  .theme-teal.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-teal .light .mdc-text-field--disabled .mdc-text-field__icon--leading,
  .theme-teal.light .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-teal .light .mdc-text-field--disabled .mdc-text-field__icon--trailing,
  .theme-teal.light .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-teal .light .mdc-text-field--disabled .mdc-text-field__affix--prefix,
  .theme-teal.light .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-teal .light .mdc-text-field--disabled .mdc-text-field__affix--suffix,
  .theme-teal.light .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-teal .light .mdc-text-field--disabled .mdc-line-ripple::before,
  .theme-teal.light .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-teal .light .mdc-text-field--disabled .mdc-notched-outline__leading,
  .theme-teal .light .mdc-text-field--disabled .mdc-notched-outline__notch,
  .theme-teal .light .mdc-text-field--disabled .mdc-notched-outline__trailing,
  .theme-teal.light .mdc-text-field--disabled .mdc-notched-outline__leading,
  .theme-teal.light .mdc-text-field--disabled .mdc-notched-outline__notch,
  .theme-teal.light .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.theme-teal .light .mdc-text-field--disabled.mdc-text-field--filled,
.theme-teal.light .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .theme-teal .light .mdc-text-field--end-aligned .mdc-text-field__input, [dir=rtl] .theme-teal.light .mdc-text-field--end-aligned .mdc-text-field__input, .theme-teal .light .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl], .theme-teal.light .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-teal .light .mdc-floating-label--required, [dir=rtl] .theme-teal.light .mdc-floating-label--required, .theme-teal .light .mdc-floating-label--required[dir=rtl], .theme-teal.light .mdc-floating-label--required[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-teal .light .mat-mdc-form-field-error,
.theme-teal.light .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mat-mdc-form-field-focus-overlay,
.theme-teal.light .mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}
.theme-teal .light .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.theme-teal.light .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
.theme-teal .light .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.theme-teal.light .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}
.theme-teal .light .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.theme-teal.light .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.theme-teal .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.theme-teal.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(13, 148, 136, 0.87);
}
.theme-teal .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.theme-teal.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(30, 41, 59, 0.87);
}
.theme-teal .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.theme-teal.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(220, 38, 38, 0.87);
}
.theme-teal .light .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.theme-teal.light .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.theme-teal .light .mat-mdc-form-field.mat-accent .mdc-text-field__input,
.theme-teal.light .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-teal .light .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-teal.light .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-teal .light .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-teal.light .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(30, 41, 59, 0.87);
}
.theme-teal .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-teal.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-teal.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-teal.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-teal.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input,
.theme-teal.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-teal.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-teal.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-teal .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-teal .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-teal.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-teal.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-teal.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-teal .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-teal .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-teal.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-teal.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-teal.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-teal .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-teal .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-teal.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-teal.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-teal.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-teal .light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-teal .light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-teal.light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-teal.light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-teal.light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-teal .light .mat-mdc-form-field.mat-warn .mdc-text-field__input,
.theme-teal.light .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-teal.light .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-teal.light .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(220, 38, 38, 0.87);
}
.theme-teal .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-teal.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-teal.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-teal.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-teal.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input,
.theme-teal.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-teal.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-teal.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-teal .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-teal .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-teal.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-teal.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-teal.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-teal .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-teal .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-teal.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-teal.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-teal.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-teal .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-teal .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-teal.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-teal.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-teal.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-teal .light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-teal .light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-teal.light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-teal.light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-teal.light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .light .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.theme-teal.light .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.theme-teal .light [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.theme-teal.light [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.theme-teal .light .mdc-menu-surface,
.theme-teal.light .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mdc-list-item__primary-text,
.theme-teal.light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-teal .light .mdc-list-item__secondary-text,
.theme-teal.light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.theme-teal .light .mdc-list-item__overline-text,
.theme-teal.light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-teal .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-teal.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-teal .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-teal.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.theme-teal .light .mdc-list-item__end,
.theme-teal.light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-teal .light .mdc-list-item--disabled .mdc-list-item__start,
.theme-teal .light .mdc-list-item--disabled .mdc-list-item__content,
.theme-teal .light .mdc-list-item--disabled .mdc-list-item__end,
.theme-teal.light .mdc-list-item--disabled .mdc-list-item__start,
.theme-teal.light .mdc-list-item--disabled .mdc-list-item__content,
.theme-teal.light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-teal .light .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-teal.light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-teal.light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-teal.light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-teal.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-teal.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-teal .light .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-teal.light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-teal.light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #0d9488);
}
.theme-teal .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #0d9488);
}
.theme-teal .light .mdc-deprecated-list-group__subheader,
.theme-teal.light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-teal .light .mdc-list-divider::after,
.theme-teal.light .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-teal .light .mdc-list-divider,
.theme-teal.light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-mdc-select-value,
.theme-teal.light .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.theme-teal .light .mat-mdc-select-placeholder,
.theme-teal.light .mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.theme-teal .light .mat-mdc-select-disabled .mat-mdc-select-value,
.theme-teal.light .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.theme-teal .light .mat-mdc-select-arrow,
.theme-teal.light .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.theme-teal .light .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow,
.theme-teal.light .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(13, 148, 136, 0.87);
}
.theme-teal .light .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow,
.theme-teal.light .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(30, 41, 59, 0.87);
}
.theme-teal .light .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow,
.theme-teal.light .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(220, 38, 38, 0.87);
}
.theme-teal .light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow,
.theme-teal.light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(220, 38, 38, 0.87);
}
.theme-teal .light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow,
.theme-teal.light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.theme-teal .light .mdc-menu-surface,
.theme-teal.light .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mdc-list-item__primary-text,
.theme-teal.light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-teal .light .mdc-list-item__secondary-text,
.theme-teal.light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.theme-teal .light .mdc-list-item__overline-text,
.theme-teal.light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-teal .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-teal.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-teal .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-teal.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.theme-teal .light .mdc-list-item__end,
.theme-teal.light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-teal .light .mdc-list-item--disabled .mdc-list-item__start,
.theme-teal .light .mdc-list-item--disabled .mdc-list-item__content,
.theme-teal .light .mdc-list-item--disabled .mdc-list-item__end,
.theme-teal.light .mdc-list-item--disabled .mdc-list-item__start,
.theme-teal.light .mdc-list-item--disabled .mdc-list-item__content,
.theme-teal.light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-teal .light .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-teal.light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-teal.light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-teal.light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-teal.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-teal.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-teal .light .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-teal.light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-teal.light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #0d9488);
}
.theme-teal .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #0d9488);
}
.theme-teal .light .mdc-deprecated-list-group__subheader,
.theme-teal.light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-teal .light .mdc-list-divider::after,
.theme-teal.light .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-teal .light .mdc-list-divider,
.theme-teal.light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-mdc-dialog-container,
.theme-teal.light .mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.theme-teal .light .mat-mdc-standard-chip,
.theme-teal.light .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.theme-teal .light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .theme-teal .light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted,
.theme-teal.light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected,
.theme-teal.light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #0d9488;
  --mdc-chip-elevated-disabled-container-color: #0d9488;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-teal .light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .theme-teal .light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted,
.theme-teal.light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected,
.theme-teal.light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-teal .light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .theme-teal .light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted,
.theme-teal.light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected,
.theme-teal.light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-teal .light .mat-mdc-chip-focus-overlay,
.theme-teal.light .mat-mdc-chip-focus-overlay {
  background: #000000;
}
.theme-teal .light .mat-mdc-slide-toggle,
.theme-teal.light .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.theme-teal .light .mat-mdc-slide-toggle .mdc-form-field,
.theme-teal.light .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-teal .light .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.theme-teal.light .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: #94a3b8;
}
.theme-teal .light .mat-mdc-slide-toggle.mat-primary,
.theme-teal.light .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #0d9488;
  --mdc-switch-selected-handle-color: #0d9488;
  --mdc-switch-selected-hover-state-layer-color: #0d9488;
  --mdc-switch-selected-pressed-state-layer-color: #0d9488;
  --mdc-switch-selected-focus-handle-color: #134e4a;
  --mdc-switch-selected-hover-handle-color: #134e4a;
  --mdc-switch-selected-pressed-handle-color: #134e4a;
  --mdc-switch-selected-focus-track-color: #5eead4;
  --mdc-switch-selected-hover-track-color: #5eead4;
  --mdc-switch-selected-pressed-track-color: #5eead4;
  --mdc-switch-selected-track-color: #5eead4;
}
.theme-teal .light .mat-mdc-slide-toggle.mat-accent,
.theme-teal.light .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.theme-teal .light .mat-mdc-slide-toggle.mat-warn,
.theme-teal.light .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.theme-teal .light .mat-mdc-radio-button,
.theme-teal.light .mat-mdc-radio-button {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mat-mdc-radio-ripple-color: #000;
}
.theme-teal .light .mat-mdc-radio-button .mdc-form-field,
.theme-teal.light .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-teal .light .mat-mdc-radio-button .mdc-radio--disabled + label,
.theme-teal.light .mat-mdc-radio-button .mdc-radio--disabled + label {
  color: #94a3b8;
}
.theme-teal .light .mat-mdc-radio-button.mat-primary,
.theme-teal.light .mat-mdc-radio-button.mat-primary {
  --mdc-radio-selected-focus-icon-color: #0d9488;
  --mdc-radio-selected-hover-icon-color: #0d9488;
  --mdc-radio-selected-icon-color: #0d9488;
  --mdc-radio-selected-pressed-icon-color: #0d9488;
  --mat-mdc-radio-checked-ripple-color: #0d9488;
}
.theme-teal .light .mat-mdc-radio-button.mat-accent,
.theme-teal.light .mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-mdc-radio-checked-ripple-color: #1e293b;
}
.theme-teal .light .mat-mdc-radio-button.mat-warn,
.theme-teal.light .mat-mdc-radio-button.mat-warn {
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-mdc-radio-checked-ripple-color: #dc2626;
}
.theme-teal .light .mat-mdc-slider,
.theme-teal.light .mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.theme-teal .light .mat-mdc-slider.mat-primary,
.theme-teal.light .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #0d9488;
  --mdc-slider-focus-handle-color: #0d9488;
  --mdc-slider-hover-handle-color: #0d9488;
  --mdc-slider-active-track-color: #0d9488;
  --mdc-slider-inactive-track-color: #0d9488;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #0d9488;
  --mat-mdc-slider-ripple-color: #0d9488;
  --mat-mdc-slider-hover-ripple-color: rgba(13, 148, 136, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(13, 148, 136, 0.2);
}
.theme-teal .light .mat-mdc-slider.mat-accent,
.theme-teal.light .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mat-mdc-slider-ripple-color: #1e293b;
  --mat-mdc-slider-hover-ripple-color: rgba(30, 41, 59, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(30, 41, 59, 0.2);
}
.theme-teal .light .mat-mdc-slider.mat-warn,
.theme-teal.light .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mat-mdc-slider-ripple-color: #dc2626;
  --mat-mdc-slider-hover-ripple-color: rgba(220, 38, 38, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(220, 38, 38, 0.2);
}
.theme-teal .light .mdc-menu-surface,
.theme-teal.light .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mdc-list-item__primary-text,
.theme-teal.light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-teal .light .mdc-list-item__secondary-text,
.theme-teal.light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.theme-teal .light .mdc-list-item__overline-text,
.theme-teal.light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-teal .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-teal.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-teal .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-teal.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.theme-teal .light .mdc-list-item__end,
.theme-teal.light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-teal .light .mdc-list-item--disabled .mdc-list-item__start,
.theme-teal .light .mdc-list-item--disabled .mdc-list-item__content,
.theme-teal .light .mdc-list-item--disabled .mdc-list-item__end,
.theme-teal.light .mdc-list-item--disabled .mdc-list-item__start,
.theme-teal.light .mdc-list-item--disabled .mdc-list-item__content,
.theme-teal.light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-teal .light .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-teal.light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-teal.light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-teal.light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-teal.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-teal.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-teal .light .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-teal.light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-teal.light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #0d9488);
}
.theme-teal .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #0d9488);
}
.theme-teal .light .mdc-deprecated-list-group__subheader,
.theme-teal.light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-teal .light .mdc-list-divider::after,
.theme-teal.light .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-teal .light .mdc-list-divider,
.theme-teal.light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-mdc-menu-item[disabled],
.theme-teal .light .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.theme-teal .light .mat-mdc-menu-item[disabled] .mat-icon-no-color,
.theme-teal.light .mat-mdc-menu-item[disabled],
.theme-teal.light .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.theme-teal.light .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}
.theme-teal .light .mat-mdc-menu-item .mat-icon-no-color,
.theme-teal .light .mat-mdc-menu-submenu-icon,
.theme-teal.light .mat-mdc-menu-item .mat-icon-no-color,
.theme-teal.light .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-teal .light .mat-mdc-menu-item:hover:not([disabled]),
.theme-teal .light .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.theme-teal .light .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-teal .light .mat-mdc-menu-item-highlighted:not([disabled]),
.theme-teal.light .mat-mdc-menu-item:hover:not([disabled]),
.theme-teal.light .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.theme-teal.light .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-teal.light .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.theme-teal .light .mat-mdc-list-item-interactive::before,
.theme-teal.light .mat-mdc-list-item-interactive::before {
  background: black;
}
.theme-teal .light .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before,
.theme-teal.light .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.04;
}
.theme-teal .light .mat-mdc-list-item-interactive.mdc-list-item--selected::before,
.theme-teal.light .mat-mdc-list-item-interactive.mdc-list-item--selected::before {
  background: #0d9488;
  opacity: 0.08;
}
.theme-teal .light .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before,
.theme-teal.light .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.12;
}
.theme-teal .light .mat-mdc-list-item-interactive:focus::before,
.theme-teal.light .mat-mdc-list-item-interactive:focus::before {
  opacity: 0.12;
}
.theme-teal .light .mdc-list-item__primary-text,
.theme-teal.light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-teal .light .mdc-list-item__secondary-text,
.theme-teal.light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.theme-teal .light .mdc-list-item__overline-text,
.theme-teal.light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-teal .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-teal.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-teal .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-teal.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.theme-teal .light .mdc-list-item__end,
.theme-teal.light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-teal .light .mdc-list-item--disabled .mdc-list-item__start,
.theme-teal .light .mdc-list-item--disabled .mdc-list-item__content,
.theme-teal .light .mdc-list-item--disabled .mdc-list-item__end,
.theme-teal.light .mdc-list-item--disabled .mdc-list-item__start,
.theme-teal.light .mdc-list-item--disabled .mdc-list-item__content,
.theme-teal.light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-teal .light .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-teal.light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-teal.light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-teal.light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-teal.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-teal.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-teal .light .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-teal.light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-teal.light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #0d9488);
}
.theme-teal .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #0d9488);
}
.theme-teal .light .mdc-deprecated-list-group__subheader,
.theme-teal.light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-teal .light .mdc-list-divider::after,
.theme-teal.light .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-teal .light .mdc-list-divider,
.theme-teal.light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-mdc-list-option .mdc-list-item__start, .theme-teal .light .mat-mdc-list-option .mdc-list-item__end,
.theme-teal.light .mat-mdc-list-option .mdc-list-item__start,
.theme-teal.light .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #0d9488;
  --mdc-checkbox-selected-hover-icon-color: #0d9488;
  --mdc-checkbox-selected-icon-color: #0d9488;
  --mdc-checkbox-selected-pressed-icon-color: #0d9488;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-teal .light .mat-mdc-list-option.mat-accent .mdc-list-item__start, .theme-teal .light .mat-mdc-list-option.mat-accent .mdc-list-item__end,
.theme-teal.light .mat-mdc-list-option.mat-accent .mdc-list-item__start,
.theme-teal.light .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-teal .light .mat-mdc-list-option.mat-warn .mdc-list-item__start, .theme-teal .light .mat-mdc-list-option.mat-warn .mdc-list-item__end,
.theme-teal.light .mat-mdc-list-option.mat-warn .mdc-list-item__start,
.theme-teal.light .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-teal .light .mat-mdc-paginator,
.theme-teal.light .mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme-teal .light .mat-mdc-paginator-icon,
.theme-teal.light .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}
.theme-teal .light .mat-mdc-paginator-decrement,
.theme-teal .light .mat-mdc-paginator-increment,
.theme-teal.light .mat-mdc-paginator-decrement,
.theme-teal.light .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.theme-teal .light .mat-mdc-paginator-first,
.theme-teal .light .mat-mdc-paginator-last,
.theme-teal.light .mat-mdc-paginator-first,
.theme-teal.light .mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.theme-teal .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.theme-teal .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.theme-teal .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.theme-teal .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last,
.theme-teal.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.theme-teal.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.theme-teal.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.theme-teal.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon,
.theme-teal.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-mdc-tab, .theme-teal .light .mat-mdc-tab-link,
.theme-teal.light .mat-mdc-tab,
.theme-teal.light .mat-mdc-tab-link {
  background-color: transparent;
}
.theme-teal .light .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label, .theme-teal .light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.theme-teal.light .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.theme-teal.light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}
.theme-teal .light .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-teal .light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-teal.light .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-teal.light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #0d9488;
}
.theme-teal .light .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-teal .light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-teal.light .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-teal.light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #0d9488;
}
.theme-teal .light .mdc-tab__ripple::before,
.theme-teal .light .mat-mdc-tab .mat-ripple-element,
.theme-teal .light .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-teal .light .mat-mdc-tab-link .mat-ripple-element,
.theme-teal.light .mdc-tab__ripple::before,
.theme-teal.light .mat-mdc-tab .mat-ripple-element,
.theme-teal.light .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-teal.light .mat-mdc-tab-link .mat-ripple-element {
  background-color: #0d9488;
}
.theme-teal .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-teal .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-teal .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-teal .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-teal.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-teal.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-teal.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-teal.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #1e293b;
}
.theme-teal .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-teal .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-teal .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-teal .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-teal.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-teal.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-teal.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-teal.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #1e293b;
}
.theme-teal .light .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.theme-teal .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-teal .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-teal .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .theme-teal .light .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.theme-teal .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-teal .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-teal .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element,
.theme-teal.light .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.theme-teal.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-teal.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-teal.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element,
.theme-teal.light .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.theme-teal.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-teal.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-teal.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #1e293b;
}
.theme-teal .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-teal .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-teal .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-teal .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-teal.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-teal.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-teal.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-teal.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #dc2626;
}
.theme-teal .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-teal .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-teal .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-teal .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-teal.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-teal.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-teal.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-teal.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #dc2626;
}
.theme-teal .light .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.theme-teal .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-teal .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-teal .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .theme-teal .light .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.theme-teal .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-teal .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-teal .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element,
.theme-teal.light .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.theme-teal.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-teal.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-teal.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element,
.theme-teal.light .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.theme-teal.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-teal.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-teal.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #dc2626;
}
.theme-teal .light .mat-mdc-tab-group.mat-background-primary, .theme-teal .light .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-teal.light .mat-mdc-tab-group.mat-background-primary,
.theme-teal.light .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #0d9488;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-teal .light .mat-mdc-tab-group.mat-background-accent, .theme-teal .light .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-teal.light .mat-mdc-tab-group.mat-background-accent,
.theme-teal.light .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #1e293b;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-teal .light .mat-mdc-tab-group.mat-background-warn, .theme-teal .light .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-teal.light .mat-mdc-tab-group.mat-background-warn,
.theme-teal.light .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #dc2626;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-teal .light .mat-mdc-tab-header-pagination-chevron,
.theme-teal.light .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #000);
}
.theme-teal .light .mat-mdc-checkbox .mdc-form-field,
.theme-teal.light .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-teal .light .mat-mdc-checkbox .mat-ripple-element,
.theme-teal.light .mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.theme-teal .light .mat-mdc-checkbox .mdc-checkbox__ripple,
.theme-teal.light .mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #000;
}
.theme-teal .light .mat-mdc-checkbox.mat-primary,
.theme-teal.light .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #0d9488;
  --mdc-checkbox-selected-hover-icon-color: #0d9488;
  --mdc-checkbox-selected-icon-color: #0d9488;
  --mdc-checkbox-selected-pressed-icon-color: #0d9488;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-teal .light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-teal.light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(13, 148, 136, 0.1);
}
.theme-teal .light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-teal.light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #0d9488;
}
.theme-teal .light .mat-mdc-checkbox.mat-accent,
.theme-teal.light .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-teal .light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-teal.light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(30, 41, 59, 0.1);
}
.theme-teal .light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-teal.light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #1e293b;
}
.theme-teal .light .mat-mdc-checkbox.mat-warn,
.theme-teal.light .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-teal .light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-teal.light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(220, 38, 38, 0.1);
}
.theme-teal .light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-teal.light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #dc2626;
}
.theme-teal .light .mat-mdc-checkbox-disabled label,
.theme-teal.light .mat-mdc-checkbox-disabled label {
  color: #94a3b8;
}
.theme-teal .light .mat-mdc-button.mat-unthemed,
.theme-teal.light .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.theme-teal .light .mat-mdc-button.mat-primary,
.theme-teal.light .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #0d9488;
}
.theme-teal .light .mat-mdc-button.mat-accent,
.theme-teal.light .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
}
.theme-teal .light .mat-mdc-button.mat-warn,
.theme-teal.light .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
}
.theme-teal .light .mat-mdc-button[disabled][disabled],
.theme-teal.light .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.theme-teal .light .mat-mdc-unelevated-button.mat-unthemed,
.theme-teal.light .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.theme-teal .light .mat-mdc-unelevated-button.mat-primary,
.theme-teal.light .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #0d9488;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-teal .light .mat-mdc-unelevated-button.mat-accent,
.theme-teal.light .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-teal .light .mat-mdc-unelevated-button.mat-warn,
.theme-teal.light .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-teal .light .mat-mdc-unelevated-button[disabled][disabled],
.theme-teal.light .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.theme-teal .light .mat-mdc-raised-button.mat-unthemed,
.theme-teal.light .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.theme-teal .light .mat-mdc-raised-button.mat-primary,
.theme-teal.light .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #0d9488;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-teal .light .mat-mdc-raised-button.mat-accent,
.theme-teal.light .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-teal .light .mat-mdc-raised-button.mat-warn,
.theme-teal.light .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-teal .light .mat-mdc-raised-button[disabled][disabled],
.theme-teal.light .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.theme-teal .light .mat-mdc-outlined-button,
.theme-teal.light .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-mdc-outlined-button.mat-unthemed,
.theme-teal.light .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.theme-teal .light .mat-mdc-outlined-button.mat-primary,
.theme-teal.light .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #0d9488;
}
.theme-teal .light .mat-mdc-outlined-button.mat-accent,
.theme-teal.light .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
}
.theme-teal .light .mat-mdc-outlined-button.mat-warn,
.theme-teal.light .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
}
.theme-teal .light .mat-mdc-outlined-button[disabled][disabled],
.theme-teal.light .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-mdc-button, .theme-teal .light .mat-mdc-outlined-button,
.theme-teal.light .mat-mdc-button,
.theme-teal.light .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-teal .light .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .theme-teal .light .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-teal .light .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-teal .light .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-teal .light .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-teal .light .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-teal .light .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .theme-teal .light .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-teal .light .mat-mdc-button.mat-primary, .theme-teal .light .mat-mdc-outlined-button.mat-primary,
.theme-teal.light .mat-mdc-button.mat-primary,
.theme-teal.light .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #0d9488;
  --mat-mdc-button-ripple-color: rgba(13, 148, 136, 0.1);
}
.theme-teal .light .mat-mdc-button.mat-accent, .theme-teal .light .mat-mdc-outlined-button.mat-accent,
.theme-teal.light .mat-mdc-button.mat-accent,
.theme-teal.light .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #1e293b;
  --mat-mdc-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-teal .light .mat-mdc-button.mat-warn, .theme-teal .light .mat-mdc-outlined-button.mat-warn,
.theme-teal.light .mat-mdc-button.mat-warn,
.theme-teal.light .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #dc2626;
  --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-teal .light .mat-mdc-raised-button, .theme-teal .light .mat-mdc-unelevated-button,
.theme-teal.light .mat-mdc-raised-button,
.theme-teal.light .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-teal .light .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .theme-teal .light .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-teal .light .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-teal .light .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-teal .light .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-teal .light .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-teal .light .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .theme-teal .light .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-teal .light .mat-mdc-raised-button.mat-primary, .theme-teal .light .mat-mdc-unelevated-button.mat-primary,
.theme-teal.light .mat-mdc-raised-button.mat-primary,
.theme-teal.light .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .light .mat-mdc-raised-button.mat-accent, .theme-teal .light .mat-mdc-unelevated-button.mat-accent,
.theme-teal.light .mat-mdc-raised-button.mat-accent,
.theme-teal.light .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .light .mat-mdc-raised-button.mat-warn, .theme-teal .light .mat-mdc-unelevated-button.mat-warn,
.theme-teal.light .mat-mdc-raised-button.mat-warn,
.theme-teal.light .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .light .mat-mdc-icon-button,
.theme-teal.light .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-teal .light .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-teal .light .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-teal .light .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-teal .light .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-teal .light .mat-mdc-icon-button.mat-primary,
.theme-teal.light .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #0d9488;
  --mat-mdc-button-ripple-color: rgba(13, 148, 136, 0.1);
}
.theme-teal .light .mat-mdc-icon-button.mat-accent,
.theme-teal.light .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #1e293b;
  --mat-mdc-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-teal .light .mat-mdc-icon-button.mat-warn,
.theme-teal.light .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #dc2626;
  --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-teal .light .mat-mdc-icon-button.mat-primary,
.theme-teal.light .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #0d9488;
}
.theme-teal .light .mat-mdc-icon-button.mat-accent,
.theme-teal.light .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
}
.theme-teal .light .mat-mdc-icon-button.mat-warn,
.theme-teal.light .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
}
.theme-teal .light .mat-mdc-icon-button[disabled][disabled],
.theme-teal.light .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.theme-teal .light .mat-mdc-fab, .theme-teal .light .mat-mdc-mini-fab,
.theme-teal.light .mat-mdc-fab,
.theme-teal.light .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-teal .light .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .theme-teal .light .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-teal .light .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-teal .light .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-teal .light .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-teal .light .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-teal .light .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .theme-teal .light .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.theme-teal.light .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-teal .light .mat-mdc-fab.mat-primary, .theme-teal .light .mat-mdc-mini-fab.mat-primary,
.theme-teal.light .mat-mdc-fab.mat-primary,
.theme-teal.light .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .light .mat-mdc-fab.mat-accent, .theme-teal .light .mat-mdc-mini-fab.mat-accent,
.theme-teal.light .mat-mdc-fab.mat-accent,
.theme-teal.light .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .light .mat-mdc-fab.mat-warn, .theme-teal .light .mat-mdc-mini-fab.mat-warn,
.theme-teal.light .mat-mdc-fab.mat-warn,
.theme-teal.light .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .light .mat-mdc-fab.mat-unthemed, .theme-teal .light .mat-mdc-mini-fab.mat-unthemed,
.theme-teal.light .mat-mdc-fab.mat-unthemed,
.theme-teal.light .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.theme-teal .light .mat-mdc-fab.mat-primary, .theme-teal .light .mat-mdc-mini-fab.mat-primary,
.theme-teal.light .mat-mdc-fab.mat-primary,
.theme-teal.light .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #0d9488;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-teal .light .mat-mdc-fab.mat-accent, .theme-teal .light .mat-mdc-mini-fab.mat-accent,
.theme-teal.light .mat-mdc-fab.mat-accent,
.theme-teal.light .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-teal .light .mat-mdc-fab.mat-warn, .theme-teal .light .mat-mdc-mini-fab.mat-warn,
.theme-teal.light .mat-mdc-fab.mat-warn,
.theme-teal.light .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-teal .light .mat-mdc-fab[disabled][disabled], .theme-teal .light .mat-mdc-mini-fab[disabled][disabled],
.theme-teal.light .mat-mdc-fab[disabled][disabled],
.theme-teal.light .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.theme-teal .light .mat-mdc-snack-bar-container,
.theme-teal.light .mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #1e293b;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
}
.theme-teal .light .mdc-data-table,
.theme-teal.light .mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mdc-data-table__row,
.theme-teal.light .mdc-data-table__row {
  background-color: inherit;
}
.theme-teal .light .mdc-data-table__header-cell,
.theme-teal.light .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}
.theme-teal .light .mdc-data-table__row--selected,
.theme-teal.light .mdc-data-table__row--selected {
  background-color: rgba(13, 148, 136, 0.04);
}
.theme-teal .light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.theme-teal .light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.theme-teal .light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing,
.theme-teal.light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.theme-teal.light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.theme-teal.light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mdc-data-table__cell,
.theme-teal .light .mdc-data-table__header-cell,
.theme-teal.light .mdc-data-table__cell,
.theme-teal.light .mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mdc-data-table__pagination,
.theme-teal.light .mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mdc-data-table__row:not(.mdc-data-table__row--selected):hover,
.theme-teal.light .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.theme-teal .light .mdc-data-table__header-cell,
.theme-teal.light .mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}
.theme-teal .light .mdc-data-table__pagination-total,
.theme-teal .light .mdc-data-table__pagination-rows-per-page-label,
.theme-teal .light .mdc-data-table__cell,
.theme-teal.light .mdc-data-table__pagination-total,
.theme-teal.light .mdc-data-table__pagination-rows-per-page-label,
.theme-teal.light .mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}
[dir=rtl] .theme-teal .light .mdc-data-table__pagination-button .mdc-button__icon, [dir=rtl] .theme-teal.light .mdc-data-table__pagination-button .mdc-button__icon, .theme-teal .light .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl], .theme-teal.light .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-teal .light .mat-mdc-table,
.theme-teal.light .mat-mdc-table {
  background: white;
}
.theme-teal .light .mat-mdc-progress-spinner,
.theme-teal.light .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #0d9488;
}
.theme-teal .light .mat-mdc-progress-spinner.mat-accent,
.theme-teal.light .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-teal .light .mat-mdc-progress-spinner.mat-warn,
.theme-teal.light .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-teal .light .mat-badge-content,
.theme-teal.light .mat-badge-content {
  color: white;
  background: #0d9488;
}
.cdk-high-contrast-active .theme-teal .light .mat-badge-content, .cdk-high-contrast-active .theme-teal.light .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.theme-teal .light .mat-badge-accent .mat-badge-content,
.theme-teal.light .mat-badge-accent .mat-badge-content {
  background: #1e293b;
  color: white;
}
.theme-teal .light .mat-badge-warn .mat-badge-content,
.theme-teal.light .mat-badge-warn .mat-badge-content {
  color: white;
  background: #dc2626;
}
.theme-teal .light .mat-badge-disabled .mat-badge-content,
.theme-teal.light .mat-badge-disabled .mat-badge-content {
  background: #94a3b8;
  color: #94a3b8;
}
.theme-teal .light .mat-bottom-sheet-container,
.theme-teal.light .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: #1e293b;
}
.theme-teal .light .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-teal .light .mat-button-toggle-group:not([class*=mat-elevation-z]),
.theme-teal.light .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-teal.light .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-teal .light .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]),
.theme-teal.light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-teal.light .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.theme-teal .light .mat-button-toggle,
.theme-teal.light .mat-button-toggle {
  color: #94a3b8;
}
.theme-teal .light .mat-button-toggle .mat-button-toggle-focus-overlay,
.theme-teal.light .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: #64748b;
}
.theme-teal .light .mat-button-toggle-appearance-standard,
.theme-teal.light .mat-button-toggle-appearance-standard {
  color: #1e293b;
  background: white;
}
.theme-teal .light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay,
.theme-teal.light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: #64748b;
}
.theme-teal .light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
.theme-teal.light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e2e8f0;
}
.theme-teal .light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
.theme-teal.light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e2e8f0;
}
.theme-teal .light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle,
.theme-teal.light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e2e8f0;
}
.theme-teal .light .mat-button-toggle-checked,
.theme-teal.light .mat-button-toggle-checked {
  background-color: #e2e8f0;
  color: #64748b;
}
.theme-teal .light .mat-button-toggle-checked.mat-button-toggle-appearance-standard,
.theme-teal.light .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: #1e293b;
}
.theme-teal .light .mat-button-toggle-disabled,
.theme-teal.light .mat-button-toggle-disabled {
  color: #94a3b8;
  background-color: #cbd5e1;
}
.theme-teal .light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard,
.theme-teal.light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.theme-teal .light .mat-button-toggle-disabled.mat-button-toggle-checked,
.theme-teal.light .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #e2e8f0;
}
.theme-teal .light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-teal .light .mat-button-toggle-group-appearance-standard,
.theme-teal.light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-teal.light .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e2e8f0;
}
.theme-teal .light .mat-calendar-arrow,
.theme-teal.light .mat-calendar-arrow {
  fill: #64748b;
}
.theme-teal .light .mat-datepicker-toggle,
.theme-teal .light .mat-datepicker-content .mat-calendar-next-button,
.theme-teal .light .mat-datepicker-content .mat-calendar-previous-button,
.theme-teal.light .mat-datepicker-toggle,
.theme-teal.light .mat-datepicker-content .mat-calendar-next-button,
.theme-teal.light .mat-datepicker-content .mat-calendar-previous-button {
  color: #64748b;
}
.theme-teal .light .mat-calendar-table-header-divider::after,
.theme-teal.light .mat-calendar-table-header-divider::after {
  background: #e2e8f0;
}
.theme-teal .light .mat-calendar-table-header,
.theme-teal .light .mat-calendar-body-label,
.theme-teal.light .mat-calendar-table-header,
.theme-teal.light .mat-calendar-body-label {
  color: #64748b;
}
.theme-teal .light .mat-calendar-body-cell-content,
.theme-teal .light .mat-date-range-input-separator,
.theme-teal.light .mat-calendar-body-cell-content,
.theme-teal.light .mat-date-range-input-separator {
  color: #1e293b;
  border-color: transparent;
}
.theme-teal .light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-teal.light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: #94a3b8;
}
.theme-teal .light .mat-form-field-disabled .mat-date-range-input-separator,
.theme-teal.light .mat-form-field-disabled .mat-date-range-input-separator {
  color: #94a3b8;
}
.theme-teal .light .mat-calendar-body-in-preview,
.theme-teal.light .mat-calendar-body-in-preview {
  color: #e2e8f0;
}
.theme-teal .light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-teal.light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #94a3b8;
}
.theme-teal .light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-teal.light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(148, 163, 184, 0.8);
}
.theme-teal .light .mat-calendar-body-in-range::before,
.theme-teal.light .mat-calendar-body-in-range::before {
  background: rgba(13, 148, 136, 0.2);
}
.theme-teal .light .mat-calendar-body-comparison-identical,
.theme-teal .light .mat-calendar-body-in-comparison-range::before,
.theme-teal.light .mat-calendar-body-comparison-identical,
.theme-teal.light .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-teal .light .mat-calendar-body-comparison-bridge-start::before,
.theme-teal .light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-teal.light .mat-calendar-body-comparison-bridge-start::before,
.theme-teal.light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(13, 148, 136, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-teal .light .mat-calendar-body-comparison-bridge-end::before,
.theme-teal .light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-teal.light .mat-calendar-body-comparison-bridge-end::before,
.theme-teal.light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(13, 148, 136, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-teal .light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-teal .light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-teal.light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-teal.light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-teal .light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-teal .light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-teal.light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-teal.light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-teal .light .mat-calendar-body-selected,
.theme-teal.light .mat-calendar-body-selected {
  background-color: #0d9488;
  color: white;
}
.theme-teal .light .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-teal.light .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(13, 148, 136, 0.4);
}
.theme-teal .light .mat-calendar-body-today.mat-calendar-body-selected,
.theme-teal.light .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-teal .light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-teal .light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-teal.light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-teal.light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(13, 148, 136, 0.3);
}
@media (hover: hover) {
  .theme-teal .light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-teal.light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(13, 148, 136, 0.3);
  }
}
.theme-teal .light .mat-datepicker-content,
.theme-teal.light .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: #1e293b;
}
.theme-teal .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before,
.theme-teal.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(30, 41, 59, 0.2);
}
.theme-teal .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-teal .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before,
.theme-teal.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-teal.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-teal .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-teal .light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-teal.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-teal.light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(30, 41, 59, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-teal .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-teal .light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-teal.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-teal.light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(30, 41, 59, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-teal .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-teal .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-teal.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-teal.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-teal .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-teal .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-teal.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-teal.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-teal .light .mat-datepicker-content.mat-accent .mat-calendar-body-selected,
.theme-teal.light .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #1e293b;
  color: white;
}
.theme-teal .light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-teal.light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(30, 41, 59, 0.4);
}
.theme-teal .light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected,
.theme-teal.light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-teal .light .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-teal .light .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-teal.light .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-teal.light .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(30, 41, 59, 0.3);
}
@media (hover: hover) {
  .theme-teal .light .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-teal.light .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(30, 41, 59, 0.3);
  }
}
.theme-teal .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before,
.theme-teal.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(220, 38, 38, 0.2);
}
.theme-teal .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-teal .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before,
.theme-teal.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-teal.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-teal .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-teal .light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-teal.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-teal.light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(220, 38, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-teal .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-teal .light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-teal.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-teal.light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(220, 38, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-teal .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-teal .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-teal.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-teal.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-teal .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-teal .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-teal.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-teal.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-teal .light .mat-datepicker-content.mat-warn .mat-calendar-body-selected,
.theme-teal.light .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #dc2626;
  color: white;
}
.theme-teal .light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-teal.light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(220, 38, 38, 0.4);
}
.theme-teal .light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected,
.theme-teal.light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-teal .light .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-teal .light .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-teal.light .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-teal.light .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(220, 38, 38, 0.3);
}
@media (hover: hover) {
  .theme-teal .light .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-teal.light .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(220, 38, 38, 0.3);
  }
}
.theme-teal .light .mat-datepicker-content-touch,
.theme-teal.light .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-datepicker-toggle-active,
.theme-teal.light .mat-datepicker-toggle-active {
  color: #0d9488;
}
.theme-teal .light .mat-datepicker-toggle-active.mat-accent,
.theme-teal.light .mat-datepicker-toggle-active.mat-accent {
  color: #1e293b;
}
.theme-teal .light .mat-datepicker-toggle-active.mat-warn,
.theme-teal.light .mat-datepicker-toggle-active.mat-warn {
  color: #dc2626;
}
.theme-teal .light .mat-date-range-input-inner[disabled],
.theme-teal.light .mat-date-range-input-inner[disabled] {
  color: #94a3b8;
}
.theme-teal .light .mat-divider,
.theme-teal.light .mat-divider {
  border-top-color: #e2e8f0;
}
.theme-teal .light .mat-divider-vertical,
.theme-teal.light .mat-divider-vertical {
  border-right-color: #e2e8f0;
}
.theme-teal .light .mat-expansion-panel,
.theme-teal.light .mat-expansion-panel {
  background: white;
  color: #1e293b;
}
.theme-teal .light .mat-expansion-panel:not([class*=mat-elevation-z]),
.theme-teal.light .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-action-row,
.theme-teal.light .mat-action-row {
  border-top-color: #e2e8f0;
}
.theme-teal .light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .theme-teal .light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .theme-teal .light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]),
.theme-teal.light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
.theme-teal.light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
.theme-teal.light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(148, 163, 184, 0.12);
}
@media (hover: none) {
  .theme-teal .light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover,
  .theme-teal.light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.theme-teal .light .mat-expansion-panel-header-title,
.theme-teal.light .mat-expansion-panel-header-title {
  color: #1e293b;
}
.theme-teal .light .mat-expansion-panel-header-description,
.theme-teal .light .mat-expansion-indicator::after,
.theme-teal.light .mat-expansion-panel-header-description,
.theme-teal.light .mat-expansion-indicator::after {
  color: #64748b;
}
.theme-teal .light .mat-expansion-panel-header[aria-disabled=true],
.theme-teal.light .mat-expansion-panel-header[aria-disabled=true] {
  color: #94a3b8;
}
.theme-teal .light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-teal .light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description,
.theme-teal.light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-teal.light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.theme-teal .light .mat-icon.mat-primary,
.theme-teal.light .mat-icon.mat-primary {
  color: #0d9488;
}
.theme-teal .light .mat-icon.mat-accent,
.theme-teal.light .mat-icon.mat-accent {
  color: #1e293b;
}
.theme-teal .light .mat-icon.mat-warn,
.theme-teal.light .mat-icon.mat-warn {
  color: #dc2626;
}
.theme-teal .light .mat-drawer-container,
.theme-teal.light .mat-drawer-container {
  background-color: #f1f5f9;
  color: #1e293b;
}
.theme-teal .light .mat-drawer,
.theme-teal.light .mat-drawer {
  background-color: white;
  color: #1e293b;
}
.theme-teal .light .mat-drawer.mat-drawer-push,
.theme-teal.light .mat-drawer.mat-drawer-push {
  background-color: white;
}
.theme-teal .light .mat-drawer:not(.mat-drawer-side),
.theme-teal.light .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-drawer-side,
.theme-teal.light .mat-drawer-side {
  border-right: solid 1px #e2e8f0;
}
.theme-teal .light .mat-drawer-side.mat-drawer-end,
.theme-teal.light .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px #e2e8f0;
  border-right: none;
}
.theme-teal .light [dir=rtl] .mat-drawer-side,
.theme-teal.light [dir=rtl] .mat-drawer-side {
  border-left: solid 1px #e2e8f0;
  border-right: none;
}
.theme-teal .light [dir=rtl] .mat-drawer-side.mat-drawer-end,
.theme-teal.light [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px #e2e8f0;
}
.theme-teal .light .mat-drawer-backdrop.mat-drawer-shown,
.theme-teal.light .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.theme-teal .light .mat-step-header.cdk-keyboard-focused, .theme-teal .light .mat-step-header.cdk-program-focused, .theme-teal .light .mat-step-header:hover:not([aria-disabled]), .theme-teal .light .mat-step-header:hover[aria-disabled=false],
.theme-teal.light .mat-step-header.cdk-keyboard-focused,
.theme-teal.light .mat-step-header.cdk-program-focused,
.theme-teal.light .mat-step-header:hover:not([aria-disabled]),
.theme-teal.light .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(148, 163, 184, 0.12);
}
.theme-teal .light .mat-step-header:hover[aria-disabled=true],
.theme-teal.light .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .theme-teal .light .mat-step-header:hover,
  .theme-teal.light .mat-step-header:hover {
    background: none;
  }
}
.theme-teal .light .mat-step-header .mat-step-label,
.theme-teal .light .mat-step-header .mat-step-optional,
.theme-teal.light .mat-step-header .mat-step-label,
.theme-teal.light .mat-step-header .mat-step-optional {
  color: #64748b;
}
.theme-teal .light .mat-step-header .mat-step-icon,
.theme-teal.light .mat-step-header .mat-step-icon {
  background-color: #64748b;
  color: white;
}
.theme-teal .light .mat-step-header .mat-step-icon-selected,
.theme-teal .light .mat-step-header .mat-step-icon-state-done,
.theme-teal .light .mat-step-header .mat-step-icon-state-edit,
.theme-teal.light .mat-step-header .mat-step-icon-selected,
.theme-teal.light .mat-step-header .mat-step-icon-state-done,
.theme-teal.light .mat-step-header .mat-step-icon-state-edit {
  background-color: #0d9488;
  color: white;
}
.theme-teal .light .mat-step-header.mat-accent .mat-step-icon,
.theme-teal.light .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.theme-teal .light .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-teal .light .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-teal .light .mat-step-header.mat-accent .mat-step-icon-state-edit,
.theme-teal.light .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-teal.light .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-teal.light .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #1e293b;
  color: white;
}
.theme-teal .light .mat-step-header.mat-warn .mat-step-icon,
.theme-teal.light .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.theme-teal .light .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-teal .light .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-teal .light .mat-step-header.mat-warn .mat-step-icon-state-edit,
.theme-teal.light .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-teal.light .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-teal.light .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #dc2626;
  color: white;
}
.theme-teal .light .mat-step-header .mat-step-icon-state-error,
.theme-teal.light .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #dc2626;
}
.theme-teal .light .mat-step-header .mat-step-label.mat-step-label-active,
.theme-teal.light .mat-step-header .mat-step-label.mat-step-label-active {
  color: #1e293b;
}
.theme-teal .light .mat-step-header .mat-step-label.mat-step-label-error,
.theme-teal.light .mat-step-header .mat-step-label.mat-step-label-error {
  color: #dc2626;
}
.theme-teal .light .mat-stepper-horizontal, .theme-teal .light .mat-stepper-vertical,
.theme-teal.light .mat-stepper-horizontal,
.theme-teal.light .mat-stepper-vertical {
  background-color: white;
}
.theme-teal .light .mat-stepper-vertical-line::before,
.theme-teal.light .mat-stepper-vertical-line::before {
  border-left-color: #e2e8f0;
}
.theme-teal .light .mat-horizontal-stepper-header::before,
.theme-teal .light .mat-horizontal-stepper-header::after,
.theme-teal .light .mat-stepper-horizontal-line,
.theme-teal.light .mat-horizontal-stepper-header::before,
.theme-teal.light .mat-horizontal-stepper-header::after,
.theme-teal.light .mat-stepper-horizontal-line {
  border-top-color: #e2e8f0;
}
.theme-teal .light .mat-sort-header-arrow,
.theme-teal.light .mat-sort-header-arrow {
  color: #64748b;
}
.theme-teal .light .mat-toolbar,
.theme-teal.light .mat-toolbar {
  background: white;
  color: #1e293b;
}
.theme-teal .light .mat-toolbar.mat-primary,
.theme-teal.light .mat-toolbar.mat-primary {
  background: #0d9488;
  color: white;
}
.theme-teal .light .mat-toolbar.mat-accent,
.theme-teal.light .mat-toolbar.mat-accent {
  background: #1e293b;
  color: white;
}
.theme-teal .light .mat-toolbar.mat-warn,
.theme-teal.light .mat-toolbar.mat-warn {
  background: #dc2626;
  color: white;
}
.theme-teal .light .mat-toolbar .mat-form-field-underline,
.theme-teal .light .mat-toolbar .mat-form-field-ripple,
.theme-teal .light .mat-toolbar .mat-focused .mat-form-field-ripple,
.theme-teal.light .mat-toolbar .mat-form-field-underline,
.theme-teal.light .mat-toolbar .mat-form-field-ripple,
.theme-teal.light .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.theme-teal .light .mat-toolbar .mat-form-field-label,
.theme-teal .light .mat-toolbar .mat-focused .mat-form-field-label,
.theme-teal .light .mat-toolbar .mat-select-value,
.theme-teal .light .mat-toolbar .mat-select-arrow,
.theme-teal .light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow,
.theme-teal.light .mat-toolbar .mat-form-field-label,
.theme-teal.light .mat-toolbar .mat-focused .mat-form-field-label,
.theme-teal.light .mat-toolbar .mat-select-value,
.theme-teal.light .mat-toolbar .mat-select-arrow,
.theme-teal.light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.theme-teal .light .mat-toolbar .mat-input-element,
.theme-teal.light .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.theme-teal .light .mat-tree,
.theme-teal.light .mat-tree {
  background: white;
}
.theme-teal .light .mat-tree-node,
.theme-teal .light .mat-nested-tree-node,
.theme-teal.light .mat-tree-node,
.theme-teal.light .mat-nested-tree-node {
  color: #1e293b;
}

.theme-teal .dark .mat-ripple-element,
.theme-teal.dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .dark .mat-mdc-option,
.theme-teal.dark .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-teal .dark .mat-mdc-option:hover:not(.mdc-list-item--disabled), .theme-teal .dark .mat-mdc-option:focus:not(.mdc-list-item--disabled), .theme-teal .dark .mat-mdc-option.mat-mdc-option-active, .theme-teal .dark .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled),
.theme-teal.dark .mat-mdc-option:hover:not(.mdc-list-item--disabled),
.theme-teal.dark .mat-mdc-option:focus:not(.mdc-list-item--disabled),
.theme-teal.dark .mat-mdc-option.mat-mdc-option-active,
.theme-teal.dark .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(255, 255, 255, 0.08);
}
.theme-teal .dark .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-teal.dark .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #0d9488);
}
.theme-teal .dark .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-teal.dark .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #1e293b);
}
.theme-teal .dark .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-teal.dark .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mat-mdc-optgroup-label,
.theme-teal.dark .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-teal .dark .mat-pseudo-checkbox,
.theme-teal.dark .mat-pseudo-checkbox {
  color: #94a3b8;
}
.theme-teal .dark .mat-pseudo-checkbox::after,
.theme-teal.dark .mat-pseudo-checkbox::after {
  color: #0f172a;
}
.theme-teal .dark .mat-pseudo-checkbox-disabled,
.theme-teal.dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.theme-teal .dark .mat-primary .mat-pseudo-checkbox-checked,
.theme-teal .dark .mat-primary .mat-pseudo-checkbox-indeterminate,
.theme-teal.dark .mat-primary .mat-pseudo-checkbox-checked,
.theme-teal.dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #0d9488;
}
.theme-teal .dark .mat-pseudo-checkbox-checked,
.theme-teal .dark .mat-pseudo-checkbox-indeterminate,
.theme-teal .dark .mat-accent .mat-pseudo-checkbox-checked,
.theme-teal .dark .mat-accent .mat-pseudo-checkbox-indeterminate,
.theme-teal.dark .mat-pseudo-checkbox-checked,
.theme-teal.dark .mat-pseudo-checkbox-indeterminate,
.theme-teal.dark .mat-accent .mat-pseudo-checkbox-checked,
.theme-teal.dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #1e293b;
}
.theme-teal .dark .mat-warn .mat-pseudo-checkbox-checked,
.theme-teal .dark .mat-warn .mat-pseudo-checkbox-indeterminate,
.theme-teal.dark .mat-warn .mat-pseudo-checkbox-checked,
.theme-teal.dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #dc2626;
}
.theme-teal .dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-teal .dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled,
.theme-teal.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-teal.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.theme-teal .dark .mat-app-background, .theme-teal .dark.mat-app-background,
.theme-teal.dark .mat-app-background,
.theme-teal.dark.mat-app-background {
  background-color: #0f172a;
  color: white;
}
.theme-teal .dark .mat-elevation-z0, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-teal.dark .mat-elevation-z0,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z1, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-teal.dark .mat-elevation-z1,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z2, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-teal.dark .mat-elevation-z2,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z3, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-teal.dark .mat-elevation-z3,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z4, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-teal.dark .mat-elevation-z4,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z5, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-teal.dark .mat-elevation-z5,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z6, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-teal.dark .mat-elevation-z6,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z7, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-teal.dark .mat-elevation-z7,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z8, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-teal.dark .mat-elevation-z8,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z9, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-teal.dark .mat-elevation-z9,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z10, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-teal.dark .mat-elevation-z10,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z11, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-teal.dark .mat-elevation-z11,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z12, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-teal.dark .mat-elevation-z12,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z13, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-teal.dark .mat-elevation-z13,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z14, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-teal.dark .mat-elevation-z14,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z15, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-teal.dark .mat-elevation-z15,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z16, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-teal.dark .mat-elevation-z16,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z17, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-teal.dark .mat-elevation-z17,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z18, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-teal.dark .mat-elevation-z18,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z19, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-teal.dark .mat-elevation-z19,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z20, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-teal.dark .mat-elevation-z20,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z21, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-teal.dark .mat-elevation-z21,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z22, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-teal.dark .mat-elevation-z22,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z23, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-teal.dark .mat-elevation-z23,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z24, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-teal.dark .mat-elevation-z24,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-teal .dark .mat-mdc-card,
.theme-teal.dark .mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #1e293b;
}
.theme-teal .dark .mat-mdc-card-outlined,
.theme-teal.dark .mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #394353;
}
.theme-teal .dark .mat-mdc-card-subtitle,
.theme-teal.dark .mat-mdc-card-subtitle {
  color: #94a3b8;
}
.theme-teal .dark .mat-mdc-progress-bar,
.theme-teal.dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #0d9488;
}
.theme-teal .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.theme-teal.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(13, 148, 136, 0.25)'/%3E%3C/svg%3E");
}
.theme-teal .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.theme-teal.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(13, 148, 136, 0.25);
}
.theme-teal .dark .mat-mdc-progress-bar.mat-accent,
.theme-teal.dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
}
.theme-teal .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.theme-teal.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(30, 41, 59, 0.25)'/%3E%3C/svg%3E");
}
.theme-teal .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.theme-teal.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(30, 41, 59, 0.25);
}
.theme-teal .dark .mat-mdc-progress-bar.mat-warn,
.theme-teal.dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
}
.theme-teal .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.theme-teal.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(220, 38, 38, 0.25)'/%3E%3C/svg%3E");
}
.theme-teal .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.theme-teal.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(220, 38, 38, 0.25);
}
.theme-teal .dark .mat-mdc-tooltip,
.theme-teal.dark .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.theme-teal .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-teal.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 255, 255, 0.6);
}
.theme-teal .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input,
.theme-teal.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.87);
}
@media all {
  .theme-teal .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder,
  .theme-teal.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}
@media all {
  .theme-teal .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder,
  .theme-teal.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}
.theme-teal .dark .mdc-text-field .mdc-text-field__input,
.theme-teal.dark .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #0d9488);
}
.theme-teal .dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text,
.theme-teal.dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.theme-teal .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.theme-teal .dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter,
.theme-teal.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.theme-teal.dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.theme-teal .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading,
.theme-teal.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.theme-teal .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-teal.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.theme-teal .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix,
.theme-teal.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-teal .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix,
.theme-teal.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-teal .dark .mdc-text-field--filled .mdc-text-field__ripple::before,
.theme-teal .dark .mdc-text-field--filled .mdc-text-field__ripple::after,
.theme-teal.dark .mdc-text-field--filled .mdc-text-field__ripple::before,
.theme-teal.dark .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(255, 255, 255, 0.87));
}
.theme-teal .dark .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .theme-teal .dark .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before,
.theme-teal.dark .mdc-text-field--filled:hover .mdc-text-field__ripple::before,
.theme-teal.dark .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.theme-teal .dark .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .theme-teal .dark .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before,
.theme-teal.dark .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before,
.theme-teal.dark .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.theme-teal .dark .mdc-text-field--filled:not(.mdc-text-field--disabled),
.theme-teal.dark .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #273243;
}
.theme-teal .dark .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-teal.dark .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.42);
}
.theme-teal .dark .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-teal.dark .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.87);
}
.theme-teal .dark .mdc-text-field--filled .mdc-line-ripple::after,
.theme-teal.dark .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #0d9488);
}
.theme-teal .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-teal .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-teal .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-teal.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-teal.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-teal.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.38);
}
.theme-teal .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-teal .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-teal .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-teal.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-teal.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-teal.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.87);
}
.theme-teal .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-teal .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-teal .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-teal.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-teal.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-teal.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #0d9488);
}
[dir=rtl] .theme-teal .dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon, [dir=rtl] .theme-teal.dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .theme-teal .dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl], .theme-teal.dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-teal .dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, [dir=rtl] .theme-teal.dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .theme-teal .dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl], .theme-teal.dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-teal .dark .mdc-text-field--outlined .mdc-text-field__ripple::before,
.theme-teal .dark .mdc-text-field--outlined .mdc-text-field__ripple::after,
.theme-teal.dark .mdc-text-field--outlined .mdc-text-field__ripple::before,
.theme-teal.dark .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .theme-teal .dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined, [dir=rtl] .theme-teal.dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .theme-teal .dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl], .theme-teal.dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-teal .dark .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-teal.dark .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(13, 148, 136, 0.87);
}
.theme-teal .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-teal.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-teal.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-teal.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-teal.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mdc-text-field--invalid .mdc-text-field__input,
.theme-teal.dark .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-teal.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-teal.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-teal .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-teal .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-teal.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-teal.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-teal.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-teal .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-teal .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-teal.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-teal.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-teal.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-teal .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-teal .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-teal.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-teal.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-teal.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mdc-text-field--disabled .mdc-text-field__input,
.theme-teal.dark .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.38);
}
@media all {
  .theme-teal .dark .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  .theme-teal.dark .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.38);
  }
}
@media all {
  .theme-teal .dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  .theme-teal.dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.38);
  }
}
.theme-teal .dark .mdc-text-field--disabled .mdc-floating-label,
.theme-teal.dark .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(255, 255, 255, 0.38);
}
.theme-teal .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
.theme-teal.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.theme-teal .dark .mdc-text-field--disabled .mdc-text-field-character-counter,
.theme-teal .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
.theme-teal.dark .mdc-text-field--disabled .mdc-text-field-character-counter,
.theme-teal.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.theme-teal .dark .mdc-text-field--disabled .mdc-text-field__icon--leading,
.theme-teal.dark .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.theme-teal .dark .mdc-text-field--disabled .mdc-text-field__icon--trailing,
.theme-teal.dark .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.theme-teal .dark .mdc-text-field--disabled .mdc-text-field__affix--prefix,
.theme-teal.dark .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-teal .dark .mdc-text-field--disabled .mdc-text-field__affix--suffix,
.theme-teal.dark .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-teal .dark .mdc-text-field--disabled .mdc-line-ripple::before,
.theme-teal.dark .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.06);
}
.theme-teal .dark .mdc-text-field--disabled .mdc-notched-outline__leading,
.theme-teal .dark .mdc-text-field--disabled .mdc-notched-outline__notch,
.theme-teal .dark .mdc-text-field--disabled .mdc-notched-outline__trailing,
.theme-teal.dark .mdc-text-field--disabled .mdc-notched-outline__leading,
.theme-teal.dark .mdc-text-field--disabled .mdc-notched-outline__notch,
.theme-teal.dark .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-teal .dark .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  .theme-teal.dark .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-teal .dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  .theme-teal.dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-teal .dark .mdc-text-field--disabled .mdc-floating-label,
  .theme-teal.dark .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-teal .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
  .theme-teal.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-teal .dark .mdc-text-field--disabled .mdc-text-field-character-counter,
  .theme-teal .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
  .theme-teal.dark .mdc-text-field--disabled .mdc-text-field-character-counter,
  .theme-teal.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-teal .dark .mdc-text-field--disabled .mdc-text-field__icon--leading,
  .theme-teal.dark .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-teal .dark .mdc-text-field--disabled .mdc-text-field__icon--trailing,
  .theme-teal.dark .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-teal .dark .mdc-text-field--disabled .mdc-text-field__affix--prefix,
  .theme-teal.dark .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-teal .dark .mdc-text-field--disabled .mdc-text-field__affix--suffix,
  .theme-teal.dark .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-teal .dark .mdc-text-field--disabled .mdc-line-ripple::before,
  .theme-teal.dark .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-teal .dark .mdc-text-field--disabled .mdc-notched-outline__leading,
  .theme-teal .dark .mdc-text-field--disabled .mdc-notched-outline__notch,
  .theme-teal .dark .mdc-text-field--disabled .mdc-notched-outline__trailing,
  .theme-teal.dark .mdc-text-field--disabled .mdc-notched-outline__leading,
  .theme-teal.dark .mdc-text-field--disabled .mdc-notched-outline__notch,
  .theme-teal.dark .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.theme-teal .dark .mdc-text-field--disabled.mdc-text-field--filled,
.theme-teal.dark .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #232d3f;
}
[dir=rtl] .theme-teal .dark .mdc-text-field--end-aligned .mdc-text-field__input, [dir=rtl] .theme-teal.dark .mdc-text-field--end-aligned .mdc-text-field__input, .theme-teal .dark .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl], .theme-teal.dark .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-teal .dark .mdc-floating-label--required, [dir=rtl] .theme-teal.dark .mdc-floating-label--required, .theme-teal .dark .mdc-floating-label--required[dir=rtl], .theme-teal.dark .mdc-floating-label--required[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-teal .dark .mat-mdc-form-field-error,
.theme-teal.dark .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mat-mdc-form-field-focus-overlay,
.theme-teal.dark .mat-mdc-form-field-focus-overlay {
  background-color: rgba(255, 255, 255, 0.87);
}
.theme-teal .dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.theme-teal.dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.08;
}
.theme-teal .dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.theme-teal.dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.24;
}
.theme-teal .dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option,
.theme-teal.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.theme-teal .dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled,
.theme-teal.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.theme-teal .dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.theme-teal.dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.54);
}
.theme-teal .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.theme-teal.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(13, 148, 136, 0.87);
}
.theme-teal .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.theme-teal.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(30, 41, 59, 0.87);
}
.theme-teal .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.theme-teal.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(220, 38, 38, 0.87);
}
.theme-teal .dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.theme-teal.dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.38);
}
.theme-teal .dark .mat-mdc-form-field.mat-accent .mdc-text-field__input,
.theme-teal.dark .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-teal .dark .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-teal.dark .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-teal .dark .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-teal.dark .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(30, 41, 59, 0.87);
}
.theme-teal .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-teal.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-teal.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-teal.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-teal.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input,
.theme-teal.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-teal.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-teal.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-teal .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-teal .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-teal.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-teal.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-teal.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-teal .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-teal .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-teal.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-teal.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-teal.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-teal .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-teal .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-teal.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-teal.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-teal.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-teal .dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-teal .dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-teal.dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-teal.dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-teal.dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-teal .dark .mat-mdc-form-field.mat-warn .mdc-text-field__input,
.theme-teal.dark .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-teal.dark .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-teal.dark .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(220, 38, 38, 0.87);
}
.theme-teal .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-teal.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-teal.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-teal.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-teal.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input,
.theme-teal.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-teal.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-teal.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-teal .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-teal .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-teal.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-teal.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-teal.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-teal .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-teal .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-teal.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-teal.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-teal.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-teal .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-teal .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-teal.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-teal.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-teal.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-teal .dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-teal .dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-teal.dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-teal.dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-teal.dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-teal .dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.theme-teal.dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.theme-teal .dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.theme-teal.dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.theme-teal .dark .mdc-menu-surface,
.theme-teal.dark .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #1e293b);
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mdc-list-item__primary-text,
.theme-teal.dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-teal .dark .mdc-list-item__secondary-text,
.theme-teal.dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.theme-teal .dark .mdc-list-item__overline-text,
.theme-teal.dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-teal .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-teal.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-teal .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-teal.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.theme-teal .dark .mdc-list-item__end,
.theme-teal.dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-teal .dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-teal .dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-teal .dark .mdc-list-item--disabled .mdc-list-item__end,
.theme-teal.dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-teal.dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-teal.dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-teal .dark .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-teal.dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-teal.dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-teal.dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-teal.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-teal.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-teal .dark .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-teal.dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-teal.dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #0d9488);
}
.theme-teal .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #0d9488);
}
.theme-teal .dark .mdc-deprecated-list-group__subheader,
.theme-teal.dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-teal .dark .mdc-list-divider::after,
.theme-teal.dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-teal .dark .mdc-list-divider,
.theme-teal.dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-teal .dark .mat-mdc-select-value,
.theme-teal.dark .mat-mdc-select-value {
  color: rgba(255, 255, 255, 0.87);
}
.theme-teal .dark .mat-mdc-select-placeholder,
.theme-teal.dark .mat-mdc-select-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.theme-teal .dark .mat-mdc-select-disabled .mat-mdc-select-value,
.theme-teal.dark .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(255, 255, 255, 0.38);
}
.theme-teal .dark .mat-mdc-select-arrow,
.theme-teal.dark .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.54);
}
.theme-teal .dark .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow,
.theme-teal.dark .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(13, 148, 136, 0.87);
}
.theme-teal .dark .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow,
.theme-teal.dark .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(30, 41, 59, 0.87);
}
.theme-teal .dark .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow,
.theme-teal.dark .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(220, 38, 38, 0.87);
}
.theme-teal .dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow,
.theme-teal.dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(220, 38, 38, 0.87);
}
.theme-teal .dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow,
.theme-teal.dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.38);
}
.theme-teal .dark .mdc-menu-surface,
.theme-teal.dark .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #1e293b);
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mdc-list-item__primary-text,
.theme-teal.dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-teal .dark .mdc-list-item__secondary-text,
.theme-teal.dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.theme-teal .dark .mdc-list-item__overline-text,
.theme-teal.dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-teal .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-teal.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-teal .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-teal.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.theme-teal .dark .mdc-list-item__end,
.theme-teal.dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-teal .dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-teal .dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-teal .dark .mdc-list-item--disabled .mdc-list-item__end,
.theme-teal.dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-teal.dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-teal.dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-teal .dark .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-teal.dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-teal.dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-teal.dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-teal.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-teal.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-teal .dark .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-teal.dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-teal.dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #0d9488);
}
.theme-teal .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #0d9488);
}
.theme-teal .dark .mdc-deprecated-list-group__subheader,
.theme-teal.dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-teal .dark .mdc-list-divider::after,
.theme-teal.dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-teal .dark .mdc-list-divider,
.theme-teal.dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-teal .dark .mat-mdc-dialog-container,
.theme-teal.dark .mat-mdc-dialog-container {
  --mdc-dialog-container-color: #1e293b;
  --mdc-dialog-with-divider-divider-color: rgba(255, 255, 255, 0.12);
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
}
.theme-teal .dark .mat-mdc-standard-chip,
.theme-teal.dark .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #394353;
  --mdc-chip-elevated-disabled-container-color: #394353;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
}
.theme-teal .dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .theme-teal .dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted,
.theme-teal.dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected,
.theme-teal.dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #0d9488;
  --mdc-chip-elevated-disabled-container-color: #0d9488;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-teal .dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .theme-teal .dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted,
.theme-teal.dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected,
.theme-teal.dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-teal .dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .theme-teal .dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted,
.theme-teal.dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected,
.theme-teal.dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-teal .dark .mat-mdc-chip-focus-overlay,
.theme-teal.dark .mat-mdc-chip-focus-overlay {
  background: #FFFFFF;
}
.theme-teal .dark .mat-mdc-slide-toggle,
.theme-teal.dark .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-unselected-icon-color: #212121;
}
.theme-teal .dark .mat-mdc-slide-toggle .mdc-form-field,
.theme-teal.dark .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-teal .dark .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.theme-teal.dark .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: #475569;
}
.theme-teal .dark .mat-mdc-slide-toggle.mat-primary,
.theme-teal.dark .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #5eead4;
  --mdc-switch-selected-handle-color: #5eead4;
  --mdc-switch-selected-hover-state-layer-color: #5eead4;
  --mdc-switch-selected-pressed-state-layer-color: #5eead4;
  --mdc-switch-selected-focus-handle-color: #99f6e4;
  --mdc-switch-selected-hover-handle-color: #99f6e4;
  --mdc-switch-selected-pressed-handle-color: #99f6e4;
  --mdc-switch-selected-focus-track-color: #0d9488;
  --mdc-switch-selected-hover-track-color: #0d9488;
  --mdc-switch-selected-pressed-track-color: #0d9488;
  --mdc-switch-selected-track-color: #0d9488;
}
.theme-teal .dark .mat-mdc-slide-toggle.mat-accent,
.theme-teal.dark .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #cbd5e1;
  --mdc-switch-selected-handle-color: #cbd5e1;
  --mdc-switch-selected-hover-state-layer-color: #cbd5e1;
  --mdc-switch-selected-pressed-state-layer-color: #cbd5e1;
  --mdc-switch-selected-focus-handle-color: #e2e8f0;
  --mdc-switch-selected-hover-handle-color: #e2e8f0;
  --mdc-switch-selected-pressed-handle-color: #e2e8f0;
  --mdc-switch-selected-focus-track-color: #475569;
  --mdc-switch-selected-hover-track-color: #475569;
  --mdc-switch-selected-pressed-track-color: #475569;
  --mdc-switch-selected-track-color: #475569;
}
.theme-teal .dark .mat-mdc-slide-toggle.mat-warn,
.theme-teal.dark .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #fca5a5;
  --mdc-switch-selected-handle-color: #fca5a5;
  --mdc-switch-selected-hover-state-layer-color: #fca5a5;
  --mdc-switch-selected-pressed-state-layer-color: #fca5a5;
  --mdc-switch-selected-focus-handle-color: #fecaca;
  --mdc-switch-selected-hover-handle-color: #fecaca;
  --mdc-switch-selected-pressed-handle-color: #fecaca;
  --mdc-switch-selected-focus-track-color: #dc2626;
  --mdc-switch-selected-hover-track-color: #dc2626;
  --mdc-switch-selected-pressed-track-color: #dc2626;
  --mdc-switch-selected-track-color: #dc2626;
}
.theme-teal .dark .mat-mdc-radio-button,
.theme-teal.dark .mat-mdc-radio-button {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mat-mdc-radio-ripple-color: #fff;
}
.theme-teal .dark .mat-mdc-radio-button .mdc-form-field,
.theme-teal.dark .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-teal .dark .mat-mdc-radio-button .mdc-radio--disabled + label,
.theme-teal.dark .mat-mdc-radio-button .mdc-radio--disabled + label {
  color: #475569;
}
.theme-teal .dark .mat-mdc-radio-button.mat-primary,
.theme-teal.dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-selected-focus-icon-color: #0d9488;
  --mdc-radio-selected-hover-icon-color: #0d9488;
  --mdc-radio-selected-icon-color: #0d9488;
  --mdc-radio-selected-pressed-icon-color: #0d9488;
  --mat-mdc-radio-checked-ripple-color: #0d9488;
}
.theme-teal .dark .mat-mdc-radio-button.mat-accent,
.theme-teal.dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-mdc-radio-checked-ripple-color: #1e293b;
}
.theme-teal .dark .mat-mdc-radio-button.mat-warn,
.theme-teal.dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-mdc-radio-checked-ripple-color: #dc2626;
}
.theme-teal .dark .mat-mdc-slider,
.theme-teal.dark .mat-mdc-slider {
  --mdc-slider-label-container-color: white;
  --mdc-slider-label-label-text-color: black;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mat-mdc-slider-value-indicator-opacity: 0.9;
}
.theme-teal .dark .mat-mdc-slider.mat-primary,
.theme-teal.dark .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #0d9488;
  --mdc-slider-focus-handle-color: #0d9488;
  --mdc-slider-hover-handle-color: #0d9488;
  --mdc-slider-active-track-color: #0d9488;
  --mdc-slider-inactive-track-color: #0d9488;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #0d9488;
  --mat-mdc-slider-ripple-color: #0d9488;
  --mat-mdc-slider-hover-ripple-color: rgba(13, 148, 136, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(13, 148, 136, 0.2);
}
.theme-teal .dark .mat-mdc-slider.mat-accent,
.theme-teal.dark .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mat-mdc-slider-ripple-color: #1e293b;
  --mat-mdc-slider-hover-ripple-color: rgba(30, 41, 59, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(30, 41, 59, 0.2);
}
.theme-teal .dark .mat-mdc-slider.mat-warn,
.theme-teal.dark .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mat-mdc-slider-ripple-color: #dc2626;
  --mat-mdc-slider-hover-ripple-color: rgba(220, 38, 38, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(220, 38, 38, 0.2);
}
.theme-teal .dark .mdc-menu-surface,
.theme-teal.dark .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #1e293b);
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mdc-list-item__primary-text,
.theme-teal.dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-teal .dark .mdc-list-item__secondary-text,
.theme-teal.dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.theme-teal .dark .mdc-list-item__overline-text,
.theme-teal.dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-teal .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-teal.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-teal .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-teal.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.theme-teal .dark .mdc-list-item__end,
.theme-teal.dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-teal .dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-teal .dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-teal .dark .mdc-list-item--disabled .mdc-list-item__end,
.theme-teal.dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-teal.dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-teal.dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-teal .dark .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-teal.dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-teal.dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-teal.dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-teal.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-teal.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-teal .dark .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-teal.dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-teal.dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #0d9488);
}
.theme-teal .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #0d9488);
}
.theme-teal .dark .mdc-deprecated-list-group__subheader,
.theme-teal.dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-teal .dark .mdc-list-divider::after,
.theme-teal.dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-teal .dark .mdc-list-divider,
.theme-teal.dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-teal .dark .mat-mdc-menu-item[disabled],
.theme-teal .dark .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.theme-teal .dark .mat-mdc-menu-item[disabled] .mat-icon-no-color,
.theme-teal.dark .mat-mdc-menu-item[disabled],
.theme-teal.dark .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.theme-teal.dark .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(255, 255, 255, 0.5));
}
.theme-teal .dark .mat-mdc-menu-item .mat-icon-no-color,
.theme-teal .dark .mat-mdc-menu-submenu-icon,
.theme-teal.dark .mat-mdc-menu-item .mat-icon-no-color,
.theme-teal.dark .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-teal .dark .mat-mdc-menu-item:hover:not([disabled]),
.theme-teal .dark .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.theme-teal .dark .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-teal .dark .mat-mdc-menu-item-highlighted:not([disabled]),
.theme-teal.dark .mat-mdc-menu-item:hover:not([disabled]),
.theme-teal.dark .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.theme-teal.dark .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-teal.dark .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.08);
}
.theme-teal .dark .mat-mdc-list-item-interactive::before,
.theme-teal.dark .mat-mdc-list-item-interactive::before {
  background: white;
}
.theme-teal .dark .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before,
.theme-teal.dark .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.08;
}
.theme-teal .dark .mat-mdc-list-item-interactive.mdc-list-item--selected::before,
.theme-teal.dark .mat-mdc-list-item-interactive.mdc-list-item--selected::before {
  background: #0d9488;
  opacity: 0.08;
}
.theme-teal .dark .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before,
.theme-teal.dark .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.12;
}
.theme-teal .dark .mat-mdc-list-item-interactive:focus::before,
.theme-teal.dark .mat-mdc-list-item-interactive:focus::before {
  opacity: 0.24;
}
.theme-teal .dark .mdc-list-item__primary-text,
.theme-teal.dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-teal .dark .mdc-list-item__secondary-text,
.theme-teal.dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.theme-teal .dark .mdc-list-item__overline-text,
.theme-teal.dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-teal .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-teal.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-teal .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-teal.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.theme-teal .dark .mdc-list-item__end,
.theme-teal.dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-teal .dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-teal .dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-teal .dark .mdc-list-item--disabled .mdc-list-item__end,
.theme-teal.dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-teal.dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-teal.dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-teal .dark .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-teal.dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-teal.dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-teal.dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-teal.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-teal.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-teal .dark .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-teal.dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-teal.dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #0d9488);
}
.theme-teal .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #0d9488);
}
.theme-teal .dark .mdc-deprecated-list-group__subheader,
.theme-teal.dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-teal .dark .mdc-list-divider::after,
.theme-teal.dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-teal .dark .mdc-list-divider,
.theme-teal.dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-teal .dark .mat-mdc-list-option .mdc-list-item__start, .theme-teal .dark .mat-mdc-list-option .mdc-list-item__end,
.theme-teal.dark .mat-mdc-list-option .mdc-list-item__start,
.theme-teal.dark .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #0d9488;
  --mdc-checkbox-selected-hover-icon-color: #0d9488;
  --mdc-checkbox-selected-icon-color: #0d9488;
  --mdc-checkbox-selected-pressed-icon-color: #0d9488;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-teal .dark .mat-mdc-list-option.mat-accent .mdc-list-item__start, .theme-teal .dark .mat-mdc-list-option.mat-accent .mdc-list-item__end,
.theme-teal.dark .mat-mdc-list-option.mat-accent .mdc-list-item__start,
.theme-teal.dark .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-teal .dark .mat-mdc-list-option.mat-warn .mdc-list-item__start, .theme-teal .dark .mat-mdc-list-option.mat-warn .mdc-list-item__end,
.theme-teal.dark .mat-mdc-list-option.mat-warn .mdc-list-item__start,
.theme-teal.dark .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-teal .dark .mat-mdc-paginator,
.theme-teal.dark .mat-mdc-paginator {
  background: #1e293b;
  color: rgba(255, 255, 255, 0.87);
}
.theme-teal .dark .mat-mdc-paginator-icon,
.theme-teal.dark .mat-mdc-paginator-icon {
  fill: rgba(255, 255, 255, 0.54);
}
.theme-teal .dark .mat-mdc-paginator-decrement,
.theme-teal .dark .mat-mdc-paginator-increment,
.theme-teal.dark .mat-mdc-paginator-decrement,
.theme-teal.dark .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(255, 255, 255, 0.54);
  border-right: 2px solid rgba(255, 255, 255, 0.54);
}
.theme-teal .dark .mat-mdc-paginator-first,
.theme-teal .dark .mat-mdc-paginator-last,
.theme-teal.dark .mat-mdc-paginator-first,
.theme-teal.dark .mat-mdc-paginator-last {
  border-top: 2px solid rgba(255, 255, 255, 0.54);
}
.theme-teal .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.theme-teal .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.theme-teal .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.theme-teal .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last,
.theme-teal.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.theme-teal.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.theme-teal.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.theme-teal.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-teal .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon,
.theme-teal.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(255, 255, 255, 0.12);
}
.theme-teal .dark .mat-mdc-tab, .theme-teal .dark .mat-mdc-tab-link,
.theme-teal.dark .mat-mdc-tab,
.theme-teal.dark .mat-mdc-tab-link {
  background-color: transparent;
}
.theme-teal .dark .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label, .theme-teal .dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.theme-teal.dark .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.theme-teal.dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  color: rgba(255, 255, 255, 0.6);
}
.theme-teal .dark .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-teal .dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-teal.dark .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-teal.dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #0d9488;
}
.theme-teal .dark .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-teal .dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-teal.dark .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-teal.dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #0d9488;
}
.theme-teal .dark .mdc-tab__ripple::before,
.theme-teal .dark .mat-mdc-tab .mat-ripple-element,
.theme-teal .dark .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-teal .dark .mat-mdc-tab-link .mat-ripple-element,
.theme-teal.dark .mdc-tab__ripple::before,
.theme-teal.dark .mat-mdc-tab .mat-ripple-element,
.theme-teal.dark .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-teal.dark .mat-mdc-tab-link .mat-ripple-element {
  background-color: #0d9488;
}
.theme-teal .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-teal .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-teal .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-teal .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-teal.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-teal.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-teal.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-teal.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #1e293b;
}
.theme-teal .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-teal .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-teal .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-teal .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-teal.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-teal.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-teal.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-teal.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #1e293b;
}
.theme-teal .dark .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.theme-teal .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-teal .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-teal .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .theme-teal .dark .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.theme-teal .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-teal .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-teal .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element,
.theme-teal.dark .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.theme-teal.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-teal.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-teal.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element,
.theme-teal.dark .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.theme-teal.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-teal.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-teal.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #1e293b;
}
.theme-teal .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-teal .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-teal .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-teal .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-teal.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-teal.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-teal.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-teal.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #dc2626;
}
.theme-teal .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-teal .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-teal .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-teal .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-teal.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-teal.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-teal.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-teal.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #dc2626;
}
.theme-teal .dark .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.theme-teal .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-teal .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-teal .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .theme-teal .dark .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.theme-teal .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-teal .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-teal .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element,
.theme-teal.dark .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.theme-teal.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-teal.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-teal.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element,
.theme-teal.dark .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.theme-teal.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-teal.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-teal.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #dc2626;
}
.theme-teal .dark .mat-mdc-tab-group.mat-background-primary, .theme-teal .dark .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-teal.dark .mat-mdc-tab-group.mat-background-primary,
.theme-teal.dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #0d9488;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-teal .dark .mat-mdc-tab-group.mat-background-accent, .theme-teal .dark .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-teal.dark .mat-mdc-tab-group.mat-background-accent,
.theme-teal.dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #1e293b;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-teal .dark .mat-mdc-tab-group.mat-background-warn, .theme-teal .dark .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-teal.dark .mat-mdc-tab-group.mat-background-warn,
.theme-teal.dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #dc2626;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-teal .dark .mat-mdc-tab-header-pagination-chevron,
.theme-teal.dark .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #fff);
}
.theme-teal .dark .mat-mdc-checkbox .mdc-form-field,
.theme-teal.dark .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-teal .dark .mat-mdc-checkbox .mat-ripple-element,
.theme-teal.dark .mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .dark .mat-mdc-checkbox .mdc-checkbox__ripple,
.theme-teal.dark .mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #fff;
}
.theme-teal .dark .mat-mdc-checkbox.mat-primary,
.theme-teal.dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #0d9488;
  --mdc-checkbox-selected-hover-icon-color: #0d9488;
  --mdc-checkbox-selected-icon-color: #0d9488;
  --mdc-checkbox-selected-pressed-icon-color: #0d9488;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-teal .dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-teal.dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(13, 148, 136, 0.1);
}
.theme-teal .dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-teal.dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #0d9488;
}
.theme-teal .dark .mat-mdc-checkbox.mat-accent,
.theme-teal.dark .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-teal .dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-teal.dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(30, 41, 59, 0.1);
}
.theme-teal .dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-teal.dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #1e293b;
}
.theme-teal .dark .mat-mdc-checkbox.mat-warn,
.theme-teal.dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-teal .dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-teal.dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(220, 38, 38, 0.1);
}
.theme-teal .dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-teal.dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #dc2626;
}
.theme-teal .dark .mat-mdc-checkbox-disabled label,
.theme-teal.dark .mat-mdc-checkbox-disabled label {
  color: #475569;
}
.theme-teal .dark .mat-mdc-button.mat-unthemed,
.theme-teal.dark .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #fff;
}
.theme-teal .dark .mat-mdc-button.mat-primary,
.theme-teal.dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #0d9488;
}
.theme-teal .dark .mat-mdc-button.mat-accent,
.theme-teal.dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
}
.theme-teal .dark .mat-mdc-button.mat-warn,
.theme-teal.dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
}
.theme-teal .dark .mat-mdc-button[disabled][disabled],
.theme-teal.dark .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-text-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.theme-teal .dark .mat-mdc-unelevated-button.mat-unthemed,
.theme-teal.dark .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-teal .dark .mat-mdc-unelevated-button.mat-primary,
.theme-teal.dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #0d9488;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-teal .dark .mat-mdc-unelevated-button.mat-accent,
.theme-teal.dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-teal .dark .mat-mdc-unelevated-button.mat-warn,
.theme-teal.dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-teal .dark .mat-mdc-unelevated-button[disabled][disabled],
.theme-teal.dark .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-filled-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.theme-teal .dark .mat-mdc-raised-button.mat-unthemed,
.theme-teal.dark .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-teal .dark .mat-mdc-raised-button.mat-primary,
.theme-teal.dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #0d9488;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-teal .dark .mat-mdc-raised-button.mat-accent,
.theme-teal.dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-teal .dark .mat-mdc-raised-button.mat-warn,
.theme-teal.dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-teal .dark .mat-mdc-raised-button[disabled][disabled],
.theme-teal.dark .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-elevation: 0;
}
.theme-teal .dark .mat-mdc-outlined-button,
.theme-teal.dark .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.theme-teal .dark .mat-mdc-outlined-button.mat-unthemed,
.theme-teal.dark .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #fff;
}
.theme-teal .dark .mat-mdc-outlined-button.mat-primary,
.theme-teal.dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #0d9488;
}
.theme-teal .dark .mat-mdc-outlined-button.mat-accent,
.theme-teal.dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
}
.theme-teal .dark .mat-mdc-outlined-button.mat-warn,
.theme-teal.dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
}
.theme-teal .dark .mat-mdc-outlined-button[disabled][disabled],
.theme-teal.dark .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
}
.theme-teal .dark .mat-mdc-button, .theme-teal .dark .mat-mdc-outlined-button,
.theme-teal.dark .mat-mdc-button,
.theme-teal.dark .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .theme-teal .dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-teal .dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-teal .dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-teal .dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-teal .dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-teal .dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .theme-teal .dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-teal .dark .mat-mdc-button.mat-primary, .theme-teal .dark .mat-mdc-outlined-button.mat-primary,
.theme-teal.dark .mat-mdc-button.mat-primary,
.theme-teal.dark .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #0d9488;
  --mat-mdc-button-ripple-color: rgba(13, 148, 136, 0.1);
}
.theme-teal .dark .mat-mdc-button.mat-accent, .theme-teal .dark .mat-mdc-outlined-button.mat-accent,
.theme-teal.dark .mat-mdc-button.mat-accent,
.theme-teal.dark .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #1e293b;
  --mat-mdc-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-teal .dark .mat-mdc-button.mat-warn, .theme-teal .dark .mat-mdc-outlined-button.mat-warn,
.theme-teal.dark .mat-mdc-button.mat-warn,
.theme-teal.dark .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #dc2626;
  --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-teal .dark .mat-mdc-raised-button, .theme-teal .dark .mat-mdc-unelevated-button,
.theme-teal.dark .mat-mdc-raised-button,
.theme-teal.dark .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .theme-teal .dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-teal .dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-teal .dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-teal .dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-teal .dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-teal .dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .theme-teal .dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-teal .dark .mat-mdc-raised-button.mat-primary, .theme-teal .dark .mat-mdc-unelevated-button.mat-primary,
.theme-teal.dark .mat-mdc-raised-button.mat-primary,
.theme-teal.dark .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .dark .mat-mdc-raised-button.mat-accent, .theme-teal .dark .mat-mdc-unelevated-button.mat-accent,
.theme-teal.dark .mat-mdc-raised-button.mat-accent,
.theme-teal.dark .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .dark .mat-mdc-raised-button.mat-warn, .theme-teal .dark .mat-mdc-unelevated-button.mat-warn,
.theme-teal.dark .mat-mdc-raised-button.mat-warn,
.theme-teal.dark .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .dark .mat-mdc-icon-button,
.theme-teal.dark .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-teal .dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-teal .dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-teal .dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-teal .dark .mat-mdc-icon-button.mat-primary,
.theme-teal.dark .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #0d9488;
  --mat-mdc-button-ripple-color: rgba(13, 148, 136, 0.1);
}
.theme-teal .dark .mat-mdc-icon-button.mat-accent,
.theme-teal.dark .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #1e293b;
  --mat-mdc-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-teal .dark .mat-mdc-icon-button.mat-warn,
.theme-teal.dark .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #dc2626;
  --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-teal .dark .mat-mdc-icon-button.mat-primary,
.theme-teal.dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #0d9488;
}
.theme-teal .dark .mat-mdc-icon-button.mat-accent,
.theme-teal.dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
}
.theme-teal .dark .mat-mdc-icon-button.mat-warn,
.theme-teal.dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
}
.theme-teal .dark .mat-mdc-icon-button[disabled][disabled],
.theme-teal.dark .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
}
.theme-teal .dark .mat-mdc-fab, .theme-teal .dark .mat-mdc-mini-fab,
.theme-teal.dark .mat-mdc-fab,
.theme-teal.dark .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .theme-teal .dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-teal .dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-teal .dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-teal .dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-teal .dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-teal .dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .theme-teal .dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.theme-teal.dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-teal .dark .mat-mdc-fab.mat-primary, .theme-teal .dark .mat-mdc-mini-fab.mat-primary,
.theme-teal.dark .mat-mdc-fab.mat-primary,
.theme-teal.dark .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .dark .mat-mdc-fab.mat-accent, .theme-teal .dark .mat-mdc-mini-fab.mat-accent,
.theme-teal.dark .mat-mdc-fab.mat-accent,
.theme-teal.dark .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .dark .mat-mdc-fab.mat-warn, .theme-teal .dark .mat-mdc-mini-fab.mat-warn,
.theme-teal.dark .mat-mdc-fab.mat-warn,
.theme-teal.dark .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .dark .mat-mdc-fab.mat-unthemed, .theme-teal .dark .mat-mdc-mini-fab.mat-unthemed,
.theme-teal.dark .mat-mdc-fab.mat-unthemed,
.theme-teal.dark .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-teal .dark .mat-mdc-fab.mat-primary, .theme-teal .dark .mat-mdc-mini-fab.mat-primary,
.theme-teal.dark .mat-mdc-fab.mat-primary,
.theme-teal.dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #0d9488;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-teal .dark .mat-mdc-fab.mat-accent, .theme-teal .dark .mat-mdc-mini-fab.mat-accent,
.theme-teal.dark .mat-mdc-fab.mat-accent,
.theme-teal.dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-teal .dark .mat-mdc-fab.mat-warn, .theme-teal .dark .mat-mdc-mini-fab.mat-warn,
.theme-teal.dark .mat-mdc-fab.mat-warn,
.theme-teal.dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-teal .dark .mat-mdc-fab[disabled][disabled], .theme-teal .dark .mat-mdc-mini-fab[disabled][disabled],
.theme-teal.dark .mat-mdc-fab[disabled][disabled],
.theme-teal.dark .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(255, 255, 255, 0.12);
  --mdc-fab-icon-color: rgba(255, 255, 255, 0.5);
  --mat-mdc-fab-color: rgba(255, 255, 255, 0.5);
}
.theme-teal .dark .mat-mdc-snack-bar-container,
.theme-teal.dark .mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: currentColor;
  --mdc-snackbar-container-color: #d2d4d8;
  --mdc-snackbar-supporting-text-color: rgba(30, 41, 59, 0.87);
}
.theme-teal .dark .mdc-data-table,
.theme-teal.dark .mdc-data-table {
  background-color: var(--mdc-theme-surface, #1e293b);
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-teal .dark .mdc-data-table__row,
.theme-teal.dark .mdc-data-table__row {
  background-color: inherit;
}
.theme-teal .dark .mdc-data-table__header-cell,
.theme-teal.dark .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #1e293b);
}
.theme-teal .dark .mdc-data-table__row--selected,
.theme-teal.dark .mdc-data-table__row--selected {
  background-color: rgba(13, 148, 136, 0.04);
}
.theme-teal .dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.theme-teal .dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.theme-teal .dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing,
.theme-teal.dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.theme-teal.dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.theme-teal.dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-teal .dark .mdc-data-table__cell,
.theme-teal .dark .mdc-data-table__header-cell,
.theme-teal.dark .mdc-data-table__cell,
.theme-teal.dark .mdc-data-table__header-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.theme-teal .dark .mdc-data-table__pagination,
.theme-teal.dark .mdc-data-table__pagination {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-teal .dark .mdc-data-table__row:not(.mdc-data-table__row--selected):hover,
.theme-teal.dark .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(255, 255, 255, 0.04);
}
.theme-teal .dark .mdc-data-table__header-cell,
.theme-teal.dark .mdc-data-table__header-cell {
  color: rgba(255, 255, 255, 0.87);
}
.theme-teal .dark .mdc-data-table__pagination-total,
.theme-teal .dark .mdc-data-table__pagination-rows-per-page-label,
.theme-teal .dark .mdc-data-table__cell,
.theme-teal.dark .mdc-data-table__pagination-total,
.theme-teal.dark .mdc-data-table__pagination-rows-per-page-label,
.theme-teal.dark .mdc-data-table__cell {
  color: rgba(255, 255, 255, 0.87);
}
[dir=rtl] .theme-teal .dark .mdc-data-table__pagination-button .mdc-button__icon, [dir=rtl] .theme-teal.dark .mdc-data-table__pagination-button .mdc-button__icon, .theme-teal .dark .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl], .theme-teal.dark .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-teal .dark .mat-mdc-table,
.theme-teal.dark .mat-mdc-table {
  background: #1e293b;
}
.theme-teal .dark .mat-mdc-progress-spinner,
.theme-teal.dark .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #0d9488;
}
.theme-teal .dark .mat-mdc-progress-spinner.mat-accent,
.theme-teal.dark .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-teal .dark .mat-mdc-progress-spinner.mat-warn,
.theme-teal.dark .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-teal .dark .mat-badge-content,
.theme-teal.dark .mat-badge-content {
  color: white;
  background: #0d9488;
}
.cdk-high-contrast-active .theme-teal .dark .mat-badge-content, .cdk-high-contrast-active .theme-teal.dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.theme-teal .dark .mat-badge-accent .mat-badge-content,
.theme-teal.dark .mat-badge-accent .mat-badge-content {
  background: #1e293b;
  color: white;
}
.theme-teal .dark .mat-badge-warn .mat-badge-content,
.theme-teal.dark .mat-badge-warn .mat-badge-content {
  color: white;
  background: #dc2626;
}
.theme-teal .dark .mat-badge-disabled .mat-badge-content,
.theme-teal.dark .mat-badge-disabled .mat-badge-content {
  background: #1e293b;
  color: #475569;
}
.theme-teal .dark .mat-bottom-sheet-container,
.theme-teal.dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #1e293b;
  color: white;
}
.theme-teal .dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-teal .dark .mat-button-toggle-group:not([class*=mat-elevation-z]),
.theme-teal.dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-teal.dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-teal .dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]),
.theme-teal.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-teal.dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.theme-teal .dark .mat-button-toggle,
.theme-teal.dark .mat-button-toggle {
  color: #64748b;
}
.theme-teal .dark .mat-button-toggle .mat-button-toggle-focus-overlay,
.theme-teal.dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: #e2e8f0;
}
.theme-teal .dark .mat-button-toggle-appearance-standard,
.theme-teal.dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #1e293b;
}
.theme-teal .dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay,
.theme-teal.dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: #e2e8f0;
}
.theme-teal .dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
.theme-teal.dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #374152;
}
.theme-teal .dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
.theme-teal.dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #374152;
}
.theme-teal .dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle,
.theme-teal.dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #374152;
}
.theme-teal .dark .mat-button-toggle-checked,
.theme-teal.dark .mat-button-toggle-checked {
  background-color: rgba(255, 255, 255, 0.05);
  color: #94a3b8;
}
.theme-teal .dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard,
.theme-teal.dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.theme-teal .dark .mat-button-toggle-disabled,
.theme-teal.dark .mat-button-toggle-disabled {
  color: #1e293b;
  background-color: #0f172a;
}
.theme-teal .dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard,
.theme-teal.dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #1e293b;
}
.theme-teal .dark .mat-button-toggle-disabled.mat-button-toggle-checked,
.theme-teal.dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #1e293b;
}
.theme-teal .dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-teal .dark .mat-button-toggle-group-appearance-standard,
.theme-teal.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-teal.dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #374152;
}
.theme-teal .dark .mat-calendar-arrow,
.theme-teal.dark .mat-calendar-arrow {
  fill: #f1f5f9;
}
.theme-teal .dark .mat-datepicker-toggle,
.theme-teal .dark .mat-datepicker-content .mat-calendar-next-button,
.theme-teal .dark .mat-datepicker-content .mat-calendar-previous-button,
.theme-teal.dark .mat-datepicker-toggle,
.theme-teal.dark .mat-datepicker-content .mat-calendar-next-button,
.theme-teal.dark .mat-datepicker-content .mat-calendar-previous-button {
  color: #f1f5f9;
}
.theme-teal .dark .mat-calendar-table-header-divider::after,
.theme-teal.dark .mat-calendar-table-header-divider::after {
  background: rgba(241, 245, 249, 0.12);
}
.theme-teal .dark .mat-calendar-table-header,
.theme-teal .dark .mat-calendar-body-label,
.theme-teal.dark .mat-calendar-table-header,
.theme-teal.dark .mat-calendar-body-label {
  color: #94a3b8;
}
.theme-teal .dark .mat-calendar-body-cell-content,
.theme-teal .dark .mat-date-range-input-separator,
.theme-teal.dark .mat-calendar-body-cell-content,
.theme-teal.dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.theme-teal .dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-teal.dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: #475569;
}
.theme-teal .dark .mat-form-field-disabled .mat-date-range-input-separator,
.theme-teal.dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: #475569;
}
.theme-teal .dark .mat-calendar-body-in-preview,
.theme-teal.dark .mat-calendar-body-in-preview {
  color: rgba(241, 245, 249, 0.24);
}
.theme-teal .dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-teal.dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #64748b;
}
.theme-teal .dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-teal.dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(100, 116, 139, 0.8);
}
.theme-teal .dark .mat-calendar-body-in-range::before,
.theme-teal.dark .mat-calendar-body-in-range::before {
  background: rgba(13, 148, 136, 0.2);
}
.theme-teal .dark .mat-calendar-body-comparison-identical,
.theme-teal .dark .mat-calendar-body-in-comparison-range::before,
.theme-teal.dark .mat-calendar-body-comparison-identical,
.theme-teal.dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-teal .dark .mat-calendar-body-comparison-bridge-start::before,
.theme-teal .dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-teal.dark .mat-calendar-body-comparison-bridge-start::before,
.theme-teal.dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(13, 148, 136, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-teal .dark .mat-calendar-body-comparison-bridge-end::before,
.theme-teal .dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-teal.dark .mat-calendar-body-comparison-bridge-end::before,
.theme-teal.dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(13, 148, 136, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-teal .dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-teal .dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-teal.dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-teal.dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-teal .dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-teal .dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-teal.dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-teal.dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-teal .dark .mat-calendar-body-selected,
.theme-teal.dark .mat-calendar-body-selected {
  background-color: #0d9488;
  color: white;
}
.theme-teal .dark .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-teal.dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(13, 148, 136, 0.4);
}
.theme-teal .dark .mat-calendar-body-today.mat-calendar-body-selected,
.theme-teal.dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-teal .dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-teal .dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-teal.dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-teal.dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(13, 148, 136, 0.3);
}
@media (hover: hover) {
  .theme-teal .dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-teal.dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(13, 148, 136, 0.3);
  }
}
.theme-teal .dark .mat-datepicker-content,
.theme-teal.dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #1e293b;
  color: white;
}
.theme-teal .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before,
.theme-teal.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(30, 41, 59, 0.2);
}
.theme-teal .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-teal .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before,
.theme-teal.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-teal.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-teal .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-teal .dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-teal.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-teal.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(30, 41, 59, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-teal .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-teal .dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-teal.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-teal.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(30, 41, 59, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-teal .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-teal .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-teal.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-teal.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-teal .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-teal .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-teal.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-teal.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-teal .dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected,
.theme-teal.dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #1e293b;
  color: white;
}
.theme-teal .dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-teal.dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(30, 41, 59, 0.4);
}
.theme-teal .dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected,
.theme-teal.dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-teal .dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-teal .dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-teal.dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-teal.dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(30, 41, 59, 0.3);
}
@media (hover: hover) {
  .theme-teal .dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-teal.dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(30, 41, 59, 0.3);
  }
}
.theme-teal .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before,
.theme-teal.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(220, 38, 38, 0.2);
}
.theme-teal .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-teal .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before,
.theme-teal.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-teal.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-teal .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-teal .dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-teal.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-teal.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(220, 38, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-teal .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-teal .dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-teal.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-teal.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(220, 38, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-teal .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-teal .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-teal.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-teal.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-teal .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-teal .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-teal.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-teal.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-teal .dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected,
.theme-teal.dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #dc2626;
  color: white;
}
.theme-teal .dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-teal.dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(220, 38, 38, 0.4);
}
.theme-teal .dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected,
.theme-teal.dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-teal .dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-teal .dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-teal.dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-teal.dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(220, 38, 38, 0.3);
}
@media (hover: hover) {
  .theme-teal .dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-teal.dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(220, 38, 38, 0.3);
  }
}
.theme-teal .dark .mat-datepicker-content-touch,
.theme-teal.dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-datepicker-toggle-active,
.theme-teal.dark .mat-datepicker-toggle-active {
  color: #0d9488;
}
.theme-teal .dark .mat-datepicker-toggle-active.mat-accent,
.theme-teal.dark .mat-datepicker-toggle-active.mat-accent {
  color: #1e293b;
}
.theme-teal .dark .mat-datepicker-toggle-active.mat-warn,
.theme-teal.dark .mat-datepicker-toggle-active.mat-warn {
  color: #dc2626;
}
.theme-teal .dark .mat-date-range-input-inner[disabled],
.theme-teal.dark .mat-date-range-input-inner[disabled] {
  color: #475569;
}
.theme-teal .dark .mat-divider,
.theme-teal.dark .mat-divider {
  border-top-color: rgba(241, 245, 249, 0.12);
}
.theme-teal .dark .mat-divider-vertical,
.theme-teal.dark .mat-divider-vertical {
  border-right-color: rgba(241, 245, 249, 0.12);
}
.theme-teal .dark .mat-expansion-panel,
.theme-teal.dark .mat-expansion-panel {
  background: #1e293b;
  color: white;
}
.theme-teal .dark .mat-expansion-panel:not([class*=mat-elevation-z]),
.theme-teal.dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-action-row,
.theme-teal.dark .mat-action-row {
  border-top-color: rgba(241, 245, 249, 0.12);
}
.theme-teal .dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .theme-teal .dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .theme-teal .dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]),
.theme-teal.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
.theme-teal.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
.theme-teal.dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.05);
}
@media (hover: none) {
  .theme-teal .dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover,
  .theme-teal.dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #1e293b;
  }
}
.theme-teal .dark .mat-expansion-panel-header-title,
.theme-teal.dark .mat-expansion-panel-header-title {
  color: white;
}
.theme-teal .dark .mat-expansion-panel-header-description,
.theme-teal .dark .mat-expansion-indicator::after,
.theme-teal.dark .mat-expansion-panel-header-description,
.theme-teal.dark .mat-expansion-indicator::after {
  color: #94a3b8;
}
.theme-teal .dark .mat-expansion-panel-header[aria-disabled=true],
.theme-teal.dark .mat-expansion-panel-header[aria-disabled=true] {
  color: #1e293b;
}
.theme-teal .dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-teal .dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description,
.theme-teal.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-teal.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.theme-teal .dark .mat-icon.mat-primary,
.theme-teal.dark .mat-icon.mat-primary {
  color: #0d9488;
}
.theme-teal .dark .mat-icon.mat-accent,
.theme-teal.dark .mat-icon.mat-accent {
  color: #1e293b;
}
.theme-teal .dark .mat-icon.mat-warn,
.theme-teal.dark .mat-icon.mat-warn {
  color: #dc2626;
}
.theme-teal .dark .mat-drawer-container,
.theme-teal.dark .mat-drawer-container {
  background-color: #0f172a;
  color: white;
}
.theme-teal .dark .mat-drawer,
.theme-teal.dark .mat-drawer {
  background-color: #1e293b;
  color: white;
}
.theme-teal .dark .mat-drawer.mat-drawer-push,
.theme-teal.dark .mat-drawer.mat-drawer-push {
  background-color: #1e293b;
}
.theme-teal .dark .mat-drawer:not(.mat-drawer-side),
.theme-teal.dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-drawer-side,
.theme-teal.dark .mat-drawer-side {
  border-right: solid 1px rgba(241, 245, 249, 0.12);
}
.theme-teal .dark .mat-drawer-side.mat-drawer-end,
.theme-teal.dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(241, 245, 249, 0.12);
  border-right: none;
}
.theme-teal .dark [dir=rtl] .mat-drawer-side,
.theme-teal.dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(241, 245, 249, 0.12);
  border-right: none;
}
.theme-teal .dark [dir=rtl] .mat-drawer-side.mat-drawer-end,
.theme-teal.dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(241, 245, 249, 0.12);
}
.theme-teal .dark .mat-drawer-backdrop.mat-drawer-shown,
.theme-teal.dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(225, 214, 196, 0.6);
}
.theme-teal .dark .mat-step-header.cdk-keyboard-focused, .theme-teal .dark .mat-step-header.cdk-program-focused, .theme-teal .dark .mat-step-header:hover:not([aria-disabled]), .theme-teal .dark .mat-step-header:hover[aria-disabled=false],
.theme-teal.dark .mat-step-header.cdk-keyboard-focused,
.theme-teal.dark .mat-step-header.cdk-program-focused,
.theme-teal.dark .mat-step-header:hover:not([aria-disabled]),
.theme-teal.dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.05);
}
.theme-teal .dark .mat-step-header:hover[aria-disabled=true],
.theme-teal.dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .theme-teal .dark .mat-step-header:hover,
  .theme-teal.dark .mat-step-header:hover {
    background: none;
  }
}
.theme-teal .dark .mat-step-header .mat-step-label,
.theme-teal .dark .mat-step-header .mat-step-optional,
.theme-teal.dark .mat-step-header .mat-step-label,
.theme-teal.dark .mat-step-header .mat-step-optional {
  color: #94a3b8;
}
.theme-teal .dark .mat-step-header .mat-step-icon,
.theme-teal.dark .mat-step-header .mat-step-icon {
  background-color: #94a3b8;
  color: white;
}
.theme-teal .dark .mat-step-header .mat-step-icon-selected,
.theme-teal .dark .mat-step-header .mat-step-icon-state-done,
.theme-teal .dark .mat-step-header .mat-step-icon-state-edit,
.theme-teal.dark .mat-step-header .mat-step-icon-selected,
.theme-teal.dark .mat-step-header .mat-step-icon-state-done,
.theme-teal.dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #0d9488;
  color: white;
}
.theme-teal .dark .mat-step-header.mat-accent .mat-step-icon,
.theme-teal.dark .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.theme-teal .dark .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-teal .dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-teal .dark .mat-step-header.mat-accent .mat-step-icon-state-edit,
.theme-teal.dark .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-teal.dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-teal.dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #1e293b;
  color: white;
}
.theme-teal .dark .mat-step-header.mat-warn .mat-step-icon,
.theme-teal.dark .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.theme-teal .dark .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-teal .dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-teal .dark .mat-step-header.mat-warn .mat-step-icon-state-edit,
.theme-teal.dark .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-teal.dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-teal.dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #dc2626;
  color: white;
}
.theme-teal .dark .mat-step-header .mat-step-icon-state-error,
.theme-teal.dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #dc2626;
}
.theme-teal .dark .mat-step-header .mat-step-label.mat-step-label-active,
.theme-teal.dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.theme-teal .dark .mat-step-header .mat-step-label.mat-step-label-error,
.theme-teal.dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #dc2626;
}
.theme-teal .dark .mat-stepper-horizontal, .theme-teal .dark .mat-stepper-vertical,
.theme-teal.dark .mat-stepper-horizontal,
.theme-teal.dark .mat-stepper-vertical {
  background-color: #1e293b;
}
.theme-teal .dark .mat-stepper-vertical-line::before,
.theme-teal.dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(241, 245, 249, 0.12);
}
.theme-teal .dark .mat-horizontal-stepper-header::before,
.theme-teal .dark .mat-horizontal-stepper-header::after,
.theme-teal .dark .mat-stepper-horizontal-line,
.theme-teal.dark .mat-horizontal-stepper-header::before,
.theme-teal.dark .mat-horizontal-stepper-header::after,
.theme-teal.dark .mat-stepper-horizontal-line {
  border-top-color: rgba(241, 245, 249, 0.12);
}
.theme-teal .dark .mat-sort-header-arrow,
.theme-teal.dark .mat-sort-header-arrow {
  color: #94a3b8;
}
.theme-teal .dark .mat-toolbar,
.theme-teal.dark .mat-toolbar {
  background: #0f172a;
  color: white;
}
.theme-teal .dark .mat-toolbar.mat-primary,
.theme-teal.dark .mat-toolbar.mat-primary {
  background: #0d9488;
  color: white;
}
.theme-teal .dark .mat-toolbar.mat-accent,
.theme-teal.dark .mat-toolbar.mat-accent {
  background: #1e293b;
  color: white;
}
.theme-teal .dark .mat-toolbar.mat-warn,
.theme-teal.dark .mat-toolbar.mat-warn {
  background: #dc2626;
  color: white;
}
.theme-teal .dark .mat-toolbar .mat-form-field-underline,
.theme-teal .dark .mat-toolbar .mat-form-field-ripple,
.theme-teal .dark .mat-toolbar .mat-focused .mat-form-field-ripple,
.theme-teal.dark .mat-toolbar .mat-form-field-underline,
.theme-teal.dark .mat-toolbar .mat-form-field-ripple,
.theme-teal.dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.theme-teal .dark .mat-toolbar .mat-form-field-label,
.theme-teal .dark .mat-toolbar .mat-focused .mat-form-field-label,
.theme-teal .dark .mat-toolbar .mat-select-value,
.theme-teal .dark .mat-toolbar .mat-select-arrow,
.theme-teal .dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow,
.theme-teal.dark .mat-toolbar .mat-form-field-label,
.theme-teal.dark .mat-toolbar .mat-focused .mat-form-field-label,
.theme-teal.dark .mat-toolbar .mat-select-value,
.theme-teal.dark .mat-toolbar .mat-select-arrow,
.theme-teal.dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.theme-teal .dark .mat-toolbar .mat-input-element,
.theme-teal.dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.theme-teal .dark .mat-tree,
.theme-teal.dark .mat-tree {
  background: #1e293b;
}
.theme-teal .dark .mat-tree-node,
.theme-teal .dark .mat-nested-tree-node,
.theme-teal.dark .mat-tree-node,
.theme-teal.dark .mat-nested-tree-node {
  color: white;
}

/* Generate the palettes */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define a light & dark Angular Material theme with the generated palettes */
/* Merge the custom base colors with the generated themes */
/* Generate and encapsulate Angular Material themes */
.theme-rose .light .mat-ripple-element,
.theme-rose.light .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.theme-rose .light .mat-mdc-option,
.theme-rose.light .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-rose .light .mat-mdc-option:hover:not(.mdc-list-item--disabled), .theme-rose .light .mat-mdc-option:focus:not(.mdc-list-item--disabled), .theme-rose .light .mat-mdc-option.mat-mdc-option-active, .theme-rose .light .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled),
.theme-rose.light .mat-mdc-option:hover:not(.mdc-list-item--disabled),
.theme-rose.light .mat-mdc-option:focus:not(.mdc-list-item--disabled),
.theme-rose.light .mat-mdc-option.mat-mdc-option-active,
.theme-rose.light .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.theme-rose .light .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-rose.light .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #f43f5e);
}
.theme-rose .light .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-rose.light .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #1e293b);
}
.theme-rose .light .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-rose.light .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mat-mdc-optgroup-label,
.theme-rose.light .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-rose .light .mat-pseudo-checkbox,
.theme-rose.light .mat-pseudo-checkbox {
  color: #64748b;
}
.theme-rose .light .mat-pseudo-checkbox::after,
.theme-rose.light .mat-pseudo-checkbox::after {
  color: #f1f5f9;
}
.theme-rose .light .mat-pseudo-checkbox-disabled,
.theme-rose.light .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.theme-rose .light .mat-primary .mat-pseudo-checkbox-checked,
.theme-rose .light .mat-primary .mat-pseudo-checkbox-indeterminate,
.theme-rose.light .mat-primary .mat-pseudo-checkbox-checked,
.theme-rose.light .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #f43f5e;
}
.theme-rose .light .mat-pseudo-checkbox-checked,
.theme-rose .light .mat-pseudo-checkbox-indeterminate,
.theme-rose .light .mat-accent .mat-pseudo-checkbox-checked,
.theme-rose .light .mat-accent .mat-pseudo-checkbox-indeterminate,
.theme-rose.light .mat-pseudo-checkbox-checked,
.theme-rose.light .mat-pseudo-checkbox-indeterminate,
.theme-rose.light .mat-accent .mat-pseudo-checkbox-checked,
.theme-rose.light .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #1e293b;
}
.theme-rose .light .mat-warn .mat-pseudo-checkbox-checked,
.theme-rose .light .mat-warn .mat-pseudo-checkbox-indeterminate,
.theme-rose.light .mat-warn .mat-pseudo-checkbox-checked,
.theme-rose.light .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #dc2626;
}
.theme-rose .light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-rose .light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled,
.theme-rose.light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-rose.light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.theme-rose .light .mat-app-background, .theme-rose .light.mat-app-background,
.theme-rose.light .mat-app-background,
.theme-rose.light.mat-app-background {
  background-color: #f1f5f9;
  color: #1e293b;
}
.theme-rose .light .mat-elevation-z0, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-rose.light .mat-elevation-z0,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z1, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-rose.light .mat-elevation-z1,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z2, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-rose.light .mat-elevation-z2,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z3, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-rose.light .mat-elevation-z3,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z4, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-rose.light .mat-elevation-z4,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z5, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-rose.light .mat-elevation-z5,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z6, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-rose.light .mat-elevation-z6,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z7, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-rose.light .mat-elevation-z7,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z8, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-rose.light .mat-elevation-z8,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z9, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-rose.light .mat-elevation-z9,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z10, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-rose.light .mat-elevation-z10,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z11, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-rose.light .mat-elevation-z11,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z12, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-rose.light .mat-elevation-z12,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z13, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-rose.light .mat-elevation-z13,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z14, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-rose.light .mat-elevation-z14,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z15, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-rose.light .mat-elevation-z15,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z16, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-rose.light .mat-elevation-z16,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z17, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-rose.light .mat-elevation-z17,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z18, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-rose.light .mat-elevation-z18,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z19, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-rose.light .mat-elevation-z19,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z20, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-rose.light .mat-elevation-z20,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z21, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-rose.light .mat-elevation-z21,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z22, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-rose.light .mat-elevation-z22,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z23, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-rose.light .mat-elevation-z23,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z24, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-rose.light .mat-elevation-z24,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-rose .light .mat-mdc-card,
.theme-rose.light .mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #fff;
}
.theme-rose .light .mat-mdc-card-outlined,
.theme-rose.light .mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #e0e0e0;
}
.theme-rose .light .mat-mdc-card-subtitle,
.theme-rose.light .mat-mdc-card-subtitle {
  color: #64748b;
}
.theme-rose .light .mat-mdc-progress-bar,
.theme-rose.light .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #f43f5e;
}
.theme-rose .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.theme-rose.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 63, 94, 0.25)'/%3E%3C/svg%3E");
}
.theme-rose .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.theme-rose.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 63, 94, 0.25);
}
.theme-rose .light .mat-mdc-progress-bar.mat-accent,
.theme-rose.light .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
}
.theme-rose .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.theme-rose.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(30, 41, 59, 0.25)'/%3E%3C/svg%3E");
}
.theme-rose .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.theme-rose.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(30, 41, 59, 0.25);
}
.theme-rose .light .mat-mdc-progress-bar.mat-warn,
.theme-rose.light .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
}
.theme-rose .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.theme-rose.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(220, 38, 38, 0.25)'/%3E%3C/svg%3E");
}
.theme-rose .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.theme-rose.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(220, 38, 38, 0.25);
}
.theme-rose .light .mat-mdc-tooltip,
.theme-rose.light .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.theme-rose .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-rose.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.theme-rose .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input,
.theme-rose.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .theme-rose .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder,
  .theme-rose.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .theme-rose .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder,
  .theme-rose.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.theme-rose .light .mdc-text-field .mdc-text-field__input,
.theme-rose.light .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #f43f5e);
}
.theme-rose .light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text,
.theme-rose.light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.theme-rose .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.theme-rose .light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter,
.theme-rose.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.theme-rose.light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.theme-rose .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading,
.theme-rose.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.theme-rose .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-rose.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.theme-rose .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix,
.theme-rose.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-rose .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix,
.theme-rose.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-rose .light .mdc-text-field--filled .mdc-text-field__ripple::before,
.theme-rose .light .mdc-text-field--filled .mdc-text-field__ripple::after,
.theme-rose.light .mdc-text-field--filled .mdc-text-field__ripple::before,
.theme-rose.light .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.theme-rose .light .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .theme-rose .light .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before,
.theme-rose.light .mdc-text-field--filled:hover .mdc-text-field__ripple::before,
.theme-rose.light .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.theme-rose .light .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .theme-rose .light .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before,
.theme-rose.light .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before,
.theme-rose.light .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.theme-rose .light .mdc-text-field--filled:not(.mdc-text-field--disabled),
.theme-rose.light .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.theme-rose .light .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-rose.light .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.theme-rose .light .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-rose.light .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.theme-rose .light .mdc-text-field--filled .mdc-line-ripple::after,
.theme-rose.light .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #f43f5e);
}
.theme-rose .light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-rose .light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-rose .light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-rose.light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-rose.light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-rose.light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.theme-rose .light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-rose .light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-rose .light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-rose.light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-rose.light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-rose.light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.theme-rose .light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-rose .light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-rose .light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-rose.light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-rose.light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-rose.light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #f43f5e);
}
[dir=rtl] .theme-rose .light .mdc-text-field--outlined.mdc-text-field--with-leading-icon, [dir=rtl] .theme-rose.light .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .theme-rose .light .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl], .theme-rose.light .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-rose .light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, [dir=rtl] .theme-rose.light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .theme-rose .light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl], .theme-rose.light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-rose .light .mdc-text-field--outlined .mdc-text-field__ripple::before,
.theme-rose .light .mdc-text-field--outlined .mdc-text-field__ripple::after,
.theme-rose.light .mdc-text-field--outlined .mdc-text-field__ripple::before,
.theme-rose.light .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .theme-rose .light .mdc-text-field--with-leading-icon.mdc-text-field--outlined, [dir=rtl] .theme-rose.light .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .theme-rose .light .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl], .theme-rose.light .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-rose .light .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-rose.light .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(244, 63, 94, 0.87);
}
.theme-rose .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-rose.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-rose.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-rose.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-rose.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mdc-text-field--invalid .mdc-text-field__input,
.theme-rose.light .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-rose.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-rose.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-rose .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-rose .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-rose.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-rose.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-rose.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-rose .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-rose .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-rose.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-rose.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-rose.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-rose .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-rose .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-rose.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-rose.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-rose.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mdc-text-field--disabled .mdc-text-field__input,
.theme-rose.light .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .theme-rose .light .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  .theme-rose.light .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .theme-rose .light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  .theme-rose.light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.theme-rose .light .mdc-text-field--disabled .mdc-floating-label,
.theme-rose.light .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.theme-rose .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
.theme-rose.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.theme-rose .light .mdc-text-field--disabled .mdc-text-field-character-counter,
.theme-rose .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
.theme-rose.light .mdc-text-field--disabled .mdc-text-field-character-counter,
.theme-rose.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.theme-rose .light .mdc-text-field--disabled .mdc-text-field__icon--leading,
.theme-rose.light .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.theme-rose .light .mdc-text-field--disabled .mdc-text-field__icon--trailing,
.theme-rose.light .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.theme-rose .light .mdc-text-field--disabled .mdc-text-field__affix--prefix,
.theme-rose.light .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-rose .light .mdc-text-field--disabled .mdc-text-field__affix--suffix,
.theme-rose.light .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-rose .light .mdc-text-field--disabled .mdc-line-ripple::before,
.theme-rose.light .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.theme-rose .light .mdc-text-field--disabled .mdc-notched-outline__leading,
.theme-rose .light .mdc-text-field--disabled .mdc-notched-outline__notch,
.theme-rose .light .mdc-text-field--disabled .mdc-notched-outline__trailing,
.theme-rose.light .mdc-text-field--disabled .mdc-notched-outline__leading,
.theme-rose.light .mdc-text-field--disabled .mdc-notched-outline__notch,
.theme-rose.light .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-rose .light .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  .theme-rose.light .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-rose .light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  .theme-rose.light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-rose .light .mdc-text-field--disabled .mdc-floating-label,
  .theme-rose.light .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-rose .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
  .theme-rose.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-rose .light .mdc-text-field--disabled .mdc-text-field-character-counter,
  .theme-rose .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
  .theme-rose.light .mdc-text-field--disabled .mdc-text-field-character-counter,
  .theme-rose.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-rose .light .mdc-text-field--disabled .mdc-text-field__icon--leading,
  .theme-rose.light .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-rose .light .mdc-text-field--disabled .mdc-text-field__icon--trailing,
  .theme-rose.light .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-rose .light .mdc-text-field--disabled .mdc-text-field__affix--prefix,
  .theme-rose.light .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-rose .light .mdc-text-field--disabled .mdc-text-field__affix--suffix,
  .theme-rose.light .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-rose .light .mdc-text-field--disabled .mdc-line-ripple::before,
  .theme-rose.light .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-rose .light .mdc-text-field--disabled .mdc-notched-outline__leading,
  .theme-rose .light .mdc-text-field--disabled .mdc-notched-outline__notch,
  .theme-rose .light .mdc-text-field--disabled .mdc-notched-outline__trailing,
  .theme-rose.light .mdc-text-field--disabled .mdc-notched-outline__leading,
  .theme-rose.light .mdc-text-field--disabled .mdc-notched-outline__notch,
  .theme-rose.light .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.theme-rose .light .mdc-text-field--disabled.mdc-text-field--filled,
.theme-rose.light .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .theme-rose .light .mdc-text-field--end-aligned .mdc-text-field__input, [dir=rtl] .theme-rose.light .mdc-text-field--end-aligned .mdc-text-field__input, .theme-rose .light .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl], .theme-rose.light .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-rose .light .mdc-floating-label--required, [dir=rtl] .theme-rose.light .mdc-floating-label--required, .theme-rose .light .mdc-floating-label--required[dir=rtl], .theme-rose.light .mdc-floating-label--required[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-rose .light .mat-mdc-form-field-error,
.theme-rose.light .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mat-mdc-form-field-focus-overlay,
.theme-rose.light .mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}
.theme-rose .light .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.theme-rose.light .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
.theme-rose .light .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.theme-rose.light .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}
.theme-rose .light .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.theme-rose.light .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.theme-rose .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.theme-rose.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(244, 63, 94, 0.87);
}
.theme-rose .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.theme-rose.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(30, 41, 59, 0.87);
}
.theme-rose .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.theme-rose.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(220, 38, 38, 0.87);
}
.theme-rose .light .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.theme-rose.light .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.theme-rose .light .mat-mdc-form-field.mat-accent .mdc-text-field__input,
.theme-rose.light .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-rose .light .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-rose.light .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-rose .light .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-rose.light .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(30, 41, 59, 0.87);
}
.theme-rose .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-rose.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-rose.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-rose.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-rose.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input,
.theme-rose.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-rose.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-rose.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-rose .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-rose .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-rose.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-rose.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-rose.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-rose .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-rose .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-rose.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-rose.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-rose.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-rose .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-rose .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-rose.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-rose.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-rose.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-rose .light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-rose .light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-rose.light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-rose.light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-rose.light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-rose .light .mat-mdc-form-field.mat-warn .mdc-text-field__input,
.theme-rose.light .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-rose.light .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-rose.light .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(220, 38, 38, 0.87);
}
.theme-rose .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-rose.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-rose.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-rose.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-rose.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input,
.theme-rose.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-rose.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-rose.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-rose .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-rose .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-rose.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-rose.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-rose.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-rose .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-rose .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-rose.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-rose.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-rose.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-rose .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-rose .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-rose.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-rose.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-rose.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-rose .light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-rose .light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-rose.light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-rose.light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-rose.light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .light .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.theme-rose.light .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.theme-rose .light [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.theme-rose.light [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.theme-rose .light .mdc-menu-surface,
.theme-rose.light .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mdc-list-item__primary-text,
.theme-rose.light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-rose .light .mdc-list-item__secondary-text,
.theme-rose.light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.theme-rose .light .mdc-list-item__overline-text,
.theme-rose.light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-rose .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-rose.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-rose .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-rose.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.theme-rose .light .mdc-list-item__end,
.theme-rose.light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-rose .light .mdc-list-item--disabled .mdc-list-item__start,
.theme-rose .light .mdc-list-item--disabled .mdc-list-item__content,
.theme-rose .light .mdc-list-item--disabled .mdc-list-item__end,
.theme-rose.light .mdc-list-item--disabled .mdc-list-item__start,
.theme-rose.light .mdc-list-item--disabled .mdc-list-item__content,
.theme-rose.light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-rose .light .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-rose.light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-rose.light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-rose.light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-rose.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-rose.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-rose .light .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-rose.light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-rose.light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #f43f5e);
}
.theme-rose .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #f43f5e);
}
.theme-rose .light .mdc-deprecated-list-group__subheader,
.theme-rose.light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-rose .light .mdc-list-divider::after,
.theme-rose.light .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-rose .light .mdc-list-divider,
.theme-rose.light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-mdc-select-value,
.theme-rose.light .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.theme-rose .light .mat-mdc-select-placeholder,
.theme-rose.light .mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.theme-rose .light .mat-mdc-select-disabled .mat-mdc-select-value,
.theme-rose.light .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.theme-rose .light .mat-mdc-select-arrow,
.theme-rose.light .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.theme-rose .light .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow,
.theme-rose.light .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(244, 63, 94, 0.87);
}
.theme-rose .light .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow,
.theme-rose.light .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(30, 41, 59, 0.87);
}
.theme-rose .light .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow,
.theme-rose.light .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(220, 38, 38, 0.87);
}
.theme-rose .light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow,
.theme-rose.light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(220, 38, 38, 0.87);
}
.theme-rose .light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow,
.theme-rose.light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.theme-rose .light .mdc-menu-surface,
.theme-rose.light .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mdc-list-item__primary-text,
.theme-rose.light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-rose .light .mdc-list-item__secondary-text,
.theme-rose.light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.theme-rose .light .mdc-list-item__overline-text,
.theme-rose.light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-rose .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-rose.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-rose .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-rose.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.theme-rose .light .mdc-list-item__end,
.theme-rose.light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-rose .light .mdc-list-item--disabled .mdc-list-item__start,
.theme-rose .light .mdc-list-item--disabled .mdc-list-item__content,
.theme-rose .light .mdc-list-item--disabled .mdc-list-item__end,
.theme-rose.light .mdc-list-item--disabled .mdc-list-item__start,
.theme-rose.light .mdc-list-item--disabled .mdc-list-item__content,
.theme-rose.light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-rose .light .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-rose.light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-rose.light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-rose.light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-rose.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-rose.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-rose .light .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-rose.light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-rose.light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #f43f5e);
}
.theme-rose .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #f43f5e);
}
.theme-rose .light .mdc-deprecated-list-group__subheader,
.theme-rose.light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-rose .light .mdc-list-divider::after,
.theme-rose.light .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-rose .light .mdc-list-divider,
.theme-rose.light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-mdc-dialog-container,
.theme-rose.light .mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.theme-rose .light .mat-mdc-standard-chip,
.theme-rose.light .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.theme-rose .light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .theme-rose .light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted,
.theme-rose.light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected,
.theme-rose.light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #f43f5e;
  --mdc-chip-elevated-disabled-container-color: #f43f5e;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-rose .light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .theme-rose .light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted,
.theme-rose.light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected,
.theme-rose.light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-rose .light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .theme-rose .light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted,
.theme-rose.light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected,
.theme-rose.light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-rose .light .mat-mdc-chip-focus-overlay,
.theme-rose.light .mat-mdc-chip-focus-overlay {
  background: #000000;
}
.theme-rose .light .mat-mdc-slide-toggle,
.theme-rose.light .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.theme-rose .light .mat-mdc-slide-toggle .mdc-form-field,
.theme-rose.light .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-rose .light .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.theme-rose.light .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: #94a3b8;
}
.theme-rose .light .mat-mdc-slide-toggle.mat-primary,
.theme-rose.light .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #e11d48;
  --mdc-switch-selected-handle-color: #e11d48;
  --mdc-switch-selected-hover-state-layer-color: #e11d48;
  --mdc-switch-selected-pressed-state-layer-color: #e11d48;
  --mdc-switch-selected-focus-handle-color: #881337;
  --mdc-switch-selected-hover-handle-color: #881337;
  --mdc-switch-selected-pressed-handle-color: #881337;
  --mdc-switch-selected-focus-track-color: #fda4af;
  --mdc-switch-selected-hover-track-color: #fda4af;
  --mdc-switch-selected-pressed-track-color: #fda4af;
  --mdc-switch-selected-track-color: #fda4af;
}
.theme-rose .light .mat-mdc-slide-toggle.mat-accent,
.theme-rose.light .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.theme-rose .light .mat-mdc-slide-toggle.mat-warn,
.theme-rose.light .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.theme-rose .light .mat-mdc-radio-button,
.theme-rose.light .mat-mdc-radio-button {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mat-mdc-radio-ripple-color: #000;
}
.theme-rose .light .mat-mdc-radio-button .mdc-form-field,
.theme-rose.light .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-rose .light .mat-mdc-radio-button .mdc-radio--disabled + label,
.theme-rose.light .mat-mdc-radio-button .mdc-radio--disabled + label {
  color: #94a3b8;
}
.theme-rose .light .mat-mdc-radio-button.mat-primary,
.theme-rose.light .mat-mdc-radio-button.mat-primary {
  --mdc-radio-selected-focus-icon-color: #f43f5e;
  --mdc-radio-selected-hover-icon-color: #f43f5e;
  --mdc-radio-selected-icon-color: #f43f5e;
  --mdc-radio-selected-pressed-icon-color: #f43f5e;
  --mat-mdc-radio-checked-ripple-color: #f43f5e;
}
.theme-rose .light .mat-mdc-radio-button.mat-accent,
.theme-rose.light .mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-mdc-radio-checked-ripple-color: #1e293b;
}
.theme-rose .light .mat-mdc-radio-button.mat-warn,
.theme-rose.light .mat-mdc-radio-button.mat-warn {
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-mdc-radio-checked-ripple-color: #dc2626;
}
.theme-rose .light .mat-mdc-slider,
.theme-rose.light .mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.theme-rose .light .mat-mdc-slider.mat-primary,
.theme-rose.light .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #f43f5e;
  --mdc-slider-focus-handle-color: #f43f5e;
  --mdc-slider-hover-handle-color: #f43f5e;
  --mdc-slider-active-track-color: #f43f5e;
  --mdc-slider-inactive-track-color: #f43f5e;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #f43f5e;
  --mat-mdc-slider-ripple-color: #f43f5e;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 63, 94, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 63, 94, 0.2);
}
.theme-rose .light .mat-mdc-slider.mat-accent,
.theme-rose.light .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mat-mdc-slider-ripple-color: #1e293b;
  --mat-mdc-slider-hover-ripple-color: rgba(30, 41, 59, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(30, 41, 59, 0.2);
}
.theme-rose .light .mat-mdc-slider.mat-warn,
.theme-rose.light .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mat-mdc-slider-ripple-color: #dc2626;
  --mat-mdc-slider-hover-ripple-color: rgba(220, 38, 38, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(220, 38, 38, 0.2);
}
.theme-rose .light .mdc-menu-surface,
.theme-rose.light .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mdc-list-item__primary-text,
.theme-rose.light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-rose .light .mdc-list-item__secondary-text,
.theme-rose.light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.theme-rose .light .mdc-list-item__overline-text,
.theme-rose.light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-rose .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-rose.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-rose .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-rose.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.theme-rose .light .mdc-list-item__end,
.theme-rose.light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-rose .light .mdc-list-item--disabled .mdc-list-item__start,
.theme-rose .light .mdc-list-item--disabled .mdc-list-item__content,
.theme-rose .light .mdc-list-item--disabled .mdc-list-item__end,
.theme-rose.light .mdc-list-item--disabled .mdc-list-item__start,
.theme-rose.light .mdc-list-item--disabled .mdc-list-item__content,
.theme-rose.light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-rose .light .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-rose.light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-rose.light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-rose.light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-rose.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-rose.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-rose .light .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-rose.light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-rose.light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #f43f5e);
}
.theme-rose .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #f43f5e);
}
.theme-rose .light .mdc-deprecated-list-group__subheader,
.theme-rose.light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-rose .light .mdc-list-divider::after,
.theme-rose.light .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-rose .light .mdc-list-divider,
.theme-rose.light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-mdc-menu-item[disabled],
.theme-rose .light .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.theme-rose .light .mat-mdc-menu-item[disabled] .mat-icon-no-color,
.theme-rose.light .mat-mdc-menu-item[disabled],
.theme-rose.light .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.theme-rose.light .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}
.theme-rose .light .mat-mdc-menu-item .mat-icon-no-color,
.theme-rose .light .mat-mdc-menu-submenu-icon,
.theme-rose.light .mat-mdc-menu-item .mat-icon-no-color,
.theme-rose.light .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-rose .light .mat-mdc-menu-item:hover:not([disabled]),
.theme-rose .light .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.theme-rose .light .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-rose .light .mat-mdc-menu-item-highlighted:not([disabled]),
.theme-rose.light .mat-mdc-menu-item:hover:not([disabled]),
.theme-rose.light .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.theme-rose.light .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-rose.light .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.theme-rose .light .mat-mdc-list-item-interactive::before,
.theme-rose.light .mat-mdc-list-item-interactive::before {
  background: black;
}
.theme-rose .light .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before,
.theme-rose.light .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.04;
}
.theme-rose .light .mat-mdc-list-item-interactive.mdc-list-item--selected::before,
.theme-rose.light .mat-mdc-list-item-interactive.mdc-list-item--selected::before {
  background: #f43f5e;
  opacity: 0.08;
}
.theme-rose .light .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before,
.theme-rose.light .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.12;
}
.theme-rose .light .mat-mdc-list-item-interactive:focus::before,
.theme-rose.light .mat-mdc-list-item-interactive:focus::before {
  opacity: 0.12;
}
.theme-rose .light .mdc-list-item__primary-text,
.theme-rose.light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-rose .light .mdc-list-item__secondary-text,
.theme-rose.light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.theme-rose .light .mdc-list-item__overline-text,
.theme-rose.light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-rose .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-rose.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-rose .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-rose.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.theme-rose .light .mdc-list-item__end,
.theme-rose.light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-rose .light .mdc-list-item--disabled .mdc-list-item__start,
.theme-rose .light .mdc-list-item--disabled .mdc-list-item__content,
.theme-rose .light .mdc-list-item--disabled .mdc-list-item__end,
.theme-rose.light .mdc-list-item--disabled .mdc-list-item__start,
.theme-rose.light .mdc-list-item--disabled .mdc-list-item__content,
.theme-rose.light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-rose .light .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-rose.light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-rose.light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-rose.light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-rose.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-rose.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-rose .light .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-rose.light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-rose.light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #f43f5e);
}
.theme-rose .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #f43f5e);
}
.theme-rose .light .mdc-deprecated-list-group__subheader,
.theme-rose.light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-rose .light .mdc-list-divider::after,
.theme-rose.light .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-rose .light .mdc-list-divider,
.theme-rose.light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-mdc-list-option .mdc-list-item__start, .theme-rose .light .mat-mdc-list-option .mdc-list-item__end,
.theme-rose.light .mat-mdc-list-option .mdc-list-item__start,
.theme-rose.light .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f43f5e;
  --mdc-checkbox-selected-hover-icon-color: #f43f5e;
  --mdc-checkbox-selected-icon-color: #f43f5e;
  --mdc-checkbox-selected-pressed-icon-color: #f43f5e;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-rose .light .mat-mdc-list-option.mat-accent .mdc-list-item__start, .theme-rose .light .mat-mdc-list-option.mat-accent .mdc-list-item__end,
.theme-rose.light .mat-mdc-list-option.mat-accent .mdc-list-item__start,
.theme-rose.light .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-rose .light .mat-mdc-list-option.mat-warn .mdc-list-item__start, .theme-rose .light .mat-mdc-list-option.mat-warn .mdc-list-item__end,
.theme-rose.light .mat-mdc-list-option.mat-warn .mdc-list-item__start,
.theme-rose.light .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-rose .light .mat-mdc-paginator,
.theme-rose.light .mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme-rose .light .mat-mdc-paginator-icon,
.theme-rose.light .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}
.theme-rose .light .mat-mdc-paginator-decrement,
.theme-rose .light .mat-mdc-paginator-increment,
.theme-rose.light .mat-mdc-paginator-decrement,
.theme-rose.light .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.theme-rose .light .mat-mdc-paginator-first,
.theme-rose .light .mat-mdc-paginator-last,
.theme-rose.light .mat-mdc-paginator-first,
.theme-rose.light .mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.theme-rose .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.theme-rose .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.theme-rose .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.theme-rose .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last,
.theme-rose.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.theme-rose.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.theme-rose.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.theme-rose.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon,
.theme-rose.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-mdc-tab, .theme-rose .light .mat-mdc-tab-link,
.theme-rose.light .mat-mdc-tab,
.theme-rose.light .mat-mdc-tab-link {
  background-color: transparent;
}
.theme-rose .light .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label, .theme-rose .light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.theme-rose.light .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.theme-rose.light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}
.theme-rose .light .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-rose .light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-rose.light .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-rose.light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #f43f5e;
}
.theme-rose .light .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-rose .light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-rose.light .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-rose.light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #f43f5e;
}
.theme-rose .light .mdc-tab__ripple::before,
.theme-rose .light .mat-mdc-tab .mat-ripple-element,
.theme-rose .light .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-rose .light .mat-mdc-tab-link .mat-ripple-element,
.theme-rose.light .mdc-tab__ripple::before,
.theme-rose.light .mat-mdc-tab .mat-ripple-element,
.theme-rose.light .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-rose.light .mat-mdc-tab-link .mat-ripple-element {
  background-color: #f43f5e;
}
.theme-rose .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-rose .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-rose .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-rose .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-rose.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-rose.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-rose.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-rose.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #1e293b;
}
.theme-rose .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-rose .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-rose .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-rose .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-rose.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-rose.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-rose.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-rose.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #1e293b;
}
.theme-rose .light .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.theme-rose .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-rose .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-rose .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .theme-rose .light .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.theme-rose .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-rose .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-rose .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element,
.theme-rose.light .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.theme-rose.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-rose.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-rose.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element,
.theme-rose.light .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.theme-rose.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-rose.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-rose.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #1e293b;
}
.theme-rose .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-rose .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-rose .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-rose .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-rose.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-rose.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-rose.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-rose.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #dc2626;
}
.theme-rose .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-rose .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-rose .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-rose .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-rose.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-rose.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-rose.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-rose.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #dc2626;
}
.theme-rose .light .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.theme-rose .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-rose .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-rose .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .theme-rose .light .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.theme-rose .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-rose .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-rose .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element,
.theme-rose.light .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.theme-rose.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-rose.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-rose.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element,
.theme-rose.light .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.theme-rose.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-rose.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-rose.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #dc2626;
}
.theme-rose .light .mat-mdc-tab-group.mat-background-primary, .theme-rose .light .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-rose.light .mat-mdc-tab-group.mat-background-primary,
.theme-rose.light .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #f43f5e;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-rose .light .mat-mdc-tab-group.mat-background-accent, .theme-rose .light .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-rose.light .mat-mdc-tab-group.mat-background-accent,
.theme-rose.light .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #1e293b;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-rose .light .mat-mdc-tab-group.mat-background-warn, .theme-rose .light .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-rose.light .mat-mdc-tab-group.mat-background-warn,
.theme-rose.light .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #dc2626;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-rose .light .mat-mdc-tab-header-pagination-chevron,
.theme-rose.light .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #000);
}
.theme-rose .light .mat-mdc-checkbox .mdc-form-field,
.theme-rose.light .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-rose .light .mat-mdc-checkbox .mat-ripple-element,
.theme-rose.light .mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.theme-rose .light .mat-mdc-checkbox .mdc-checkbox__ripple,
.theme-rose.light .mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #000;
}
.theme-rose .light .mat-mdc-checkbox.mat-primary,
.theme-rose.light .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f43f5e;
  --mdc-checkbox-selected-hover-icon-color: #f43f5e;
  --mdc-checkbox-selected-icon-color: #f43f5e;
  --mdc-checkbox-selected-pressed-icon-color: #f43f5e;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-rose .light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-rose.light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(244, 63, 94, 0.1);
}
.theme-rose .light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-rose.light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #f43f5e;
}
.theme-rose .light .mat-mdc-checkbox.mat-accent,
.theme-rose.light .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-rose .light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-rose.light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(30, 41, 59, 0.1);
}
.theme-rose .light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-rose.light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #1e293b;
}
.theme-rose .light .mat-mdc-checkbox.mat-warn,
.theme-rose.light .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-rose .light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-rose.light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(220, 38, 38, 0.1);
}
.theme-rose .light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-rose.light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #dc2626;
}
.theme-rose .light .mat-mdc-checkbox-disabled label,
.theme-rose.light .mat-mdc-checkbox-disabled label {
  color: #94a3b8;
}
.theme-rose .light .mat-mdc-button.mat-unthemed,
.theme-rose.light .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.theme-rose .light .mat-mdc-button.mat-primary,
.theme-rose.light .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #f43f5e;
}
.theme-rose .light .mat-mdc-button.mat-accent,
.theme-rose.light .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
}
.theme-rose .light .mat-mdc-button.mat-warn,
.theme-rose.light .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
}
.theme-rose .light .mat-mdc-button[disabled][disabled],
.theme-rose.light .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.theme-rose .light .mat-mdc-unelevated-button.mat-unthemed,
.theme-rose.light .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.theme-rose .light .mat-mdc-unelevated-button.mat-primary,
.theme-rose.light .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #f43f5e;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-rose .light .mat-mdc-unelevated-button.mat-accent,
.theme-rose.light .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-rose .light .mat-mdc-unelevated-button.mat-warn,
.theme-rose.light .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-rose .light .mat-mdc-unelevated-button[disabled][disabled],
.theme-rose.light .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.theme-rose .light .mat-mdc-raised-button.mat-unthemed,
.theme-rose.light .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.theme-rose .light .mat-mdc-raised-button.mat-primary,
.theme-rose.light .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #f43f5e;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-rose .light .mat-mdc-raised-button.mat-accent,
.theme-rose.light .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-rose .light .mat-mdc-raised-button.mat-warn,
.theme-rose.light .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-rose .light .mat-mdc-raised-button[disabled][disabled],
.theme-rose.light .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.theme-rose .light .mat-mdc-outlined-button,
.theme-rose.light .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-mdc-outlined-button.mat-unthemed,
.theme-rose.light .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.theme-rose .light .mat-mdc-outlined-button.mat-primary,
.theme-rose.light .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #f43f5e;
}
.theme-rose .light .mat-mdc-outlined-button.mat-accent,
.theme-rose.light .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
}
.theme-rose .light .mat-mdc-outlined-button.mat-warn,
.theme-rose.light .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
}
.theme-rose .light .mat-mdc-outlined-button[disabled][disabled],
.theme-rose.light .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-mdc-button, .theme-rose .light .mat-mdc-outlined-button,
.theme-rose.light .mat-mdc-button,
.theme-rose.light .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-rose .light .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .theme-rose .light .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-rose .light .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-rose .light .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-rose .light .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-rose .light .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-rose .light .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .theme-rose .light .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-rose .light .mat-mdc-button.mat-primary, .theme-rose .light .mat-mdc-outlined-button.mat-primary,
.theme-rose.light .mat-mdc-button.mat-primary,
.theme-rose.light .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #f43f5e;
  --mat-mdc-button-ripple-color: rgba(244, 63, 94, 0.1);
}
.theme-rose .light .mat-mdc-button.mat-accent, .theme-rose .light .mat-mdc-outlined-button.mat-accent,
.theme-rose.light .mat-mdc-button.mat-accent,
.theme-rose.light .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #1e293b;
  --mat-mdc-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-rose .light .mat-mdc-button.mat-warn, .theme-rose .light .mat-mdc-outlined-button.mat-warn,
.theme-rose.light .mat-mdc-button.mat-warn,
.theme-rose.light .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #dc2626;
  --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-rose .light .mat-mdc-raised-button, .theme-rose .light .mat-mdc-unelevated-button,
.theme-rose.light .mat-mdc-raised-button,
.theme-rose.light .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-rose .light .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .theme-rose .light .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-rose .light .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-rose .light .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-rose .light .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-rose .light .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-rose .light .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .theme-rose .light .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-rose .light .mat-mdc-raised-button.mat-primary, .theme-rose .light .mat-mdc-unelevated-button.mat-primary,
.theme-rose.light .mat-mdc-raised-button.mat-primary,
.theme-rose.light .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .light .mat-mdc-raised-button.mat-accent, .theme-rose .light .mat-mdc-unelevated-button.mat-accent,
.theme-rose.light .mat-mdc-raised-button.mat-accent,
.theme-rose.light .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .light .mat-mdc-raised-button.mat-warn, .theme-rose .light .mat-mdc-unelevated-button.mat-warn,
.theme-rose.light .mat-mdc-raised-button.mat-warn,
.theme-rose.light .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .light .mat-mdc-icon-button,
.theme-rose.light .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-rose .light .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-rose .light .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-rose .light .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-rose .light .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-rose .light .mat-mdc-icon-button.mat-primary,
.theme-rose.light .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #f43f5e;
  --mat-mdc-button-ripple-color: rgba(244, 63, 94, 0.1);
}
.theme-rose .light .mat-mdc-icon-button.mat-accent,
.theme-rose.light .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #1e293b;
  --mat-mdc-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-rose .light .mat-mdc-icon-button.mat-warn,
.theme-rose.light .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #dc2626;
  --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-rose .light .mat-mdc-icon-button.mat-primary,
.theme-rose.light .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #f43f5e;
}
.theme-rose .light .mat-mdc-icon-button.mat-accent,
.theme-rose.light .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
}
.theme-rose .light .mat-mdc-icon-button.mat-warn,
.theme-rose.light .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
}
.theme-rose .light .mat-mdc-icon-button[disabled][disabled],
.theme-rose.light .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.theme-rose .light .mat-mdc-fab, .theme-rose .light .mat-mdc-mini-fab,
.theme-rose.light .mat-mdc-fab,
.theme-rose.light .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-rose .light .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .theme-rose .light .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-rose .light .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-rose .light .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-rose .light .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-rose .light .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-rose .light .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .theme-rose .light .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.theme-rose.light .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-rose .light .mat-mdc-fab.mat-primary, .theme-rose .light .mat-mdc-mini-fab.mat-primary,
.theme-rose.light .mat-mdc-fab.mat-primary,
.theme-rose.light .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .light .mat-mdc-fab.mat-accent, .theme-rose .light .mat-mdc-mini-fab.mat-accent,
.theme-rose.light .mat-mdc-fab.mat-accent,
.theme-rose.light .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .light .mat-mdc-fab.mat-warn, .theme-rose .light .mat-mdc-mini-fab.mat-warn,
.theme-rose.light .mat-mdc-fab.mat-warn,
.theme-rose.light .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .light .mat-mdc-fab.mat-unthemed, .theme-rose .light .mat-mdc-mini-fab.mat-unthemed,
.theme-rose.light .mat-mdc-fab.mat-unthemed,
.theme-rose.light .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.theme-rose .light .mat-mdc-fab.mat-primary, .theme-rose .light .mat-mdc-mini-fab.mat-primary,
.theme-rose.light .mat-mdc-fab.mat-primary,
.theme-rose.light .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #f43f5e;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-rose .light .mat-mdc-fab.mat-accent, .theme-rose .light .mat-mdc-mini-fab.mat-accent,
.theme-rose.light .mat-mdc-fab.mat-accent,
.theme-rose.light .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-rose .light .mat-mdc-fab.mat-warn, .theme-rose .light .mat-mdc-mini-fab.mat-warn,
.theme-rose.light .mat-mdc-fab.mat-warn,
.theme-rose.light .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-rose .light .mat-mdc-fab[disabled][disabled], .theme-rose .light .mat-mdc-mini-fab[disabled][disabled],
.theme-rose.light .mat-mdc-fab[disabled][disabled],
.theme-rose.light .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.theme-rose .light .mat-mdc-snack-bar-container,
.theme-rose.light .mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #1e293b;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
}
.theme-rose .light .mdc-data-table,
.theme-rose.light .mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mdc-data-table__row,
.theme-rose.light .mdc-data-table__row {
  background-color: inherit;
}
.theme-rose .light .mdc-data-table__header-cell,
.theme-rose.light .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}
.theme-rose .light .mdc-data-table__row--selected,
.theme-rose.light .mdc-data-table__row--selected {
  background-color: rgba(244, 63, 94, 0.04);
}
.theme-rose .light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.theme-rose .light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.theme-rose .light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing,
.theme-rose.light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.theme-rose.light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.theme-rose.light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mdc-data-table__cell,
.theme-rose .light .mdc-data-table__header-cell,
.theme-rose.light .mdc-data-table__cell,
.theme-rose.light .mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mdc-data-table__pagination,
.theme-rose.light .mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mdc-data-table__row:not(.mdc-data-table__row--selected):hover,
.theme-rose.light .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.theme-rose .light .mdc-data-table__header-cell,
.theme-rose.light .mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}
.theme-rose .light .mdc-data-table__pagination-total,
.theme-rose .light .mdc-data-table__pagination-rows-per-page-label,
.theme-rose .light .mdc-data-table__cell,
.theme-rose.light .mdc-data-table__pagination-total,
.theme-rose.light .mdc-data-table__pagination-rows-per-page-label,
.theme-rose.light .mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}
[dir=rtl] .theme-rose .light .mdc-data-table__pagination-button .mdc-button__icon, [dir=rtl] .theme-rose.light .mdc-data-table__pagination-button .mdc-button__icon, .theme-rose .light .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl], .theme-rose.light .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-rose .light .mat-mdc-table,
.theme-rose.light .mat-mdc-table {
  background: white;
}
.theme-rose .light .mat-mdc-progress-spinner,
.theme-rose.light .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #f43f5e;
}
.theme-rose .light .mat-mdc-progress-spinner.mat-accent,
.theme-rose.light .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-rose .light .mat-mdc-progress-spinner.mat-warn,
.theme-rose.light .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-rose .light .mat-badge-content,
.theme-rose.light .mat-badge-content {
  color: white;
  background: #f43f5e;
}
.cdk-high-contrast-active .theme-rose .light .mat-badge-content, .cdk-high-contrast-active .theme-rose.light .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.theme-rose .light .mat-badge-accent .mat-badge-content,
.theme-rose.light .mat-badge-accent .mat-badge-content {
  background: #1e293b;
  color: white;
}
.theme-rose .light .mat-badge-warn .mat-badge-content,
.theme-rose.light .mat-badge-warn .mat-badge-content {
  color: white;
  background: #dc2626;
}
.theme-rose .light .mat-badge-disabled .mat-badge-content,
.theme-rose.light .mat-badge-disabled .mat-badge-content {
  background: #94a3b8;
  color: #94a3b8;
}
.theme-rose .light .mat-bottom-sheet-container,
.theme-rose.light .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: #1e293b;
}
.theme-rose .light .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-rose .light .mat-button-toggle-group:not([class*=mat-elevation-z]),
.theme-rose.light .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-rose.light .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-rose .light .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]),
.theme-rose.light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-rose.light .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.theme-rose .light .mat-button-toggle,
.theme-rose.light .mat-button-toggle {
  color: #94a3b8;
}
.theme-rose .light .mat-button-toggle .mat-button-toggle-focus-overlay,
.theme-rose.light .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: #64748b;
}
.theme-rose .light .mat-button-toggle-appearance-standard,
.theme-rose.light .mat-button-toggle-appearance-standard {
  color: #1e293b;
  background: white;
}
.theme-rose .light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay,
.theme-rose.light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: #64748b;
}
.theme-rose .light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
.theme-rose.light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e2e8f0;
}
.theme-rose .light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
.theme-rose.light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e2e8f0;
}
.theme-rose .light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle,
.theme-rose.light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e2e8f0;
}
.theme-rose .light .mat-button-toggle-checked,
.theme-rose.light .mat-button-toggle-checked {
  background-color: #e2e8f0;
  color: #64748b;
}
.theme-rose .light .mat-button-toggle-checked.mat-button-toggle-appearance-standard,
.theme-rose.light .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: #1e293b;
}
.theme-rose .light .mat-button-toggle-disabled,
.theme-rose.light .mat-button-toggle-disabled {
  color: #94a3b8;
  background-color: #cbd5e1;
}
.theme-rose .light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard,
.theme-rose.light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.theme-rose .light .mat-button-toggle-disabled.mat-button-toggle-checked,
.theme-rose.light .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #e2e8f0;
}
.theme-rose .light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-rose .light .mat-button-toggle-group-appearance-standard,
.theme-rose.light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-rose.light .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e2e8f0;
}
.theme-rose .light .mat-calendar-arrow,
.theme-rose.light .mat-calendar-arrow {
  fill: #64748b;
}
.theme-rose .light .mat-datepicker-toggle,
.theme-rose .light .mat-datepicker-content .mat-calendar-next-button,
.theme-rose .light .mat-datepicker-content .mat-calendar-previous-button,
.theme-rose.light .mat-datepicker-toggle,
.theme-rose.light .mat-datepicker-content .mat-calendar-next-button,
.theme-rose.light .mat-datepicker-content .mat-calendar-previous-button {
  color: #64748b;
}
.theme-rose .light .mat-calendar-table-header-divider::after,
.theme-rose.light .mat-calendar-table-header-divider::after {
  background: #e2e8f0;
}
.theme-rose .light .mat-calendar-table-header,
.theme-rose .light .mat-calendar-body-label,
.theme-rose.light .mat-calendar-table-header,
.theme-rose.light .mat-calendar-body-label {
  color: #64748b;
}
.theme-rose .light .mat-calendar-body-cell-content,
.theme-rose .light .mat-date-range-input-separator,
.theme-rose.light .mat-calendar-body-cell-content,
.theme-rose.light .mat-date-range-input-separator {
  color: #1e293b;
  border-color: transparent;
}
.theme-rose .light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-rose.light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: #94a3b8;
}
.theme-rose .light .mat-form-field-disabled .mat-date-range-input-separator,
.theme-rose.light .mat-form-field-disabled .mat-date-range-input-separator {
  color: #94a3b8;
}
.theme-rose .light .mat-calendar-body-in-preview,
.theme-rose.light .mat-calendar-body-in-preview {
  color: #e2e8f0;
}
.theme-rose .light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-rose.light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #94a3b8;
}
.theme-rose .light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-rose.light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(148, 163, 184, 0.8);
}
.theme-rose .light .mat-calendar-body-in-range::before,
.theme-rose.light .mat-calendar-body-in-range::before {
  background: rgba(244, 63, 94, 0.2);
}
.theme-rose .light .mat-calendar-body-comparison-identical,
.theme-rose .light .mat-calendar-body-in-comparison-range::before,
.theme-rose.light .mat-calendar-body-comparison-identical,
.theme-rose.light .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-rose .light .mat-calendar-body-comparison-bridge-start::before,
.theme-rose .light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-rose.light .mat-calendar-body-comparison-bridge-start::before,
.theme-rose.light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 63, 94, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-rose .light .mat-calendar-body-comparison-bridge-end::before,
.theme-rose .light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-rose.light .mat-calendar-body-comparison-bridge-end::before,
.theme-rose.light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 63, 94, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-rose .light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-rose .light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-rose.light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-rose.light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-rose .light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-rose .light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-rose.light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-rose.light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-rose .light .mat-calendar-body-selected,
.theme-rose.light .mat-calendar-body-selected {
  background-color: #f43f5e;
  color: white;
}
.theme-rose .light .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-rose.light .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 63, 94, 0.4);
}
.theme-rose .light .mat-calendar-body-today.mat-calendar-body-selected,
.theme-rose.light .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-rose .light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-rose .light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-rose.light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-rose.light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 63, 94, 0.3);
}
@media (hover: hover) {
  .theme-rose .light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-rose.light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 63, 94, 0.3);
  }
}
.theme-rose .light .mat-datepicker-content,
.theme-rose.light .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: #1e293b;
}
.theme-rose .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before,
.theme-rose.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(30, 41, 59, 0.2);
}
.theme-rose .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-rose .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before,
.theme-rose.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-rose.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-rose .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-rose .light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-rose.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-rose.light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(30, 41, 59, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-rose .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-rose .light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-rose.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-rose.light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(30, 41, 59, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-rose .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-rose .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-rose.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-rose.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-rose .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-rose .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-rose.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-rose.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-rose .light .mat-datepicker-content.mat-accent .mat-calendar-body-selected,
.theme-rose.light .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #1e293b;
  color: white;
}
.theme-rose .light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-rose.light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(30, 41, 59, 0.4);
}
.theme-rose .light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected,
.theme-rose.light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-rose .light .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-rose .light .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-rose.light .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-rose.light .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(30, 41, 59, 0.3);
}
@media (hover: hover) {
  .theme-rose .light .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-rose.light .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(30, 41, 59, 0.3);
  }
}
.theme-rose .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before,
.theme-rose.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(220, 38, 38, 0.2);
}
.theme-rose .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-rose .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before,
.theme-rose.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-rose.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-rose .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-rose .light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-rose.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-rose.light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(220, 38, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-rose .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-rose .light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-rose.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-rose.light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(220, 38, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-rose .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-rose .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-rose.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-rose.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-rose .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-rose .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-rose.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-rose.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-rose .light .mat-datepicker-content.mat-warn .mat-calendar-body-selected,
.theme-rose.light .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #dc2626;
  color: white;
}
.theme-rose .light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-rose.light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(220, 38, 38, 0.4);
}
.theme-rose .light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected,
.theme-rose.light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-rose .light .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-rose .light .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-rose.light .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-rose.light .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(220, 38, 38, 0.3);
}
@media (hover: hover) {
  .theme-rose .light .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-rose.light .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(220, 38, 38, 0.3);
  }
}
.theme-rose .light .mat-datepicker-content-touch,
.theme-rose.light .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-datepicker-toggle-active,
.theme-rose.light .mat-datepicker-toggle-active {
  color: #f43f5e;
}
.theme-rose .light .mat-datepicker-toggle-active.mat-accent,
.theme-rose.light .mat-datepicker-toggle-active.mat-accent {
  color: #1e293b;
}
.theme-rose .light .mat-datepicker-toggle-active.mat-warn,
.theme-rose.light .mat-datepicker-toggle-active.mat-warn {
  color: #dc2626;
}
.theme-rose .light .mat-date-range-input-inner[disabled],
.theme-rose.light .mat-date-range-input-inner[disabled] {
  color: #94a3b8;
}
.theme-rose .light .mat-divider,
.theme-rose.light .mat-divider {
  border-top-color: #e2e8f0;
}
.theme-rose .light .mat-divider-vertical,
.theme-rose.light .mat-divider-vertical {
  border-right-color: #e2e8f0;
}
.theme-rose .light .mat-expansion-panel,
.theme-rose.light .mat-expansion-panel {
  background: white;
  color: #1e293b;
}
.theme-rose .light .mat-expansion-panel:not([class*=mat-elevation-z]),
.theme-rose.light .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-action-row,
.theme-rose.light .mat-action-row {
  border-top-color: #e2e8f0;
}
.theme-rose .light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .theme-rose .light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .theme-rose .light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]),
.theme-rose.light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
.theme-rose.light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
.theme-rose.light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(148, 163, 184, 0.12);
}
@media (hover: none) {
  .theme-rose .light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover,
  .theme-rose.light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.theme-rose .light .mat-expansion-panel-header-title,
.theme-rose.light .mat-expansion-panel-header-title {
  color: #1e293b;
}
.theme-rose .light .mat-expansion-panel-header-description,
.theme-rose .light .mat-expansion-indicator::after,
.theme-rose.light .mat-expansion-panel-header-description,
.theme-rose.light .mat-expansion-indicator::after {
  color: #64748b;
}
.theme-rose .light .mat-expansion-panel-header[aria-disabled=true],
.theme-rose.light .mat-expansion-panel-header[aria-disabled=true] {
  color: #94a3b8;
}
.theme-rose .light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-rose .light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description,
.theme-rose.light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-rose.light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.theme-rose .light .mat-icon.mat-primary,
.theme-rose.light .mat-icon.mat-primary {
  color: #f43f5e;
}
.theme-rose .light .mat-icon.mat-accent,
.theme-rose.light .mat-icon.mat-accent {
  color: #1e293b;
}
.theme-rose .light .mat-icon.mat-warn,
.theme-rose.light .mat-icon.mat-warn {
  color: #dc2626;
}
.theme-rose .light .mat-drawer-container,
.theme-rose.light .mat-drawer-container {
  background-color: #f1f5f9;
  color: #1e293b;
}
.theme-rose .light .mat-drawer,
.theme-rose.light .mat-drawer {
  background-color: white;
  color: #1e293b;
}
.theme-rose .light .mat-drawer.mat-drawer-push,
.theme-rose.light .mat-drawer.mat-drawer-push {
  background-color: white;
}
.theme-rose .light .mat-drawer:not(.mat-drawer-side),
.theme-rose.light .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-drawer-side,
.theme-rose.light .mat-drawer-side {
  border-right: solid 1px #e2e8f0;
}
.theme-rose .light .mat-drawer-side.mat-drawer-end,
.theme-rose.light .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px #e2e8f0;
  border-right: none;
}
.theme-rose .light [dir=rtl] .mat-drawer-side,
.theme-rose.light [dir=rtl] .mat-drawer-side {
  border-left: solid 1px #e2e8f0;
  border-right: none;
}
.theme-rose .light [dir=rtl] .mat-drawer-side.mat-drawer-end,
.theme-rose.light [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px #e2e8f0;
}
.theme-rose .light .mat-drawer-backdrop.mat-drawer-shown,
.theme-rose.light .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.theme-rose .light .mat-step-header.cdk-keyboard-focused, .theme-rose .light .mat-step-header.cdk-program-focused, .theme-rose .light .mat-step-header:hover:not([aria-disabled]), .theme-rose .light .mat-step-header:hover[aria-disabled=false],
.theme-rose.light .mat-step-header.cdk-keyboard-focused,
.theme-rose.light .mat-step-header.cdk-program-focused,
.theme-rose.light .mat-step-header:hover:not([aria-disabled]),
.theme-rose.light .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(148, 163, 184, 0.12);
}
.theme-rose .light .mat-step-header:hover[aria-disabled=true],
.theme-rose.light .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .theme-rose .light .mat-step-header:hover,
  .theme-rose.light .mat-step-header:hover {
    background: none;
  }
}
.theme-rose .light .mat-step-header .mat-step-label,
.theme-rose .light .mat-step-header .mat-step-optional,
.theme-rose.light .mat-step-header .mat-step-label,
.theme-rose.light .mat-step-header .mat-step-optional {
  color: #64748b;
}
.theme-rose .light .mat-step-header .mat-step-icon,
.theme-rose.light .mat-step-header .mat-step-icon {
  background-color: #64748b;
  color: white;
}
.theme-rose .light .mat-step-header .mat-step-icon-selected,
.theme-rose .light .mat-step-header .mat-step-icon-state-done,
.theme-rose .light .mat-step-header .mat-step-icon-state-edit,
.theme-rose.light .mat-step-header .mat-step-icon-selected,
.theme-rose.light .mat-step-header .mat-step-icon-state-done,
.theme-rose.light .mat-step-header .mat-step-icon-state-edit {
  background-color: #f43f5e;
  color: white;
}
.theme-rose .light .mat-step-header.mat-accent .mat-step-icon,
.theme-rose.light .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.theme-rose .light .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-rose .light .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-rose .light .mat-step-header.mat-accent .mat-step-icon-state-edit,
.theme-rose.light .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-rose.light .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-rose.light .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #1e293b;
  color: white;
}
.theme-rose .light .mat-step-header.mat-warn .mat-step-icon,
.theme-rose.light .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.theme-rose .light .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-rose .light .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-rose .light .mat-step-header.mat-warn .mat-step-icon-state-edit,
.theme-rose.light .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-rose.light .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-rose.light .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #dc2626;
  color: white;
}
.theme-rose .light .mat-step-header .mat-step-icon-state-error,
.theme-rose.light .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #dc2626;
}
.theme-rose .light .mat-step-header .mat-step-label.mat-step-label-active,
.theme-rose.light .mat-step-header .mat-step-label.mat-step-label-active {
  color: #1e293b;
}
.theme-rose .light .mat-step-header .mat-step-label.mat-step-label-error,
.theme-rose.light .mat-step-header .mat-step-label.mat-step-label-error {
  color: #dc2626;
}
.theme-rose .light .mat-stepper-horizontal, .theme-rose .light .mat-stepper-vertical,
.theme-rose.light .mat-stepper-horizontal,
.theme-rose.light .mat-stepper-vertical {
  background-color: white;
}
.theme-rose .light .mat-stepper-vertical-line::before,
.theme-rose.light .mat-stepper-vertical-line::before {
  border-left-color: #e2e8f0;
}
.theme-rose .light .mat-horizontal-stepper-header::before,
.theme-rose .light .mat-horizontal-stepper-header::after,
.theme-rose .light .mat-stepper-horizontal-line,
.theme-rose.light .mat-horizontal-stepper-header::before,
.theme-rose.light .mat-horizontal-stepper-header::after,
.theme-rose.light .mat-stepper-horizontal-line {
  border-top-color: #e2e8f0;
}
.theme-rose .light .mat-sort-header-arrow,
.theme-rose.light .mat-sort-header-arrow {
  color: #64748b;
}
.theme-rose .light .mat-toolbar,
.theme-rose.light .mat-toolbar {
  background: white;
  color: #1e293b;
}
.theme-rose .light .mat-toolbar.mat-primary,
.theme-rose.light .mat-toolbar.mat-primary {
  background: #f43f5e;
  color: white;
}
.theme-rose .light .mat-toolbar.mat-accent,
.theme-rose.light .mat-toolbar.mat-accent {
  background: #1e293b;
  color: white;
}
.theme-rose .light .mat-toolbar.mat-warn,
.theme-rose.light .mat-toolbar.mat-warn {
  background: #dc2626;
  color: white;
}
.theme-rose .light .mat-toolbar .mat-form-field-underline,
.theme-rose .light .mat-toolbar .mat-form-field-ripple,
.theme-rose .light .mat-toolbar .mat-focused .mat-form-field-ripple,
.theme-rose.light .mat-toolbar .mat-form-field-underline,
.theme-rose.light .mat-toolbar .mat-form-field-ripple,
.theme-rose.light .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.theme-rose .light .mat-toolbar .mat-form-field-label,
.theme-rose .light .mat-toolbar .mat-focused .mat-form-field-label,
.theme-rose .light .mat-toolbar .mat-select-value,
.theme-rose .light .mat-toolbar .mat-select-arrow,
.theme-rose .light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow,
.theme-rose.light .mat-toolbar .mat-form-field-label,
.theme-rose.light .mat-toolbar .mat-focused .mat-form-field-label,
.theme-rose.light .mat-toolbar .mat-select-value,
.theme-rose.light .mat-toolbar .mat-select-arrow,
.theme-rose.light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.theme-rose .light .mat-toolbar .mat-input-element,
.theme-rose.light .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.theme-rose .light .mat-tree,
.theme-rose.light .mat-tree {
  background: white;
}
.theme-rose .light .mat-tree-node,
.theme-rose .light .mat-nested-tree-node,
.theme-rose.light .mat-tree-node,
.theme-rose.light .mat-nested-tree-node {
  color: #1e293b;
}

.theme-rose .dark .mat-ripple-element,
.theme-rose.dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .dark .mat-mdc-option,
.theme-rose.dark .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-rose .dark .mat-mdc-option:hover:not(.mdc-list-item--disabled), .theme-rose .dark .mat-mdc-option:focus:not(.mdc-list-item--disabled), .theme-rose .dark .mat-mdc-option.mat-mdc-option-active, .theme-rose .dark .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled),
.theme-rose.dark .mat-mdc-option:hover:not(.mdc-list-item--disabled),
.theme-rose.dark .mat-mdc-option:focus:not(.mdc-list-item--disabled),
.theme-rose.dark .mat-mdc-option.mat-mdc-option-active,
.theme-rose.dark .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(255, 255, 255, 0.08);
}
.theme-rose .dark .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-rose.dark .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #f43f5e);
}
.theme-rose .dark .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-rose.dark .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #1e293b);
}
.theme-rose .dark .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-rose.dark .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mat-mdc-optgroup-label,
.theme-rose.dark .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-rose .dark .mat-pseudo-checkbox,
.theme-rose.dark .mat-pseudo-checkbox {
  color: #94a3b8;
}
.theme-rose .dark .mat-pseudo-checkbox::after,
.theme-rose.dark .mat-pseudo-checkbox::after {
  color: #0f172a;
}
.theme-rose .dark .mat-pseudo-checkbox-disabled,
.theme-rose.dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.theme-rose .dark .mat-primary .mat-pseudo-checkbox-checked,
.theme-rose .dark .mat-primary .mat-pseudo-checkbox-indeterminate,
.theme-rose.dark .mat-primary .mat-pseudo-checkbox-checked,
.theme-rose.dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #f43f5e;
}
.theme-rose .dark .mat-pseudo-checkbox-checked,
.theme-rose .dark .mat-pseudo-checkbox-indeterminate,
.theme-rose .dark .mat-accent .mat-pseudo-checkbox-checked,
.theme-rose .dark .mat-accent .mat-pseudo-checkbox-indeterminate,
.theme-rose.dark .mat-pseudo-checkbox-checked,
.theme-rose.dark .mat-pseudo-checkbox-indeterminate,
.theme-rose.dark .mat-accent .mat-pseudo-checkbox-checked,
.theme-rose.dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #1e293b;
}
.theme-rose .dark .mat-warn .mat-pseudo-checkbox-checked,
.theme-rose .dark .mat-warn .mat-pseudo-checkbox-indeterminate,
.theme-rose.dark .mat-warn .mat-pseudo-checkbox-checked,
.theme-rose.dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #dc2626;
}
.theme-rose .dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-rose .dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled,
.theme-rose.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-rose.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.theme-rose .dark .mat-app-background, .theme-rose .dark.mat-app-background,
.theme-rose.dark .mat-app-background,
.theme-rose.dark.mat-app-background {
  background-color: #0f172a;
  color: white;
}
.theme-rose .dark .mat-elevation-z0, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-rose.dark .mat-elevation-z0,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z1, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-rose.dark .mat-elevation-z1,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z2, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-rose.dark .mat-elevation-z2,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z3, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-rose.dark .mat-elevation-z3,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z4, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-rose.dark .mat-elevation-z4,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z5, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-rose.dark .mat-elevation-z5,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z6, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-rose.dark .mat-elevation-z6,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z7, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-rose.dark .mat-elevation-z7,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z8, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-rose.dark .mat-elevation-z8,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z9, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-rose.dark .mat-elevation-z9,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z10, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-rose.dark .mat-elevation-z10,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z11, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-rose.dark .mat-elevation-z11,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z12, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-rose.dark .mat-elevation-z12,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z13, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-rose.dark .mat-elevation-z13,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z14, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-rose.dark .mat-elevation-z14,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z15, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-rose.dark .mat-elevation-z15,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z16, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-rose.dark .mat-elevation-z16,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z17, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-rose.dark .mat-elevation-z17,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z18, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-rose.dark .mat-elevation-z18,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z19, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-rose.dark .mat-elevation-z19,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z20, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-rose.dark .mat-elevation-z20,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z21, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-rose.dark .mat-elevation-z21,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z22, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-rose.dark .mat-elevation-z22,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z23, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-rose.dark .mat-elevation-z23,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z24, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-rose.dark .mat-elevation-z24,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-rose .dark .mat-mdc-card,
.theme-rose.dark .mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #1e293b;
}
.theme-rose .dark .mat-mdc-card-outlined,
.theme-rose.dark .mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #394353;
}
.theme-rose .dark .mat-mdc-card-subtitle,
.theme-rose.dark .mat-mdc-card-subtitle {
  color: #94a3b8;
}
.theme-rose .dark .mat-mdc-progress-bar,
.theme-rose.dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #f43f5e;
}
.theme-rose .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.theme-rose.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 63, 94, 0.25)'/%3E%3C/svg%3E");
}
.theme-rose .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.theme-rose.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 63, 94, 0.25);
}
.theme-rose .dark .mat-mdc-progress-bar.mat-accent,
.theme-rose.dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
}
.theme-rose .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.theme-rose.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(30, 41, 59, 0.25)'/%3E%3C/svg%3E");
}
.theme-rose .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.theme-rose.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(30, 41, 59, 0.25);
}
.theme-rose .dark .mat-mdc-progress-bar.mat-warn,
.theme-rose.dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
}
.theme-rose .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.theme-rose.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(220, 38, 38, 0.25)'/%3E%3C/svg%3E");
}
.theme-rose .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.theme-rose.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(220, 38, 38, 0.25);
}
.theme-rose .dark .mat-mdc-tooltip,
.theme-rose.dark .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.theme-rose .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-rose.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 255, 255, 0.6);
}
.theme-rose .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input,
.theme-rose.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.87);
}
@media all {
  .theme-rose .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder,
  .theme-rose.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}
@media all {
  .theme-rose .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder,
  .theme-rose.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}
.theme-rose .dark .mdc-text-field .mdc-text-field__input,
.theme-rose.dark .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #f43f5e);
}
.theme-rose .dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text,
.theme-rose.dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.theme-rose .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.theme-rose .dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter,
.theme-rose.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.theme-rose.dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.theme-rose .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading,
.theme-rose.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.theme-rose .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-rose.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.theme-rose .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix,
.theme-rose.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-rose .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix,
.theme-rose.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-rose .dark .mdc-text-field--filled .mdc-text-field__ripple::before,
.theme-rose .dark .mdc-text-field--filled .mdc-text-field__ripple::after,
.theme-rose.dark .mdc-text-field--filled .mdc-text-field__ripple::before,
.theme-rose.dark .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(255, 255, 255, 0.87));
}
.theme-rose .dark .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .theme-rose .dark .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before,
.theme-rose.dark .mdc-text-field--filled:hover .mdc-text-field__ripple::before,
.theme-rose.dark .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.theme-rose .dark .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .theme-rose .dark .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before,
.theme-rose.dark .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before,
.theme-rose.dark .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.theme-rose .dark .mdc-text-field--filled:not(.mdc-text-field--disabled),
.theme-rose.dark .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #273243;
}
.theme-rose .dark .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-rose.dark .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.42);
}
.theme-rose .dark .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-rose.dark .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.87);
}
.theme-rose .dark .mdc-text-field--filled .mdc-line-ripple::after,
.theme-rose.dark .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #f43f5e);
}
.theme-rose .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-rose .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-rose .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-rose.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-rose.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-rose.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.38);
}
.theme-rose .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-rose .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-rose .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-rose.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-rose.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-rose.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.87);
}
.theme-rose .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-rose .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-rose .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-rose.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-rose.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-rose.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #f43f5e);
}
[dir=rtl] .theme-rose .dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon, [dir=rtl] .theme-rose.dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .theme-rose .dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl], .theme-rose.dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-rose .dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, [dir=rtl] .theme-rose.dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .theme-rose .dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl], .theme-rose.dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-rose .dark .mdc-text-field--outlined .mdc-text-field__ripple::before,
.theme-rose .dark .mdc-text-field--outlined .mdc-text-field__ripple::after,
.theme-rose.dark .mdc-text-field--outlined .mdc-text-field__ripple::before,
.theme-rose.dark .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .theme-rose .dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined, [dir=rtl] .theme-rose.dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .theme-rose .dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl], .theme-rose.dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-rose .dark .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-rose.dark .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(244, 63, 94, 0.87);
}
.theme-rose .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-rose.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-rose.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-rose.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-rose.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mdc-text-field--invalid .mdc-text-field__input,
.theme-rose.dark .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-rose.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-rose.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-rose .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-rose .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-rose.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-rose.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-rose.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-rose .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-rose .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-rose.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-rose.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-rose.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-rose .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-rose .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-rose.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-rose.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-rose.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mdc-text-field--disabled .mdc-text-field__input,
.theme-rose.dark .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.38);
}
@media all {
  .theme-rose .dark .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  .theme-rose.dark .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.38);
  }
}
@media all {
  .theme-rose .dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  .theme-rose.dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.38);
  }
}
.theme-rose .dark .mdc-text-field--disabled .mdc-floating-label,
.theme-rose.dark .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(255, 255, 255, 0.38);
}
.theme-rose .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
.theme-rose.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.theme-rose .dark .mdc-text-field--disabled .mdc-text-field-character-counter,
.theme-rose .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
.theme-rose.dark .mdc-text-field--disabled .mdc-text-field-character-counter,
.theme-rose.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.theme-rose .dark .mdc-text-field--disabled .mdc-text-field__icon--leading,
.theme-rose.dark .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.theme-rose .dark .mdc-text-field--disabled .mdc-text-field__icon--trailing,
.theme-rose.dark .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.theme-rose .dark .mdc-text-field--disabled .mdc-text-field__affix--prefix,
.theme-rose.dark .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-rose .dark .mdc-text-field--disabled .mdc-text-field__affix--suffix,
.theme-rose.dark .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-rose .dark .mdc-text-field--disabled .mdc-line-ripple::before,
.theme-rose.dark .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.06);
}
.theme-rose .dark .mdc-text-field--disabled .mdc-notched-outline__leading,
.theme-rose .dark .mdc-text-field--disabled .mdc-notched-outline__notch,
.theme-rose .dark .mdc-text-field--disabled .mdc-notched-outline__trailing,
.theme-rose.dark .mdc-text-field--disabled .mdc-notched-outline__leading,
.theme-rose.dark .mdc-text-field--disabled .mdc-notched-outline__notch,
.theme-rose.dark .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-rose .dark .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  .theme-rose.dark .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-rose .dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  .theme-rose.dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-rose .dark .mdc-text-field--disabled .mdc-floating-label,
  .theme-rose.dark .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-rose .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
  .theme-rose.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-rose .dark .mdc-text-field--disabled .mdc-text-field-character-counter,
  .theme-rose .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
  .theme-rose.dark .mdc-text-field--disabled .mdc-text-field-character-counter,
  .theme-rose.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-rose .dark .mdc-text-field--disabled .mdc-text-field__icon--leading,
  .theme-rose.dark .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-rose .dark .mdc-text-field--disabled .mdc-text-field__icon--trailing,
  .theme-rose.dark .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-rose .dark .mdc-text-field--disabled .mdc-text-field__affix--prefix,
  .theme-rose.dark .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-rose .dark .mdc-text-field--disabled .mdc-text-field__affix--suffix,
  .theme-rose.dark .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-rose .dark .mdc-text-field--disabled .mdc-line-ripple::before,
  .theme-rose.dark .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-rose .dark .mdc-text-field--disabled .mdc-notched-outline__leading,
  .theme-rose .dark .mdc-text-field--disabled .mdc-notched-outline__notch,
  .theme-rose .dark .mdc-text-field--disabled .mdc-notched-outline__trailing,
  .theme-rose.dark .mdc-text-field--disabled .mdc-notched-outline__leading,
  .theme-rose.dark .mdc-text-field--disabled .mdc-notched-outline__notch,
  .theme-rose.dark .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.theme-rose .dark .mdc-text-field--disabled.mdc-text-field--filled,
.theme-rose.dark .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #232d3f;
}
[dir=rtl] .theme-rose .dark .mdc-text-field--end-aligned .mdc-text-field__input, [dir=rtl] .theme-rose.dark .mdc-text-field--end-aligned .mdc-text-field__input, .theme-rose .dark .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl], .theme-rose.dark .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-rose .dark .mdc-floating-label--required, [dir=rtl] .theme-rose.dark .mdc-floating-label--required, .theme-rose .dark .mdc-floating-label--required[dir=rtl], .theme-rose.dark .mdc-floating-label--required[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-rose .dark .mat-mdc-form-field-error,
.theme-rose.dark .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mat-mdc-form-field-focus-overlay,
.theme-rose.dark .mat-mdc-form-field-focus-overlay {
  background-color: rgba(255, 255, 255, 0.87);
}
.theme-rose .dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.theme-rose.dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.08;
}
.theme-rose .dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.theme-rose.dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.24;
}
.theme-rose .dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option,
.theme-rose.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.theme-rose .dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled,
.theme-rose.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.theme-rose .dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.theme-rose.dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.54);
}
.theme-rose .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.theme-rose.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(244, 63, 94, 0.87);
}
.theme-rose .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.theme-rose.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(30, 41, 59, 0.87);
}
.theme-rose .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.theme-rose.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(220, 38, 38, 0.87);
}
.theme-rose .dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.theme-rose.dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.38);
}
.theme-rose .dark .mat-mdc-form-field.mat-accent .mdc-text-field__input,
.theme-rose.dark .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-rose .dark .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-rose.dark .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-rose .dark .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-rose.dark .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(30, 41, 59, 0.87);
}
.theme-rose .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-rose.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-rose.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-rose.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-rose.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input,
.theme-rose.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-rose.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-rose.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-rose .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-rose .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-rose.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-rose.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-rose.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-rose .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-rose .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-rose.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-rose.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-rose.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-rose .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-rose .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-rose.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-rose.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-rose.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-rose .dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-rose .dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-rose.dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-rose.dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-rose.dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-rose .dark .mat-mdc-form-field.mat-warn .mdc-text-field__input,
.theme-rose.dark .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-rose.dark .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-rose.dark .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(220, 38, 38, 0.87);
}
.theme-rose .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-rose.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-rose.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-rose.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-rose.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input,
.theme-rose.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-rose.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-rose.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-rose .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-rose .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-rose.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-rose.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-rose.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-rose .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-rose .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-rose.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-rose.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-rose.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-rose .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-rose .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-rose.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-rose.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-rose.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-rose .dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-rose .dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-rose.dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-rose.dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-rose.dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-rose .dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.theme-rose.dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.theme-rose .dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.theme-rose.dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.theme-rose .dark .mdc-menu-surface,
.theme-rose.dark .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #1e293b);
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mdc-list-item__primary-text,
.theme-rose.dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-rose .dark .mdc-list-item__secondary-text,
.theme-rose.dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.theme-rose .dark .mdc-list-item__overline-text,
.theme-rose.dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-rose .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-rose.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-rose .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-rose.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.theme-rose .dark .mdc-list-item__end,
.theme-rose.dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-rose .dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-rose .dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-rose .dark .mdc-list-item--disabled .mdc-list-item__end,
.theme-rose.dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-rose.dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-rose.dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-rose .dark .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-rose.dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-rose.dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-rose.dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-rose.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-rose.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-rose .dark .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-rose.dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-rose.dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #f43f5e);
}
.theme-rose .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #f43f5e);
}
.theme-rose .dark .mdc-deprecated-list-group__subheader,
.theme-rose.dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-rose .dark .mdc-list-divider::after,
.theme-rose.dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-rose .dark .mdc-list-divider,
.theme-rose.dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-rose .dark .mat-mdc-select-value,
.theme-rose.dark .mat-mdc-select-value {
  color: rgba(255, 255, 255, 0.87);
}
.theme-rose .dark .mat-mdc-select-placeholder,
.theme-rose.dark .mat-mdc-select-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.theme-rose .dark .mat-mdc-select-disabled .mat-mdc-select-value,
.theme-rose.dark .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(255, 255, 255, 0.38);
}
.theme-rose .dark .mat-mdc-select-arrow,
.theme-rose.dark .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.54);
}
.theme-rose .dark .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow,
.theme-rose.dark .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(244, 63, 94, 0.87);
}
.theme-rose .dark .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow,
.theme-rose.dark .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(30, 41, 59, 0.87);
}
.theme-rose .dark .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow,
.theme-rose.dark .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(220, 38, 38, 0.87);
}
.theme-rose .dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow,
.theme-rose.dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(220, 38, 38, 0.87);
}
.theme-rose .dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow,
.theme-rose.dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.38);
}
.theme-rose .dark .mdc-menu-surface,
.theme-rose.dark .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #1e293b);
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mdc-list-item__primary-text,
.theme-rose.dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-rose .dark .mdc-list-item__secondary-text,
.theme-rose.dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.theme-rose .dark .mdc-list-item__overline-text,
.theme-rose.dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-rose .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-rose.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-rose .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-rose.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.theme-rose .dark .mdc-list-item__end,
.theme-rose.dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-rose .dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-rose .dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-rose .dark .mdc-list-item--disabled .mdc-list-item__end,
.theme-rose.dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-rose.dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-rose.dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-rose .dark .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-rose.dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-rose.dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-rose.dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-rose.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-rose.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-rose .dark .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-rose.dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-rose.dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #f43f5e);
}
.theme-rose .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #f43f5e);
}
.theme-rose .dark .mdc-deprecated-list-group__subheader,
.theme-rose.dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-rose .dark .mdc-list-divider::after,
.theme-rose.dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-rose .dark .mdc-list-divider,
.theme-rose.dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-rose .dark .mat-mdc-dialog-container,
.theme-rose.dark .mat-mdc-dialog-container {
  --mdc-dialog-container-color: #1e293b;
  --mdc-dialog-with-divider-divider-color: rgba(255, 255, 255, 0.12);
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
}
.theme-rose .dark .mat-mdc-standard-chip,
.theme-rose.dark .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #394353;
  --mdc-chip-elevated-disabled-container-color: #394353;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
}
.theme-rose .dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .theme-rose .dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted,
.theme-rose.dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected,
.theme-rose.dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #f43f5e;
  --mdc-chip-elevated-disabled-container-color: #f43f5e;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-rose .dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .theme-rose .dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted,
.theme-rose.dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected,
.theme-rose.dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-rose .dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .theme-rose .dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted,
.theme-rose.dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected,
.theme-rose.dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-rose .dark .mat-mdc-chip-focus-overlay,
.theme-rose.dark .mat-mdc-chip-focus-overlay {
  background: #FFFFFF;
}
.theme-rose .dark .mat-mdc-slide-toggle,
.theme-rose.dark .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-unselected-icon-color: #212121;
}
.theme-rose .dark .mat-mdc-slide-toggle .mdc-form-field,
.theme-rose.dark .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-rose .dark .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.theme-rose.dark .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: #475569;
}
.theme-rose .dark .mat-mdc-slide-toggle.mat-primary,
.theme-rose.dark .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #fda4af;
  --mdc-switch-selected-handle-color: #fda4af;
  --mdc-switch-selected-hover-state-layer-color: #fda4af;
  --mdc-switch-selected-pressed-state-layer-color: #fda4af;
  --mdc-switch-selected-focus-handle-color: #fecdd3;
  --mdc-switch-selected-hover-handle-color: #fecdd3;
  --mdc-switch-selected-pressed-handle-color: #fecdd3;
  --mdc-switch-selected-focus-track-color: #e11d48;
  --mdc-switch-selected-hover-track-color: #e11d48;
  --mdc-switch-selected-pressed-track-color: #e11d48;
  --mdc-switch-selected-track-color: #e11d48;
}
.theme-rose .dark .mat-mdc-slide-toggle.mat-accent,
.theme-rose.dark .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #cbd5e1;
  --mdc-switch-selected-handle-color: #cbd5e1;
  --mdc-switch-selected-hover-state-layer-color: #cbd5e1;
  --mdc-switch-selected-pressed-state-layer-color: #cbd5e1;
  --mdc-switch-selected-focus-handle-color: #e2e8f0;
  --mdc-switch-selected-hover-handle-color: #e2e8f0;
  --mdc-switch-selected-pressed-handle-color: #e2e8f0;
  --mdc-switch-selected-focus-track-color: #475569;
  --mdc-switch-selected-hover-track-color: #475569;
  --mdc-switch-selected-pressed-track-color: #475569;
  --mdc-switch-selected-track-color: #475569;
}
.theme-rose .dark .mat-mdc-slide-toggle.mat-warn,
.theme-rose.dark .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #fca5a5;
  --mdc-switch-selected-handle-color: #fca5a5;
  --mdc-switch-selected-hover-state-layer-color: #fca5a5;
  --mdc-switch-selected-pressed-state-layer-color: #fca5a5;
  --mdc-switch-selected-focus-handle-color: #fecaca;
  --mdc-switch-selected-hover-handle-color: #fecaca;
  --mdc-switch-selected-pressed-handle-color: #fecaca;
  --mdc-switch-selected-focus-track-color: #dc2626;
  --mdc-switch-selected-hover-track-color: #dc2626;
  --mdc-switch-selected-pressed-track-color: #dc2626;
  --mdc-switch-selected-track-color: #dc2626;
}
.theme-rose .dark .mat-mdc-radio-button,
.theme-rose.dark .mat-mdc-radio-button {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mat-mdc-radio-ripple-color: #fff;
}
.theme-rose .dark .mat-mdc-radio-button .mdc-form-field,
.theme-rose.dark .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-rose .dark .mat-mdc-radio-button .mdc-radio--disabled + label,
.theme-rose.dark .mat-mdc-radio-button .mdc-radio--disabled + label {
  color: #475569;
}
.theme-rose .dark .mat-mdc-radio-button.mat-primary,
.theme-rose.dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-selected-focus-icon-color: #f43f5e;
  --mdc-radio-selected-hover-icon-color: #f43f5e;
  --mdc-radio-selected-icon-color: #f43f5e;
  --mdc-radio-selected-pressed-icon-color: #f43f5e;
  --mat-mdc-radio-checked-ripple-color: #f43f5e;
}
.theme-rose .dark .mat-mdc-radio-button.mat-accent,
.theme-rose.dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-mdc-radio-checked-ripple-color: #1e293b;
}
.theme-rose .dark .mat-mdc-radio-button.mat-warn,
.theme-rose.dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-mdc-radio-checked-ripple-color: #dc2626;
}
.theme-rose .dark .mat-mdc-slider,
.theme-rose.dark .mat-mdc-slider {
  --mdc-slider-label-container-color: white;
  --mdc-slider-label-label-text-color: black;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mat-mdc-slider-value-indicator-opacity: 0.9;
}
.theme-rose .dark .mat-mdc-slider.mat-primary,
.theme-rose.dark .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #f43f5e;
  --mdc-slider-focus-handle-color: #f43f5e;
  --mdc-slider-hover-handle-color: #f43f5e;
  --mdc-slider-active-track-color: #f43f5e;
  --mdc-slider-inactive-track-color: #f43f5e;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #f43f5e;
  --mat-mdc-slider-ripple-color: #f43f5e;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 63, 94, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 63, 94, 0.2);
}
.theme-rose .dark .mat-mdc-slider.mat-accent,
.theme-rose.dark .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mat-mdc-slider-ripple-color: #1e293b;
  --mat-mdc-slider-hover-ripple-color: rgba(30, 41, 59, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(30, 41, 59, 0.2);
}
.theme-rose .dark .mat-mdc-slider.mat-warn,
.theme-rose.dark .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mat-mdc-slider-ripple-color: #dc2626;
  --mat-mdc-slider-hover-ripple-color: rgba(220, 38, 38, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(220, 38, 38, 0.2);
}
.theme-rose .dark .mdc-menu-surface,
.theme-rose.dark .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #1e293b);
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mdc-list-item__primary-text,
.theme-rose.dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-rose .dark .mdc-list-item__secondary-text,
.theme-rose.dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.theme-rose .dark .mdc-list-item__overline-text,
.theme-rose.dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-rose .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-rose.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-rose .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-rose.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.theme-rose .dark .mdc-list-item__end,
.theme-rose.dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-rose .dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-rose .dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-rose .dark .mdc-list-item--disabled .mdc-list-item__end,
.theme-rose.dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-rose.dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-rose.dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-rose .dark .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-rose.dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-rose.dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-rose.dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-rose.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-rose.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-rose .dark .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-rose.dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-rose.dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #f43f5e);
}
.theme-rose .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #f43f5e);
}
.theme-rose .dark .mdc-deprecated-list-group__subheader,
.theme-rose.dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-rose .dark .mdc-list-divider::after,
.theme-rose.dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-rose .dark .mdc-list-divider,
.theme-rose.dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-rose .dark .mat-mdc-menu-item[disabled],
.theme-rose .dark .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.theme-rose .dark .mat-mdc-menu-item[disabled] .mat-icon-no-color,
.theme-rose.dark .mat-mdc-menu-item[disabled],
.theme-rose.dark .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.theme-rose.dark .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(255, 255, 255, 0.5));
}
.theme-rose .dark .mat-mdc-menu-item .mat-icon-no-color,
.theme-rose .dark .mat-mdc-menu-submenu-icon,
.theme-rose.dark .mat-mdc-menu-item .mat-icon-no-color,
.theme-rose.dark .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-rose .dark .mat-mdc-menu-item:hover:not([disabled]),
.theme-rose .dark .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.theme-rose .dark .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-rose .dark .mat-mdc-menu-item-highlighted:not([disabled]),
.theme-rose.dark .mat-mdc-menu-item:hover:not([disabled]),
.theme-rose.dark .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.theme-rose.dark .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-rose.dark .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.08);
}
.theme-rose .dark .mat-mdc-list-item-interactive::before,
.theme-rose.dark .mat-mdc-list-item-interactive::before {
  background: white;
}
.theme-rose .dark .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before,
.theme-rose.dark .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.08;
}
.theme-rose .dark .mat-mdc-list-item-interactive.mdc-list-item--selected::before,
.theme-rose.dark .mat-mdc-list-item-interactive.mdc-list-item--selected::before {
  background: #f43f5e;
  opacity: 0.08;
}
.theme-rose .dark .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before,
.theme-rose.dark .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.12;
}
.theme-rose .dark .mat-mdc-list-item-interactive:focus::before,
.theme-rose.dark .mat-mdc-list-item-interactive:focus::before {
  opacity: 0.24;
}
.theme-rose .dark .mdc-list-item__primary-text,
.theme-rose.dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-rose .dark .mdc-list-item__secondary-text,
.theme-rose.dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.theme-rose .dark .mdc-list-item__overline-text,
.theme-rose.dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-rose .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-rose.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-rose .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-rose.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.theme-rose .dark .mdc-list-item__end,
.theme-rose.dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-rose .dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-rose .dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-rose .dark .mdc-list-item--disabled .mdc-list-item__end,
.theme-rose.dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-rose.dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-rose.dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-rose .dark .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-rose.dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-rose.dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-rose.dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-rose.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-rose.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-rose .dark .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-rose.dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-rose.dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #f43f5e);
}
.theme-rose .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #f43f5e);
}
.theme-rose .dark .mdc-deprecated-list-group__subheader,
.theme-rose.dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-rose .dark .mdc-list-divider::after,
.theme-rose.dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-rose .dark .mdc-list-divider,
.theme-rose.dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-rose .dark .mat-mdc-list-option .mdc-list-item__start, .theme-rose .dark .mat-mdc-list-option .mdc-list-item__end,
.theme-rose.dark .mat-mdc-list-option .mdc-list-item__start,
.theme-rose.dark .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f43f5e;
  --mdc-checkbox-selected-hover-icon-color: #f43f5e;
  --mdc-checkbox-selected-icon-color: #f43f5e;
  --mdc-checkbox-selected-pressed-icon-color: #f43f5e;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-rose .dark .mat-mdc-list-option.mat-accent .mdc-list-item__start, .theme-rose .dark .mat-mdc-list-option.mat-accent .mdc-list-item__end,
.theme-rose.dark .mat-mdc-list-option.mat-accent .mdc-list-item__start,
.theme-rose.dark .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-rose .dark .mat-mdc-list-option.mat-warn .mdc-list-item__start, .theme-rose .dark .mat-mdc-list-option.mat-warn .mdc-list-item__end,
.theme-rose.dark .mat-mdc-list-option.mat-warn .mdc-list-item__start,
.theme-rose.dark .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-rose .dark .mat-mdc-paginator,
.theme-rose.dark .mat-mdc-paginator {
  background: #1e293b;
  color: rgba(255, 255, 255, 0.87);
}
.theme-rose .dark .mat-mdc-paginator-icon,
.theme-rose.dark .mat-mdc-paginator-icon {
  fill: rgba(255, 255, 255, 0.54);
}
.theme-rose .dark .mat-mdc-paginator-decrement,
.theme-rose .dark .mat-mdc-paginator-increment,
.theme-rose.dark .mat-mdc-paginator-decrement,
.theme-rose.dark .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(255, 255, 255, 0.54);
  border-right: 2px solid rgba(255, 255, 255, 0.54);
}
.theme-rose .dark .mat-mdc-paginator-first,
.theme-rose .dark .mat-mdc-paginator-last,
.theme-rose.dark .mat-mdc-paginator-first,
.theme-rose.dark .mat-mdc-paginator-last {
  border-top: 2px solid rgba(255, 255, 255, 0.54);
}
.theme-rose .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.theme-rose .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.theme-rose .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.theme-rose .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last,
.theme-rose.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.theme-rose.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.theme-rose.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.theme-rose.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-rose .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon,
.theme-rose.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(255, 255, 255, 0.12);
}
.theme-rose .dark .mat-mdc-tab, .theme-rose .dark .mat-mdc-tab-link,
.theme-rose.dark .mat-mdc-tab,
.theme-rose.dark .mat-mdc-tab-link {
  background-color: transparent;
}
.theme-rose .dark .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label, .theme-rose .dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.theme-rose.dark .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.theme-rose.dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  color: rgba(255, 255, 255, 0.6);
}
.theme-rose .dark .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-rose .dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-rose.dark .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-rose.dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #f43f5e;
}
.theme-rose .dark .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-rose .dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-rose.dark .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-rose.dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #f43f5e;
}
.theme-rose .dark .mdc-tab__ripple::before,
.theme-rose .dark .mat-mdc-tab .mat-ripple-element,
.theme-rose .dark .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-rose .dark .mat-mdc-tab-link .mat-ripple-element,
.theme-rose.dark .mdc-tab__ripple::before,
.theme-rose.dark .mat-mdc-tab .mat-ripple-element,
.theme-rose.dark .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-rose.dark .mat-mdc-tab-link .mat-ripple-element {
  background-color: #f43f5e;
}
.theme-rose .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-rose .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-rose .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-rose .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-rose.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-rose.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-rose.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-rose.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #1e293b;
}
.theme-rose .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-rose .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-rose .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-rose .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-rose.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-rose.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-rose.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-rose.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #1e293b;
}
.theme-rose .dark .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.theme-rose .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-rose .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-rose .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .theme-rose .dark .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.theme-rose .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-rose .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-rose .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element,
.theme-rose.dark .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.theme-rose.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-rose.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-rose.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element,
.theme-rose.dark .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.theme-rose.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-rose.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-rose.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #1e293b;
}
.theme-rose .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-rose .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-rose .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-rose .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-rose.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-rose.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-rose.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-rose.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #dc2626;
}
.theme-rose .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-rose .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-rose .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-rose .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-rose.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-rose.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-rose.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-rose.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #dc2626;
}
.theme-rose .dark .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.theme-rose .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-rose .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-rose .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .theme-rose .dark .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.theme-rose .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-rose .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-rose .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element,
.theme-rose.dark .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.theme-rose.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-rose.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-rose.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element,
.theme-rose.dark .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.theme-rose.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-rose.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-rose.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #dc2626;
}
.theme-rose .dark .mat-mdc-tab-group.mat-background-primary, .theme-rose .dark .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-rose.dark .mat-mdc-tab-group.mat-background-primary,
.theme-rose.dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #f43f5e;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-rose .dark .mat-mdc-tab-group.mat-background-accent, .theme-rose .dark .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-rose.dark .mat-mdc-tab-group.mat-background-accent,
.theme-rose.dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #1e293b;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-rose .dark .mat-mdc-tab-group.mat-background-warn, .theme-rose .dark .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-rose.dark .mat-mdc-tab-group.mat-background-warn,
.theme-rose.dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #dc2626;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-rose .dark .mat-mdc-tab-header-pagination-chevron,
.theme-rose.dark .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #fff);
}
.theme-rose .dark .mat-mdc-checkbox .mdc-form-field,
.theme-rose.dark .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-rose .dark .mat-mdc-checkbox .mat-ripple-element,
.theme-rose.dark .mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .dark .mat-mdc-checkbox .mdc-checkbox__ripple,
.theme-rose.dark .mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #fff;
}
.theme-rose .dark .mat-mdc-checkbox.mat-primary,
.theme-rose.dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f43f5e;
  --mdc-checkbox-selected-hover-icon-color: #f43f5e;
  --mdc-checkbox-selected-icon-color: #f43f5e;
  --mdc-checkbox-selected-pressed-icon-color: #f43f5e;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-rose .dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-rose.dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(244, 63, 94, 0.1);
}
.theme-rose .dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-rose.dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #f43f5e;
}
.theme-rose .dark .mat-mdc-checkbox.mat-accent,
.theme-rose.dark .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-rose .dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-rose.dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(30, 41, 59, 0.1);
}
.theme-rose .dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-rose.dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #1e293b;
}
.theme-rose .dark .mat-mdc-checkbox.mat-warn,
.theme-rose.dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-rose .dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-rose.dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(220, 38, 38, 0.1);
}
.theme-rose .dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-rose.dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #dc2626;
}
.theme-rose .dark .mat-mdc-checkbox-disabled label,
.theme-rose.dark .mat-mdc-checkbox-disabled label {
  color: #475569;
}
.theme-rose .dark .mat-mdc-button.mat-unthemed,
.theme-rose.dark .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #fff;
}
.theme-rose .dark .mat-mdc-button.mat-primary,
.theme-rose.dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #f43f5e;
}
.theme-rose .dark .mat-mdc-button.mat-accent,
.theme-rose.dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
}
.theme-rose .dark .mat-mdc-button.mat-warn,
.theme-rose.dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
}
.theme-rose .dark .mat-mdc-button[disabled][disabled],
.theme-rose.dark .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-text-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.theme-rose .dark .mat-mdc-unelevated-button.mat-unthemed,
.theme-rose.dark .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-rose .dark .mat-mdc-unelevated-button.mat-primary,
.theme-rose.dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #f43f5e;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-rose .dark .mat-mdc-unelevated-button.mat-accent,
.theme-rose.dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-rose .dark .mat-mdc-unelevated-button.mat-warn,
.theme-rose.dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-rose .dark .mat-mdc-unelevated-button[disabled][disabled],
.theme-rose.dark .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-filled-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.theme-rose .dark .mat-mdc-raised-button.mat-unthemed,
.theme-rose.dark .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-rose .dark .mat-mdc-raised-button.mat-primary,
.theme-rose.dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #f43f5e;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-rose .dark .mat-mdc-raised-button.mat-accent,
.theme-rose.dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-rose .dark .mat-mdc-raised-button.mat-warn,
.theme-rose.dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-rose .dark .mat-mdc-raised-button[disabled][disabled],
.theme-rose.dark .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-elevation: 0;
}
.theme-rose .dark .mat-mdc-outlined-button,
.theme-rose.dark .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.theme-rose .dark .mat-mdc-outlined-button.mat-unthemed,
.theme-rose.dark .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #fff;
}
.theme-rose .dark .mat-mdc-outlined-button.mat-primary,
.theme-rose.dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #f43f5e;
}
.theme-rose .dark .mat-mdc-outlined-button.mat-accent,
.theme-rose.dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
}
.theme-rose .dark .mat-mdc-outlined-button.mat-warn,
.theme-rose.dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
}
.theme-rose .dark .mat-mdc-outlined-button[disabled][disabled],
.theme-rose.dark .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
}
.theme-rose .dark .mat-mdc-button, .theme-rose .dark .mat-mdc-outlined-button,
.theme-rose.dark .mat-mdc-button,
.theme-rose.dark .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .theme-rose .dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-rose .dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-rose .dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-rose .dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-rose .dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-rose .dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .theme-rose .dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-rose .dark .mat-mdc-button.mat-primary, .theme-rose .dark .mat-mdc-outlined-button.mat-primary,
.theme-rose.dark .mat-mdc-button.mat-primary,
.theme-rose.dark .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #f43f5e;
  --mat-mdc-button-ripple-color: rgba(244, 63, 94, 0.1);
}
.theme-rose .dark .mat-mdc-button.mat-accent, .theme-rose .dark .mat-mdc-outlined-button.mat-accent,
.theme-rose.dark .mat-mdc-button.mat-accent,
.theme-rose.dark .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #1e293b;
  --mat-mdc-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-rose .dark .mat-mdc-button.mat-warn, .theme-rose .dark .mat-mdc-outlined-button.mat-warn,
.theme-rose.dark .mat-mdc-button.mat-warn,
.theme-rose.dark .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #dc2626;
  --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-rose .dark .mat-mdc-raised-button, .theme-rose .dark .mat-mdc-unelevated-button,
.theme-rose.dark .mat-mdc-raised-button,
.theme-rose.dark .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .theme-rose .dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-rose .dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-rose .dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-rose .dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-rose .dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-rose .dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .theme-rose .dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-rose .dark .mat-mdc-raised-button.mat-primary, .theme-rose .dark .mat-mdc-unelevated-button.mat-primary,
.theme-rose.dark .mat-mdc-raised-button.mat-primary,
.theme-rose.dark .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .dark .mat-mdc-raised-button.mat-accent, .theme-rose .dark .mat-mdc-unelevated-button.mat-accent,
.theme-rose.dark .mat-mdc-raised-button.mat-accent,
.theme-rose.dark .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .dark .mat-mdc-raised-button.mat-warn, .theme-rose .dark .mat-mdc-unelevated-button.mat-warn,
.theme-rose.dark .mat-mdc-raised-button.mat-warn,
.theme-rose.dark .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .dark .mat-mdc-icon-button,
.theme-rose.dark .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-rose .dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-rose .dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-rose .dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-rose .dark .mat-mdc-icon-button.mat-primary,
.theme-rose.dark .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #f43f5e;
  --mat-mdc-button-ripple-color: rgba(244, 63, 94, 0.1);
}
.theme-rose .dark .mat-mdc-icon-button.mat-accent,
.theme-rose.dark .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #1e293b;
  --mat-mdc-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-rose .dark .mat-mdc-icon-button.mat-warn,
.theme-rose.dark .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #dc2626;
  --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-rose .dark .mat-mdc-icon-button.mat-primary,
.theme-rose.dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #f43f5e;
}
.theme-rose .dark .mat-mdc-icon-button.mat-accent,
.theme-rose.dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
}
.theme-rose .dark .mat-mdc-icon-button.mat-warn,
.theme-rose.dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
}
.theme-rose .dark .mat-mdc-icon-button[disabled][disabled],
.theme-rose.dark .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
}
.theme-rose .dark .mat-mdc-fab, .theme-rose .dark .mat-mdc-mini-fab,
.theme-rose.dark .mat-mdc-fab,
.theme-rose.dark .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .theme-rose .dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-rose .dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-rose .dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-rose .dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-rose .dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-rose .dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .theme-rose .dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.theme-rose.dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-rose .dark .mat-mdc-fab.mat-primary, .theme-rose .dark .mat-mdc-mini-fab.mat-primary,
.theme-rose.dark .mat-mdc-fab.mat-primary,
.theme-rose.dark .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .dark .mat-mdc-fab.mat-accent, .theme-rose .dark .mat-mdc-mini-fab.mat-accent,
.theme-rose.dark .mat-mdc-fab.mat-accent,
.theme-rose.dark .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .dark .mat-mdc-fab.mat-warn, .theme-rose .dark .mat-mdc-mini-fab.mat-warn,
.theme-rose.dark .mat-mdc-fab.mat-warn,
.theme-rose.dark .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .dark .mat-mdc-fab.mat-unthemed, .theme-rose .dark .mat-mdc-mini-fab.mat-unthemed,
.theme-rose.dark .mat-mdc-fab.mat-unthemed,
.theme-rose.dark .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-rose .dark .mat-mdc-fab.mat-primary, .theme-rose .dark .mat-mdc-mini-fab.mat-primary,
.theme-rose.dark .mat-mdc-fab.mat-primary,
.theme-rose.dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #f43f5e;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-rose .dark .mat-mdc-fab.mat-accent, .theme-rose .dark .mat-mdc-mini-fab.mat-accent,
.theme-rose.dark .mat-mdc-fab.mat-accent,
.theme-rose.dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-rose .dark .mat-mdc-fab.mat-warn, .theme-rose .dark .mat-mdc-mini-fab.mat-warn,
.theme-rose.dark .mat-mdc-fab.mat-warn,
.theme-rose.dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-rose .dark .mat-mdc-fab[disabled][disabled], .theme-rose .dark .mat-mdc-mini-fab[disabled][disabled],
.theme-rose.dark .mat-mdc-fab[disabled][disabled],
.theme-rose.dark .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(255, 255, 255, 0.12);
  --mdc-fab-icon-color: rgba(255, 255, 255, 0.5);
  --mat-mdc-fab-color: rgba(255, 255, 255, 0.5);
}
.theme-rose .dark .mat-mdc-snack-bar-container,
.theme-rose.dark .mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: currentColor;
  --mdc-snackbar-container-color: #d2d4d8;
  --mdc-snackbar-supporting-text-color: rgba(30, 41, 59, 0.87);
}
.theme-rose .dark .mdc-data-table,
.theme-rose.dark .mdc-data-table {
  background-color: var(--mdc-theme-surface, #1e293b);
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-rose .dark .mdc-data-table__row,
.theme-rose.dark .mdc-data-table__row {
  background-color: inherit;
}
.theme-rose .dark .mdc-data-table__header-cell,
.theme-rose.dark .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #1e293b);
}
.theme-rose .dark .mdc-data-table__row--selected,
.theme-rose.dark .mdc-data-table__row--selected {
  background-color: rgba(244, 63, 94, 0.04);
}
.theme-rose .dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.theme-rose .dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.theme-rose .dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing,
.theme-rose.dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.theme-rose.dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.theme-rose.dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-rose .dark .mdc-data-table__cell,
.theme-rose .dark .mdc-data-table__header-cell,
.theme-rose.dark .mdc-data-table__cell,
.theme-rose.dark .mdc-data-table__header-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.theme-rose .dark .mdc-data-table__pagination,
.theme-rose.dark .mdc-data-table__pagination {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-rose .dark .mdc-data-table__row:not(.mdc-data-table__row--selected):hover,
.theme-rose.dark .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(255, 255, 255, 0.04);
}
.theme-rose .dark .mdc-data-table__header-cell,
.theme-rose.dark .mdc-data-table__header-cell {
  color: rgba(255, 255, 255, 0.87);
}
.theme-rose .dark .mdc-data-table__pagination-total,
.theme-rose .dark .mdc-data-table__pagination-rows-per-page-label,
.theme-rose .dark .mdc-data-table__cell,
.theme-rose.dark .mdc-data-table__pagination-total,
.theme-rose.dark .mdc-data-table__pagination-rows-per-page-label,
.theme-rose.dark .mdc-data-table__cell {
  color: rgba(255, 255, 255, 0.87);
}
[dir=rtl] .theme-rose .dark .mdc-data-table__pagination-button .mdc-button__icon, [dir=rtl] .theme-rose.dark .mdc-data-table__pagination-button .mdc-button__icon, .theme-rose .dark .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl], .theme-rose.dark .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-rose .dark .mat-mdc-table,
.theme-rose.dark .mat-mdc-table {
  background: #1e293b;
}
.theme-rose .dark .mat-mdc-progress-spinner,
.theme-rose.dark .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #f43f5e;
}
.theme-rose .dark .mat-mdc-progress-spinner.mat-accent,
.theme-rose.dark .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-rose .dark .mat-mdc-progress-spinner.mat-warn,
.theme-rose.dark .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-rose .dark .mat-badge-content,
.theme-rose.dark .mat-badge-content {
  color: white;
  background: #f43f5e;
}
.cdk-high-contrast-active .theme-rose .dark .mat-badge-content, .cdk-high-contrast-active .theme-rose.dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.theme-rose .dark .mat-badge-accent .mat-badge-content,
.theme-rose.dark .mat-badge-accent .mat-badge-content {
  background: #1e293b;
  color: white;
}
.theme-rose .dark .mat-badge-warn .mat-badge-content,
.theme-rose.dark .mat-badge-warn .mat-badge-content {
  color: white;
  background: #dc2626;
}
.theme-rose .dark .mat-badge-disabled .mat-badge-content,
.theme-rose.dark .mat-badge-disabled .mat-badge-content {
  background: #1e293b;
  color: #475569;
}
.theme-rose .dark .mat-bottom-sheet-container,
.theme-rose.dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #1e293b;
  color: white;
}
.theme-rose .dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-rose .dark .mat-button-toggle-group:not([class*=mat-elevation-z]),
.theme-rose.dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-rose.dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-rose .dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]),
.theme-rose.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-rose.dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.theme-rose .dark .mat-button-toggle,
.theme-rose.dark .mat-button-toggle {
  color: #64748b;
}
.theme-rose .dark .mat-button-toggle .mat-button-toggle-focus-overlay,
.theme-rose.dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: #e2e8f0;
}
.theme-rose .dark .mat-button-toggle-appearance-standard,
.theme-rose.dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #1e293b;
}
.theme-rose .dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay,
.theme-rose.dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: #e2e8f0;
}
.theme-rose .dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
.theme-rose.dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #374152;
}
.theme-rose .dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
.theme-rose.dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #374152;
}
.theme-rose .dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle,
.theme-rose.dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #374152;
}
.theme-rose .dark .mat-button-toggle-checked,
.theme-rose.dark .mat-button-toggle-checked {
  background-color: rgba(255, 255, 255, 0.05);
  color: #94a3b8;
}
.theme-rose .dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard,
.theme-rose.dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.theme-rose .dark .mat-button-toggle-disabled,
.theme-rose.dark .mat-button-toggle-disabled {
  color: #1e293b;
  background-color: #0f172a;
}
.theme-rose .dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard,
.theme-rose.dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #1e293b;
}
.theme-rose .dark .mat-button-toggle-disabled.mat-button-toggle-checked,
.theme-rose.dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #1e293b;
}
.theme-rose .dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-rose .dark .mat-button-toggle-group-appearance-standard,
.theme-rose.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-rose.dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #374152;
}
.theme-rose .dark .mat-calendar-arrow,
.theme-rose.dark .mat-calendar-arrow {
  fill: #f1f5f9;
}
.theme-rose .dark .mat-datepicker-toggle,
.theme-rose .dark .mat-datepicker-content .mat-calendar-next-button,
.theme-rose .dark .mat-datepicker-content .mat-calendar-previous-button,
.theme-rose.dark .mat-datepicker-toggle,
.theme-rose.dark .mat-datepicker-content .mat-calendar-next-button,
.theme-rose.dark .mat-datepicker-content .mat-calendar-previous-button {
  color: #f1f5f9;
}
.theme-rose .dark .mat-calendar-table-header-divider::after,
.theme-rose.dark .mat-calendar-table-header-divider::after {
  background: rgba(241, 245, 249, 0.12);
}
.theme-rose .dark .mat-calendar-table-header,
.theme-rose .dark .mat-calendar-body-label,
.theme-rose.dark .mat-calendar-table-header,
.theme-rose.dark .mat-calendar-body-label {
  color: #94a3b8;
}
.theme-rose .dark .mat-calendar-body-cell-content,
.theme-rose .dark .mat-date-range-input-separator,
.theme-rose.dark .mat-calendar-body-cell-content,
.theme-rose.dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.theme-rose .dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-rose.dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: #475569;
}
.theme-rose .dark .mat-form-field-disabled .mat-date-range-input-separator,
.theme-rose.dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: #475569;
}
.theme-rose .dark .mat-calendar-body-in-preview,
.theme-rose.dark .mat-calendar-body-in-preview {
  color: rgba(241, 245, 249, 0.24);
}
.theme-rose .dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-rose.dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #64748b;
}
.theme-rose .dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-rose.dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(100, 116, 139, 0.8);
}
.theme-rose .dark .mat-calendar-body-in-range::before,
.theme-rose.dark .mat-calendar-body-in-range::before {
  background: rgba(244, 63, 94, 0.2);
}
.theme-rose .dark .mat-calendar-body-comparison-identical,
.theme-rose .dark .mat-calendar-body-in-comparison-range::before,
.theme-rose.dark .mat-calendar-body-comparison-identical,
.theme-rose.dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-rose .dark .mat-calendar-body-comparison-bridge-start::before,
.theme-rose .dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-rose.dark .mat-calendar-body-comparison-bridge-start::before,
.theme-rose.dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 63, 94, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-rose .dark .mat-calendar-body-comparison-bridge-end::before,
.theme-rose .dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-rose.dark .mat-calendar-body-comparison-bridge-end::before,
.theme-rose.dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 63, 94, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-rose .dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-rose .dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-rose.dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-rose.dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-rose .dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-rose .dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-rose.dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-rose.dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-rose .dark .mat-calendar-body-selected,
.theme-rose.dark .mat-calendar-body-selected {
  background-color: #f43f5e;
  color: white;
}
.theme-rose .dark .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-rose.dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 63, 94, 0.4);
}
.theme-rose .dark .mat-calendar-body-today.mat-calendar-body-selected,
.theme-rose.dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-rose .dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-rose .dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-rose.dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-rose.dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 63, 94, 0.3);
}
@media (hover: hover) {
  .theme-rose .dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-rose.dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 63, 94, 0.3);
  }
}
.theme-rose .dark .mat-datepicker-content,
.theme-rose.dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #1e293b;
  color: white;
}
.theme-rose .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before,
.theme-rose.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(30, 41, 59, 0.2);
}
.theme-rose .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-rose .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before,
.theme-rose.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-rose.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-rose .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-rose .dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-rose.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-rose.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(30, 41, 59, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-rose .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-rose .dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-rose.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-rose.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(30, 41, 59, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-rose .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-rose .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-rose.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-rose.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-rose .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-rose .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-rose.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-rose.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-rose .dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected,
.theme-rose.dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #1e293b;
  color: white;
}
.theme-rose .dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-rose.dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(30, 41, 59, 0.4);
}
.theme-rose .dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected,
.theme-rose.dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-rose .dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-rose .dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-rose.dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-rose.dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(30, 41, 59, 0.3);
}
@media (hover: hover) {
  .theme-rose .dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-rose.dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(30, 41, 59, 0.3);
  }
}
.theme-rose .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before,
.theme-rose.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(220, 38, 38, 0.2);
}
.theme-rose .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-rose .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before,
.theme-rose.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-rose.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-rose .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-rose .dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-rose.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-rose.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(220, 38, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-rose .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-rose .dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-rose.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-rose.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(220, 38, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-rose .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-rose .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-rose.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-rose.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-rose .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-rose .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-rose.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-rose.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-rose .dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected,
.theme-rose.dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #dc2626;
  color: white;
}
.theme-rose .dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-rose.dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(220, 38, 38, 0.4);
}
.theme-rose .dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected,
.theme-rose.dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-rose .dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-rose .dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-rose.dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-rose.dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(220, 38, 38, 0.3);
}
@media (hover: hover) {
  .theme-rose .dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-rose.dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(220, 38, 38, 0.3);
  }
}
.theme-rose .dark .mat-datepicker-content-touch,
.theme-rose.dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-datepicker-toggle-active,
.theme-rose.dark .mat-datepicker-toggle-active {
  color: #f43f5e;
}
.theme-rose .dark .mat-datepicker-toggle-active.mat-accent,
.theme-rose.dark .mat-datepicker-toggle-active.mat-accent {
  color: #1e293b;
}
.theme-rose .dark .mat-datepicker-toggle-active.mat-warn,
.theme-rose.dark .mat-datepicker-toggle-active.mat-warn {
  color: #dc2626;
}
.theme-rose .dark .mat-date-range-input-inner[disabled],
.theme-rose.dark .mat-date-range-input-inner[disabled] {
  color: #475569;
}
.theme-rose .dark .mat-divider,
.theme-rose.dark .mat-divider {
  border-top-color: rgba(241, 245, 249, 0.12);
}
.theme-rose .dark .mat-divider-vertical,
.theme-rose.dark .mat-divider-vertical {
  border-right-color: rgba(241, 245, 249, 0.12);
}
.theme-rose .dark .mat-expansion-panel,
.theme-rose.dark .mat-expansion-panel {
  background: #1e293b;
  color: white;
}
.theme-rose .dark .mat-expansion-panel:not([class*=mat-elevation-z]),
.theme-rose.dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-action-row,
.theme-rose.dark .mat-action-row {
  border-top-color: rgba(241, 245, 249, 0.12);
}
.theme-rose .dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .theme-rose .dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .theme-rose .dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]),
.theme-rose.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
.theme-rose.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
.theme-rose.dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.05);
}
@media (hover: none) {
  .theme-rose .dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover,
  .theme-rose.dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #1e293b;
  }
}
.theme-rose .dark .mat-expansion-panel-header-title,
.theme-rose.dark .mat-expansion-panel-header-title {
  color: white;
}
.theme-rose .dark .mat-expansion-panel-header-description,
.theme-rose .dark .mat-expansion-indicator::after,
.theme-rose.dark .mat-expansion-panel-header-description,
.theme-rose.dark .mat-expansion-indicator::after {
  color: #94a3b8;
}
.theme-rose .dark .mat-expansion-panel-header[aria-disabled=true],
.theme-rose.dark .mat-expansion-panel-header[aria-disabled=true] {
  color: #1e293b;
}
.theme-rose .dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-rose .dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description,
.theme-rose.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-rose.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.theme-rose .dark .mat-icon.mat-primary,
.theme-rose.dark .mat-icon.mat-primary {
  color: #f43f5e;
}
.theme-rose .dark .mat-icon.mat-accent,
.theme-rose.dark .mat-icon.mat-accent {
  color: #1e293b;
}
.theme-rose .dark .mat-icon.mat-warn,
.theme-rose.dark .mat-icon.mat-warn {
  color: #dc2626;
}
.theme-rose .dark .mat-drawer-container,
.theme-rose.dark .mat-drawer-container {
  background-color: #0f172a;
  color: white;
}
.theme-rose .dark .mat-drawer,
.theme-rose.dark .mat-drawer {
  background-color: #1e293b;
  color: white;
}
.theme-rose .dark .mat-drawer.mat-drawer-push,
.theme-rose.dark .mat-drawer.mat-drawer-push {
  background-color: #1e293b;
}
.theme-rose .dark .mat-drawer:not(.mat-drawer-side),
.theme-rose.dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-drawer-side,
.theme-rose.dark .mat-drawer-side {
  border-right: solid 1px rgba(241, 245, 249, 0.12);
}
.theme-rose .dark .mat-drawer-side.mat-drawer-end,
.theme-rose.dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(241, 245, 249, 0.12);
  border-right: none;
}
.theme-rose .dark [dir=rtl] .mat-drawer-side,
.theme-rose.dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(241, 245, 249, 0.12);
  border-right: none;
}
.theme-rose .dark [dir=rtl] .mat-drawer-side.mat-drawer-end,
.theme-rose.dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(241, 245, 249, 0.12);
}
.theme-rose .dark .mat-drawer-backdrop.mat-drawer-shown,
.theme-rose.dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(225, 214, 196, 0.6);
}
.theme-rose .dark .mat-step-header.cdk-keyboard-focused, .theme-rose .dark .mat-step-header.cdk-program-focused, .theme-rose .dark .mat-step-header:hover:not([aria-disabled]), .theme-rose .dark .mat-step-header:hover[aria-disabled=false],
.theme-rose.dark .mat-step-header.cdk-keyboard-focused,
.theme-rose.dark .mat-step-header.cdk-program-focused,
.theme-rose.dark .mat-step-header:hover:not([aria-disabled]),
.theme-rose.dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.05);
}
.theme-rose .dark .mat-step-header:hover[aria-disabled=true],
.theme-rose.dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .theme-rose .dark .mat-step-header:hover,
  .theme-rose.dark .mat-step-header:hover {
    background: none;
  }
}
.theme-rose .dark .mat-step-header .mat-step-label,
.theme-rose .dark .mat-step-header .mat-step-optional,
.theme-rose.dark .mat-step-header .mat-step-label,
.theme-rose.dark .mat-step-header .mat-step-optional {
  color: #94a3b8;
}
.theme-rose .dark .mat-step-header .mat-step-icon,
.theme-rose.dark .mat-step-header .mat-step-icon {
  background-color: #94a3b8;
  color: white;
}
.theme-rose .dark .mat-step-header .mat-step-icon-selected,
.theme-rose .dark .mat-step-header .mat-step-icon-state-done,
.theme-rose .dark .mat-step-header .mat-step-icon-state-edit,
.theme-rose.dark .mat-step-header .mat-step-icon-selected,
.theme-rose.dark .mat-step-header .mat-step-icon-state-done,
.theme-rose.dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #f43f5e;
  color: white;
}
.theme-rose .dark .mat-step-header.mat-accent .mat-step-icon,
.theme-rose.dark .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.theme-rose .dark .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-rose .dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-rose .dark .mat-step-header.mat-accent .mat-step-icon-state-edit,
.theme-rose.dark .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-rose.dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-rose.dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #1e293b;
  color: white;
}
.theme-rose .dark .mat-step-header.mat-warn .mat-step-icon,
.theme-rose.dark .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.theme-rose .dark .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-rose .dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-rose .dark .mat-step-header.mat-warn .mat-step-icon-state-edit,
.theme-rose.dark .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-rose.dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-rose.dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #dc2626;
  color: white;
}
.theme-rose .dark .mat-step-header .mat-step-icon-state-error,
.theme-rose.dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #dc2626;
}
.theme-rose .dark .mat-step-header .mat-step-label.mat-step-label-active,
.theme-rose.dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.theme-rose .dark .mat-step-header .mat-step-label.mat-step-label-error,
.theme-rose.dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #dc2626;
}
.theme-rose .dark .mat-stepper-horizontal, .theme-rose .dark .mat-stepper-vertical,
.theme-rose.dark .mat-stepper-horizontal,
.theme-rose.dark .mat-stepper-vertical {
  background-color: #1e293b;
}
.theme-rose .dark .mat-stepper-vertical-line::before,
.theme-rose.dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(241, 245, 249, 0.12);
}
.theme-rose .dark .mat-horizontal-stepper-header::before,
.theme-rose .dark .mat-horizontal-stepper-header::after,
.theme-rose .dark .mat-stepper-horizontal-line,
.theme-rose.dark .mat-horizontal-stepper-header::before,
.theme-rose.dark .mat-horizontal-stepper-header::after,
.theme-rose.dark .mat-stepper-horizontal-line {
  border-top-color: rgba(241, 245, 249, 0.12);
}
.theme-rose .dark .mat-sort-header-arrow,
.theme-rose.dark .mat-sort-header-arrow {
  color: #94a3b8;
}
.theme-rose .dark .mat-toolbar,
.theme-rose.dark .mat-toolbar {
  background: #0f172a;
  color: white;
}
.theme-rose .dark .mat-toolbar.mat-primary,
.theme-rose.dark .mat-toolbar.mat-primary {
  background: #f43f5e;
  color: white;
}
.theme-rose .dark .mat-toolbar.mat-accent,
.theme-rose.dark .mat-toolbar.mat-accent {
  background: #1e293b;
  color: white;
}
.theme-rose .dark .mat-toolbar.mat-warn,
.theme-rose.dark .mat-toolbar.mat-warn {
  background: #dc2626;
  color: white;
}
.theme-rose .dark .mat-toolbar .mat-form-field-underline,
.theme-rose .dark .mat-toolbar .mat-form-field-ripple,
.theme-rose .dark .mat-toolbar .mat-focused .mat-form-field-ripple,
.theme-rose.dark .mat-toolbar .mat-form-field-underline,
.theme-rose.dark .mat-toolbar .mat-form-field-ripple,
.theme-rose.dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.theme-rose .dark .mat-toolbar .mat-form-field-label,
.theme-rose .dark .mat-toolbar .mat-focused .mat-form-field-label,
.theme-rose .dark .mat-toolbar .mat-select-value,
.theme-rose .dark .mat-toolbar .mat-select-arrow,
.theme-rose .dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow,
.theme-rose.dark .mat-toolbar .mat-form-field-label,
.theme-rose.dark .mat-toolbar .mat-focused .mat-form-field-label,
.theme-rose.dark .mat-toolbar .mat-select-value,
.theme-rose.dark .mat-toolbar .mat-select-arrow,
.theme-rose.dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.theme-rose .dark .mat-toolbar .mat-input-element,
.theme-rose.dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.theme-rose .dark .mat-tree,
.theme-rose.dark .mat-tree {
  background: #1e293b;
}
.theme-rose .dark .mat-tree-node,
.theme-rose .dark .mat-nested-tree-node,
.theme-rose.dark .mat-tree-node,
.theme-rose.dark .mat-nested-tree-node {
  color: white;
}

/* Generate the palettes */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define a light & dark Angular Material theme with the generated palettes */
/* Merge the custom base colors with the generated themes */
/* Generate and encapsulate Angular Material themes */
.theme-purple .light .mat-ripple-element,
.theme-purple.light .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.theme-purple .light .mat-mdc-option,
.theme-purple.light .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-purple .light .mat-mdc-option:hover:not(.mdc-list-item--disabled), .theme-purple .light .mat-mdc-option:focus:not(.mdc-list-item--disabled), .theme-purple .light .mat-mdc-option.mat-mdc-option-active, .theme-purple .light .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled),
.theme-purple.light .mat-mdc-option:hover:not(.mdc-list-item--disabled),
.theme-purple.light .mat-mdc-option:focus:not(.mdc-list-item--disabled),
.theme-purple.light .mat-mdc-option.mat-mdc-option-active,
.theme-purple.light .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.theme-purple .light .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-purple.light .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #9333ea);
}
.theme-purple .light .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-purple.light .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #1e293b);
}
.theme-purple .light .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-purple.light .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mat-mdc-optgroup-label,
.theme-purple.light .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-purple .light .mat-pseudo-checkbox,
.theme-purple.light .mat-pseudo-checkbox {
  color: #64748b;
}
.theme-purple .light .mat-pseudo-checkbox::after,
.theme-purple.light .mat-pseudo-checkbox::after {
  color: #f1f5f9;
}
.theme-purple .light .mat-pseudo-checkbox-disabled,
.theme-purple.light .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.theme-purple .light .mat-primary .mat-pseudo-checkbox-checked,
.theme-purple .light .mat-primary .mat-pseudo-checkbox-indeterminate,
.theme-purple.light .mat-primary .mat-pseudo-checkbox-checked,
.theme-purple.light .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #9333ea;
}
.theme-purple .light .mat-pseudo-checkbox-checked,
.theme-purple .light .mat-pseudo-checkbox-indeterminate,
.theme-purple .light .mat-accent .mat-pseudo-checkbox-checked,
.theme-purple .light .mat-accent .mat-pseudo-checkbox-indeterminate,
.theme-purple.light .mat-pseudo-checkbox-checked,
.theme-purple.light .mat-pseudo-checkbox-indeterminate,
.theme-purple.light .mat-accent .mat-pseudo-checkbox-checked,
.theme-purple.light .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #1e293b;
}
.theme-purple .light .mat-warn .mat-pseudo-checkbox-checked,
.theme-purple .light .mat-warn .mat-pseudo-checkbox-indeterminate,
.theme-purple.light .mat-warn .mat-pseudo-checkbox-checked,
.theme-purple.light .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #dc2626;
}
.theme-purple .light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-purple .light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled,
.theme-purple.light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-purple.light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.theme-purple .light .mat-app-background, .theme-purple .light.mat-app-background,
.theme-purple.light .mat-app-background,
.theme-purple.light.mat-app-background {
  background-color: #f1f5f9;
  color: #1e293b;
}
.theme-purple .light .mat-elevation-z0, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-purple.light .mat-elevation-z0,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z1, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-purple.light .mat-elevation-z1,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z2, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-purple.light .mat-elevation-z2,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z3, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-purple.light .mat-elevation-z3,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z4, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-purple.light .mat-elevation-z4,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z5, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-purple.light .mat-elevation-z5,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z6, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-purple.light .mat-elevation-z6,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z7, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-purple.light .mat-elevation-z7,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z8, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-purple.light .mat-elevation-z8,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z9, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-purple.light .mat-elevation-z9,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z10, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-purple.light .mat-elevation-z10,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z11, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-purple.light .mat-elevation-z11,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z12, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-purple.light .mat-elevation-z12,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z13, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-purple.light .mat-elevation-z13,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z14, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-purple.light .mat-elevation-z14,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z15, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-purple.light .mat-elevation-z15,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z16, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-purple.light .mat-elevation-z16,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z17, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-purple.light .mat-elevation-z17,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z18, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-purple.light .mat-elevation-z18,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z19, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-purple.light .mat-elevation-z19,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z20, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-purple.light .mat-elevation-z20,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z21, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-purple.light .mat-elevation-z21,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z22, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-purple.light .mat-elevation-z22,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z23, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-purple.light .mat-elevation-z23,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z24, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-purple.light .mat-elevation-z24,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-purple .light .mat-mdc-card,
.theme-purple.light .mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #fff;
}
.theme-purple .light .mat-mdc-card-outlined,
.theme-purple.light .mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #e0e0e0;
}
.theme-purple .light .mat-mdc-card-subtitle,
.theme-purple.light .mat-mdc-card-subtitle {
  color: #64748b;
}
.theme-purple .light .mat-mdc-progress-bar,
.theme-purple.light .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #9333ea;
}
.theme-purple .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.theme-purple.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(147, 51, 234, 0.25)'/%3E%3C/svg%3E");
}
.theme-purple .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.theme-purple.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(147, 51, 234, 0.25);
}
.theme-purple .light .mat-mdc-progress-bar.mat-accent,
.theme-purple.light .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
}
.theme-purple .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.theme-purple.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(30, 41, 59, 0.25)'/%3E%3C/svg%3E");
}
.theme-purple .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.theme-purple.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(30, 41, 59, 0.25);
}
.theme-purple .light .mat-mdc-progress-bar.mat-warn,
.theme-purple.light .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
}
.theme-purple .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.theme-purple.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(220, 38, 38, 0.25)'/%3E%3C/svg%3E");
}
.theme-purple .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.theme-purple.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(220, 38, 38, 0.25);
}
.theme-purple .light .mat-mdc-tooltip,
.theme-purple.light .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.theme-purple .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-purple.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.theme-purple .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input,
.theme-purple.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .theme-purple .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder,
  .theme-purple.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .theme-purple .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder,
  .theme-purple.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.theme-purple .light .mdc-text-field .mdc-text-field__input,
.theme-purple.light .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #9333ea);
}
.theme-purple .light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text,
.theme-purple.light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.theme-purple .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.theme-purple .light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter,
.theme-purple.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.theme-purple.light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.theme-purple .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading,
.theme-purple.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.theme-purple .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-purple.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.theme-purple .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix,
.theme-purple.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-purple .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix,
.theme-purple.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-purple .light .mdc-text-field--filled .mdc-text-field__ripple::before,
.theme-purple .light .mdc-text-field--filled .mdc-text-field__ripple::after,
.theme-purple.light .mdc-text-field--filled .mdc-text-field__ripple::before,
.theme-purple.light .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.theme-purple .light .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .theme-purple .light .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before,
.theme-purple.light .mdc-text-field--filled:hover .mdc-text-field__ripple::before,
.theme-purple.light .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.theme-purple .light .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .theme-purple .light .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before,
.theme-purple.light .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before,
.theme-purple.light .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.theme-purple .light .mdc-text-field--filled:not(.mdc-text-field--disabled),
.theme-purple.light .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.theme-purple .light .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-purple.light .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.theme-purple .light .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-purple.light .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.theme-purple .light .mdc-text-field--filled .mdc-line-ripple::after,
.theme-purple.light .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #9333ea);
}
.theme-purple .light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-purple .light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-purple .light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-purple.light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-purple.light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-purple.light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.theme-purple .light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-purple .light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-purple .light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-purple.light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-purple.light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-purple.light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.theme-purple .light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-purple .light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-purple .light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-purple.light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-purple.light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-purple.light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #9333ea);
}
[dir=rtl] .theme-purple .light .mdc-text-field--outlined.mdc-text-field--with-leading-icon, [dir=rtl] .theme-purple.light .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .theme-purple .light .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl], .theme-purple.light .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-purple .light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, [dir=rtl] .theme-purple.light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .theme-purple .light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl], .theme-purple.light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-purple .light .mdc-text-field--outlined .mdc-text-field__ripple::before,
.theme-purple .light .mdc-text-field--outlined .mdc-text-field__ripple::after,
.theme-purple.light .mdc-text-field--outlined .mdc-text-field__ripple::before,
.theme-purple.light .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .theme-purple .light .mdc-text-field--with-leading-icon.mdc-text-field--outlined, [dir=rtl] .theme-purple.light .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .theme-purple .light .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl], .theme-purple.light .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-purple .light .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-purple.light .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(147, 51, 234, 0.87);
}
.theme-purple .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-purple.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-purple.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-purple.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-purple.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mdc-text-field--invalid .mdc-text-field__input,
.theme-purple.light .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-purple.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-purple.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-purple .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-purple .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-purple.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-purple.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-purple.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-purple .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-purple .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-purple.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-purple.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-purple.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-purple .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-purple .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-purple.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-purple.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-purple.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mdc-text-field--disabled .mdc-text-field__input,
.theme-purple.light .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .theme-purple .light .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  .theme-purple.light .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .theme-purple .light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  .theme-purple.light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.theme-purple .light .mdc-text-field--disabled .mdc-floating-label,
.theme-purple.light .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.theme-purple .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
.theme-purple.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.theme-purple .light .mdc-text-field--disabled .mdc-text-field-character-counter,
.theme-purple .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
.theme-purple.light .mdc-text-field--disabled .mdc-text-field-character-counter,
.theme-purple.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.theme-purple .light .mdc-text-field--disabled .mdc-text-field__icon--leading,
.theme-purple.light .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.theme-purple .light .mdc-text-field--disabled .mdc-text-field__icon--trailing,
.theme-purple.light .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.theme-purple .light .mdc-text-field--disabled .mdc-text-field__affix--prefix,
.theme-purple.light .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-purple .light .mdc-text-field--disabled .mdc-text-field__affix--suffix,
.theme-purple.light .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-purple .light .mdc-text-field--disabled .mdc-line-ripple::before,
.theme-purple.light .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.theme-purple .light .mdc-text-field--disabled .mdc-notched-outline__leading,
.theme-purple .light .mdc-text-field--disabled .mdc-notched-outline__notch,
.theme-purple .light .mdc-text-field--disabled .mdc-notched-outline__trailing,
.theme-purple.light .mdc-text-field--disabled .mdc-notched-outline__leading,
.theme-purple.light .mdc-text-field--disabled .mdc-notched-outline__notch,
.theme-purple.light .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-purple .light .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  .theme-purple.light .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-purple .light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  .theme-purple.light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-purple .light .mdc-text-field--disabled .mdc-floating-label,
  .theme-purple.light .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-purple .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
  .theme-purple.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-purple .light .mdc-text-field--disabled .mdc-text-field-character-counter,
  .theme-purple .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
  .theme-purple.light .mdc-text-field--disabled .mdc-text-field-character-counter,
  .theme-purple.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-purple .light .mdc-text-field--disabled .mdc-text-field__icon--leading,
  .theme-purple.light .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-purple .light .mdc-text-field--disabled .mdc-text-field__icon--trailing,
  .theme-purple.light .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-purple .light .mdc-text-field--disabled .mdc-text-field__affix--prefix,
  .theme-purple.light .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-purple .light .mdc-text-field--disabled .mdc-text-field__affix--suffix,
  .theme-purple.light .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-purple .light .mdc-text-field--disabled .mdc-line-ripple::before,
  .theme-purple.light .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-purple .light .mdc-text-field--disabled .mdc-notched-outline__leading,
  .theme-purple .light .mdc-text-field--disabled .mdc-notched-outline__notch,
  .theme-purple .light .mdc-text-field--disabled .mdc-notched-outline__trailing,
  .theme-purple.light .mdc-text-field--disabled .mdc-notched-outline__leading,
  .theme-purple.light .mdc-text-field--disabled .mdc-notched-outline__notch,
  .theme-purple.light .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.theme-purple .light .mdc-text-field--disabled.mdc-text-field--filled,
.theme-purple.light .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .theme-purple .light .mdc-text-field--end-aligned .mdc-text-field__input, [dir=rtl] .theme-purple.light .mdc-text-field--end-aligned .mdc-text-field__input, .theme-purple .light .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl], .theme-purple.light .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-purple .light .mdc-floating-label--required, [dir=rtl] .theme-purple.light .mdc-floating-label--required, .theme-purple .light .mdc-floating-label--required[dir=rtl], .theme-purple.light .mdc-floating-label--required[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-purple .light .mat-mdc-form-field-error,
.theme-purple.light .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mat-mdc-form-field-focus-overlay,
.theme-purple.light .mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}
.theme-purple .light .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.theme-purple.light .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
.theme-purple .light .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.theme-purple.light .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}
.theme-purple .light .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.theme-purple.light .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.theme-purple .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.theme-purple.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(147, 51, 234, 0.87);
}
.theme-purple .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.theme-purple.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(30, 41, 59, 0.87);
}
.theme-purple .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.theme-purple.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(220, 38, 38, 0.87);
}
.theme-purple .light .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.theme-purple.light .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.theme-purple .light .mat-mdc-form-field.mat-accent .mdc-text-field__input,
.theme-purple.light .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-purple .light .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-purple.light .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-purple .light .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-purple.light .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(30, 41, 59, 0.87);
}
.theme-purple .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-purple.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-purple.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-purple.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-purple.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input,
.theme-purple.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-purple.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-purple.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-purple .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-purple .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-purple.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-purple.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-purple.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-purple .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-purple .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-purple.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-purple.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-purple.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-purple .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-purple .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-purple.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-purple.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-purple.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-purple .light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-purple .light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-purple.light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-purple.light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-purple.light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-purple .light .mat-mdc-form-field.mat-warn .mdc-text-field__input,
.theme-purple.light .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-purple.light .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-purple.light .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(220, 38, 38, 0.87);
}
.theme-purple .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-purple.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-purple.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-purple.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-purple.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input,
.theme-purple.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-purple.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-purple.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-purple .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-purple .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-purple.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-purple.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-purple.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-purple .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-purple .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-purple.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-purple.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-purple.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-purple .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-purple .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-purple.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-purple.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-purple.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-purple .light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-purple .light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-purple.light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-purple.light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-purple.light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .light .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.theme-purple.light .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.theme-purple .light [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.theme-purple.light [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.theme-purple .light .mdc-menu-surface,
.theme-purple.light .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mdc-list-item__primary-text,
.theme-purple.light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-purple .light .mdc-list-item__secondary-text,
.theme-purple.light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.theme-purple .light .mdc-list-item__overline-text,
.theme-purple.light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-purple .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-purple.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-purple .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-purple.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.theme-purple .light .mdc-list-item__end,
.theme-purple.light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-purple .light .mdc-list-item--disabled .mdc-list-item__start,
.theme-purple .light .mdc-list-item--disabled .mdc-list-item__content,
.theme-purple .light .mdc-list-item--disabled .mdc-list-item__end,
.theme-purple.light .mdc-list-item--disabled .mdc-list-item__start,
.theme-purple.light .mdc-list-item--disabled .mdc-list-item__content,
.theme-purple.light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-purple .light .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-purple.light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-purple.light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-purple.light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-purple.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-purple.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-purple .light .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-purple.light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-purple.light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #9333ea);
}
.theme-purple .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #9333ea);
}
.theme-purple .light .mdc-deprecated-list-group__subheader,
.theme-purple.light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-purple .light .mdc-list-divider::after,
.theme-purple.light .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-purple .light .mdc-list-divider,
.theme-purple.light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-mdc-select-value,
.theme-purple.light .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.theme-purple .light .mat-mdc-select-placeholder,
.theme-purple.light .mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.theme-purple .light .mat-mdc-select-disabled .mat-mdc-select-value,
.theme-purple.light .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.theme-purple .light .mat-mdc-select-arrow,
.theme-purple.light .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.theme-purple .light .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow,
.theme-purple.light .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(147, 51, 234, 0.87);
}
.theme-purple .light .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow,
.theme-purple.light .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(30, 41, 59, 0.87);
}
.theme-purple .light .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow,
.theme-purple.light .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(220, 38, 38, 0.87);
}
.theme-purple .light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow,
.theme-purple.light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(220, 38, 38, 0.87);
}
.theme-purple .light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow,
.theme-purple.light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.theme-purple .light .mdc-menu-surface,
.theme-purple.light .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mdc-list-item__primary-text,
.theme-purple.light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-purple .light .mdc-list-item__secondary-text,
.theme-purple.light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.theme-purple .light .mdc-list-item__overline-text,
.theme-purple.light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-purple .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-purple.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-purple .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-purple.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.theme-purple .light .mdc-list-item__end,
.theme-purple.light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-purple .light .mdc-list-item--disabled .mdc-list-item__start,
.theme-purple .light .mdc-list-item--disabled .mdc-list-item__content,
.theme-purple .light .mdc-list-item--disabled .mdc-list-item__end,
.theme-purple.light .mdc-list-item--disabled .mdc-list-item__start,
.theme-purple.light .mdc-list-item--disabled .mdc-list-item__content,
.theme-purple.light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-purple .light .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-purple.light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-purple.light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-purple.light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-purple.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-purple.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-purple .light .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-purple.light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-purple.light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #9333ea);
}
.theme-purple .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #9333ea);
}
.theme-purple .light .mdc-deprecated-list-group__subheader,
.theme-purple.light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-purple .light .mdc-list-divider::after,
.theme-purple.light .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-purple .light .mdc-list-divider,
.theme-purple.light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-mdc-dialog-container,
.theme-purple.light .mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.theme-purple .light .mat-mdc-standard-chip,
.theme-purple.light .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.theme-purple .light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .theme-purple .light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted,
.theme-purple.light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected,
.theme-purple.light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #9333ea;
  --mdc-chip-elevated-disabled-container-color: #9333ea;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-purple .light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .theme-purple .light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted,
.theme-purple.light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected,
.theme-purple.light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-purple .light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .theme-purple .light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted,
.theme-purple.light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected,
.theme-purple.light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-purple .light .mat-mdc-chip-focus-overlay,
.theme-purple.light .mat-mdc-chip-focus-overlay {
  background: #000000;
}
.theme-purple .light .mat-mdc-slide-toggle,
.theme-purple.light .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.theme-purple .light .mat-mdc-slide-toggle .mdc-form-field,
.theme-purple.light .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-purple .light .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.theme-purple.light .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: #94a3b8;
}
.theme-purple .light .mat-mdc-slide-toggle.mat-primary,
.theme-purple.light .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #9333ea;
  --mdc-switch-selected-handle-color: #9333ea;
  --mdc-switch-selected-hover-state-layer-color: #9333ea;
  --mdc-switch-selected-pressed-state-layer-color: #9333ea;
  --mdc-switch-selected-focus-handle-color: #581c87;
  --mdc-switch-selected-hover-handle-color: #581c87;
  --mdc-switch-selected-pressed-handle-color: #581c87;
  --mdc-switch-selected-focus-track-color: #d8b4fe;
  --mdc-switch-selected-hover-track-color: #d8b4fe;
  --mdc-switch-selected-pressed-track-color: #d8b4fe;
  --mdc-switch-selected-track-color: #d8b4fe;
}
.theme-purple .light .mat-mdc-slide-toggle.mat-accent,
.theme-purple.light .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.theme-purple .light .mat-mdc-slide-toggle.mat-warn,
.theme-purple.light .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.theme-purple .light .mat-mdc-radio-button,
.theme-purple.light .mat-mdc-radio-button {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mat-mdc-radio-ripple-color: #000;
}
.theme-purple .light .mat-mdc-radio-button .mdc-form-field,
.theme-purple.light .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-purple .light .mat-mdc-radio-button .mdc-radio--disabled + label,
.theme-purple.light .mat-mdc-radio-button .mdc-radio--disabled + label {
  color: #94a3b8;
}
.theme-purple .light .mat-mdc-radio-button.mat-primary,
.theme-purple.light .mat-mdc-radio-button.mat-primary {
  --mdc-radio-selected-focus-icon-color: #9333ea;
  --mdc-radio-selected-hover-icon-color: #9333ea;
  --mdc-radio-selected-icon-color: #9333ea;
  --mdc-radio-selected-pressed-icon-color: #9333ea;
  --mat-mdc-radio-checked-ripple-color: #9333ea;
}
.theme-purple .light .mat-mdc-radio-button.mat-accent,
.theme-purple.light .mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-mdc-radio-checked-ripple-color: #1e293b;
}
.theme-purple .light .mat-mdc-radio-button.mat-warn,
.theme-purple.light .mat-mdc-radio-button.mat-warn {
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-mdc-radio-checked-ripple-color: #dc2626;
}
.theme-purple .light .mat-mdc-slider,
.theme-purple.light .mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.theme-purple .light .mat-mdc-slider.mat-primary,
.theme-purple.light .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #9333ea;
  --mdc-slider-focus-handle-color: #9333ea;
  --mdc-slider-hover-handle-color: #9333ea;
  --mdc-slider-active-track-color: #9333ea;
  --mdc-slider-inactive-track-color: #9333ea;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #9333ea;
  --mat-mdc-slider-ripple-color: #9333ea;
  --mat-mdc-slider-hover-ripple-color: rgba(147, 51, 234, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(147, 51, 234, 0.2);
}
.theme-purple .light .mat-mdc-slider.mat-accent,
.theme-purple.light .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mat-mdc-slider-ripple-color: #1e293b;
  --mat-mdc-slider-hover-ripple-color: rgba(30, 41, 59, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(30, 41, 59, 0.2);
}
.theme-purple .light .mat-mdc-slider.mat-warn,
.theme-purple.light .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mat-mdc-slider-ripple-color: #dc2626;
  --mat-mdc-slider-hover-ripple-color: rgba(220, 38, 38, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(220, 38, 38, 0.2);
}
.theme-purple .light .mdc-menu-surface,
.theme-purple.light .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mdc-list-item__primary-text,
.theme-purple.light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-purple .light .mdc-list-item__secondary-text,
.theme-purple.light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.theme-purple .light .mdc-list-item__overline-text,
.theme-purple.light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-purple .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-purple.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-purple .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-purple.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.theme-purple .light .mdc-list-item__end,
.theme-purple.light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-purple .light .mdc-list-item--disabled .mdc-list-item__start,
.theme-purple .light .mdc-list-item--disabled .mdc-list-item__content,
.theme-purple .light .mdc-list-item--disabled .mdc-list-item__end,
.theme-purple.light .mdc-list-item--disabled .mdc-list-item__start,
.theme-purple.light .mdc-list-item--disabled .mdc-list-item__content,
.theme-purple.light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-purple .light .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-purple.light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-purple.light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-purple.light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-purple.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-purple.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-purple .light .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-purple.light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-purple.light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #9333ea);
}
.theme-purple .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #9333ea);
}
.theme-purple .light .mdc-deprecated-list-group__subheader,
.theme-purple.light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-purple .light .mdc-list-divider::after,
.theme-purple.light .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-purple .light .mdc-list-divider,
.theme-purple.light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-mdc-menu-item[disabled],
.theme-purple .light .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.theme-purple .light .mat-mdc-menu-item[disabled] .mat-icon-no-color,
.theme-purple.light .mat-mdc-menu-item[disabled],
.theme-purple.light .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.theme-purple.light .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}
.theme-purple .light .mat-mdc-menu-item .mat-icon-no-color,
.theme-purple .light .mat-mdc-menu-submenu-icon,
.theme-purple.light .mat-mdc-menu-item .mat-icon-no-color,
.theme-purple.light .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-purple .light .mat-mdc-menu-item:hover:not([disabled]),
.theme-purple .light .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.theme-purple .light .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-purple .light .mat-mdc-menu-item-highlighted:not([disabled]),
.theme-purple.light .mat-mdc-menu-item:hover:not([disabled]),
.theme-purple.light .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.theme-purple.light .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-purple.light .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.theme-purple .light .mat-mdc-list-item-interactive::before,
.theme-purple.light .mat-mdc-list-item-interactive::before {
  background: black;
}
.theme-purple .light .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before,
.theme-purple.light .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.04;
}
.theme-purple .light .mat-mdc-list-item-interactive.mdc-list-item--selected::before,
.theme-purple.light .mat-mdc-list-item-interactive.mdc-list-item--selected::before {
  background: #9333ea;
  opacity: 0.08;
}
.theme-purple .light .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before,
.theme-purple.light .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.12;
}
.theme-purple .light .mat-mdc-list-item-interactive:focus::before,
.theme-purple.light .mat-mdc-list-item-interactive:focus::before {
  opacity: 0.12;
}
.theme-purple .light .mdc-list-item__primary-text,
.theme-purple.light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-purple .light .mdc-list-item__secondary-text,
.theme-purple.light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.theme-purple .light .mdc-list-item__overline-text,
.theme-purple.light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-purple .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-purple.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-purple .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-purple.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.theme-purple .light .mdc-list-item__end,
.theme-purple.light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-purple .light .mdc-list-item--disabled .mdc-list-item__start,
.theme-purple .light .mdc-list-item--disabled .mdc-list-item__content,
.theme-purple .light .mdc-list-item--disabled .mdc-list-item__end,
.theme-purple.light .mdc-list-item--disabled .mdc-list-item__start,
.theme-purple.light .mdc-list-item--disabled .mdc-list-item__content,
.theme-purple.light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-purple .light .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-purple.light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-purple.light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-purple.light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-purple.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-purple.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-purple .light .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-purple.light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-purple.light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #9333ea);
}
.theme-purple .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #9333ea);
}
.theme-purple .light .mdc-deprecated-list-group__subheader,
.theme-purple.light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-purple .light .mdc-list-divider::after,
.theme-purple.light .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-purple .light .mdc-list-divider,
.theme-purple.light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-mdc-list-option .mdc-list-item__start, .theme-purple .light .mat-mdc-list-option .mdc-list-item__end,
.theme-purple.light .mat-mdc-list-option .mdc-list-item__start,
.theme-purple.light .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #9333ea;
  --mdc-checkbox-selected-hover-icon-color: #9333ea;
  --mdc-checkbox-selected-icon-color: #9333ea;
  --mdc-checkbox-selected-pressed-icon-color: #9333ea;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-purple .light .mat-mdc-list-option.mat-accent .mdc-list-item__start, .theme-purple .light .mat-mdc-list-option.mat-accent .mdc-list-item__end,
.theme-purple.light .mat-mdc-list-option.mat-accent .mdc-list-item__start,
.theme-purple.light .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-purple .light .mat-mdc-list-option.mat-warn .mdc-list-item__start, .theme-purple .light .mat-mdc-list-option.mat-warn .mdc-list-item__end,
.theme-purple.light .mat-mdc-list-option.mat-warn .mdc-list-item__start,
.theme-purple.light .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-purple .light .mat-mdc-paginator,
.theme-purple.light .mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme-purple .light .mat-mdc-paginator-icon,
.theme-purple.light .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}
.theme-purple .light .mat-mdc-paginator-decrement,
.theme-purple .light .mat-mdc-paginator-increment,
.theme-purple.light .mat-mdc-paginator-decrement,
.theme-purple.light .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.theme-purple .light .mat-mdc-paginator-first,
.theme-purple .light .mat-mdc-paginator-last,
.theme-purple.light .mat-mdc-paginator-first,
.theme-purple.light .mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.theme-purple .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.theme-purple .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.theme-purple .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.theme-purple .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last,
.theme-purple.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.theme-purple.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.theme-purple.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.theme-purple.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon,
.theme-purple.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-mdc-tab, .theme-purple .light .mat-mdc-tab-link,
.theme-purple.light .mat-mdc-tab,
.theme-purple.light .mat-mdc-tab-link {
  background-color: transparent;
}
.theme-purple .light .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label, .theme-purple .light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.theme-purple.light .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.theme-purple.light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}
.theme-purple .light .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-purple .light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-purple.light .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-purple.light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #9333ea;
}
.theme-purple .light .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-purple .light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-purple.light .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-purple.light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #9333ea;
}
.theme-purple .light .mdc-tab__ripple::before,
.theme-purple .light .mat-mdc-tab .mat-ripple-element,
.theme-purple .light .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-purple .light .mat-mdc-tab-link .mat-ripple-element,
.theme-purple.light .mdc-tab__ripple::before,
.theme-purple.light .mat-mdc-tab .mat-ripple-element,
.theme-purple.light .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-purple.light .mat-mdc-tab-link .mat-ripple-element {
  background-color: #9333ea;
}
.theme-purple .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-purple .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-purple .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-purple .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-purple.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-purple.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-purple.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-purple.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #1e293b;
}
.theme-purple .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-purple .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-purple .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-purple .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-purple.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-purple.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-purple.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-purple.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #1e293b;
}
.theme-purple .light .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.theme-purple .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-purple .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-purple .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .theme-purple .light .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.theme-purple .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-purple .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-purple .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element,
.theme-purple.light .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.theme-purple.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-purple.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-purple.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element,
.theme-purple.light .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.theme-purple.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-purple.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-purple.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #1e293b;
}
.theme-purple .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-purple .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-purple .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-purple .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-purple.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-purple.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-purple.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-purple.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #dc2626;
}
.theme-purple .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-purple .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-purple .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-purple .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-purple.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-purple.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-purple.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-purple.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #dc2626;
}
.theme-purple .light .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.theme-purple .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-purple .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-purple .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .theme-purple .light .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.theme-purple .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-purple .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-purple .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element,
.theme-purple.light .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.theme-purple.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-purple.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-purple.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element,
.theme-purple.light .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.theme-purple.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-purple.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-purple.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #dc2626;
}
.theme-purple .light .mat-mdc-tab-group.mat-background-primary, .theme-purple .light .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-purple.light .mat-mdc-tab-group.mat-background-primary,
.theme-purple.light .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #9333ea;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-purple .light .mat-mdc-tab-group.mat-background-accent, .theme-purple .light .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-purple.light .mat-mdc-tab-group.mat-background-accent,
.theme-purple.light .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #1e293b;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-purple .light .mat-mdc-tab-group.mat-background-warn, .theme-purple .light .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-purple.light .mat-mdc-tab-group.mat-background-warn,
.theme-purple.light .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #dc2626;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-purple .light .mat-mdc-tab-header-pagination-chevron,
.theme-purple.light .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #000);
}
.theme-purple .light .mat-mdc-checkbox .mdc-form-field,
.theme-purple.light .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-purple .light .mat-mdc-checkbox .mat-ripple-element,
.theme-purple.light .mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.theme-purple .light .mat-mdc-checkbox .mdc-checkbox__ripple,
.theme-purple.light .mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #000;
}
.theme-purple .light .mat-mdc-checkbox.mat-primary,
.theme-purple.light .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #9333ea;
  --mdc-checkbox-selected-hover-icon-color: #9333ea;
  --mdc-checkbox-selected-icon-color: #9333ea;
  --mdc-checkbox-selected-pressed-icon-color: #9333ea;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-purple .light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-purple.light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(147, 51, 234, 0.1);
}
.theme-purple .light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-purple.light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #9333ea;
}
.theme-purple .light .mat-mdc-checkbox.mat-accent,
.theme-purple.light .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-purple .light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-purple.light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(30, 41, 59, 0.1);
}
.theme-purple .light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-purple.light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #1e293b;
}
.theme-purple .light .mat-mdc-checkbox.mat-warn,
.theme-purple.light .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-purple .light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-purple.light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(220, 38, 38, 0.1);
}
.theme-purple .light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-purple.light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #dc2626;
}
.theme-purple .light .mat-mdc-checkbox-disabled label,
.theme-purple.light .mat-mdc-checkbox-disabled label {
  color: #94a3b8;
}
.theme-purple .light .mat-mdc-button.mat-unthemed,
.theme-purple.light .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.theme-purple .light .mat-mdc-button.mat-primary,
.theme-purple.light .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #9333ea;
}
.theme-purple .light .mat-mdc-button.mat-accent,
.theme-purple.light .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
}
.theme-purple .light .mat-mdc-button.mat-warn,
.theme-purple.light .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
}
.theme-purple .light .mat-mdc-button[disabled][disabled],
.theme-purple.light .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.theme-purple .light .mat-mdc-unelevated-button.mat-unthemed,
.theme-purple.light .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.theme-purple .light .mat-mdc-unelevated-button.mat-primary,
.theme-purple.light .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #9333ea;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-purple .light .mat-mdc-unelevated-button.mat-accent,
.theme-purple.light .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-purple .light .mat-mdc-unelevated-button.mat-warn,
.theme-purple.light .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-purple .light .mat-mdc-unelevated-button[disabled][disabled],
.theme-purple.light .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.theme-purple .light .mat-mdc-raised-button.mat-unthemed,
.theme-purple.light .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.theme-purple .light .mat-mdc-raised-button.mat-primary,
.theme-purple.light .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #9333ea;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-purple .light .mat-mdc-raised-button.mat-accent,
.theme-purple.light .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-purple .light .mat-mdc-raised-button.mat-warn,
.theme-purple.light .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-purple .light .mat-mdc-raised-button[disabled][disabled],
.theme-purple.light .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.theme-purple .light .mat-mdc-outlined-button,
.theme-purple.light .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-mdc-outlined-button.mat-unthemed,
.theme-purple.light .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.theme-purple .light .mat-mdc-outlined-button.mat-primary,
.theme-purple.light .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #9333ea;
}
.theme-purple .light .mat-mdc-outlined-button.mat-accent,
.theme-purple.light .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
}
.theme-purple .light .mat-mdc-outlined-button.mat-warn,
.theme-purple.light .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
}
.theme-purple .light .mat-mdc-outlined-button[disabled][disabled],
.theme-purple.light .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-mdc-button, .theme-purple .light .mat-mdc-outlined-button,
.theme-purple.light .mat-mdc-button,
.theme-purple.light .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-purple .light .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .theme-purple .light .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-purple .light .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-purple .light .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-purple .light .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-purple .light .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-purple .light .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .theme-purple .light .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-purple .light .mat-mdc-button.mat-primary, .theme-purple .light .mat-mdc-outlined-button.mat-primary,
.theme-purple.light .mat-mdc-button.mat-primary,
.theme-purple.light .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #9333ea;
  --mat-mdc-button-ripple-color: rgba(147, 51, 234, 0.1);
}
.theme-purple .light .mat-mdc-button.mat-accent, .theme-purple .light .mat-mdc-outlined-button.mat-accent,
.theme-purple.light .mat-mdc-button.mat-accent,
.theme-purple.light .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #1e293b;
  --mat-mdc-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-purple .light .mat-mdc-button.mat-warn, .theme-purple .light .mat-mdc-outlined-button.mat-warn,
.theme-purple.light .mat-mdc-button.mat-warn,
.theme-purple.light .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #dc2626;
  --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-purple .light .mat-mdc-raised-button, .theme-purple .light .mat-mdc-unelevated-button,
.theme-purple.light .mat-mdc-raised-button,
.theme-purple.light .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-purple .light .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .theme-purple .light .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-purple .light .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-purple .light .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-purple .light .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-purple .light .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-purple .light .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .theme-purple .light .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-purple .light .mat-mdc-raised-button.mat-primary, .theme-purple .light .mat-mdc-unelevated-button.mat-primary,
.theme-purple.light .mat-mdc-raised-button.mat-primary,
.theme-purple.light .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .light .mat-mdc-raised-button.mat-accent, .theme-purple .light .mat-mdc-unelevated-button.mat-accent,
.theme-purple.light .mat-mdc-raised-button.mat-accent,
.theme-purple.light .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .light .mat-mdc-raised-button.mat-warn, .theme-purple .light .mat-mdc-unelevated-button.mat-warn,
.theme-purple.light .mat-mdc-raised-button.mat-warn,
.theme-purple.light .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .light .mat-mdc-icon-button,
.theme-purple.light .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-purple .light .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-purple .light .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-purple .light .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-purple .light .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-purple .light .mat-mdc-icon-button.mat-primary,
.theme-purple.light .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #9333ea;
  --mat-mdc-button-ripple-color: rgba(147, 51, 234, 0.1);
}
.theme-purple .light .mat-mdc-icon-button.mat-accent,
.theme-purple.light .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #1e293b;
  --mat-mdc-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-purple .light .mat-mdc-icon-button.mat-warn,
.theme-purple.light .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #dc2626;
  --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-purple .light .mat-mdc-icon-button.mat-primary,
.theme-purple.light .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #9333ea;
}
.theme-purple .light .mat-mdc-icon-button.mat-accent,
.theme-purple.light .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
}
.theme-purple .light .mat-mdc-icon-button.mat-warn,
.theme-purple.light .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
}
.theme-purple .light .mat-mdc-icon-button[disabled][disabled],
.theme-purple.light .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.theme-purple .light .mat-mdc-fab, .theme-purple .light .mat-mdc-mini-fab,
.theme-purple.light .mat-mdc-fab,
.theme-purple.light .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-purple .light .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .theme-purple .light .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-purple .light .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-purple .light .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-purple .light .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-purple .light .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-purple .light .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .theme-purple .light .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.theme-purple.light .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-purple .light .mat-mdc-fab.mat-primary, .theme-purple .light .mat-mdc-mini-fab.mat-primary,
.theme-purple.light .mat-mdc-fab.mat-primary,
.theme-purple.light .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .light .mat-mdc-fab.mat-accent, .theme-purple .light .mat-mdc-mini-fab.mat-accent,
.theme-purple.light .mat-mdc-fab.mat-accent,
.theme-purple.light .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .light .mat-mdc-fab.mat-warn, .theme-purple .light .mat-mdc-mini-fab.mat-warn,
.theme-purple.light .mat-mdc-fab.mat-warn,
.theme-purple.light .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .light .mat-mdc-fab.mat-unthemed, .theme-purple .light .mat-mdc-mini-fab.mat-unthemed,
.theme-purple.light .mat-mdc-fab.mat-unthemed,
.theme-purple.light .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.theme-purple .light .mat-mdc-fab.mat-primary, .theme-purple .light .mat-mdc-mini-fab.mat-primary,
.theme-purple.light .mat-mdc-fab.mat-primary,
.theme-purple.light .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #9333ea;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-purple .light .mat-mdc-fab.mat-accent, .theme-purple .light .mat-mdc-mini-fab.mat-accent,
.theme-purple.light .mat-mdc-fab.mat-accent,
.theme-purple.light .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-purple .light .mat-mdc-fab.mat-warn, .theme-purple .light .mat-mdc-mini-fab.mat-warn,
.theme-purple.light .mat-mdc-fab.mat-warn,
.theme-purple.light .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-purple .light .mat-mdc-fab[disabled][disabled], .theme-purple .light .mat-mdc-mini-fab[disabled][disabled],
.theme-purple.light .mat-mdc-fab[disabled][disabled],
.theme-purple.light .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.theme-purple .light .mat-mdc-snack-bar-container,
.theme-purple.light .mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #1e293b;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
}
.theme-purple .light .mdc-data-table,
.theme-purple.light .mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mdc-data-table__row,
.theme-purple.light .mdc-data-table__row {
  background-color: inherit;
}
.theme-purple .light .mdc-data-table__header-cell,
.theme-purple.light .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}
.theme-purple .light .mdc-data-table__row--selected,
.theme-purple.light .mdc-data-table__row--selected {
  background-color: rgba(147, 51, 234, 0.04);
}
.theme-purple .light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.theme-purple .light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.theme-purple .light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing,
.theme-purple.light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.theme-purple.light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.theme-purple.light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mdc-data-table__cell,
.theme-purple .light .mdc-data-table__header-cell,
.theme-purple.light .mdc-data-table__cell,
.theme-purple.light .mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mdc-data-table__pagination,
.theme-purple.light .mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mdc-data-table__row:not(.mdc-data-table__row--selected):hover,
.theme-purple.light .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.theme-purple .light .mdc-data-table__header-cell,
.theme-purple.light .mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}
.theme-purple .light .mdc-data-table__pagination-total,
.theme-purple .light .mdc-data-table__pagination-rows-per-page-label,
.theme-purple .light .mdc-data-table__cell,
.theme-purple.light .mdc-data-table__pagination-total,
.theme-purple.light .mdc-data-table__pagination-rows-per-page-label,
.theme-purple.light .mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}
[dir=rtl] .theme-purple .light .mdc-data-table__pagination-button .mdc-button__icon, [dir=rtl] .theme-purple.light .mdc-data-table__pagination-button .mdc-button__icon, .theme-purple .light .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl], .theme-purple.light .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-purple .light .mat-mdc-table,
.theme-purple.light .mat-mdc-table {
  background: white;
}
.theme-purple .light .mat-mdc-progress-spinner,
.theme-purple.light .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #9333ea;
}
.theme-purple .light .mat-mdc-progress-spinner.mat-accent,
.theme-purple.light .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-purple .light .mat-mdc-progress-spinner.mat-warn,
.theme-purple.light .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-purple .light .mat-badge-content,
.theme-purple.light .mat-badge-content {
  color: white;
  background: #9333ea;
}
.cdk-high-contrast-active .theme-purple .light .mat-badge-content, .cdk-high-contrast-active .theme-purple.light .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.theme-purple .light .mat-badge-accent .mat-badge-content,
.theme-purple.light .mat-badge-accent .mat-badge-content {
  background: #1e293b;
  color: white;
}
.theme-purple .light .mat-badge-warn .mat-badge-content,
.theme-purple.light .mat-badge-warn .mat-badge-content {
  color: white;
  background: #dc2626;
}
.theme-purple .light .mat-badge-disabled .mat-badge-content,
.theme-purple.light .mat-badge-disabled .mat-badge-content {
  background: #94a3b8;
  color: #94a3b8;
}
.theme-purple .light .mat-bottom-sheet-container,
.theme-purple.light .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: #1e293b;
}
.theme-purple .light .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-purple .light .mat-button-toggle-group:not([class*=mat-elevation-z]),
.theme-purple.light .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-purple.light .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-purple .light .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]),
.theme-purple.light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-purple.light .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.theme-purple .light .mat-button-toggle,
.theme-purple.light .mat-button-toggle {
  color: #94a3b8;
}
.theme-purple .light .mat-button-toggle .mat-button-toggle-focus-overlay,
.theme-purple.light .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: #64748b;
}
.theme-purple .light .mat-button-toggle-appearance-standard,
.theme-purple.light .mat-button-toggle-appearance-standard {
  color: #1e293b;
  background: white;
}
.theme-purple .light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay,
.theme-purple.light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: #64748b;
}
.theme-purple .light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
.theme-purple.light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e2e8f0;
}
.theme-purple .light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
.theme-purple.light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e2e8f0;
}
.theme-purple .light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle,
.theme-purple.light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e2e8f0;
}
.theme-purple .light .mat-button-toggle-checked,
.theme-purple.light .mat-button-toggle-checked {
  background-color: #e2e8f0;
  color: #64748b;
}
.theme-purple .light .mat-button-toggle-checked.mat-button-toggle-appearance-standard,
.theme-purple.light .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: #1e293b;
}
.theme-purple .light .mat-button-toggle-disabled,
.theme-purple.light .mat-button-toggle-disabled {
  color: #94a3b8;
  background-color: #cbd5e1;
}
.theme-purple .light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard,
.theme-purple.light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.theme-purple .light .mat-button-toggle-disabled.mat-button-toggle-checked,
.theme-purple.light .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #e2e8f0;
}
.theme-purple .light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-purple .light .mat-button-toggle-group-appearance-standard,
.theme-purple.light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-purple.light .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e2e8f0;
}
.theme-purple .light .mat-calendar-arrow,
.theme-purple.light .mat-calendar-arrow {
  fill: #64748b;
}
.theme-purple .light .mat-datepicker-toggle,
.theme-purple .light .mat-datepicker-content .mat-calendar-next-button,
.theme-purple .light .mat-datepicker-content .mat-calendar-previous-button,
.theme-purple.light .mat-datepicker-toggle,
.theme-purple.light .mat-datepicker-content .mat-calendar-next-button,
.theme-purple.light .mat-datepicker-content .mat-calendar-previous-button {
  color: #64748b;
}
.theme-purple .light .mat-calendar-table-header-divider::after,
.theme-purple.light .mat-calendar-table-header-divider::after {
  background: #e2e8f0;
}
.theme-purple .light .mat-calendar-table-header,
.theme-purple .light .mat-calendar-body-label,
.theme-purple.light .mat-calendar-table-header,
.theme-purple.light .mat-calendar-body-label {
  color: #64748b;
}
.theme-purple .light .mat-calendar-body-cell-content,
.theme-purple .light .mat-date-range-input-separator,
.theme-purple.light .mat-calendar-body-cell-content,
.theme-purple.light .mat-date-range-input-separator {
  color: #1e293b;
  border-color: transparent;
}
.theme-purple .light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-purple.light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: #94a3b8;
}
.theme-purple .light .mat-form-field-disabled .mat-date-range-input-separator,
.theme-purple.light .mat-form-field-disabled .mat-date-range-input-separator {
  color: #94a3b8;
}
.theme-purple .light .mat-calendar-body-in-preview,
.theme-purple.light .mat-calendar-body-in-preview {
  color: #e2e8f0;
}
.theme-purple .light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-purple.light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #94a3b8;
}
.theme-purple .light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-purple.light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(148, 163, 184, 0.8);
}
.theme-purple .light .mat-calendar-body-in-range::before,
.theme-purple.light .mat-calendar-body-in-range::before {
  background: rgba(147, 51, 234, 0.2);
}
.theme-purple .light .mat-calendar-body-comparison-identical,
.theme-purple .light .mat-calendar-body-in-comparison-range::before,
.theme-purple.light .mat-calendar-body-comparison-identical,
.theme-purple.light .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-purple .light .mat-calendar-body-comparison-bridge-start::before,
.theme-purple .light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-purple.light .mat-calendar-body-comparison-bridge-start::before,
.theme-purple.light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(147, 51, 234, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-purple .light .mat-calendar-body-comparison-bridge-end::before,
.theme-purple .light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-purple.light .mat-calendar-body-comparison-bridge-end::before,
.theme-purple.light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(147, 51, 234, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-purple .light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-purple .light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-purple.light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-purple.light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-purple .light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-purple .light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-purple.light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-purple.light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-purple .light .mat-calendar-body-selected,
.theme-purple.light .mat-calendar-body-selected {
  background-color: #9333ea;
  color: white;
}
.theme-purple .light .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-purple.light .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(147, 51, 234, 0.4);
}
.theme-purple .light .mat-calendar-body-today.mat-calendar-body-selected,
.theme-purple.light .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-purple .light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-purple .light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-purple.light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-purple.light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(147, 51, 234, 0.3);
}
@media (hover: hover) {
  .theme-purple .light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-purple.light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(147, 51, 234, 0.3);
  }
}
.theme-purple .light .mat-datepicker-content,
.theme-purple.light .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: #1e293b;
}
.theme-purple .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before,
.theme-purple.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(30, 41, 59, 0.2);
}
.theme-purple .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-purple .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before,
.theme-purple.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-purple.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-purple .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-purple .light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-purple.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-purple.light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(30, 41, 59, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-purple .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-purple .light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-purple.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-purple.light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(30, 41, 59, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-purple .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-purple .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-purple.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-purple.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-purple .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-purple .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-purple.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-purple.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-purple .light .mat-datepicker-content.mat-accent .mat-calendar-body-selected,
.theme-purple.light .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #1e293b;
  color: white;
}
.theme-purple .light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-purple.light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(30, 41, 59, 0.4);
}
.theme-purple .light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected,
.theme-purple.light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-purple .light .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-purple .light .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-purple.light .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-purple.light .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(30, 41, 59, 0.3);
}
@media (hover: hover) {
  .theme-purple .light .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-purple.light .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(30, 41, 59, 0.3);
  }
}
.theme-purple .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before,
.theme-purple.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(220, 38, 38, 0.2);
}
.theme-purple .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-purple .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before,
.theme-purple.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-purple.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-purple .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-purple .light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-purple.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-purple.light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(220, 38, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-purple .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-purple .light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-purple.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-purple.light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(220, 38, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-purple .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-purple .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-purple.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-purple.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-purple .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-purple .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-purple.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-purple.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-purple .light .mat-datepicker-content.mat-warn .mat-calendar-body-selected,
.theme-purple.light .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #dc2626;
  color: white;
}
.theme-purple .light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-purple.light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(220, 38, 38, 0.4);
}
.theme-purple .light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected,
.theme-purple.light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-purple .light .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-purple .light .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-purple.light .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-purple.light .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(220, 38, 38, 0.3);
}
@media (hover: hover) {
  .theme-purple .light .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-purple.light .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(220, 38, 38, 0.3);
  }
}
.theme-purple .light .mat-datepicker-content-touch,
.theme-purple.light .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-datepicker-toggle-active,
.theme-purple.light .mat-datepicker-toggle-active {
  color: #9333ea;
}
.theme-purple .light .mat-datepicker-toggle-active.mat-accent,
.theme-purple.light .mat-datepicker-toggle-active.mat-accent {
  color: #1e293b;
}
.theme-purple .light .mat-datepicker-toggle-active.mat-warn,
.theme-purple.light .mat-datepicker-toggle-active.mat-warn {
  color: #dc2626;
}
.theme-purple .light .mat-date-range-input-inner[disabled],
.theme-purple.light .mat-date-range-input-inner[disabled] {
  color: #94a3b8;
}
.theme-purple .light .mat-divider,
.theme-purple.light .mat-divider {
  border-top-color: #e2e8f0;
}
.theme-purple .light .mat-divider-vertical,
.theme-purple.light .mat-divider-vertical {
  border-right-color: #e2e8f0;
}
.theme-purple .light .mat-expansion-panel,
.theme-purple.light .mat-expansion-panel {
  background: white;
  color: #1e293b;
}
.theme-purple .light .mat-expansion-panel:not([class*=mat-elevation-z]),
.theme-purple.light .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-action-row,
.theme-purple.light .mat-action-row {
  border-top-color: #e2e8f0;
}
.theme-purple .light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .theme-purple .light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .theme-purple .light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]),
.theme-purple.light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
.theme-purple.light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
.theme-purple.light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(148, 163, 184, 0.12);
}
@media (hover: none) {
  .theme-purple .light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover,
  .theme-purple.light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.theme-purple .light .mat-expansion-panel-header-title,
.theme-purple.light .mat-expansion-panel-header-title {
  color: #1e293b;
}
.theme-purple .light .mat-expansion-panel-header-description,
.theme-purple .light .mat-expansion-indicator::after,
.theme-purple.light .mat-expansion-panel-header-description,
.theme-purple.light .mat-expansion-indicator::after {
  color: #64748b;
}
.theme-purple .light .mat-expansion-panel-header[aria-disabled=true],
.theme-purple.light .mat-expansion-panel-header[aria-disabled=true] {
  color: #94a3b8;
}
.theme-purple .light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-purple .light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description,
.theme-purple.light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-purple.light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.theme-purple .light .mat-icon.mat-primary,
.theme-purple.light .mat-icon.mat-primary {
  color: #9333ea;
}
.theme-purple .light .mat-icon.mat-accent,
.theme-purple.light .mat-icon.mat-accent {
  color: #1e293b;
}
.theme-purple .light .mat-icon.mat-warn,
.theme-purple.light .mat-icon.mat-warn {
  color: #dc2626;
}
.theme-purple .light .mat-drawer-container,
.theme-purple.light .mat-drawer-container {
  background-color: #f1f5f9;
  color: #1e293b;
}
.theme-purple .light .mat-drawer,
.theme-purple.light .mat-drawer {
  background-color: white;
  color: #1e293b;
}
.theme-purple .light .mat-drawer.mat-drawer-push,
.theme-purple.light .mat-drawer.mat-drawer-push {
  background-color: white;
}
.theme-purple .light .mat-drawer:not(.mat-drawer-side),
.theme-purple.light .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-drawer-side,
.theme-purple.light .mat-drawer-side {
  border-right: solid 1px #e2e8f0;
}
.theme-purple .light .mat-drawer-side.mat-drawer-end,
.theme-purple.light .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px #e2e8f0;
  border-right: none;
}
.theme-purple .light [dir=rtl] .mat-drawer-side,
.theme-purple.light [dir=rtl] .mat-drawer-side {
  border-left: solid 1px #e2e8f0;
  border-right: none;
}
.theme-purple .light [dir=rtl] .mat-drawer-side.mat-drawer-end,
.theme-purple.light [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px #e2e8f0;
}
.theme-purple .light .mat-drawer-backdrop.mat-drawer-shown,
.theme-purple.light .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.theme-purple .light .mat-step-header.cdk-keyboard-focused, .theme-purple .light .mat-step-header.cdk-program-focused, .theme-purple .light .mat-step-header:hover:not([aria-disabled]), .theme-purple .light .mat-step-header:hover[aria-disabled=false],
.theme-purple.light .mat-step-header.cdk-keyboard-focused,
.theme-purple.light .mat-step-header.cdk-program-focused,
.theme-purple.light .mat-step-header:hover:not([aria-disabled]),
.theme-purple.light .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(148, 163, 184, 0.12);
}
.theme-purple .light .mat-step-header:hover[aria-disabled=true],
.theme-purple.light .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .theme-purple .light .mat-step-header:hover,
  .theme-purple.light .mat-step-header:hover {
    background: none;
  }
}
.theme-purple .light .mat-step-header .mat-step-label,
.theme-purple .light .mat-step-header .mat-step-optional,
.theme-purple.light .mat-step-header .mat-step-label,
.theme-purple.light .mat-step-header .mat-step-optional {
  color: #64748b;
}
.theme-purple .light .mat-step-header .mat-step-icon,
.theme-purple.light .mat-step-header .mat-step-icon {
  background-color: #64748b;
  color: white;
}
.theme-purple .light .mat-step-header .mat-step-icon-selected,
.theme-purple .light .mat-step-header .mat-step-icon-state-done,
.theme-purple .light .mat-step-header .mat-step-icon-state-edit,
.theme-purple.light .mat-step-header .mat-step-icon-selected,
.theme-purple.light .mat-step-header .mat-step-icon-state-done,
.theme-purple.light .mat-step-header .mat-step-icon-state-edit {
  background-color: #9333ea;
  color: white;
}
.theme-purple .light .mat-step-header.mat-accent .mat-step-icon,
.theme-purple.light .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.theme-purple .light .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-purple .light .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-purple .light .mat-step-header.mat-accent .mat-step-icon-state-edit,
.theme-purple.light .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-purple.light .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-purple.light .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #1e293b;
  color: white;
}
.theme-purple .light .mat-step-header.mat-warn .mat-step-icon,
.theme-purple.light .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.theme-purple .light .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-purple .light .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-purple .light .mat-step-header.mat-warn .mat-step-icon-state-edit,
.theme-purple.light .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-purple.light .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-purple.light .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #dc2626;
  color: white;
}
.theme-purple .light .mat-step-header .mat-step-icon-state-error,
.theme-purple.light .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #dc2626;
}
.theme-purple .light .mat-step-header .mat-step-label.mat-step-label-active,
.theme-purple.light .mat-step-header .mat-step-label.mat-step-label-active {
  color: #1e293b;
}
.theme-purple .light .mat-step-header .mat-step-label.mat-step-label-error,
.theme-purple.light .mat-step-header .mat-step-label.mat-step-label-error {
  color: #dc2626;
}
.theme-purple .light .mat-stepper-horizontal, .theme-purple .light .mat-stepper-vertical,
.theme-purple.light .mat-stepper-horizontal,
.theme-purple.light .mat-stepper-vertical {
  background-color: white;
}
.theme-purple .light .mat-stepper-vertical-line::before,
.theme-purple.light .mat-stepper-vertical-line::before {
  border-left-color: #e2e8f0;
}
.theme-purple .light .mat-horizontal-stepper-header::before,
.theme-purple .light .mat-horizontal-stepper-header::after,
.theme-purple .light .mat-stepper-horizontal-line,
.theme-purple.light .mat-horizontal-stepper-header::before,
.theme-purple.light .mat-horizontal-stepper-header::after,
.theme-purple.light .mat-stepper-horizontal-line {
  border-top-color: #e2e8f0;
}
.theme-purple .light .mat-sort-header-arrow,
.theme-purple.light .mat-sort-header-arrow {
  color: #64748b;
}
.theme-purple .light .mat-toolbar,
.theme-purple.light .mat-toolbar {
  background: white;
  color: #1e293b;
}
.theme-purple .light .mat-toolbar.mat-primary,
.theme-purple.light .mat-toolbar.mat-primary {
  background: #9333ea;
  color: white;
}
.theme-purple .light .mat-toolbar.mat-accent,
.theme-purple.light .mat-toolbar.mat-accent {
  background: #1e293b;
  color: white;
}
.theme-purple .light .mat-toolbar.mat-warn,
.theme-purple.light .mat-toolbar.mat-warn {
  background: #dc2626;
  color: white;
}
.theme-purple .light .mat-toolbar .mat-form-field-underline,
.theme-purple .light .mat-toolbar .mat-form-field-ripple,
.theme-purple .light .mat-toolbar .mat-focused .mat-form-field-ripple,
.theme-purple.light .mat-toolbar .mat-form-field-underline,
.theme-purple.light .mat-toolbar .mat-form-field-ripple,
.theme-purple.light .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.theme-purple .light .mat-toolbar .mat-form-field-label,
.theme-purple .light .mat-toolbar .mat-focused .mat-form-field-label,
.theme-purple .light .mat-toolbar .mat-select-value,
.theme-purple .light .mat-toolbar .mat-select-arrow,
.theme-purple .light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow,
.theme-purple.light .mat-toolbar .mat-form-field-label,
.theme-purple.light .mat-toolbar .mat-focused .mat-form-field-label,
.theme-purple.light .mat-toolbar .mat-select-value,
.theme-purple.light .mat-toolbar .mat-select-arrow,
.theme-purple.light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.theme-purple .light .mat-toolbar .mat-input-element,
.theme-purple.light .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.theme-purple .light .mat-tree,
.theme-purple.light .mat-tree {
  background: white;
}
.theme-purple .light .mat-tree-node,
.theme-purple .light .mat-nested-tree-node,
.theme-purple.light .mat-tree-node,
.theme-purple.light .mat-nested-tree-node {
  color: #1e293b;
}

.theme-purple .dark .mat-ripple-element,
.theme-purple.dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .dark .mat-mdc-option,
.theme-purple.dark .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-purple .dark .mat-mdc-option:hover:not(.mdc-list-item--disabled), .theme-purple .dark .mat-mdc-option:focus:not(.mdc-list-item--disabled), .theme-purple .dark .mat-mdc-option.mat-mdc-option-active, .theme-purple .dark .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled),
.theme-purple.dark .mat-mdc-option:hover:not(.mdc-list-item--disabled),
.theme-purple.dark .mat-mdc-option:focus:not(.mdc-list-item--disabled),
.theme-purple.dark .mat-mdc-option.mat-mdc-option-active,
.theme-purple.dark .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(255, 255, 255, 0.08);
}
.theme-purple .dark .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-purple.dark .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #9333ea);
}
.theme-purple .dark .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-purple.dark .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #1e293b);
}
.theme-purple .dark .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-purple.dark .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mat-mdc-optgroup-label,
.theme-purple.dark .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-purple .dark .mat-pseudo-checkbox,
.theme-purple.dark .mat-pseudo-checkbox {
  color: #94a3b8;
}
.theme-purple .dark .mat-pseudo-checkbox::after,
.theme-purple.dark .mat-pseudo-checkbox::after {
  color: #0f172a;
}
.theme-purple .dark .mat-pseudo-checkbox-disabled,
.theme-purple.dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.theme-purple .dark .mat-primary .mat-pseudo-checkbox-checked,
.theme-purple .dark .mat-primary .mat-pseudo-checkbox-indeterminate,
.theme-purple.dark .mat-primary .mat-pseudo-checkbox-checked,
.theme-purple.dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #9333ea;
}
.theme-purple .dark .mat-pseudo-checkbox-checked,
.theme-purple .dark .mat-pseudo-checkbox-indeterminate,
.theme-purple .dark .mat-accent .mat-pseudo-checkbox-checked,
.theme-purple .dark .mat-accent .mat-pseudo-checkbox-indeterminate,
.theme-purple.dark .mat-pseudo-checkbox-checked,
.theme-purple.dark .mat-pseudo-checkbox-indeterminate,
.theme-purple.dark .mat-accent .mat-pseudo-checkbox-checked,
.theme-purple.dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #1e293b;
}
.theme-purple .dark .mat-warn .mat-pseudo-checkbox-checked,
.theme-purple .dark .mat-warn .mat-pseudo-checkbox-indeterminate,
.theme-purple.dark .mat-warn .mat-pseudo-checkbox-checked,
.theme-purple.dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #dc2626;
}
.theme-purple .dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-purple .dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled,
.theme-purple.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-purple.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.theme-purple .dark .mat-app-background, .theme-purple .dark.mat-app-background,
.theme-purple.dark .mat-app-background,
.theme-purple.dark.mat-app-background {
  background-color: #0f172a;
  color: white;
}
.theme-purple .dark .mat-elevation-z0, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-purple.dark .mat-elevation-z0,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z1, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-purple.dark .mat-elevation-z1,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z2, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-purple.dark .mat-elevation-z2,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z3, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-purple.dark .mat-elevation-z3,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z4, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-purple.dark .mat-elevation-z4,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z5, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-purple.dark .mat-elevation-z5,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z6, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-purple.dark .mat-elevation-z6,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z7, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-purple.dark .mat-elevation-z7,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z8, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-purple.dark .mat-elevation-z8,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z9, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-purple.dark .mat-elevation-z9,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z10, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-purple.dark .mat-elevation-z10,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z11, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-purple.dark .mat-elevation-z11,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z12, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-purple.dark .mat-elevation-z12,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z13, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-purple.dark .mat-elevation-z13,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z14, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-purple.dark .mat-elevation-z14,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z15, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-purple.dark .mat-elevation-z15,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z16, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-purple.dark .mat-elevation-z16,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z17, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-purple.dark .mat-elevation-z17,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z18, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-purple.dark .mat-elevation-z18,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z19, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-purple.dark .mat-elevation-z19,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z20, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-purple.dark .mat-elevation-z20,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z21, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-purple.dark .mat-elevation-z21,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z22, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-purple.dark .mat-elevation-z22,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z23, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-purple.dark .mat-elevation-z23,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z24, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-purple.dark .mat-elevation-z24,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-purple .dark .mat-mdc-card,
.theme-purple.dark .mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #1e293b;
}
.theme-purple .dark .mat-mdc-card-outlined,
.theme-purple.dark .mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #394353;
}
.theme-purple .dark .mat-mdc-card-subtitle,
.theme-purple.dark .mat-mdc-card-subtitle {
  color: #94a3b8;
}
.theme-purple .dark .mat-mdc-progress-bar,
.theme-purple.dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #9333ea;
}
.theme-purple .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.theme-purple.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(147, 51, 234, 0.25)'/%3E%3C/svg%3E");
}
.theme-purple .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.theme-purple.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(147, 51, 234, 0.25);
}
.theme-purple .dark .mat-mdc-progress-bar.mat-accent,
.theme-purple.dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
}
.theme-purple .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.theme-purple.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(30, 41, 59, 0.25)'/%3E%3C/svg%3E");
}
.theme-purple .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.theme-purple.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(30, 41, 59, 0.25);
}
.theme-purple .dark .mat-mdc-progress-bar.mat-warn,
.theme-purple.dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
}
.theme-purple .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.theme-purple.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(220, 38, 38, 0.25)'/%3E%3C/svg%3E");
}
.theme-purple .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.theme-purple.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(220, 38, 38, 0.25);
}
.theme-purple .dark .mat-mdc-tooltip,
.theme-purple.dark .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.theme-purple .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-purple.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 255, 255, 0.6);
}
.theme-purple .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input,
.theme-purple.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.87);
}
@media all {
  .theme-purple .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder,
  .theme-purple.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}
@media all {
  .theme-purple .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder,
  .theme-purple.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}
.theme-purple .dark .mdc-text-field .mdc-text-field__input,
.theme-purple.dark .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #9333ea);
}
.theme-purple .dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text,
.theme-purple.dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.theme-purple .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.theme-purple .dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter,
.theme-purple.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.theme-purple.dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.theme-purple .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading,
.theme-purple.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.theme-purple .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-purple.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.theme-purple .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix,
.theme-purple.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-purple .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix,
.theme-purple.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-purple .dark .mdc-text-field--filled .mdc-text-field__ripple::before,
.theme-purple .dark .mdc-text-field--filled .mdc-text-field__ripple::after,
.theme-purple.dark .mdc-text-field--filled .mdc-text-field__ripple::before,
.theme-purple.dark .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(255, 255, 255, 0.87));
}
.theme-purple .dark .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .theme-purple .dark .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before,
.theme-purple.dark .mdc-text-field--filled:hover .mdc-text-field__ripple::before,
.theme-purple.dark .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.theme-purple .dark .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .theme-purple .dark .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before,
.theme-purple.dark .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before,
.theme-purple.dark .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.theme-purple .dark .mdc-text-field--filled:not(.mdc-text-field--disabled),
.theme-purple.dark .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #273243;
}
.theme-purple .dark .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-purple.dark .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.42);
}
.theme-purple .dark .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-purple.dark .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.87);
}
.theme-purple .dark .mdc-text-field--filled .mdc-line-ripple::after,
.theme-purple.dark .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #9333ea);
}
.theme-purple .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-purple .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-purple .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-purple.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-purple.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-purple.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.38);
}
.theme-purple .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-purple .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-purple .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-purple.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-purple.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-purple.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.87);
}
.theme-purple .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-purple .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-purple .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-purple.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-purple.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-purple.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #9333ea);
}
[dir=rtl] .theme-purple .dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon, [dir=rtl] .theme-purple.dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .theme-purple .dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl], .theme-purple.dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-purple .dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, [dir=rtl] .theme-purple.dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .theme-purple .dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl], .theme-purple.dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-purple .dark .mdc-text-field--outlined .mdc-text-field__ripple::before,
.theme-purple .dark .mdc-text-field--outlined .mdc-text-field__ripple::after,
.theme-purple.dark .mdc-text-field--outlined .mdc-text-field__ripple::before,
.theme-purple.dark .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .theme-purple .dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined, [dir=rtl] .theme-purple.dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .theme-purple .dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl], .theme-purple.dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-purple .dark .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-purple.dark .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(147, 51, 234, 0.87);
}
.theme-purple .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-purple.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-purple.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-purple.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-purple.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mdc-text-field--invalid .mdc-text-field__input,
.theme-purple.dark .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-purple.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-purple.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-purple .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-purple .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-purple.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-purple.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-purple.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-purple .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-purple .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-purple.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-purple.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-purple.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-purple .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-purple .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-purple.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-purple.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-purple.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mdc-text-field--disabled .mdc-text-field__input,
.theme-purple.dark .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.38);
}
@media all {
  .theme-purple .dark .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  .theme-purple.dark .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.38);
  }
}
@media all {
  .theme-purple .dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  .theme-purple.dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.38);
  }
}
.theme-purple .dark .mdc-text-field--disabled .mdc-floating-label,
.theme-purple.dark .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(255, 255, 255, 0.38);
}
.theme-purple .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
.theme-purple.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.theme-purple .dark .mdc-text-field--disabled .mdc-text-field-character-counter,
.theme-purple .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
.theme-purple.dark .mdc-text-field--disabled .mdc-text-field-character-counter,
.theme-purple.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.theme-purple .dark .mdc-text-field--disabled .mdc-text-field__icon--leading,
.theme-purple.dark .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.theme-purple .dark .mdc-text-field--disabled .mdc-text-field__icon--trailing,
.theme-purple.dark .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.theme-purple .dark .mdc-text-field--disabled .mdc-text-field__affix--prefix,
.theme-purple.dark .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-purple .dark .mdc-text-field--disabled .mdc-text-field__affix--suffix,
.theme-purple.dark .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-purple .dark .mdc-text-field--disabled .mdc-line-ripple::before,
.theme-purple.dark .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.06);
}
.theme-purple .dark .mdc-text-field--disabled .mdc-notched-outline__leading,
.theme-purple .dark .mdc-text-field--disabled .mdc-notched-outline__notch,
.theme-purple .dark .mdc-text-field--disabled .mdc-notched-outline__trailing,
.theme-purple.dark .mdc-text-field--disabled .mdc-notched-outline__leading,
.theme-purple.dark .mdc-text-field--disabled .mdc-notched-outline__notch,
.theme-purple.dark .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-purple .dark .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  .theme-purple.dark .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-purple .dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  .theme-purple.dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-purple .dark .mdc-text-field--disabled .mdc-floating-label,
  .theme-purple.dark .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-purple .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
  .theme-purple.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-purple .dark .mdc-text-field--disabled .mdc-text-field-character-counter,
  .theme-purple .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
  .theme-purple.dark .mdc-text-field--disabled .mdc-text-field-character-counter,
  .theme-purple.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-purple .dark .mdc-text-field--disabled .mdc-text-field__icon--leading,
  .theme-purple.dark .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-purple .dark .mdc-text-field--disabled .mdc-text-field__icon--trailing,
  .theme-purple.dark .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-purple .dark .mdc-text-field--disabled .mdc-text-field__affix--prefix,
  .theme-purple.dark .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-purple .dark .mdc-text-field--disabled .mdc-text-field__affix--suffix,
  .theme-purple.dark .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-purple .dark .mdc-text-field--disabled .mdc-line-ripple::before,
  .theme-purple.dark .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-purple .dark .mdc-text-field--disabled .mdc-notched-outline__leading,
  .theme-purple .dark .mdc-text-field--disabled .mdc-notched-outline__notch,
  .theme-purple .dark .mdc-text-field--disabled .mdc-notched-outline__trailing,
  .theme-purple.dark .mdc-text-field--disabled .mdc-notched-outline__leading,
  .theme-purple.dark .mdc-text-field--disabled .mdc-notched-outline__notch,
  .theme-purple.dark .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.theme-purple .dark .mdc-text-field--disabled.mdc-text-field--filled,
.theme-purple.dark .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #232d3f;
}
[dir=rtl] .theme-purple .dark .mdc-text-field--end-aligned .mdc-text-field__input, [dir=rtl] .theme-purple.dark .mdc-text-field--end-aligned .mdc-text-field__input, .theme-purple .dark .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl], .theme-purple.dark .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-purple .dark .mdc-floating-label--required, [dir=rtl] .theme-purple.dark .mdc-floating-label--required, .theme-purple .dark .mdc-floating-label--required[dir=rtl], .theme-purple.dark .mdc-floating-label--required[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-purple .dark .mat-mdc-form-field-error,
.theme-purple.dark .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mat-mdc-form-field-focus-overlay,
.theme-purple.dark .mat-mdc-form-field-focus-overlay {
  background-color: rgba(255, 255, 255, 0.87);
}
.theme-purple .dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.theme-purple.dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.08;
}
.theme-purple .dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.theme-purple.dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.24;
}
.theme-purple .dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option,
.theme-purple.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.theme-purple .dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled,
.theme-purple.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.theme-purple .dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.theme-purple.dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.54);
}
.theme-purple .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.theme-purple.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(147, 51, 234, 0.87);
}
.theme-purple .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.theme-purple.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(30, 41, 59, 0.87);
}
.theme-purple .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.theme-purple.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(220, 38, 38, 0.87);
}
.theme-purple .dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.theme-purple.dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.38);
}
.theme-purple .dark .mat-mdc-form-field.mat-accent .mdc-text-field__input,
.theme-purple.dark .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-purple .dark .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-purple.dark .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-purple .dark .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-purple.dark .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(30, 41, 59, 0.87);
}
.theme-purple .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-purple.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-purple.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-purple.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-purple.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input,
.theme-purple.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-purple.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-purple.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-purple .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-purple .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-purple.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-purple.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-purple.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-purple .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-purple .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-purple.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-purple.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-purple.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-purple .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-purple .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-purple.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-purple.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-purple.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-purple .dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-purple .dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-purple.dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-purple.dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-purple.dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-purple .dark .mat-mdc-form-field.mat-warn .mdc-text-field__input,
.theme-purple.dark .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-purple.dark .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-purple.dark .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(220, 38, 38, 0.87);
}
.theme-purple .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-purple.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-purple.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-purple.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-purple.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input,
.theme-purple.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-purple.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-purple.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-purple .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-purple .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-purple.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-purple.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-purple.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-purple .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-purple .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-purple.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-purple.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-purple.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-purple .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-purple .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-purple.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-purple.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-purple.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-purple .dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-purple .dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-purple.dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-purple.dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-purple.dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-purple .dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.theme-purple.dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.theme-purple .dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.theme-purple.dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.theme-purple .dark .mdc-menu-surface,
.theme-purple.dark .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #1e293b);
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mdc-list-item__primary-text,
.theme-purple.dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-purple .dark .mdc-list-item__secondary-text,
.theme-purple.dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.theme-purple .dark .mdc-list-item__overline-text,
.theme-purple.dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-purple .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-purple.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-purple .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-purple.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.theme-purple .dark .mdc-list-item__end,
.theme-purple.dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-purple .dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-purple .dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-purple .dark .mdc-list-item--disabled .mdc-list-item__end,
.theme-purple.dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-purple.dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-purple.dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-purple .dark .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-purple.dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-purple.dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-purple.dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-purple.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-purple.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-purple .dark .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-purple.dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-purple.dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #9333ea);
}
.theme-purple .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #9333ea);
}
.theme-purple .dark .mdc-deprecated-list-group__subheader,
.theme-purple.dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-purple .dark .mdc-list-divider::after,
.theme-purple.dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-purple .dark .mdc-list-divider,
.theme-purple.dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-purple .dark .mat-mdc-select-value,
.theme-purple.dark .mat-mdc-select-value {
  color: rgba(255, 255, 255, 0.87);
}
.theme-purple .dark .mat-mdc-select-placeholder,
.theme-purple.dark .mat-mdc-select-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.theme-purple .dark .mat-mdc-select-disabled .mat-mdc-select-value,
.theme-purple.dark .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(255, 255, 255, 0.38);
}
.theme-purple .dark .mat-mdc-select-arrow,
.theme-purple.dark .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.54);
}
.theme-purple .dark .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow,
.theme-purple.dark .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(147, 51, 234, 0.87);
}
.theme-purple .dark .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow,
.theme-purple.dark .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(30, 41, 59, 0.87);
}
.theme-purple .dark .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow,
.theme-purple.dark .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(220, 38, 38, 0.87);
}
.theme-purple .dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow,
.theme-purple.dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(220, 38, 38, 0.87);
}
.theme-purple .dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow,
.theme-purple.dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.38);
}
.theme-purple .dark .mdc-menu-surface,
.theme-purple.dark .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #1e293b);
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mdc-list-item__primary-text,
.theme-purple.dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-purple .dark .mdc-list-item__secondary-text,
.theme-purple.dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.theme-purple .dark .mdc-list-item__overline-text,
.theme-purple.dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-purple .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-purple.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-purple .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-purple.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.theme-purple .dark .mdc-list-item__end,
.theme-purple.dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-purple .dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-purple .dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-purple .dark .mdc-list-item--disabled .mdc-list-item__end,
.theme-purple.dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-purple.dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-purple.dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-purple .dark .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-purple.dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-purple.dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-purple.dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-purple.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-purple.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-purple .dark .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-purple.dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-purple.dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #9333ea);
}
.theme-purple .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #9333ea);
}
.theme-purple .dark .mdc-deprecated-list-group__subheader,
.theme-purple.dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-purple .dark .mdc-list-divider::after,
.theme-purple.dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-purple .dark .mdc-list-divider,
.theme-purple.dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-purple .dark .mat-mdc-dialog-container,
.theme-purple.dark .mat-mdc-dialog-container {
  --mdc-dialog-container-color: #1e293b;
  --mdc-dialog-with-divider-divider-color: rgba(255, 255, 255, 0.12);
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
}
.theme-purple .dark .mat-mdc-standard-chip,
.theme-purple.dark .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #394353;
  --mdc-chip-elevated-disabled-container-color: #394353;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
}
.theme-purple .dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .theme-purple .dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted,
.theme-purple.dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected,
.theme-purple.dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #9333ea;
  --mdc-chip-elevated-disabled-container-color: #9333ea;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-purple .dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .theme-purple .dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted,
.theme-purple.dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected,
.theme-purple.dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-purple .dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .theme-purple .dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted,
.theme-purple.dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected,
.theme-purple.dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-purple .dark .mat-mdc-chip-focus-overlay,
.theme-purple.dark .mat-mdc-chip-focus-overlay {
  background: #FFFFFF;
}
.theme-purple .dark .mat-mdc-slide-toggle,
.theme-purple.dark .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-unselected-icon-color: #212121;
}
.theme-purple .dark .mat-mdc-slide-toggle .mdc-form-field,
.theme-purple.dark .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-purple .dark .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.theme-purple.dark .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: #475569;
}
.theme-purple .dark .mat-mdc-slide-toggle.mat-primary,
.theme-purple.dark .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #d8b4fe;
  --mdc-switch-selected-handle-color: #d8b4fe;
  --mdc-switch-selected-hover-state-layer-color: #d8b4fe;
  --mdc-switch-selected-pressed-state-layer-color: #d8b4fe;
  --mdc-switch-selected-focus-handle-color: #e9d5ff;
  --mdc-switch-selected-hover-handle-color: #e9d5ff;
  --mdc-switch-selected-pressed-handle-color: #e9d5ff;
  --mdc-switch-selected-focus-track-color: #9333ea;
  --mdc-switch-selected-hover-track-color: #9333ea;
  --mdc-switch-selected-pressed-track-color: #9333ea;
  --mdc-switch-selected-track-color: #9333ea;
}
.theme-purple .dark .mat-mdc-slide-toggle.mat-accent,
.theme-purple.dark .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #cbd5e1;
  --mdc-switch-selected-handle-color: #cbd5e1;
  --mdc-switch-selected-hover-state-layer-color: #cbd5e1;
  --mdc-switch-selected-pressed-state-layer-color: #cbd5e1;
  --mdc-switch-selected-focus-handle-color: #e2e8f0;
  --mdc-switch-selected-hover-handle-color: #e2e8f0;
  --mdc-switch-selected-pressed-handle-color: #e2e8f0;
  --mdc-switch-selected-focus-track-color: #475569;
  --mdc-switch-selected-hover-track-color: #475569;
  --mdc-switch-selected-pressed-track-color: #475569;
  --mdc-switch-selected-track-color: #475569;
}
.theme-purple .dark .mat-mdc-slide-toggle.mat-warn,
.theme-purple.dark .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #fca5a5;
  --mdc-switch-selected-handle-color: #fca5a5;
  --mdc-switch-selected-hover-state-layer-color: #fca5a5;
  --mdc-switch-selected-pressed-state-layer-color: #fca5a5;
  --mdc-switch-selected-focus-handle-color: #fecaca;
  --mdc-switch-selected-hover-handle-color: #fecaca;
  --mdc-switch-selected-pressed-handle-color: #fecaca;
  --mdc-switch-selected-focus-track-color: #dc2626;
  --mdc-switch-selected-hover-track-color: #dc2626;
  --mdc-switch-selected-pressed-track-color: #dc2626;
  --mdc-switch-selected-track-color: #dc2626;
}
.theme-purple .dark .mat-mdc-radio-button,
.theme-purple.dark .mat-mdc-radio-button {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mat-mdc-radio-ripple-color: #fff;
}
.theme-purple .dark .mat-mdc-radio-button .mdc-form-field,
.theme-purple.dark .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-purple .dark .mat-mdc-radio-button .mdc-radio--disabled + label,
.theme-purple.dark .mat-mdc-radio-button .mdc-radio--disabled + label {
  color: #475569;
}
.theme-purple .dark .mat-mdc-radio-button.mat-primary,
.theme-purple.dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-selected-focus-icon-color: #9333ea;
  --mdc-radio-selected-hover-icon-color: #9333ea;
  --mdc-radio-selected-icon-color: #9333ea;
  --mdc-radio-selected-pressed-icon-color: #9333ea;
  --mat-mdc-radio-checked-ripple-color: #9333ea;
}
.theme-purple .dark .mat-mdc-radio-button.mat-accent,
.theme-purple.dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-mdc-radio-checked-ripple-color: #1e293b;
}
.theme-purple .dark .mat-mdc-radio-button.mat-warn,
.theme-purple.dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-mdc-radio-checked-ripple-color: #dc2626;
}
.theme-purple .dark .mat-mdc-slider,
.theme-purple.dark .mat-mdc-slider {
  --mdc-slider-label-container-color: white;
  --mdc-slider-label-label-text-color: black;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mat-mdc-slider-value-indicator-opacity: 0.9;
}
.theme-purple .dark .mat-mdc-slider.mat-primary,
.theme-purple.dark .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #9333ea;
  --mdc-slider-focus-handle-color: #9333ea;
  --mdc-slider-hover-handle-color: #9333ea;
  --mdc-slider-active-track-color: #9333ea;
  --mdc-slider-inactive-track-color: #9333ea;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #9333ea;
  --mat-mdc-slider-ripple-color: #9333ea;
  --mat-mdc-slider-hover-ripple-color: rgba(147, 51, 234, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(147, 51, 234, 0.2);
}
.theme-purple .dark .mat-mdc-slider.mat-accent,
.theme-purple.dark .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mat-mdc-slider-ripple-color: #1e293b;
  --mat-mdc-slider-hover-ripple-color: rgba(30, 41, 59, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(30, 41, 59, 0.2);
}
.theme-purple .dark .mat-mdc-slider.mat-warn,
.theme-purple.dark .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mat-mdc-slider-ripple-color: #dc2626;
  --mat-mdc-slider-hover-ripple-color: rgba(220, 38, 38, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(220, 38, 38, 0.2);
}
.theme-purple .dark .mdc-menu-surface,
.theme-purple.dark .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #1e293b);
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mdc-list-item__primary-text,
.theme-purple.dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-purple .dark .mdc-list-item__secondary-text,
.theme-purple.dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.theme-purple .dark .mdc-list-item__overline-text,
.theme-purple.dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-purple .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-purple.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-purple .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-purple.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.theme-purple .dark .mdc-list-item__end,
.theme-purple.dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-purple .dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-purple .dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-purple .dark .mdc-list-item--disabled .mdc-list-item__end,
.theme-purple.dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-purple.dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-purple.dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-purple .dark .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-purple.dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-purple.dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-purple.dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-purple.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-purple.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-purple .dark .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-purple.dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-purple.dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #9333ea);
}
.theme-purple .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #9333ea);
}
.theme-purple .dark .mdc-deprecated-list-group__subheader,
.theme-purple.dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-purple .dark .mdc-list-divider::after,
.theme-purple.dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-purple .dark .mdc-list-divider,
.theme-purple.dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-purple .dark .mat-mdc-menu-item[disabled],
.theme-purple .dark .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.theme-purple .dark .mat-mdc-menu-item[disabled] .mat-icon-no-color,
.theme-purple.dark .mat-mdc-menu-item[disabled],
.theme-purple.dark .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.theme-purple.dark .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(255, 255, 255, 0.5));
}
.theme-purple .dark .mat-mdc-menu-item .mat-icon-no-color,
.theme-purple .dark .mat-mdc-menu-submenu-icon,
.theme-purple.dark .mat-mdc-menu-item .mat-icon-no-color,
.theme-purple.dark .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-purple .dark .mat-mdc-menu-item:hover:not([disabled]),
.theme-purple .dark .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.theme-purple .dark .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-purple .dark .mat-mdc-menu-item-highlighted:not([disabled]),
.theme-purple.dark .mat-mdc-menu-item:hover:not([disabled]),
.theme-purple.dark .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.theme-purple.dark .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-purple.dark .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.08);
}
.theme-purple .dark .mat-mdc-list-item-interactive::before,
.theme-purple.dark .mat-mdc-list-item-interactive::before {
  background: white;
}
.theme-purple .dark .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before,
.theme-purple.dark .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.08;
}
.theme-purple .dark .mat-mdc-list-item-interactive.mdc-list-item--selected::before,
.theme-purple.dark .mat-mdc-list-item-interactive.mdc-list-item--selected::before {
  background: #9333ea;
  opacity: 0.08;
}
.theme-purple .dark .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before,
.theme-purple.dark .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.12;
}
.theme-purple .dark .mat-mdc-list-item-interactive:focus::before,
.theme-purple.dark .mat-mdc-list-item-interactive:focus::before {
  opacity: 0.24;
}
.theme-purple .dark .mdc-list-item__primary-text,
.theme-purple.dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-purple .dark .mdc-list-item__secondary-text,
.theme-purple.dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.theme-purple .dark .mdc-list-item__overline-text,
.theme-purple.dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-purple .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-purple.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-purple .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-purple.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.theme-purple .dark .mdc-list-item__end,
.theme-purple.dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-purple .dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-purple .dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-purple .dark .mdc-list-item--disabled .mdc-list-item__end,
.theme-purple.dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-purple.dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-purple.dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-purple .dark .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-purple.dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-purple.dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-purple.dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-purple.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-purple.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-purple .dark .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-purple.dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-purple.dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #9333ea);
}
.theme-purple .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #9333ea);
}
.theme-purple .dark .mdc-deprecated-list-group__subheader,
.theme-purple.dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-purple .dark .mdc-list-divider::after,
.theme-purple.dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-purple .dark .mdc-list-divider,
.theme-purple.dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-purple .dark .mat-mdc-list-option .mdc-list-item__start, .theme-purple .dark .mat-mdc-list-option .mdc-list-item__end,
.theme-purple.dark .mat-mdc-list-option .mdc-list-item__start,
.theme-purple.dark .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #9333ea;
  --mdc-checkbox-selected-hover-icon-color: #9333ea;
  --mdc-checkbox-selected-icon-color: #9333ea;
  --mdc-checkbox-selected-pressed-icon-color: #9333ea;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-purple .dark .mat-mdc-list-option.mat-accent .mdc-list-item__start, .theme-purple .dark .mat-mdc-list-option.mat-accent .mdc-list-item__end,
.theme-purple.dark .mat-mdc-list-option.mat-accent .mdc-list-item__start,
.theme-purple.dark .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-purple .dark .mat-mdc-list-option.mat-warn .mdc-list-item__start, .theme-purple .dark .mat-mdc-list-option.mat-warn .mdc-list-item__end,
.theme-purple.dark .mat-mdc-list-option.mat-warn .mdc-list-item__start,
.theme-purple.dark .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-purple .dark .mat-mdc-paginator,
.theme-purple.dark .mat-mdc-paginator {
  background: #1e293b;
  color: rgba(255, 255, 255, 0.87);
}
.theme-purple .dark .mat-mdc-paginator-icon,
.theme-purple.dark .mat-mdc-paginator-icon {
  fill: rgba(255, 255, 255, 0.54);
}
.theme-purple .dark .mat-mdc-paginator-decrement,
.theme-purple .dark .mat-mdc-paginator-increment,
.theme-purple.dark .mat-mdc-paginator-decrement,
.theme-purple.dark .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(255, 255, 255, 0.54);
  border-right: 2px solid rgba(255, 255, 255, 0.54);
}
.theme-purple .dark .mat-mdc-paginator-first,
.theme-purple .dark .mat-mdc-paginator-last,
.theme-purple.dark .mat-mdc-paginator-first,
.theme-purple.dark .mat-mdc-paginator-last {
  border-top: 2px solid rgba(255, 255, 255, 0.54);
}
.theme-purple .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.theme-purple .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.theme-purple .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.theme-purple .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last,
.theme-purple.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.theme-purple.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.theme-purple.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.theme-purple.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-purple .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon,
.theme-purple.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(255, 255, 255, 0.12);
}
.theme-purple .dark .mat-mdc-tab, .theme-purple .dark .mat-mdc-tab-link,
.theme-purple.dark .mat-mdc-tab,
.theme-purple.dark .mat-mdc-tab-link {
  background-color: transparent;
}
.theme-purple .dark .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label, .theme-purple .dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.theme-purple.dark .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.theme-purple.dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  color: rgba(255, 255, 255, 0.6);
}
.theme-purple .dark .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-purple .dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-purple.dark .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-purple.dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #9333ea;
}
.theme-purple .dark .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-purple .dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-purple.dark .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-purple.dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #9333ea;
}
.theme-purple .dark .mdc-tab__ripple::before,
.theme-purple .dark .mat-mdc-tab .mat-ripple-element,
.theme-purple .dark .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-purple .dark .mat-mdc-tab-link .mat-ripple-element,
.theme-purple.dark .mdc-tab__ripple::before,
.theme-purple.dark .mat-mdc-tab .mat-ripple-element,
.theme-purple.dark .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-purple.dark .mat-mdc-tab-link .mat-ripple-element {
  background-color: #9333ea;
}
.theme-purple .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-purple .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-purple .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-purple .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-purple.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-purple.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-purple.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-purple.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #1e293b;
}
.theme-purple .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-purple .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-purple .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-purple .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-purple.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-purple.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-purple.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-purple.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #1e293b;
}
.theme-purple .dark .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.theme-purple .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-purple .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-purple .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .theme-purple .dark .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.theme-purple .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-purple .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-purple .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element,
.theme-purple.dark .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.theme-purple.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-purple.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-purple.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element,
.theme-purple.dark .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.theme-purple.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-purple.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-purple.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #1e293b;
}
.theme-purple .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-purple .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-purple .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-purple .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-purple.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-purple.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-purple.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-purple.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #dc2626;
}
.theme-purple .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-purple .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-purple .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-purple .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-purple.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-purple.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-purple.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-purple.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #dc2626;
}
.theme-purple .dark .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.theme-purple .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-purple .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-purple .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .theme-purple .dark .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.theme-purple .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-purple .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-purple .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element,
.theme-purple.dark .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.theme-purple.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-purple.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-purple.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element,
.theme-purple.dark .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.theme-purple.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-purple.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-purple.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #dc2626;
}
.theme-purple .dark .mat-mdc-tab-group.mat-background-primary, .theme-purple .dark .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-purple.dark .mat-mdc-tab-group.mat-background-primary,
.theme-purple.dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #9333ea;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-purple .dark .mat-mdc-tab-group.mat-background-accent, .theme-purple .dark .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-purple.dark .mat-mdc-tab-group.mat-background-accent,
.theme-purple.dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #1e293b;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-purple .dark .mat-mdc-tab-group.mat-background-warn, .theme-purple .dark .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-purple.dark .mat-mdc-tab-group.mat-background-warn,
.theme-purple.dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #dc2626;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-purple .dark .mat-mdc-tab-header-pagination-chevron,
.theme-purple.dark .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #fff);
}
.theme-purple .dark .mat-mdc-checkbox .mdc-form-field,
.theme-purple.dark .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-purple .dark .mat-mdc-checkbox .mat-ripple-element,
.theme-purple.dark .mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .dark .mat-mdc-checkbox .mdc-checkbox__ripple,
.theme-purple.dark .mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #fff;
}
.theme-purple .dark .mat-mdc-checkbox.mat-primary,
.theme-purple.dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #9333ea;
  --mdc-checkbox-selected-hover-icon-color: #9333ea;
  --mdc-checkbox-selected-icon-color: #9333ea;
  --mdc-checkbox-selected-pressed-icon-color: #9333ea;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-purple .dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-purple.dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(147, 51, 234, 0.1);
}
.theme-purple .dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-purple.dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #9333ea;
}
.theme-purple .dark .mat-mdc-checkbox.mat-accent,
.theme-purple.dark .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-purple .dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-purple.dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(30, 41, 59, 0.1);
}
.theme-purple .dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-purple.dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #1e293b;
}
.theme-purple .dark .mat-mdc-checkbox.mat-warn,
.theme-purple.dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-purple .dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-purple.dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(220, 38, 38, 0.1);
}
.theme-purple .dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-purple.dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #dc2626;
}
.theme-purple .dark .mat-mdc-checkbox-disabled label,
.theme-purple.dark .mat-mdc-checkbox-disabled label {
  color: #475569;
}
.theme-purple .dark .mat-mdc-button.mat-unthemed,
.theme-purple.dark .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #fff;
}
.theme-purple .dark .mat-mdc-button.mat-primary,
.theme-purple.dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #9333ea;
}
.theme-purple .dark .mat-mdc-button.mat-accent,
.theme-purple.dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
}
.theme-purple .dark .mat-mdc-button.mat-warn,
.theme-purple.dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
}
.theme-purple .dark .mat-mdc-button[disabled][disabled],
.theme-purple.dark .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-text-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.theme-purple .dark .mat-mdc-unelevated-button.mat-unthemed,
.theme-purple.dark .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-purple .dark .mat-mdc-unelevated-button.mat-primary,
.theme-purple.dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #9333ea;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-purple .dark .mat-mdc-unelevated-button.mat-accent,
.theme-purple.dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-purple .dark .mat-mdc-unelevated-button.mat-warn,
.theme-purple.dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-purple .dark .mat-mdc-unelevated-button[disabled][disabled],
.theme-purple.dark .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-filled-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.theme-purple .dark .mat-mdc-raised-button.mat-unthemed,
.theme-purple.dark .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-purple .dark .mat-mdc-raised-button.mat-primary,
.theme-purple.dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #9333ea;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-purple .dark .mat-mdc-raised-button.mat-accent,
.theme-purple.dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-purple .dark .mat-mdc-raised-button.mat-warn,
.theme-purple.dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-purple .dark .mat-mdc-raised-button[disabled][disabled],
.theme-purple.dark .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-elevation: 0;
}
.theme-purple .dark .mat-mdc-outlined-button,
.theme-purple.dark .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.theme-purple .dark .mat-mdc-outlined-button.mat-unthemed,
.theme-purple.dark .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #fff;
}
.theme-purple .dark .mat-mdc-outlined-button.mat-primary,
.theme-purple.dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #9333ea;
}
.theme-purple .dark .mat-mdc-outlined-button.mat-accent,
.theme-purple.dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
}
.theme-purple .dark .mat-mdc-outlined-button.mat-warn,
.theme-purple.dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
}
.theme-purple .dark .mat-mdc-outlined-button[disabled][disabled],
.theme-purple.dark .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
}
.theme-purple .dark .mat-mdc-button, .theme-purple .dark .mat-mdc-outlined-button,
.theme-purple.dark .mat-mdc-button,
.theme-purple.dark .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .theme-purple .dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-purple .dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-purple .dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-purple .dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-purple .dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-purple .dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .theme-purple .dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-purple .dark .mat-mdc-button.mat-primary, .theme-purple .dark .mat-mdc-outlined-button.mat-primary,
.theme-purple.dark .mat-mdc-button.mat-primary,
.theme-purple.dark .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #9333ea;
  --mat-mdc-button-ripple-color: rgba(147, 51, 234, 0.1);
}
.theme-purple .dark .mat-mdc-button.mat-accent, .theme-purple .dark .mat-mdc-outlined-button.mat-accent,
.theme-purple.dark .mat-mdc-button.mat-accent,
.theme-purple.dark .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #1e293b;
  --mat-mdc-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-purple .dark .mat-mdc-button.mat-warn, .theme-purple .dark .mat-mdc-outlined-button.mat-warn,
.theme-purple.dark .mat-mdc-button.mat-warn,
.theme-purple.dark .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #dc2626;
  --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-purple .dark .mat-mdc-raised-button, .theme-purple .dark .mat-mdc-unelevated-button,
.theme-purple.dark .mat-mdc-raised-button,
.theme-purple.dark .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .theme-purple .dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-purple .dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-purple .dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-purple .dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-purple .dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-purple .dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .theme-purple .dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-purple .dark .mat-mdc-raised-button.mat-primary, .theme-purple .dark .mat-mdc-unelevated-button.mat-primary,
.theme-purple.dark .mat-mdc-raised-button.mat-primary,
.theme-purple.dark .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .dark .mat-mdc-raised-button.mat-accent, .theme-purple .dark .mat-mdc-unelevated-button.mat-accent,
.theme-purple.dark .mat-mdc-raised-button.mat-accent,
.theme-purple.dark .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .dark .mat-mdc-raised-button.mat-warn, .theme-purple .dark .mat-mdc-unelevated-button.mat-warn,
.theme-purple.dark .mat-mdc-raised-button.mat-warn,
.theme-purple.dark .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .dark .mat-mdc-icon-button,
.theme-purple.dark .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-purple .dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-purple .dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-purple .dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-purple .dark .mat-mdc-icon-button.mat-primary,
.theme-purple.dark .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #9333ea;
  --mat-mdc-button-ripple-color: rgba(147, 51, 234, 0.1);
}
.theme-purple .dark .mat-mdc-icon-button.mat-accent,
.theme-purple.dark .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #1e293b;
  --mat-mdc-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-purple .dark .mat-mdc-icon-button.mat-warn,
.theme-purple.dark .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #dc2626;
  --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-purple .dark .mat-mdc-icon-button.mat-primary,
.theme-purple.dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #9333ea;
}
.theme-purple .dark .mat-mdc-icon-button.mat-accent,
.theme-purple.dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
}
.theme-purple .dark .mat-mdc-icon-button.mat-warn,
.theme-purple.dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
}
.theme-purple .dark .mat-mdc-icon-button[disabled][disabled],
.theme-purple.dark .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
}
.theme-purple .dark .mat-mdc-fab, .theme-purple .dark .mat-mdc-mini-fab,
.theme-purple.dark .mat-mdc-fab,
.theme-purple.dark .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .theme-purple .dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-purple .dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-purple .dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-purple .dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-purple .dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-purple .dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .theme-purple .dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.theme-purple.dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-purple .dark .mat-mdc-fab.mat-primary, .theme-purple .dark .mat-mdc-mini-fab.mat-primary,
.theme-purple.dark .mat-mdc-fab.mat-primary,
.theme-purple.dark .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .dark .mat-mdc-fab.mat-accent, .theme-purple .dark .mat-mdc-mini-fab.mat-accent,
.theme-purple.dark .mat-mdc-fab.mat-accent,
.theme-purple.dark .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .dark .mat-mdc-fab.mat-warn, .theme-purple .dark .mat-mdc-mini-fab.mat-warn,
.theme-purple.dark .mat-mdc-fab.mat-warn,
.theme-purple.dark .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .dark .mat-mdc-fab.mat-unthemed, .theme-purple .dark .mat-mdc-mini-fab.mat-unthemed,
.theme-purple.dark .mat-mdc-fab.mat-unthemed,
.theme-purple.dark .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-purple .dark .mat-mdc-fab.mat-primary, .theme-purple .dark .mat-mdc-mini-fab.mat-primary,
.theme-purple.dark .mat-mdc-fab.mat-primary,
.theme-purple.dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #9333ea;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-purple .dark .mat-mdc-fab.mat-accent, .theme-purple .dark .mat-mdc-mini-fab.mat-accent,
.theme-purple.dark .mat-mdc-fab.mat-accent,
.theme-purple.dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-purple .dark .mat-mdc-fab.mat-warn, .theme-purple .dark .mat-mdc-mini-fab.mat-warn,
.theme-purple.dark .mat-mdc-fab.mat-warn,
.theme-purple.dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-purple .dark .mat-mdc-fab[disabled][disabled], .theme-purple .dark .mat-mdc-mini-fab[disabled][disabled],
.theme-purple.dark .mat-mdc-fab[disabled][disabled],
.theme-purple.dark .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(255, 255, 255, 0.12);
  --mdc-fab-icon-color: rgba(255, 255, 255, 0.5);
  --mat-mdc-fab-color: rgba(255, 255, 255, 0.5);
}
.theme-purple .dark .mat-mdc-snack-bar-container,
.theme-purple.dark .mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: currentColor;
  --mdc-snackbar-container-color: #d2d4d8;
  --mdc-snackbar-supporting-text-color: rgba(30, 41, 59, 0.87);
}
.theme-purple .dark .mdc-data-table,
.theme-purple.dark .mdc-data-table {
  background-color: var(--mdc-theme-surface, #1e293b);
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-purple .dark .mdc-data-table__row,
.theme-purple.dark .mdc-data-table__row {
  background-color: inherit;
}
.theme-purple .dark .mdc-data-table__header-cell,
.theme-purple.dark .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #1e293b);
}
.theme-purple .dark .mdc-data-table__row--selected,
.theme-purple.dark .mdc-data-table__row--selected {
  background-color: rgba(147, 51, 234, 0.04);
}
.theme-purple .dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.theme-purple .dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.theme-purple .dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing,
.theme-purple.dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.theme-purple.dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.theme-purple.dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-purple .dark .mdc-data-table__cell,
.theme-purple .dark .mdc-data-table__header-cell,
.theme-purple.dark .mdc-data-table__cell,
.theme-purple.dark .mdc-data-table__header-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.theme-purple .dark .mdc-data-table__pagination,
.theme-purple.dark .mdc-data-table__pagination {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-purple .dark .mdc-data-table__row:not(.mdc-data-table__row--selected):hover,
.theme-purple.dark .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(255, 255, 255, 0.04);
}
.theme-purple .dark .mdc-data-table__header-cell,
.theme-purple.dark .mdc-data-table__header-cell {
  color: rgba(255, 255, 255, 0.87);
}
.theme-purple .dark .mdc-data-table__pagination-total,
.theme-purple .dark .mdc-data-table__pagination-rows-per-page-label,
.theme-purple .dark .mdc-data-table__cell,
.theme-purple.dark .mdc-data-table__pagination-total,
.theme-purple.dark .mdc-data-table__pagination-rows-per-page-label,
.theme-purple.dark .mdc-data-table__cell {
  color: rgba(255, 255, 255, 0.87);
}
[dir=rtl] .theme-purple .dark .mdc-data-table__pagination-button .mdc-button__icon, [dir=rtl] .theme-purple.dark .mdc-data-table__pagination-button .mdc-button__icon, .theme-purple .dark .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl], .theme-purple.dark .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-purple .dark .mat-mdc-table,
.theme-purple.dark .mat-mdc-table {
  background: #1e293b;
}
.theme-purple .dark .mat-mdc-progress-spinner,
.theme-purple.dark .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #9333ea;
}
.theme-purple .dark .mat-mdc-progress-spinner.mat-accent,
.theme-purple.dark .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-purple .dark .mat-mdc-progress-spinner.mat-warn,
.theme-purple.dark .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-purple .dark .mat-badge-content,
.theme-purple.dark .mat-badge-content {
  color: white;
  background: #9333ea;
}
.cdk-high-contrast-active .theme-purple .dark .mat-badge-content, .cdk-high-contrast-active .theme-purple.dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.theme-purple .dark .mat-badge-accent .mat-badge-content,
.theme-purple.dark .mat-badge-accent .mat-badge-content {
  background: #1e293b;
  color: white;
}
.theme-purple .dark .mat-badge-warn .mat-badge-content,
.theme-purple.dark .mat-badge-warn .mat-badge-content {
  color: white;
  background: #dc2626;
}
.theme-purple .dark .mat-badge-disabled .mat-badge-content,
.theme-purple.dark .mat-badge-disabled .mat-badge-content {
  background: #1e293b;
  color: #475569;
}
.theme-purple .dark .mat-bottom-sheet-container,
.theme-purple.dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #1e293b;
  color: white;
}
.theme-purple .dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-purple .dark .mat-button-toggle-group:not([class*=mat-elevation-z]),
.theme-purple.dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-purple.dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-purple .dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]),
.theme-purple.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-purple.dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.theme-purple .dark .mat-button-toggle,
.theme-purple.dark .mat-button-toggle {
  color: #64748b;
}
.theme-purple .dark .mat-button-toggle .mat-button-toggle-focus-overlay,
.theme-purple.dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: #e2e8f0;
}
.theme-purple .dark .mat-button-toggle-appearance-standard,
.theme-purple.dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #1e293b;
}
.theme-purple .dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay,
.theme-purple.dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: #e2e8f0;
}
.theme-purple .dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
.theme-purple.dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #374152;
}
.theme-purple .dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
.theme-purple.dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #374152;
}
.theme-purple .dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle,
.theme-purple.dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #374152;
}
.theme-purple .dark .mat-button-toggle-checked,
.theme-purple.dark .mat-button-toggle-checked {
  background-color: rgba(255, 255, 255, 0.05);
  color: #94a3b8;
}
.theme-purple .dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard,
.theme-purple.dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.theme-purple .dark .mat-button-toggle-disabled,
.theme-purple.dark .mat-button-toggle-disabled {
  color: #1e293b;
  background-color: #0f172a;
}
.theme-purple .dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard,
.theme-purple.dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #1e293b;
}
.theme-purple .dark .mat-button-toggle-disabled.mat-button-toggle-checked,
.theme-purple.dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #1e293b;
}
.theme-purple .dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-purple .dark .mat-button-toggle-group-appearance-standard,
.theme-purple.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-purple.dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #374152;
}
.theme-purple .dark .mat-calendar-arrow,
.theme-purple.dark .mat-calendar-arrow {
  fill: #f1f5f9;
}
.theme-purple .dark .mat-datepicker-toggle,
.theme-purple .dark .mat-datepicker-content .mat-calendar-next-button,
.theme-purple .dark .mat-datepicker-content .mat-calendar-previous-button,
.theme-purple.dark .mat-datepicker-toggle,
.theme-purple.dark .mat-datepicker-content .mat-calendar-next-button,
.theme-purple.dark .mat-datepicker-content .mat-calendar-previous-button {
  color: #f1f5f9;
}
.theme-purple .dark .mat-calendar-table-header-divider::after,
.theme-purple.dark .mat-calendar-table-header-divider::after {
  background: rgba(241, 245, 249, 0.12);
}
.theme-purple .dark .mat-calendar-table-header,
.theme-purple .dark .mat-calendar-body-label,
.theme-purple.dark .mat-calendar-table-header,
.theme-purple.dark .mat-calendar-body-label {
  color: #94a3b8;
}
.theme-purple .dark .mat-calendar-body-cell-content,
.theme-purple .dark .mat-date-range-input-separator,
.theme-purple.dark .mat-calendar-body-cell-content,
.theme-purple.dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.theme-purple .dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-purple.dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: #475569;
}
.theme-purple .dark .mat-form-field-disabled .mat-date-range-input-separator,
.theme-purple.dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: #475569;
}
.theme-purple .dark .mat-calendar-body-in-preview,
.theme-purple.dark .mat-calendar-body-in-preview {
  color: rgba(241, 245, 249, 0.24);
}
.theme-purple .dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-purple.dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #64748b;
}
.theme-purple .dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-purple.dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(100, 116, 139, 0.8);
}
.theme-purple .dark .mat-calendar-body-in-range::before,
.theme-purple.dark .mat-calendar-body-in-range::before {
  background: rgba(147, 51, 234, 0.2);
}
.theme-purple .dark .mat-calendar-body-comparison-identical,
.theme-purple .dark .mat-calendar-body-in-comparison-range::before,
.theme-purple.dark .mat-calendar-body-comparison-identical,
.theme-purple.dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-purple .dark .mat-calendar-body-comparison-bridge-start::before,
.theme-purple .dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-purple.dark .mat-calendar-body-comparison-bridge-start::before,
.theme-purple.dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(147, 51, 234, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-purple .dark .mat-calendar-body-comparison-bridge-end::before,
.theme-purple .dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-purple.dark .mat-calendar-body-comparison-bridge-end::before,
.theme-purple.dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(147, 51, 234, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-purple .dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-purple .dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-purple.dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-purple.dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-purple .dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-purple .dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-purple.dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-purple.dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-purple .dark .mat-calendar-body-selected,
.theme-purple.dark .mat-calendar-body-selected {
  background-color: #9333ea;
  color: white;
}
.theme-purple .dark .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-purple.dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(147, 51, 234, 0.4);
}
.theme-purple .dark .mat-calendar-body-today.mat-calendar-body-selected,
.theme-purple.dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-purple .dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-purple .dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-purple.dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-purple.dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(147, 51, 234, 0.3);
}
@media (hover: hover) {
  .theme-purple .dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-purple.dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(147, 51, 234, 0.3);
  }
}
.theme-purple .dark .mat-datepicker-content,
.theme-purple.dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #1e293b;
  color: white;
}
.theme-purple .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before,
.theme-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(30, 41, 59, 0.2);
}
.theme-purple .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-purple .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before,
.theme-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-purple .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-purple .dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-purple.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(30, 41, 59, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-purple .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-purple .dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-purple.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(30, 41, 59, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-purple .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-purple .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-purple .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-purple .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-purple .dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected,
.theme-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #1e293b;
  color: white;
}
.theme-purple .dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(30, 41, 59, 0.4);
}
.theme-purple .dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected,
.theme-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-purple .dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-purple .dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-purple.dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-purple.dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(30, 41, 59, 0.3);
}
@media (hover: hover) {
  .theme-purple .dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(30, 41, 59, 0.3);
  }
}
.theme-purple .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before,
.theme-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(220, 38, 38, 0.2);
}
.theme-purple .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-purple .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before,
.theme-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-purple .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-purple .dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-purple.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(220, 38, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-purple .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-purple .dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-purple.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(220, 38, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-purple .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-purple .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-purple .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-purple .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-purple .dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected,
.theme-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #dc2626;
  color: white;
}
.theme-purple .dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(220, 38, 38, 0.4);
}
.theme-purple .dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected,
.theme-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-purple .dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-purple .dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-purple.dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-purple.dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(220, 38, 38, 0.3);
}
@media (hover: hover) {
  .theme-purple .dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(220, 38, 38, 0.3);
  }
}
.theme-purple .dark .mat-datepicker-content-touch,
.theme-purple.dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-datepicker-toggle-active,
.theme-purple.dark .mat-datepicker-toggle-active {
  color: #9333ea;
}
.theme-purple .dark .mat-datepicker-toggle-active.mat-accent,
.theme-purple.dark .mat-datepicker-toggle-active.mat-accent {
  color: #1e293b;
}
.theme-purple .dark .mat-datepicker-toggle-active.mat-warn,
.theme-purple.dark .mat-datepicker-toggle-active.mat-warn {
  color: #dc2626;
}
.theme-purple .dark .mat-date-range-input-inner[disabled],
.theme-purple.dark .mat-date-range-input-inner[disabled] {
  color: #475569;
}
.theme-purple .dark .mat-divider,
.theme-purple.dark .mat-divider {
  border-top-color: rgba(241, 245, 249, 0.12);
}
.theme-purple .dark .mat-divider-vertical,
.theme-purple.dark .mat-divider-vertical {
  border-right-color: rgba(241, 245, 249, 0.12);
}
.theme-purple .dark .mat-expansion-panel,
.theme-purple.dark .mat-expansion-panel {
  background: #1e293b;
  color: white;
}
.theme-purple .dark .mat-expansion-panel:not([class*=mat-elevation-z]),
.theme-purple.dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-action-row,
.theme-purple.dark .mat-action-row {
  border-top-color: rgba(241, 245, 249, 0.12);
}
.theme-purple .dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .theme-purple .dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .theme-purple .dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]),
.theme-purple.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
.theme-purple.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
.theme-purple.dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.05);
}
@media (hover: none) {
  .theme-purple .dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover,
  .theme-purple.dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #1e293b;
  }
}
.theme-purple .dark .mat-expansion-panel-header-title,
.theme-purple.dark .mat-expansion-panel-header-title {
  color: white;
}
.theme-purple .dark .mat-expansion-panel-header-description,
.theme-purple .dark .mat-expansion-indicator::after,
.theme-purple.dark .mat-expansion-panel-header-description,
.theme-purple.dark .mat-expansion-indicator::after {
  color: #94a3b8;
}
.theme-purple .dark .mat-expansion-panel-header[aria-disabled=true],
.theme-purple.dark .mat-expansion-panel-header[aria-disabled=true] {
  color: #1e293b;
}
.theme-purple .dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-purple .dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description,
.theme-purple.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-purple.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.theme-purple .dark .mat-icon.mat-primary,
.theme-purple.dark .mat-icon.mat-primary {
  color: #9333ea;
}
.theme-purple .dark .mat-icon.mat-accent,
.theme-purple.dark .mat-icon.mat-accent {
  color: #1e293b;
}
.theme-purple .dark .mat-icon.mat-warn,
.theme-purple.dark .mat-icon.mat-warn {
  color: #dc2626;
}
.theme-purple .dark .mat-drawer-container,
.theme-purple.dark .mat-drawer-container {
  background-color: #0f172a;
  color: white;
}
.theme-purple .dark .mat-drawer,
.theme-purple.dark .mat-drawer {
  background-color: #1e293b;
  color: white;
}
.theme-purple .dark .mat-drawer.mat-drawer-push,
.theme-purple.dark .mat-drawer.mat-drawer-push {
  background-color: #1e293b;
}
.theme-purple .dark .mat-drawer:not(.mat-drawer-side),
.theme-purple.dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-drawer-side,
.theme-purple.dark .mat-drawer-side {
  border-right: solid 1px rgba(241, 245, 249, 0.12);
}
.theme-purple .dark .mat-drawer-side.mat-drawer-end,
.theme-purple.dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(241, 245, 249, 0.12);
  border-right: none;
}
.theme-purple .dark [dir=rtl] .mat-drawer-side,
.theme-purple.dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(241, 245, 249, 0.12);
  border-right: none;
}
.theme-purple .dark [dir=rtl] .mat-drawer-side.mat-drawer-end,
.theme-purple.dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(241, 245, 249, 0.12);
}
.theme-purple .dark .mat-drawer-backdrop.mat-drawer-shown,
.theme-purple.dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(225, 214, 196, 0.6);
}
.theme-purple .dark .mat-step-header.cdk-keyboard-focused, .theme-purple .dark .mat-step-header.cdk-program-focused, .theme-purple .dark .mat-step-header:hover:not([aria-disabled]), .theme-purple .dark .mat-step-header:hover[aria-disabled=false],
.theme-purple.dark .mat-step-header.cdk-keyboard-focused,
.theme-purple.dark .mat-step-header.cdk-program-focused,
.theme-purple.dark .mat-step-header:hover:not([aria-disabled]),
.theme-purple.dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.05);
}
.theme-purple .dark .mat-step-header:hover[aria-disabled=true],
.theme-purple.dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .theme-purple .dark .mat-step-header:hover,
  .theme-purple.dark .mat-step-header:hover {
    background: none;
  }
}
.theme-purple .dark .mat-step-header .mat-step-label,
.theme-purple .dark .mat-step-header .mat-step-optional,
.theme-purple.dark .mat-step-header .mat-step-label,
.theme-purple.dark .mat-step-header .mat-step-optional {
  color: #94a3b8;
}
.theme-purple .dark .mat-step-header .mat-step-icon,
.theme-purple.dark .mat-step-header .mat-step-icon {
  background-color: #94a3b8;
  color: white;
}
.theme-purple .dark .mat-step-header .mat-step-icon-selected,
.theme-purple .dark .mat-step-header .mat-step-icon-state-done,
.theme-purple .dark .mat-step-header .mat-step-icon-state-edit,
.theme-purple.dark .mat-step-header .mat-step-icon-selected,
.theme-purple.dark .mat-step-header .mat-step-icon-state-done,
.theme-purple.dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #9333ea;
  color: white;
}
.theme-purple .dark .mat-step-header.mat-accent .mat-step-icon,
.theme-purple.dark .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.theme-purple .dark .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-purple .dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-purple .dark .mat-step-header.mat-accent .mat-step-icon-state-edit,
.theme-purple.dark .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-purple.dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-purple.dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #1e293b;
  color: white;
}
.theme-purple .dark .mat-step-header.mat-warn .mat-step-icon,
.theme-purple.dark .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.theme-purple .dark .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-purple .dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-purple .dark .mat-step-header.mat-warn .mat-step-icon-state-edit,
.theme-purple.dark .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-purple.dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-purple.dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #dc2626;
  color: white;
}
.theme-purple .dark .mat-step-header .mat-step-icon-state-error,
.theme-purple.dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #dc2626;
}
.theme-purple .dark .mat-step-header .mat-step-label.mat-step-label-active,
.theme-purple.dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.theme-purple .dark .mat-step-header .mat-step-label.mat-step-label-error,
.theme-purple.dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #dc2626;
}
.theme-purple .dark .mat-stepper-horizontal, .theme-purple .dark .mat-stepper-vertical,
.theme-purple.dark .mat-stepper-horizontal,
.theme-purple.dark .mat-stepper-vertical {
  background-color: #1e293b;
}
.theme-purple .dark .mat-stepper-vertical-line::before,
.theme-purple.dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(241, 245, 249, 0.12);
}
.theme-purple .dark .mat-horizontal-stepper-header::before,
.theme-purple .dark .mat-horizontal-stepper-header::after,
.theme-purple .dark .mat-stepper-horizontal-line,
.theme-purple.dark .mat-horizontal-stepper-header::before,
.theme-purple.dark .mat-horizontal-stepper-header::after,
.theme-purple.dark .mat-stepper-horizontal-line {
  border-top-color: rgba(241, 245, 249, 0.12);
}
.theme-purple .dark .mat-sort-header-arrow,
.theme-purple.dark .mat-sort-header-arrow {
  color: #94a3b8;
}
.theme-purple .dark .mat-toolbar,
.theme-purple.dark .mat-toolbar {
  background: #0f172a;
  color: white;
}
.theme-purple .dark .mat-toolbar.mat-primary,
.theme-purple.dark .mat-toolbar.mat-primary {
  background: #9333ea;
  color: white;
}
.theme-purple .dark .mat-toolbar.mat-accent,
.theme-purple.dark .mat-toolbar.mat-accent {
  background: #1e293b;
  color: white;
}
.theme-purple .dark .mat-toolbar.mat-warn,
.theme-purple.dark .mat-toolbar.mat-warn {
  background: #dc2626;
  color: white;
}
.theme-purple .dark .mat-toolbar .mat-form-field-underline,
.theme-purple .dark .mat-toolbar .mat-form-field-ripple,
.theme-purple .dark .mat-toolbar .mat-focused .mat-form-field-ripple,
.theme-purple.dark .mat-toolbar .mat-form-field-underline,
.theme-purple.dark .mat-toolbar .mat-form-field-ripple,
.theme-purple.dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.theme-purple .dark .mat-toolbar .mat-form-field-label,
.theme-purple .dark .mat-toolbar .mat-focused .mat-form-field-label,
.theme-purple .dark .mat-toolbar .mat-select-value,
.theme-purple .dark .mat-toolbar .mat-select-arrow,
.theme-purple .dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow,
.theme-purple.dark .mat-toolbar .mat-form-field-label,
.theme-purple.dark .mat-toolbar .mat-focused .mat-form-field-label,
.theme-purple.dark .mat-toolbar .mat-select-value,
.theme-purple.dark .mat-toolbar .mat-select-arrow,
.theme-purple.dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.theme-purple .dark .mat-toolbar .mat-input-element,
.theme-purple.dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.theme-purple .dark .mat-tree,
.theme-purple.dark .mat-tree {
  background: #1e293b;
}
.theme-purple .dark .mat-tree-node,
.theme-purple .dark .mat-nested-tree-node,
.theme-purple.dark .mat-tree-node,
.theme-purple.dark .mat-nested-tree-node {
  color: white;
}

/* Generate the palettes */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define a light & dark Angular Material theme with the generated palettes */
/* Merge the custom base colors with the generated themes */
/* Generate and encapsulate Angular Material themes */
.theme-amber .light .mat-ripple-element,
.theme-amber.light .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.theme-amber .light .mat-mdc-option,
.theme-amber.light .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-amber .light .mat-mdc-option:hover:not(.mdc-list-item--disabled), .theme-amber .light .mat-mdc-option:focus:not(.mdc-list-item--disabled), .theme-amber .light .mat-mdc-option.mat-mdc-option-active, .theme-amber .light .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled),
.theme-amber.light .mat-mdc-option:hover:not(.mdc-list-item--disabled),
.theme-amber.light .mat-mdc-option:focus:not(.mdc-list-item--disabled),
.theme-amber.light .mat-mdc-option.mat-mdc-option-active,
.theme-amber.light .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.theme-amber .light .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-amber.light .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #f59e0b);
}
.theme-amber .light .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-amber.light .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #1e293b);
}
.theme-amber .light .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-amber.light .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mat-mdc-optgroup-label,
.theme-amber.light .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-amber .light .mat-pseudo-checkbox,
.theme-amber.light .mat-pseudo-checkbox {
  color: #64748b;
}
.theme-amber .light .mat-pseudo-checkbox::after,
.theme-amber.light .mat-pseudo-checkbox::after {
  color: #f1f5f9;
}
.theme-amber .light .mat-pseudo-checkbox-disabled,
.theme-amber.light .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.theme-amber .light .mat-primary .mat-pseudo-checkbox-checked,
.theme-amber .light .mat-primary .mat-pseudo-checkbox-indeterminate,
.theme-amber.light .mat-primary .mat-pseudo-checkbox-checked,
.theme-amber.light .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #f59e0b;
}
.theme-amber .light .mat-pseudo-checkbox-checked,
.theme-amber .light .mat-pseudo-checkbox-indeterminate,
.theme-amber .light .mat-accent .mat-pseudo-checkbox-checked,
.theme-amber .light .mat-accent .mat-pseudo-checkbox-indeterminate,
.theme-amber.light .mat-pseudo-checkbox-checked,
.theme-amber.light .mat-pseudo-checkbox-indeterminate,
.theme-amber.light .mat-accent .mat-pseudo-checkbox-checked,
.theme-amber.light .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #1e293b;
}
.theme-amber .light .mat-warn .mat-pseudo-checkbox-checked,
.theme-amber .light .mat-warn .mat-pseudo-checkbox-indeterminate,
.theme-amber.light .mat-warn .mat-pseudo-checkbox-checked,
.theme-amber.light .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #dc2626;
}
.theme-amber .light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-amber .light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled,
.theme-amber.light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-amber.light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.theme-amber .light .mat-app-background, .theme-amber .light.mat-app-background,
.theme-amber.light .mat-app-background,
.theme-amber.light.mat-app-background {
  background-color: #f1f5f9;
  color: #1e293b;
}
.theme-amber .light .mat-elevation-z0, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-amber.light .mat-elevation-z0,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z1, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-amber.light .mat-elevation-z1,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z2, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-amber.light .mat-elevation-z2,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z3, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-amber.light .mat-elevation-z3,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z4, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-amber.light .mat-elevation-z4,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z5, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-amber.light .mat-elevation-z5,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z6, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-amber.light .mat-elevation-z6,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z7, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-amber.light .mat-elevation-z7,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z8, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-amber.light .mat-elevation-z8,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z9, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-amber.light .mat-elevation-z9,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z10, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-amber.light .mat-elevation-z10,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z11, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-amber.light .mat-elevation-z11,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z12, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-amber.light .mat-elevation-z12,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z13, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-amber.light .mat-elevation-z13,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z14, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-amber.light .mat-elevation-z14,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z15, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-amber.light .mat-elevation-z15,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z16, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-amber.light .mat-elevation-z16,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z17, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-amber.light .mat-elevation-z17,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z18, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-amber.light .mat-elevation-z18,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z19, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-amber.light .mat-elevation-z19,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z20, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-amber.light .mat-elevation-z20,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z21, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-amber.light .mat-elevation-z21,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z22, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-amber.light .mat-elevation-z22,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z23, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-amber.light .mat-elevation-z23,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z24, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-amber.light .mat-elevation-z24,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-amber .light .mat-mdc-card,
.theme-amber.light .mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #fff;
}
.theme-amber .light .mat-mdc-card-outlined,
.theme-amber.light .mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #e0e0e0;
}
.theme-amber .light .mat-mdc-card-subtitle,
.theme-amber.light .mat-mdc-card-subtitle {
  color: #64748b;
}
.theme-amber .light .mat-mdc-progress-bar,
.theme-amber.light .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #f59e0b;
}
.theme-amber .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.theme-amber.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(245, 158, 11, 0.25)'/%3E%3C/svg%3E");
}
.theme-amber .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.theme-amber.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(245, 158, 11, 0.25);
}
.theme-amber .light .mat-mdc-progress-bar.mat-accent,
.theme-amber.light .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
}
.theme-amber .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.theme-amber.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(30, 41, 59, 0.25)'/%3E%3C/svg%3E");
}
.theme-amber .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.theme-amber.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(30, 41, 59, 0.25);
}
.theme-amber .light .mat-mdc-progress-bar.mat-warn,
.theme-amber.light .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
}
.theme-amber .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.theme-amber.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(220, 38, 38, 0.25)'/%3E%3C/svg%3E");
}
.theme-amber .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.theme-amber.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(220, 38, 38, 0.25);
}
.theme-amber .light .mat-mdc-tooltip,
.theme-amber.light .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.theme-amber .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-amber.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.theme-amber .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input,
.theme-amber.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .theme-amber .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder,
  .theme-amber.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .theme-amber .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder,
  .theme-amber.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.theme-amber .light .mdc-text-field .mdc-text-field__input,
.theme-amber.light .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #f59e0b);
}
.theme-amber .light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text,
.theme-amber.light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.theme-amber .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.theme-amber .light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter,
.theme-amber.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.theme-amber.light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.theme-amber .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading,
.theme-amber.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.theme-amber .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-amber.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.theme-amber .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix,
.theme-amber.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-amber .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix,
.theme-amber.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-amber .light .mdc-text-field--filled .mdc-text-field__ripple::before,
.theme-amber .light .mdc-text-field--filled .mdc-text-field__ripple::after,
.theme-amber.light .mdc-text-field--filled .mdc-text-field__ripple::before,
.theme-amber.light .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.theme-amber .light .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .theme-amber .light .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before,
.theme-amber.light .mdc-text-field--filled:hover .mdc-text-field__ripple::before,
.theme-amber.light .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.theme-amber .light .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .theme-amber .light .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before,
.theme-amber.light .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before,
.theme-amber.light .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.theme-amber .light .mdc-text-field--filled:not(.mdc-text-field--disabled),
.theme-amber.light .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.theme-amber .light .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-amber.light .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.theme-amber .light .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-amber.light .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.theme-amber .light .mdc-text-field--filled .mdc-line-ripple::after,
.theme-amber.light .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #f59e0b);
}
.theme-amber .light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-amber .light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-amber .light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-amber.light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-amber.light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-amber.light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.theme-amber .light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-amber .light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-amber .light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-amber.light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-amber.light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-amber.light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.theme-amber .light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-amber .light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-amber .light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-amber.light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-amber.light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-amber.light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #f59e0b);
}
[dir=rtl] .theme-amber .light .mdc-text-field--outlined.mdc-text-field--with-leading-icon, [dir=rtl] .theme-amber.light .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .theme-amber .light .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl], .theme-amber.light .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-amber .light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, [dir=rtl] .theme-amber.light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .theme-amber .light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl], .theme-amber.light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-amber .light .mdc-text-field--outlined .mdc-text-field__ripple::before,
.theme-amber .light .mdc-text-field--outlined .mdc-text-field__ripple::after,
.theme-amber.light .mdc-text-field--outlined .mdc-text-field__ripple::before,
.theme-amber.light .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .theme-amber .light .mdc-text-field--with-leading-icon.mdc-text-field--outlined, [dir=rtl] .theme-amber.light .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .theme-amber .light .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl], .theme-amber.light .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-amber .light .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-amber.light .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(245, 158, 11, 0.87);
}
.theme-amber .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-amber.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-amber.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-amber.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-amber.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mdc-text-field--invalid .mdc-text-field__input,
.theme-amber.light .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-amber.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-amber.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-amber .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-amber .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-amber.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-amber.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-amber.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-amber .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-amber .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-amber.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-amber.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-amber.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-amber .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-amber .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-amber.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-amber.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-amber.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mdc-text-field--disabled .mdc-text-field__input,
.theme-amber.light .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .theme-amber .light .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  .theme-amber.light .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .theme-amber .light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  .theme-amber.light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.theme-amber .light .mdc-text-field--disabled .mdc-floating-label,
.theme-amber.light .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.theme-amber .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
.theme-amber.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.theme-amber .light .mdc-text-field--disabled .mdc-text-field-character-counter,
.theme-amber .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
.theme-amber.light .mdc-text-field--disabled .mdc-text-field-character-counter,
.theme-amber.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.theme-amber .light .mdc-text-field--disabled .mdc-text-field__icon--leading,
.theme-amber.light .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.theme-amber .light .mdc-text-field--disabled .mdc-text-field__icon--trailing,
.theme-amber.light .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.theme-amber .light .mdc-text-field--disabled .mdc-text-field__affix--prefix,
.theme-amber.light .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-amber .light .mdc-text-field--disabled .mdc-text-field__affix--suffix,
.theme-amber.light .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-amber .light .mdc-text-field--disabled .mdc-line-ripple::before,
.theme-amber.light .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.theme-amber .light .mdc-text-field--disabled .mdc-notched-outline__leading,
.theme-amber .light .mdc-text-field--disabled .mdc-notched-outline__notch,
.theme-amber .light .mdc-text-field--disabled .mdc-notched-outline__trailing,
.theme-amber.light .mdc-text-field--disabled .mdc-notched-outline__leading,
.theme-amber.light .mdc-text-field--disabled .mdc-notched-outline__notch,
.theme-amber.light .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-amber .light .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  .theme-amber.light .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-amber .light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  .theme-amber.light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-amber .light .mdc-text-field--disabled .mdc-floating-label,
  .theme-amber.light .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-amber .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
  .theme-amber.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-amber .light .mdc-text-field--disabled .mdc-text-field-character-counter,
  .theme-amber .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
  .theme-amber.light .mdc-text-field--disabled .mdc-text-field-character-counter,
  .theme-amber.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-amber .light .mdc-text-field--disabled .mdc-text-field__icon--leading,
  .theme-amber.light .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-amber .light .mdc-text-field--disabled .mdc-text-field__icon--trailing,
  .theme-amber.light .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-amber .light .mdc-text-field--disabled .mdc-text-field__affix--prefix,
  .theme-amber.light .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-amber .light .mdc-text-field--disabled .mdc-text-field__affix--suffix,
  .theme-amber.light .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-amber .light .mdc-text-field--disabled .mdc-line-ripple::before,
  .theme-amber.light .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-amber .light .mdc-text-field--disabled .mdc-notched-outline__leading,
  .theme-amber .light .mdc-text-field--disabled .mdc-notched-outline__notch,
  .theme-amber .light .mdc-text-field--disabled .mdc-notched-outline__trailing,
  .theme-amber.light .mdc-text-field--disabled .mdc-notched-outline__leading,
  .theme-amber.light .mdc-text-field--disabled .mdc-notched-outline__notch,
  .theme-amber.light .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.theme-amber .light .mdc-text-field--disabled.mdc-text-field--filled,
.theme-amber.light .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .theme-amber .light .mdc-text-field--end-aligned .mdc-text-field__input, [dir=rtl] .theme-amber.light .mdc-text-field--end-aligned .mdc-text-field__input, .theme-amber .light .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl], .theme-amber.light .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-amber .light .mdc-floating-label--required, [dir=rtl] .theme-amber.light .mdc-floating-label--required, .theme-amber .light .mdc-floating-label--required[dir=rtl], .theme-amber.light .mdc-floating-label--required[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-amber .light .mat-mdc-form-field-error,
.theme-amber.light .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mat-mdc-form-field-focus-overlay,
.theme-amber.light .mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}
.theme-amber .light .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.theme-amber.light .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
.theme-amber .light .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.theme-amber.light .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}
.theme-amber .light .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.theme-amber.light .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.theme-amber .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.theme-amber.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(245, 158, 11, 0.87);
}
.theme-amber .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.theme-amber.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(30, 41, 59, 0.87);
}
.theme-amber .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.theme-amber.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(220, 38, 38, 0.87);
}
.theme-amber .light .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.theme-amber.light .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.theme-amber .light .mat-mdc-form-field.mat-accent .mdc-text-field__input,
.theme-amber.light .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-amber .light .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-amber.light .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-amber .light .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-amber.light .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(30, 41, 59, 0.87);
}
.theme-amber .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-amber.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-amber.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-amber.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-amber.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input,
.theme-amber.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-amber.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-amber.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-amber .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-amber .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-amber.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-amber.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-amber.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-amber .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-amber .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-amber.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-amber.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-amber.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-amber .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-amber .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-amber.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-amber.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-amber.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-amber .light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-amber .light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-amber.light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-amber.light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-amber.light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-amber .light .mat-mdc-form-field.mat-warn .mdc-text-field__input,
.theme-amber.light .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-amber.light .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-amber.light .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(220, 38, 38, 0.87);
}
.theme-amber .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-amber.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-amber.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-amber.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-amber.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input,
.theme-amber.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-amber.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-amber.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-amber .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-amber .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-amber.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-amber.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-amber.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-amber .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-amber .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-amber.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-amber.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-amber.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-amber .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-amber .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-amber.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-amber.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-amber.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-amber .light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-amber .light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-amber.light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-amber.light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-amber.light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .light .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.theme-amber.light .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.theme-amber .light [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.theme-amber.light [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.theme-amber .light .mdc-menu-surface,
.theme-amber.light .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mdc-list-item__primary-text,
.theme-amber.light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-amber .light .mdc-list-item__secondary-text,
.theme-amber.light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.theme-amber .light .mdc-list-item__overline-text,
.theme-amber.light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-amber .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-amber.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-amber .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-amber.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.theme-amber .light .mdc-list-item__end,
.theme-amber.light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-amber .light .mdc-list-item--disabled .mdc-list-item__start,
.theme-amber .light .mdc-list-item--disabled .mdc-list-item__content,
.theme-amber .light .mdc-list-item--disabled .mdc-list-item__end,
.theme-amber.light .mdc-list-item--disabled .mdc-list-item__start,
.theme-amber.light .mdc-list-item--disabled .mdc-list-item__content,
.theme-amber.light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-amber .light .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-amber.light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-amber.light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-amber.light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-amber.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-amber.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-amber .light .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-amber.light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-amber.light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #f59e0b);
}
.theme-amber .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #f59e0b);
}
.theme-amber .light .mdc-deprecated-list-group__subheader,
.theme-amber.light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-amber .light .mdc-list-divider::after,
.theme-amber.light .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-amber .light .mdc-list-divider,
.theme-amber.light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-mdc-select-value,
.theme-amber.light .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.theme-amber .light .mat-mdc-select-placeholder,
.theme-amber.light .mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.theme-amber .light .mat-mdc-select-disabled .mat-mdc-select-value,
.theme-amber.light .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.theme-amber .light .mat-mdc-select-arrow,
.theme-amber.light .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.theme-amber .light .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow,
.theme-amber.light .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(245, 158, 11, 0.87);
}
.theme-amber .light .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow,
.theme-amber.light .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(30, 41, 59, 0.87);
}
.theme-amber .light .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow,
.theme-amber.light .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(220, 38, 38, 0.87);
}
.theme-amber .light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow,
.theme-amber.light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(220, 38, 38, 0.87);
}
.theme-amber .light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow,
.theme-amber.light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.theme-amber .light .mdc-menu-surface,
.theme-amber.light .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mdc-list-item__primary-text,
.theme-amber.light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-amber .light .mdc-list-item__secondary-text,
.theme-amber.light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.theme-amber .light .mdc-list-item__overline-text,
.theme-amber.light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-amber .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-amber.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-amber .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-amber.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.theme-amber .light .mdc-list-item__end,
.theme-amber.light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-amber .light .mdc-list-item--disabled .mdc-list-item__start,
.theme-amber .light .mdc-list-item--disabled .mdc-list-item__content,
.theme-amber .light .mdc-list-item--disabled .mdc-list-item__end,
.theme-amber.light .mdc-list-item--disabled .mdc-list-item__start,
.theme-amber.light .mdc-list-item--disabled .mdc-list-item__content,
.theme-amber.light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-amber .light .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-amber.light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-amber.light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-amber.light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-amber.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-amber.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-amber .light .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-amber.light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-amber.light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #f59e0b);
}
.theme-amber .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #f59e0b);
}
.theme-amber .light .mdc-deprecated-list-group__subheader,
.theme-amber.light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-amber .light .mdc-list-divider::after,
.theme-amber.light .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-amber .light .mdc-list-divider,
.theme-amber.light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-mdc-dialog-container,
.theme-amber.light .mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.theme-amber .light .mat-mdc-standard-chip,
.theme-amber.light .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.theme-amber .light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .theme-amber .light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted,
.theme-amber.light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected,
.theme-amber.light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #f59e0b;
  --mdc-chip-elevated-disabled-container-color: #f59e0b;
  --mdc-chip-label-text-color: #78350f;
  --mdc-chip-disabled-label-text-color: #78350f;
  --mdc-chip-with-icon-icon-color: #78350f;
  --mdc-chip-with-icon-disabled-icon-color: #78350f;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #78350f;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #78350f;
  --mdc-chip-with-icon-selected-icon-color: #78350f;
}
.theme-amber .light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .theme-amber .light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted,
.theme-amber.light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected,
.theme-amber.light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-amber .light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .theme-amber .light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted,
.theme-amber.light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected,
.theme-amber.light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-amber .light .mat-mdc-chip-focus-overlay,
.theme-amber.light .mat-mdc-chip-focus-overlay {
  background: #000000;
}
.theme-amber .light .mat-mdc-slide-toggle,
.theme-amber.light .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.theme-amber .light .mat-mdc-slide-toggle .mdc-form-field,
.theme-amber.light .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-amber .light .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.theme-amber.light .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: #94a3b8;
}
.theme-amber .light .mat-mdc-slide-toggle.mat-primary,
.theme-amber.light .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #d97706;
  --mdc-switch-selected-handle-color: #d97706;
  --mdc-switch-selected-hover-state-layer-color: #d97706;
  --mdc-switch-selected-pressed-state-layer-color: #d97706;
  --mdc-switch-selected-focus-handle-color: #78350f;
  --mdc-switch-selected-hover-handle-color: #78350f;
  --mdc-switch-selected-pressed-handle-color: #78350f;
  --mdc-switch-selected-focus-track-color: #fcd34d;
  --mdc-switch-selected-hover-track-color: #fcd34d;
  --mdc-switch-selected-pressed-track-color: #fcd34d;
  --mdc-switch-selected-track-color: #fcd34d;
}
.theme-amber .light .mat-mdc-slide-toggle.mat-accent,
.theme-amber.light .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.theme-amber .light .mat-mdc-slide-toggle.mat-warn,
.theme-amber.light .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.theme-amber .light .mat-mdc-radio-button,
.theme-amber.light .mat-mdc-radio-button {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mat-mdc-radio-ripple-color: #000;
}
.theme-amber .light .mat-mdc-radio-button .mdc-form-field,
.theme-amber.light .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-amber .light .mat-mdc-radio-button .mdc-radio--disabled + label,
.theme-amber.light .mat-mdc-radio-button .mdc-radio--disabled + label {
  color: #94a3b8;
}
.theme-amber .light .mat-mdc-radio-button.mat-primary,
.theme-amber.light .mat-mdc-radio-button.mat-primary {
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
  --mat-mdc-radio-checked-ripple-color: #f59e0b;
}
.theme-amber .light .mat-mdc-radio-button.mat-accent,
.theme-amber.light .mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-mdc-radio-checked-ripple-color: #1e293b;
}
.theme-amber .light .mat-mdc-radio-button.mat-warn,
.theme-amber.light .mat-mdc-radio-button.mat-warn {
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-mdc-radio-checked-ripple-color: #dc2626;
}
.theme-amber .light .mat-mdc-slider,
.theme-amber.light .mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.theme-amber .light .mat-mdc-slider.mat-primary,
.theme-amber.light .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #f59e0b;
  --mdc-slider-focus-handle-color: #f59e0b;
  --mdc-slider-hover-handle-color: #f59e0b;
  --mdc-slider-active-track-color: #f59e0b;
  --mdc-slider-inactive-track-color: #f59e0b;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #f59e0b;
  --mat-mdc-slider-ripple-color: #f59e0b;
  --mat-mdc-slider-hover-ripple-color: rgba(245, 158, 11, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(245, 158, 11, 0.2);
}
.theme-amber .light .mat-mdc-slider.mat-accent,
.theme-amber.light .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mat-mdc-slider-ripple-color: #1e293b;
  --mat-mdc-slider-hover-ripple-color: rgba(30, 41, 59, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(30, 41, 59, 0.2);
}
.theme-amber .light .mat-mdc-slider.mat-warn,
.theme-amber.light .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mat-mdc-slider-ripple-color: #dc2626;
  --mat-mdc-slider-hover-ripple-color: rgba(220, 38, 38, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(220, 38, 38, 0.2);
}
.theme-amber .light .mdc-menu-surface,
.theme-amber.light .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mdc-list-item__primary-text,
.theme-amber.light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-amber .light .mdc-list-item__secondary-text,
.theme-amber.light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.theme-amber .light .mdc-list-item__overline-text,
.theme-amber.light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-amber .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-amber.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-amber .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-amber.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.theme-amber .light .mdc-list-item__end,
.theme-amber.light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-amber .light .mdc-list-item--disabled .mdc-list-item__start,
.theme-amber .light .mdc-list-item--disabled .mdc-list-item__content,
.theme-amber .light .mdc-list-item--disabled .mdc-list-item__end,
.theme-amber.light .mdc-list-item--disabled .mdc-list-item__start,
.theme-amber.light .mdc-list-item--disabled .mdc-list-item__content,
.theme-amber.light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-amber .light .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-amber.light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-amber.light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-amber.light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-amber.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-amber.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-amber .light .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-amber.light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-amber.light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #f59e0b);
}
.theme-amber .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #f59e0b);
}
.theme-amber .light .mdc-deprecated-list-group__subheader,
.theme-amber.light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-amber .light .mdc-list-divider::after,
.theme-amber.light .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-amber .light .mdc-list-divider,
.theme-amber.light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-mdc-menu-item[disabled],
.theme-amber .light .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.theme-amber .light .mat-mdc-menu-item[disabled] .mat-icon-no-color,
.theme-amber.light .mat-mdc-menu-item[disabled],
.theme-amber.light .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.theme-amber.light .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}
.theme-amber .light .mat-mdc-menu-item .mat-icon-no-color,
.theme-amber .light .mat-mdc-menu-submenu-icon,
.theme-amber.light .mat-mdc-menu-item .mat-icon-no-color,
.theme-amber.light .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-amber .light .mat-mdc-menu-item:hover:not([disabled]),
.theme-amber .light .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.theme-amber .light .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-amber .light .mat-mdc-menu-item-highlighted:not([disabled]),
.theme-amber.light .mat-mdc-menu-item:hover:not([disabled]),
.theme-amber.light .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.theme-amber.light .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-amber.light .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.theme-amber .light .mat-mdc-list-item-interactive::before,
.theme-amber.light .mat-mdc-list-item-interactive::before {
  background: black;
}
.theme-amber .light .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before,
.theme-amber.light .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.04;
}
.theme-amber .light .mat-mdc-list-item-interactive.mdc-list-item--selected::before,
.theme-amber.light .mat-mdc-list-item-interactive.mdc-list-item--selected::before {
  background: #f59e0b;
  opacity: 0.16;
}
.theme-amber .light .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before,
.theme-amber.light .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.24;
}
.theme-amber .light .mat-mdc-list-item-interactive:focus::before,
.theme-amber.light .mat-mdc-list-item-interactive:focus::before {
  opacity: 0.12;
}
.theme-amber .light .mdc-list-item__primary-text,
.theme-amber.light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-amber .light .mdc-list-item__secondary-text,
.theme-amber.light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.theme-amber .light .mdc-list-item__overline-text,
.theme-amber.light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-amber .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-amber.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-amber .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber .light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-amber.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.theme-amber .light .mdc-list-item__end,
.theme-amber.light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-amber .light .mdc-list-item--disabled .mdc-list-item__start,
.theme-amber .light .mdc-list-item--disabled .mdc-list-item__content,
.theme-amber .light .mdc-list-item--disabled .mdc-list-item__end,
.theme-amber.light .mdc-list-item--disabled .mdc-list-item__start,
.theme-amber.light .mdc-list-item--disabled .mdc-list-item__content,
.theme-amber.light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-amber .light .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-amber.light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-amber.light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-amber.light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-amber.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-amber.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-amber .light .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-amber.light .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-amber.light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #f59e0b);
}
.theme-amber .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #f59e0b);
}
.theme-amber .light .mdc-deprecated-list-group__subheader,
.theme-amber.light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-amber .light .mdc-list-divider::after,
.theme-amber.light .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-amber .light .mdc-list-divider,
.theme-amber.light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-mdc-list-option .mdc-list-item__start, .theme-amber .light .mat-mdc-list-option .mdc-list-item__end,
.theme-amber.light .mat-mdc-list-option .mdc-list-item__start,
.theme-amber.light .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-amber .light .mat-mdc-list-option.mat-accent .mdc-list-item__start, .theme-amber .light .mat-mdc-list-option.mat-accent .mdc-list-item__end,
.theme-amber.light .mat-mdc-list-option.mat-accent .mdc-list-item__start,
.theme-amber.light .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-amber .light .mat-mdc-list-option.mat-warn .mdc-list-item__start, .theme-amber .light .mat-mdc-list-option.mat-warn .mdc-list-item__end,
.theme-amber.light .mat-mdc-list-option.mat-warn .mdc-list-item__start,
.theme-amber.light .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-amber .light .mat-mdc-paginator,
.theme-amber.light .mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme-amber .light .mat-mdc-paginator-icon,
.theme-amber.light .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}
.theme-amber .light .mat-mdc-paginator-decrement,
.theme-amber .light .mat-mdc-paginator-increment,
.theme-amber.light .mat-mdc-paginator-decrement,
.theme-amber.light .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.theme-amber .light .mat-mdc-paginator-first,
.theme-amber .light .mat-mdc-paginator-last,
.theme-amber.light .mat-mdc-paginator-first,
.theme-amber.light .mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.theme-amber .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.theme-amber .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.theme-amber .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.theme-amber .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last,
.theme-amber.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.theme-amber.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.theme-amber.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.theme-amber.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon,
.theme-amber.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-mdc-tab, .theme-amber .light .mat-mdc-tab-link,
.theme-amber.light .mat-mdc-tab,
.theme-amber.light .mat-mdc-tab-link {
  background-color: transparent;
}
.theme-amber .light .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label, .theme-amber .light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.theme-amber.light .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.theme-amber.light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}
.theme-amber .light .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-amber .light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-amber.light .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-amber.light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #f59e0b;
}
.theme-amber .light .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-amber .light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-amber.light .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-amber.light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #f59e0b;
}
.theme-amber .light .mdc-tab__ripple::before,
.theme-amber .light .mat-mdc-tab .mat-ripple-element,
.theme-amber .light .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-amber .light .mat-mdc-tab-link .mat-ripple-element,
.theme-amber.light .mdc-tab__ripple::before,
.theme-amber.light .mat-mdc-tab .mat-ripple-element,
.theme-amber.light .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-amber.light .mat-mdc-tab-link .mat-ripple-element {
  background-color: #f59e0b;
}
.theme-amber .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-amber .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-amber .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-amber .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-amber.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-amber.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-amber.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-amber.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #1e293b;
}
.theme-amber .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-amber .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-amber .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-amber .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-amber.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-amber.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-amber.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-amber.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #1e293b;
}
.theme-amber .light .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.theme-amber .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-amber .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-amber .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .theme-amber .light .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.theme-amber .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-amber .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-amber .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element,
.theme-amber.light .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.theme-amber.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-amber.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-amber.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element,
.theme-amber.light .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.theme-amber.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-amber.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-amber.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #1e293b;
}
.theme-amber .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-amber .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-amber .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-amber .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-amber.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-amber.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-amber.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-amber.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #dc2626;
}
.theme-amber .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-amber .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-amber .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-amber .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-amber.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-amber.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-amber.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-amber.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #dc2626;
}
.theme-amber .light .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.theme-amber .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-amber .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-amber .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .theme-amber .light .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.theme-amber .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-amber .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-amber .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element,
.theme-amber.light .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.theme-amber.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-amber.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-amber.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element,
.theme-amber.light .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.theme-amber.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-amber.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-amber.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #dc2626;
}
.theme-amber .light .mat-mdc-tab-group.mat-background-primary, .theme-amber .light .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-amber.light .mat-mdc-tab-group.mat-background-primary,
.theme-amber.light .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #f59e0b;
  --mat-mdc-tab-header-with-background-foreground-color: #000;
}
.theme-amber .light .mat-mdc-tab-group.mat-background-accent, .theme-amber .light .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-amber.light .mat-mdc-tab-group.mat-background-accent,
.theme-amber.light .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #1e293b;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-amber .light .mat-mdc-tab-group.mat-background-warn, .theme-amber .light .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-amber.light .mat-mdc-tab-group.mat-background-warn,
.theme-amber.light .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #dc2626;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-amber .light .mat-mdc-tab-header-pagination-chevron,
.theme-amber.light .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #000);
}
.theme-amber .light .mat-mdc-checkbox .mdc-form-field,
.theme-amber.light .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-amber .light .mat-mdc-checkbox .mat-ripple-element,
.theme-amber.light .mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.theme-amber .light .mat-mdc-checkbox .mdc-checkbox__ripple,
.theme-amber.light .mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #000;
}
.theme-amber .light .mat-mdc-checkbox.mat-primary,
.theme-amber.light .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-amber .light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-amber.light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(245, 158, 11, 0.1);
}
.theme-amber .light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-amber.light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #f59e0b;
}
.theme-amber .light .mat-mdc-checkbox.mat-accent,
.theme-amber.light .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-amber .light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-amber.light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(30, 41, 59, 0.1);
}
.theme-amber .light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-amber.light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #1e293b;
}
.theme-amber .light .mat-mdc-checkbox.mat-warn,
.theme-amber.light .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-amber .light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-amber.light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(220, 38, 38, 0.1);
}
.theme-amber .light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-amber.light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #dc2626;
}
.theme-amber .light .mat-mdc-checkbox-disabled label,
.theme-amber.light .mat-mdc-checkbox-disabled label {
  color: #94a3b8;
}
.theme-amber .light .mat-mdc-button.mat-unthemed,
.theme-amber.light .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.theme-amber .light .mat-mdc-button.mat-primary,
.theme-amber.light .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #f59e0b;
}
.theme-amber .light .mat-mdc-button.mat-accent,
.theme-amber.light .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
}
.theme-amber .light .mat-mdc-button.mat-warn,
.theme-amber.light .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
}
.theme-amber .light .mat-mdc-button[disabled][disabled],
.theme-amber.light .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.theme-amber .light .mat-mdc-unelevated-button.mat-unthemed,
.theme-amber.light .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.theme-amber .light .mat-mdc-unelevated-button.mat-primary,
.theme-amber.light .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #f59e0b;
  --mdc-filled-button-label-text-color: #000;
}
.theme-amber .light .mat-mdc-unelevated-button.mat-accent,
.theme-amber.light .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-amber .light .mat-mdc-unelevated-button.mat-warn,
.theme-amber.light .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-amber .light .mat-mdc-unelevated-button[disabled][disabled],
.theme-amber.light .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.theme-amber .light .mat-mdc-raised-button.mat-unthemed,
.theme-amber.light .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.theme-amber .light .mat-mdc-raised-button.mat-primary,
.theme-amber.light .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #f59e0b;
  --mdc-protected-button-label-text-color: #000;
}
.theme-amber .light .mat-mdc-raised-button.mat-accent,
.theme-amber.light .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-amber .light .mat-mdc-raised-button.mat-warn,
.theme-amber.light .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-amber .light .mat-mdc-raised-button[disabled][disabled],
.theme-amber.light .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.theme-amber .light .mat-mdc-outlined-button,
.theme-amber.light .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-mdc-outlined-button.mat-unthemed,
.theme-amber.light .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.theme-amber .light .mat-mdc-outlined-button.mat-primary,
.theme-amber.light .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #f59e0b;
}
.theme-amber .light .mat-mdc-outlined-button.mat-accent,
.theme-amber.light .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
}
.theme-amber .light .mat-mdc-outlined-button.mat-warn,
.theme-amber.light .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
}
.theme-amber .light .mat-mdc-outlined-button[disabled][disabled],
.theme-amber.light .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-mdc-button, .theme-amber .light .mat-mdc-outlined-button,
.theme-amber.light .mat-mdc-button,
.theme-amber.light .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-amber .light .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .theme-amber .light .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-amber .light .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-amber .light .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-amber .light .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-amber .light .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-amber .light .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .theme-amber .light .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-amber .light .mat-mdc-button.mat-primary, .theme-amber .light .mat-mdc-outlined-button.mat-primary,
.theme-amber.light .mat-mdc-button.mat-primary,
.theme-amber.light .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.theme-amber .light .mat-mdc-button.mat-accent, .theme-amber .light .mat-mdc-outlined-button.mat-accent,
.theme-amber.light .mat-mdc-button.mat-accent,
.theme-amber.light .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #1e293b;
  --mat-mdc-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-amber .light .mat-mdc-button.mat-warn, .theme-amber .light .mat-mdc-outlined-button.mat-warn,
.theme-amber.light .mat-mdc-button.mat-warn,
.theme-amber.light .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #dc2626;
  --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-amber .light .mat-mdc-raised-button, .theme-amber .light .mat-mdc-unelevated-button,
.theme-amber.light .mat-mdc-raised-button,
.theme-amber.light .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-amber .light .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .theme-amber .light .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-amber .light .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-amber .light .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-amber .light .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-amber .light .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-amber .light .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .theme-amber .light .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-amber .light .mat-mdc-raised-button.mat-primary, .theme-amber .light .mat-mdc-unelevated-button.mat-primary,
.theme-amber.light .mat-mdc-raised-button.mat-primary,
.theme-amber.light .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-amber .light .mat-mdc-raised-button.mat-accent, .theme-amber .light .mat-mdc-unelevated-button.mat-accent,
.theme-amber.light .mat-mdc-raised-button.mat-accent,
.theme-amber.light .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .light .mat-mdc-raised-button.mat-warn, .theme-amber .light .mat-mdc-unelevated-button.mat-warn,
.theme-amber.light .mat-mdc-raised-button.mat-warn,
.theme-amber.light .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .light .mat-mdc-icon-button,
.theme-amber.light .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-amber .light .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-amber .light .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-amber .light .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-amber .light .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-amber .light .mat-mdc-icon-button.mat-primary,
.theme-amber.light .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.theme-amber .light .mat-mdc-icon-button.mat-accent,
.theme-amber.light .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #1e293b;
  --mat-mdc-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-amber .light .mat-mdc-icon-button.mat-warn,
.theme-amber.light .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #dc2626;
  --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-amber .light .mat-mdc-icon-button.mat-primary,
.theme-amber.light .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #f59e0b;
}
.theme-amber .light .mat-mdc-icon-button.mat-accent,
.theme-amber.light .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
}
.theme-amber .light .mat-mdc-icon-button.mat-warn,
.theme-amber.light .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
}
.theme-amber .light .mat-mdc-icon-button[disabled][disabled],
.theme-amber.light .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.theme-amber .light .mat-mdc-fab, .theme-amber .light .mat-mdc-mini-fab,
.theme-amber.light .mat-mdc-fab,
.theme-amber.light .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-amber .light .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .theme-amber .light .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-amber .light .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-amber .light .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-amber .light .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-amber .light .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-amber .light .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .theme-amber .light .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.theme-amber.light .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-amber .light .mat-mdc-fab.mat-primary, .theme-amber .light .mat-mdc-mini-fab.mat-primary,
.theme-amber.light .mat-mdc-fab.mat-primary,
.theme-amber.light .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-amber .light .mat-mdc-fab.mat-accent, .theme-amber .light .mat-mdc-mini-fab.mat-accent,
.theme-amber.light .mat-mdc-fab.mat-accent,
.theme-amber.light .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .light .mat-mdc-fab.mat-warn, .theme-amber .light .mat-mdc-mini-fab.mat-warn,
.theme-amber.light .mat-mdc-fab.mat-warn,
.theme-amber.light .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .light .mat-mdc-fab.mat-unthemed, .theme-amber .light .mat-mdc-mini-fab.mat-unthemed,
.theme-amber.light .mat-mdc-fab.mat-unthemed,
.theme-amber.light .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.theme-amber .light .mat-mdc-fab.mat-primary, .theme-amber .light .mat-mdc-mini-fab.mat-primary,
.theme-amber.light .mat-mdc-fab.mat-primary,
.theme-amber.light .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #f59e0b;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.theme-amber .light .mat-mdc-fab.mat-accent, .theme-amber .light .mat-mdc-mini-fab.mat-accent,
.theme-amber.light .mat-mdc-fab.mat-accent,
.theme-amber.light .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-amber .light .mat-mdc-fab.mat-warn, .theme-amber .light .mat-mdc-mini-fab.mat-warn,
.theme-amber.light .mat-mdc-fab.mat-warn,
.theme-amber.light .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-amber .light .mat-mdc-fab[disabled][disabled], .theme-amber .light .mat-mdc-mini-fab[disabled][disabled],
.theme-amber.light .mat-mdc-fab[disabled][disabled],
.theme-amber.light .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.theme-amber .light .mat-mdc-snack-bar-container,
.theme-amber.light .mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #1e293b;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
}
.theme-amber .light .mdc-data-table,
.theme-amber.light .mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mdc-data-table__row,
.theme-amber.light .mdc-data-table__row {
  background-color: inherit;
}
.theme-amber .light .mdc-data-table__header-cell,
.theme-amber.light .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}
.theme-amber .light .mdc-data-table__row--selected,
.theme-amber.light .mdc-data-table__row--selected {
  background-color: rgba(245, 158, 11, 0.04);
}
.theme-amber .light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.theme-amber .light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.theme-amber .light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing,
.theme-amber.light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.theme-amber.light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.theme-amber.light .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mdc-data-table__cell,
.theme-amber .light .mdc-data-table__header-cell,
.theme-amber.light .mdc-data-table__cell,
.theme-amber.light .mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mdc-data-table__pagination,
.theme-amber.light .mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mdc-data-table__row:not(.mdc-data-table__row--selected):hover,
.theme-amber.light .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.theme-amber .light .mdc-data-table__header-cell,
.theme-amber.light .mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}
.theme-amber .light .mdc-data-table__pagination-total,
.theme-amber .light .mdc-data-table__pagination-rows-per-page-label,
.theme-amber .light .mdc-data-table__cell,
.theme-amber.light .mdc-data-table__pagination-total,
.theme-amber.light .mdc-data-table__pagination-rows-per-page-label,
.theme-amber.light .mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}
[dir=rtl] .theme-amber .light .mdc-data-table__pagination-button .mdc-button__icon, [dir=rtl] .theme-amber.light .mdc-data-table__pagination-button .mdc-button__icon, .theme-amber .light .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl], .theme-amber.light .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-amber .light .mat-mdc-table,
.theme-amber.light .mat-mdc-table {
  background: white;
}
.theme-amber .light .mat-mdc-progress-spinner,
.theme-amber.light .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #f59e0b;
}
.theme-amber .light .mat-mdc-progress-spinner.mat-accent,
.theme-amber.light .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-amber .light .mat-mdc-progress-spinner.mat-warn,
.theme-amber.light .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-amber .light .mat-badge-content,
.theme-amber.light .mat-badge-content {
  color: #78350f;
  background: #f59e0b;
}
.cdk-high-contrast-active .theme-amber .light .mat-badge-content, .cdk-high-contrast-active .theme-amber.light .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.theme-amber .light .mat-badge-accent .mat-badge-content,
.theme-amber.light .mat-badge-accent .mat-badge-content {
  background: #1e293b;
  color: white;
}
.theme-amber .light .mat-badge-warn .mat-badge-content,
.theme-amber.light .mat-badge-warn .mat-badge-content {
  color: white;
  background: #dc2626;
}
.theme-amber .light .mat-badge-disabled .mat-badge-content,
.theme-amber.light .mat-badge-disabled .mat-badge-content {
  background: #94a3b8;
  color: #94a3b8;
}
.theme-amber .light .mat-bottom-sheet-container,
.theme-amber.light .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: #1e293b;
}
.theme-amber .light .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-amber .light .mat-button-toggle-group:not([class*=mat-elevation-z]),
.theme-amber.light .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-amber.light .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-amber .light .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]),
.theme-amber.light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-amber.light .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.theme-amber .light .mat-button-toggle,
.theme-amber.light .mat-button-toggle {
  color: #94a3b8;
}
.theme-amber .light .mat-button-toggle .mat-button-toggle-focus-overlay,
.theme-amber.light .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: #64748b;
}
.theme-amber .light .mat-button-toggle-appearance-standard,
.theme-amber.light .mat-button-toggle-appearance-standard {
  color: #1e293b;
  background: white;
}
.theme-amber .light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay,
.theme-amber.light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: #64748b;
}
.theme-amber .light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
.theme-amber.light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e2e8f0;
}
.theme-amber .light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
.theme-amber.light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e2e8f0;
}
.theme-amber .light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle,
.theme-amber.light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e2e8f0;
}
.theme-amber .light .mat-button-toggle-checked,
.theme-amber.light .mat-button-toggle-checked {
  background-color: #e2e8f0;
  color: #64748b;
}
.theme-amber .light .mat-button-toggle-checked.mat-button-toggle-appearance-standard,
.theme-amber.light .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: #1e293b;
}
.theme-amber .light .mat-button-toggle-disabled,
.theme-amber.light .mat-button-toggle-disabled {
  color: #94a3b8;
  background-color: #cbd5e1;
}
.theme-amber .light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard,
.theme-amber.light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.theme-amber .light .mat-button-toggle-disabled.mat-button-toggle-checked,
.theme-amber.light .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #e2e8f0;
}
.theme-amber .light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-amber .light .mat-button-toggle-group-appearance-standard,
.theme-amber.light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-amber.light .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e2e8f0;
}
.theme-amber .light .mat-calendar-arrow,
.theme-amber.light .mat-calendar-arrow {
  fill: #64748b;
}
.theme-amber .light .mat-datepicker-toggle,
.theme-amber .light .mat-datepicker-content .mat-calendar-next-button,
.theme-amber .light .mat-datepicker-content .mat-calendar-previous-button,
.theme-amber.light .mat-datepicker-toggle,
.theme-amber.light .mat-datepicker-content .mat-calendar-next-button,
.theme-amber.light .mat-datepicker-content .mat-calendar-previous-button {
  color: #64748b;
}
.theme-amber .light .mat-calendar-table-header-divider::after,
.theme-amber.light .mat-calendar-table-header-divider::after {
  background: #e2e8f0;
}
.theme-amber .light .mat-calendar-table-header,
.theme-amber .light .mat-calendar-body-label,
.theme-amber.light .mat-calendar-table-header,
.theme-amber.light .mat-calendar-body-label {
  color: #64748b;
}
.theme-amber .light .mat-calendar-body-cell-content,
.theme-amber .light .mat-date-range-input-separator,
.theme-amber.light .mat-calendar-body-cell-content,
.theme-amber.light .mat-date-range-input-separator {
  color: #1e293b;
  border-color: transparent;
}
.theme-amber .light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-amber.light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: #94a3b8;
}
.theme-amber .light .mat-form-field-disabled .mat-date-range-input-separator,
.theme-amber.light .mat-form-field-disabled .mat-date-range-input-separator {
  color: #94a3b8;
}
.theme-amber .light .mat-calendar-body-in-preview,
.theme-amber.light .mat-calendar-body-in-preview {
  color: #e2e8f0;
}
.theme-amber .light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-amber.light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #94a3b8;
}
.theme-amber .light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-amber.light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(148, 163, 184, 0.8);
}
.theme-amber .light .mat-calendar-body-in-range::before,
.theme-amber.light .mat-calendar-body-in-range::before {
  background: rgba(245, 158, 11, 0.2);
}
.theme-amber .light .mat-calendar-body-comparison-identical,
.theme-amber .light .mat-calendar-body-in-comparison-range::before,
.theme-amber.light .mat-calendar-body-comparison-identical,
.theme-amber.light .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-amber .light .mat-calendar-body-comparison-bridge-start::before,
.theme-amber .light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-amber.light .mat-calendar-body-comparison-bridge-start::before,
.theme-amber.light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(245, 158, 11, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-amber .light .mat-calendar-body-comparison-bridge-end::before,
.theme-amber .light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-amber.light .mat-calendar-body-comparison-bridge-end::before,
.theme-amber.light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(245, 158, 11, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-amber .light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-amber .light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-amber.light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-amber.light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-amber .light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-amber .light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-amber.light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-amber.light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-amber .light .mat-calendar-body-selected,
.theme-amber.light .mat-calendar-body-selected {
  background-color: #f59e0b;
  color: #78350f;
}
.theme-amber .light .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-amber.light .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(245, 158, 11, 0.4);
}
.theme-amber .light .mat-calendar-body-today.mat-calendar-body-selected,
.theme-amber.light .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #78350f;
}
.theme-amber .light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-amber .light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-amber.light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-amber.light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(245, 158, 11, 0.3);
}
@media (hover: hover) {
  .theme-amber .light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-amber.light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(245, 158, 11, 0.3);
  }
}
.theme-amber .light .mat-datepicker-content,
.theme-amber.light .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: #1e293b;
}
.theme-amber .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before,
.theme-amber.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(30, 41, 59, 0.2);
}
.theme-amber .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-amber .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before,
.theme-amber.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-amber.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-amber .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-amber .light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-amber.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-amber.light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(30, 41, 59, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-amber .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-amber .light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-amber.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-amber.light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(30, 41, 59, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-amber .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-amber .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-amber.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-amber.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-amber .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-amber .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-amber.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-amber.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-amber .light .mat-datepicker-content.mat-accent .mat-calendar-body-selected,
.theme-amber.light .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #1e293b;
  color: white;
}
.theme-amber .light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-amber.light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(30, 41, 59, 0.4);
}
.theme-amber .light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected,
.theme-amber.light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-amber .light .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-amber .light .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-amber.light .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-amber.light .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(30, 41, 59, 0.3);
}
@media (hover: hover) {
  .theme-amber .light .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-amber.light .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(30, 41, 59, 0.3);
  }
}
.theme-amber .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before,
.theme-amber.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(220, 38, 38, 0.2);
}
.theme-amber .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-amber .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before,
.theme-amber.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-amber.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-amber .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-amber .light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-amber.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-amber.light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(220, 38, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-amber .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-amber .light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-amber.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-amber.light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(220, 38, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-amber .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-amber .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-amber.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-amber.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-amber .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-amber .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-amber.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-amber.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-amber .light .mat-datepicker-content.mat-warn .mat-calendar-body-selected,
.theme-amber.light .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #dc2626;
  color: white;
}
.theme-amber .light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-amber.light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(220, 38, 38, 0.4);
}
.theme-amber .light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected,
.theme-amber.light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-amber .light .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-amber .light .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-amber.light .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-amber.light .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(220, 38, 38, 0.3);
}
@media (hover: hover) {
  .theme-amber .light .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-amber.light .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(220, 38, 38, 0.3);
  }
}
.theme-amber .light .mat-datepicker-content-touch,
.theme-amber.light .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-datepicker-toggle-active,
.theme-amber.light .mat-datepicker-toggle-active {
  color: #f59e0b;
}
.theme-amber .light .mat-datepicker-toggle-active.mat-accent,
.theme-amber.light .mat-datepicker-toggle-active.mat-accent {
  color: #1e293b;
}
.theme-amber .light .mat-datepicker-toggle-active.mat-warn,
.theme-amber.light .mat-datepicker-toggle-active.mat-warn {
  color: #dc2626;
}
.theme-amber .light .mat-date-range-input-inner[disabled],
.theme-amber.light .mat-date-range-input-inner[disabled] {
  color: #94a3b8;
}
.theme-amber .light .mat-divider,
.theme-amber.light .mat-divider {
  border-top-color: #e2e8f0;
}
.theme-amber .light .mat-divider-vertical,
.theme-amber.light .mat-divider-vertical {
  border-right-color: #e2e8f0;
}
.theme-amber .light .mat-expansion-panel,
.theme-amber.light .mat-expansion-panel {
  background: white;
  color: #1e293b;
}
.theme-amber .light .mat-expansion-panel:not([class*=mat-elevation-z]),
.theme-amber.light .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-action-row,
.theme-amber.light .mat-action-row {
  border-top-color: #e2e8f0;
}
.theme-amber .light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .theme-amber .light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .theme-amber .light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]),
.theme-amber.light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
.theme-amber.light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
.theme-amber.light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(148, 163, 184, 0.12);
}
@media (hover: none) {
  .theme-amber .light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover,
  .theme-amber.light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.theme-amber .light .mat-expansion-panel-header-title,
.theme-amber.light .mat-expansion-panel-header-title {
  color: #1e293b;
}
.theme-amber .light .mat-expansion-panel-header-description,
.theme-amber .light .mat-expansion-indicator::after,
.theme-amber.light .mat-expansion-panel-header-description,
.theme-amber.light .mat-expansion-indicator::after {
  color: #64748b;
}
.theme-amber .light .mat-expansion-panel-header[aria-disabled=true],
.theme-amber.light .mat-expansion-panel-header[aria-disabled=true] {
  color: #94a3b8;
}
.theme-amber .light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-amber .light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description,
.theme-amber.light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-amber.light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.theme-amber .light .mat-icon.mat-primary,
.theme-amber.light .mat-icon.mat-primary {
  color: #f59e0b;
}
.theme-amber .light .mat-icon.mat-accent,
.theme-amber.light .mat-icon.mat-accent {
  color: #1e293b;
}
.theme-amber .light .mat-icon.mat-warn,
.theme-amber.light .mat-icon.mat-warn {
  color: #dc2626;
}
.theme-amber .light .mat-drawer-container,
.theme-amber.light .mat-drawer-container {
  background-color: #f1f5f9;
  color: #1e293b;
}
.theme-amber .light .mat-drawer,
.theme-amber.light .mat-drawer {
  background-color: white;
  color: #1e293b;
}
.theme-amber .light .mat-drawer.mat-drawer-push,
.theme-amber.light .mat-drawer.mat-drawer-push {
  background-color: white;
}
.theme-amber .light .mat-drawer:not(.mat-drawer-side),
.theme-amber.light .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-drawer-side,
.theme-amber.light .mat-drawer-side {
  border-right: solid 1px #e2e8f0;
}
.theme-amber .light .mat-drawer-side.mat-drawer-end,
.theme-amber.light .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px #e2e8f0;
  border-right: none;
}
.theme-amber .light [dir=rtl] .mat-drawer-side,
.theme-amber.light [dir=rtl] .mat-drawer-side {
  border-left: solid 1px #e2e8f0;
  border-right: none;
}
.theme-amber .light [dir=rtl] .mat-drawer-side.mat-drawer-end,
.theme-amber.light [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px #e2e8f0;
}
.theme-amber .light .mat-drawer-backdrop.mat-drawer-shown,
.theme-amber.light .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.theme-amber .light .mat-step-header.cdk-keyboard-focused, .theme-amber .light .mat-step-header.cdk-program-focused, .theme-amber .light .mat-step-header:hover:not([aria-disabled]), .theme-amber .light .mat-step-header:hover[aria-disabled=false],
.theme-amber.light .mat-step-header.cdk-keyboard-focused,
.theme-amber.light .mat-step-header.cdk-program-focused,
.theme-amber.light .mat-step-header:hover:not([aria-disabled]),
.theme-amber.light .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(148, 163, 184, 0.12);
}
.theme-amber .light .mat-step-header:hover[aria-disabled=true],
.theme-amber.light .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .theme-amber .light .mat-step-header:hover,
  .theme-amber.light .mat-step-header:hover {
    background: none;
  }
}
.theme-amber .light .mat-step-header .mat-step-label,
.theme-amber .light .mat-step-header .mat-step-optional,
.theme-amber.light .mat-step-header .mat-step-label,
.theme-amber.light .mat-step-header .mat-step-optional {
  color: #64748b;
}
.theme-amber .light .mat-step-header .mat-step-icon,
.theme-amber.light .mat-step-header .mat-step-icon {
  background-color: #64748b;
  color: #78350f;
}
.theme-amber .light .mat-step-header .mat-step-icon-selected,
.theme-amber .light .mat-step-header .mat-step-icon-state-done,
.theme-amber .light .mat-step-header .mat-step-icon-state-edit,
.theme-amber.light .mat-step-header .mat-step-icon-selected,
.theme-amber.light .mat-step-header .mat-step-icon-state-done,
.theme-amber.light .mat-step-header .mat-step-icon-state-edit {
  background-color: #f59e0b;
  color: #78350f;
}
.theme-amber .light .mat-step-header.mat-accent .mat-step-icon,
.theme-amber.light .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.theme-amber .light .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-amber .light .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-amber .light .mat-step-header.mat-accent .mat-step-icon-state-edit,
.theme-amber.light .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-amber.light .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-amber.light .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #1e293b;
  color: white;
}
.theme-amber .light .mat-step-header.mat-warn .mat-step-icon,
.theme-amber.light .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.theme-amber .light .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-amber .light .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-amber .light .mat-step-header.mat-warn .mat-step-icon-state-edit,
.theme-amber.light .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-amber.light .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-amber.light .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #dc2626;
  color: white;
}
.theme-amber .light .mat-step-header .mat-step-icon-state-error,
.theme-amber.light .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #dc2626;
}
.theme-amber .light .mat-step-header .mat-step-label.mat-step-label-active,
.theme-amber.light .mat-step-header .mat-step-label.mat-step-label-active {
  color: #1e293b;
}
.theme-amber .light .mat-step-header .mat-step-label.mat-step-label-error,
.theme-amber.light .mat-step-header .mat-step-label.mat-step-label-error {
  color: #dc2626;
}
.theme-amber .light .mat-stepper-horizontal, .theme-amber .light .mat-stepper-vertical,
.theme-amber.light .mat-stepper-horizontal,
.theme-amber.light .mat-stepper-vertical {
  background-color: white;
}
.theme-amber .light .mat-stepper-vertical-line::before,
.theme-amber.light .mat-stepper-vertical-line::before {
  border-left-color: #e2e8f0;
}
.theme-amber .light .mat-horizontal-stepper-header::before,
.theme-amber .light .mat-horizontal-stepper-header::after,
.theme-amber .light .mat-stepper-horizontal-line,
.theme-amber.light .mat-horizontal-stepper-header::before,
.theme-amber.light .mat-horizontal-stepper-header::after,
.theme-amber.light .mat-stepper-horizontal-line {
  border-top-color: #e2e8f0;
}
.theme-amber .light .mat-sort-header-arrow,
.theme-amber.light .mat-sort-header-arrow {
  color: #64748b;
}
.theme-amber .light .mat-toolbar,
.theme-amber.light .mat-toolbar {
  background: white;
  color: #1e293b;
}
.theme-amber .light .mat-toolbar.mat-primary,
.theme-amber.light .mat-toolbar.mat-primary {
  background: #f59e0b;
  color: #78350f;
}
.theme-amber .light .mat-toolbar.mat-accent,
.theme-amber.light .mat-toolbar.mat-accent {
  background: #1e293b;
  color: white;
}
.theme-amber .light .mat-toolbar.mat-warn,
.theme-amber.light .mat-toolbar.mat-warn {
  background: #dc2626;
  color: white;
}
.theme-amber .light .mat-toolbar .mat-form-field-underline,
.theme-amber .light .mat-toolbar .mat-form-field-ripple,
.theme-amber .light .mat-toolbar .mat-focused .mat-form-field-ripple,
.theme-amber.light .mat-toolbar .mat-form-field-underline,
.theme-amber.light .mat-toolbar .mat-form-field-ripple,
.theme-amber.light .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.theme-amber .light .mat-toolbar .mat-form-field-label,
.theme-amber .light .mat-toolbar .mat-focused .mat-form-field-label,
.theme-amber .light .mat-toolbar .mat-select-value,
.theme-amber .light .mat-toolbar .mat-select-arrow,
.theme-amber .light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow,
.theme-amber.light .mat-toolbar .mat-form-field-label,
.theme-amber.light .mat-toolbar .mat-focused .mat-form-field-label,
.theme-amber.light .mat-toolbar .mat-select-value,
.theme-amber.light .mat-toolbar .mat-select-arrow,
.theme-amber.light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.theme-amber .light .mat-toolbar .mat-input-element,
.theme-amber.light .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.theme-amber .light .mat-tree,
.theme-amber.light .mat-tree {
  background: white;
}
.theme-amber .light .mat-tree-node,
.theme-amber .light .mat-nested-tree-node,
.theme-amber.light .mat-tree-node,
.theme-amber.light .mat-nested-tree-node {
  color: #1e293b;
}

.theme-amber .dark .mat-ripple-element,
.theme-amber.dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .dark .mat-mdc-option,
.theme-amber.dark .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-amber .dark .mat-mdc-option:hover:not(.mdc-list-item--disabled), .theme-amber .dark .mat-mdc-option:focus:not(.mdc-list-item--disabled), .theme-amber .dark .mat-mdc-option.mat-mdc-option-active, .theme-amber .dark .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled),
.theme-amber.dark .mat-mdc-option:hover:not(.mdc-list-item--disabled),
.theme-amber.dark .mat-mdc-option:focus:not(.mdc-list-item--disabled),
.theme-amber.dark .mat-mdc-option.mat-mdc-option-active,
.theme-amber.dark .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(255, 255, 255, 0.08);
}
.theme-amber .dark .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-amber.dark .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #f59e0b);
}
.theme-amber .dark .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-amber.dark .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #1e293b);
}
.theme-amber .dark .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.theme-amber.dark .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mat-mdc-optgroup-label,
.theme-amber.dark .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-amber .dark .mat-pseudo-checkbox,
.theme-amber.dark .mat-pseudo-checkbox {
  color: #94a3b8;
}
.theme-amber .dark .mat-pseudo-checkbox::after,
.theme-amber.dark .mat-pseudo-checkbox::after {
  color: #0f172a;
}
.theme-amber .dark .mat-pseudo-checkbox-disabled,
.theme-amber.dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.theme-amber .dark .mat-primary .mat-pseudo-checkbox-checked,
.theme-amber .dark .mat-primary .mat-pseudo-checkbox-indeterminate,
.theme-amber.dark .mat-primary .mat-pseudo-checkbox-checked,
.theme-amber.dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #f59e0b;
}
.theme-amber .dark .mat-pseudo-checkbox-checked,
.theme-amber .dark .mat-pseudo-checkbox-indeterminate,
.theme-amber .dark .mat-accent .mat-pseudo-checkbox-checked,
.theme-amber .dark .mat-accent .mat-pseudo-checkbox-indeterminate,
.theme-amber.dark .mat-pseudo-checkbox-checked,
.theme-amber.dark .mat-pseudo-checkbox-indeterminate,
.theme-amber.dark .mat-accent .mat-pseudo-checkbox-checked,
.theme-amber.dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #1e293b;
}
.theme-amber .dark .mat-warn .mat-pseudo-checkbox-checked,
.theme-amber .dark .mat-warn .mat-pseudo-checkbox-indeterminate,
.theme-amber.dark .mat-warn .mat-pseudo-checkbox-checked,
.theme-amber.dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #dc2626;
}
.theme-amber .dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-amber .dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled,
.theme-amber.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-amber.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.theme-amber .dark .mat-app-background, .theme-amber .dark.mat-app-background,
.theme-amber.dark .mat-app-background,
.theme-amber.dark.mat-app-background {
  background-color: #0f172a;
  color: white;
}
.theme-amber .dark .mat-elevation-z0, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-amber.dark .mat-elevation-z0,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z1, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-amber.dark .mat-elevation-z1,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z2, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-amber.dark .mat-elevation-z2,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z3, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-amber.dark .mat-elevation-z3,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z4, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-amber.dark .mat-elevation-z4,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z5, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-amber.dark .mat-elevation-z5,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z6, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-amber.dark .mat-elevation-z6,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z7, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-amber.dark .mat-elevation-z7,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z8, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-amber.dark .mat-elevation-z8,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z9, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-amber.dark .mat-elevation-z9,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z10, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-amber.dark .mat-elevation-z10,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z11, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-amber.dark .mat-elevation-z11,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z12, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-amber.dark .mat-elevation-z12,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z13, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-amber.dark .mat-elevation-z13,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z14, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-amber.dark .mat-elevation-z14,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z15, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-amber.dark .mat-elevation-z15,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z16, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-amber.dark .mat-elevation-z16,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z17, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-amber.dark .mat-elevation-z17,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z18, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-amber.dark .mat-elevation-z18,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z19, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-amber.dark .mat-elevation-z19,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z20, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-amber.dark .mat-elevation-z20,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z21, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-amber.dark .mat-elevation-z21,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z22, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-amber.dark .mat-elevation-z22,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z23, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-amber.dark .mat-elevation-z23,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z24, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-amber.dark .mat-elevation-z24,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-amber .dark .mat-mdc-card,
.theme-amber.dark .mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #1e293b;
}
.theme-amber .dark .mat-mdc-card-outlined,
.theme-amber.dark .mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #394353;
}
.theme-amber .dark .mat-mdc-card-subtitle,
.theme-amber.dark .mat-mdc-card-subtitle {
  color: #94a3b8;
}
.theme-amber .dark .mat-mdc-progress-bar,
.theme-amber.dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #f59e0b;
}
.theme-amber .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.theme-amber.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(245, 158, 11, 0.25)'/%3E%3C/svg%3E");
}
.theme-amber .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.theme-amber.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(245, 158, 11, 0.25);
}
.theme-amber .dark .mat-mdc-progress-bar.mat-accent,
.theme-amber.dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
}
.theme-amber .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.theme-amber.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(30, 41, 59, 0.25)'/%3E%3C/svg%3E");
}
.theme-amber .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.theme-amber.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(30, 41, 59, 0.25);
}
.theme-amber .dark .mat-mdc-progress-bar.mat-warn,
.theme-amber.dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
}
.theme-amber .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.theme-amber.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(220, 38, 38, 0.25)'/%3E%3C/svg%3E");
}
.theme-amber .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.theme-amber.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(220, 38, 38, 0.25);
}
.theme-amber .dark .mat-mdc-tooltip,
.theme-amber.dark .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.theme-amber .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-amber.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 255, 255, 0.6);
}
.theme-amber .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input,
.theme-amber.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.87);
}
@media all {
  .theme-amber .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder,
  .theme-amber.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}
@media all {
  .theme-amber .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder,
  .theme-amber.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}
.theme-amber .dark .mdc-text-field .mdc-text-field__input,
.theme-amber.dark .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #f59e0b);
}
.theme-amber .dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text,
.theme-amber.dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.theme-amber .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.theme-amber .dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter,
.theme-amber.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.theme-amber.dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.theme-amber .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading,
.theme-amber.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.theme-amber .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-amber.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.theme-amber .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix,
.theme-amber.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-amber .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix,
.theme-amber.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-amber .dark .mdc-text-field--filled .mdc-text-field__ripple::before,
.theme-amber .dark .mdc-text-field--filled .mdc-text-field__ripple::after,
.theme-amber.dark .mdc-text-field--filled .mdc-text-field__ripple::before,
.theme-amber.dark .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(255, 255, 255, 0.87));
}
.theme-amber .dark .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .theme-amber .dark .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before,
.theme-amber.dark .mdc-text-field--filled:hover .mdc-text-field__ripple::before,
.theme-amber.dark .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.theme-amber .dark .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .theme-amber .dark .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before,
.theme-amber.dark .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before,
.theme-amber.dark .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.theme-amber .dark .mdc-text-field--filled:not(.mdc-text-field--disabled),
.theme-amber.dark .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #273243;
}
.theme-amber .dark .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-amber.dark .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.42);
}
.theme-amber .dark .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-amber.dark .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.87);
}
.theme-amber .dark .mdc-text-field--filled .mdc-line-ripple::after,
.theme-amber.dark .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #f59e0b);
}
.theme-amber .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-amber .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-amber .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-amber.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-amber.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-amber.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.38);
}
.theme-amber .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-amber .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-amber .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-amber.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-amber.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-amber.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.87);
}
.theme-amber .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-amber .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-amber .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-amber.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-amber.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-amber.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #f59e0b);
}
[dir=rtl] .theme-amber .dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon, [dir=rtl] .theme-amber.dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .theme-amber .dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl], .theme-amber.dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-amber .dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, [dir=rtl] .theme-amber.dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .theme-amber .dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl], .theme-amber.dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-amber .dark .mdc-text-field--outlined .mdc-text-field__ripple::before,
.theme-amber .dark .mdc-text-field--outlined .mdc-text-field__ripple::after,
.theme-amber.dark .mdc-text-field--outlined .mdc-text-field__ripple::before,
.theme-amber.dark .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .theme-amber .dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined, [dir=rtl] .theme-amber.dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .theme-amber .dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl], .theme-amber.dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-amber .dark .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-amber.dark .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(245, 158, 11, 0.87);
}
.theme-amber .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-amber.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-amber.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-amber.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-amber.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mdc-text-field--invalid .mdc-text-field__input,
.theme-amber.dark .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-amber.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-amber.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-amber .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-amber .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-amber.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-amber.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-amber.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-amber .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-amber .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-amber.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-amber.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-amber.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-amber .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-amber .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-amber.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-amber.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-amber.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mdc-text-field--disabled .mdc-text-field__input,
.theme-amber.dark .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.38);
}
@media all {
  .theme-amber .dark .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  .theme-amber.dark .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.38);
  }
}
@media all {
  .theme-amber .dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  .theme-amber.dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.38);
  }
}
.theme-amber .dark .mdc-text-field--disabled .mdc-floating-label,
.theme-amber.dark .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(255, 255, 255, 0.38);
}
.theme-amber .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
.theme-amber.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.theme-amber .dark .mdc-text-field--disabled .mdc-text-field-character-counter,
.theme-amber .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
.theme-amber.dark .mdc-text-field--disabled .mdc-text-field-character-counter,
.theme-amber.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.theme-amber .dark .mdc-text-field--disabled .mdc-text-field__icon--leading,
.theme-amber.dark .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.theme-amber .dark .mdc-text-field--disabled .mdc-text-field__icon--trailing,
.theme-amber.dark .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.theme-amber .dark .mdc-text-field--disabled .mdc-text-field__affix--prefix,
.theme-amber.dark .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-amber .dark .mdc-text-field--disabled .mdc-text-field__affix--suffix,
.theme-amber.dark .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-amber .dark .mdc-text-field--disabled .mdc-line-ripple::before,
.theme-amber.dark .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.06);
}
.theme-amber .dark .mdc-text-field--disabled .mdc-notched-outline__leading,
.theme-amber .dark .mdc-text-field--disabled .mdc-notched-outline__notch,
.theme-amber .dark .mdc-text-field--disabled .mdc-notched-outline__trailing,
.theme-amber.dark .mdc-text-field--disabled .mdc-notched-outline__leading,
.theme-amber.dark .mdc-text-field--disabled .mdc-notched-outline__notch,
.theme-amber.dark .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-amber .dark .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  .theme-amber.dark .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-amber .dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  .theme-amber.dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-amber .dark .mdc-text-field--disabled .mdc-floating-label,
  .theme-amber.dark .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-amber .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
  .theme-amber.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-amber .dark .mdc-text-field--disabled .mdc-text-field-character-counter,
  .theme-amber .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
  .theme-amber.dark .mdc-text-field--disabled .mdc-text-field-character-counter,
  .theme-amber.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-amber .dark .mdc-text-field--disabled .mdc-text-field__icon--leading,
  .theme-amber.dark .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-amber .dark .mdc-text-field--disabled .mdc-text-field__icon--trailing,
  .theme-amber.dark .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-amber .dark .mdc-text-field--disabled .mdc-text-field__affix--prefix,
  .theme-amber.dark .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-amber .dark .mdc-text-field--disabled .mdc-text-field__affix--suffix,
  .theme-amber.dark .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-amber .dark .mdc-text-field--disabled .mdc-line-ripple::before,
  .theme-amber.dark .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-amber .dark .mdc-text-field--disabled .mdc-notched-outline__leading,
  .theme-amber .dark .mdc-text-field--disabled .mdc-notched-outline__notch,
  .theme-amber .dark .mdc-text-field--disabled .mdc-notched-outline__trailing,
  .theme-amber.dark .mdc-text-field--disabled .mdc-notched-outline__leading,
  .theme-amber.dark .mdc-text-field--disabled .mdc-notched-outline__notch,
  .theme-amber.dark .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.theme-amber .dark .mdc-text-field--disabled.mdc-text-field--filled,
.theme-amber.dark .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #232d3f;
}
[dir=rtl] .theme-amber .dark .mdc-text-field--end-aligned .mdc-text-field__input, [dir=rtl] .theme-amber.dark .mdc-text-field--end-aligned .mdc-text-field__input, .theme-amber .dark .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl], .theme-amber.dark .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-amber .dark .mdc-floating-label--required, [dir=rtl] .theme-amber.dark .mdc-floating-label--required, .theme-amber .dark .mdc-floating-label--required[dir=rtl], .theme-amber.dark .mdc-floating-label--required[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-amber .dark .mat-mdc-form-field-error,
.theme-amber.dark .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mat-mdc-form-field-focus-overlay,
.theme-amber.dark .mat-mdc-form-field-focus-overlay {
  background-color: rgba(255, 255, 255, 0.87);
}
.theme-amber .dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.theme-amber.dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.08;
}
.theme-amber .dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.theme-amber.dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.24;
}
.theme-amber .dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option,
.theme-amber.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.theme-amber .dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled,
.theme-amber.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.theme-amber .dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.theme-amber.dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.54);
}
.theme-amber .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.theme-amber.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(245, 158, 11, 0.87);
}
.theme-amber .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.theme-amber.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(30, 41, 59, 0.87);
}
.theme-amber .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.theme-amber.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(220, 38, 38, 0.87);
}
.theme-amber .dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.theme-amber.dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.38);
}
.theme-amber .dark .mat-mdc-form-field.mat-accent .mdc-text-field__input,
.theme-amber.dark .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-amber .dark .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-amber.dark .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-amber .dark .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-amber.dark .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(30, 41, 59, 0.87);
}
.theme-amber .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-amber.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-amber.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-amber.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-amber.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input,
.theme-amber.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-amber.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-amber.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-amber .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-amber .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-amber.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-amber.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-amber.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-amber .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-amber .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-amber.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-amber.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-amber.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-amber .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-amber .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-amber.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-amber.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-amber.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-amber .dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-amber .dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-amber.dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-amber.dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-amber.dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #1e293b);
}
.theme-amber .dark .mat-mdc-form-field.mat-warn .mdc-text-field__input,
.theme-amber.dark .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-amber.dark .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-amber.dark .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(220, 38, 38, 0.87);
}
.theme-amber .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
.theme-amber.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.theme-amber.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.theme-amber.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
.theme-amber.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input,
.theme-amber.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
.theme-amber.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.theme-amber.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-amber .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-amber .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.theme-amber.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-amber.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-amber.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-amber .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-amber .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
.theme-amber.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-amber.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-amber.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-amber .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-amber .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-amber.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-amber.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-amber.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-amber .dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-amber .dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.theme-amber.dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-amber.dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-amber.dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #dc2626);
}
.theme-amber .dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.theme-amber.dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.theme-amber .dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.theme-amber.dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.theme-amber .dark .mdc-menu-surface,
.theme-amber.dark .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #1e293b);
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mdc-list-item__primary-text,
.theme-amber.dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-amber .dark .mdc-list-item__secondary-text,
.theme-amber.dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.theme-amber .dark .mdc-list-item__overline-text,
.theme-amber.dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-amber .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-amber.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-amber .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-amber.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.theme-amber .dark .mdc-list-item__end,
.theme-amber.dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-amber .dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-amber .dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-amber .dark .mdc-list-item--disabled .mdc-list-item__end,
.theme-amber.dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-amber.dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-amber.dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-amber .dark .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-amber.dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-amber.dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-amber.dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-amber.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-amber.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-amber .dark .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-amber.dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-amber.dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #f59e0b);
}
.theme-amber .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #f59e0b);
}
.theme-amber .dark .mdc-deprecated-list-group__subheader,
.theme-amber.dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-amber .dark .mdc-list-divider::after,
.theme-amber.dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-amber .dark .mdc-list-divider,
.theme-amber.dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-amber .dark .mat-mdc-select-value,
.theme-amber.dark .mat-mdc-select-value {
  color: rgba(255, 255, 255, 0.87);
}
.theme-amber .dark .mat-mdc-select-placeholder,
.theme-amber.dark .mat-mdc-select-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.theme-amber .dark .mat-mdc-select-disabled .mat-mdc-select-value,
.theme-amber.dark .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(255, 255, 255, 0.38);
}
.theme-amber .dark .mat-mdc-select-arrow,
.theme-amber.dark .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.54);
}
.theme-amber .dark .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow,
.theme-amber.dark .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(245, 158, 11, 0.87);
}
.theme-amber .dark .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow,
.theme-amber.dark .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(30, 41, 59, 0.87);
}
.theme-amber .dark .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow,
.theme-amber.dark .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(220, 38, 38, 0.87);
}
.theme-amber .dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow,
.theme-amber.dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(220, 38, 38, 0.87);
}
.theme-amber .dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow,
.theme-amber.dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.38);
}
.theme-amber .dark .mdc-menu-surface,
.theme-amber.dark .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #1e293b);
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mdc-list-item__primary-text,
.theme-amber.dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-amber .dark .mdc-list-item__secondary-text,
.theme-amber.dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.theme-amber .dark .mdc-list-item__overline-text,
.theme-amber.dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-amber .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-amber.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-amber .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-amber.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.theme-amber .dark .mdc-list-item__end,
.theme-amber.dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-amber .dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-amber .dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-amber .dark .mdc-list-item--disabled .mdc-list-item__end,
.theme-amber.dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-amber.dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-amber.dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-amber .dark .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-amber.dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-amber.dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-amber.dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-amber.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-amber.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-amber .dark .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-amber.dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-amber.dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #f59e0b);
}
.theme-amber .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #f59e0b);
}
.theme-amber .dark .mdc-deprecated-list-group__subheader,
.theme-amber.dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-amber .dark .mdc-list-divider::after,
.theme-amber.dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-amber .dark .mdc-list-divider,
.theme-amber.dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-amber .dark .mat-mdc-dialog-container,
.theme-amber.dark .mat-mdc-dialog-container {
  --mdc-dialog-container-color: #1e293b;
  --mdc-dialog-with-divider-divider-color: rgba(255, 255, 255, 0.12);
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
}
.theme-amber .dark .mat-mdc-standard-chip,
.theme-amber.dark .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #394353;
  --mdc-chip-elevated-disabled-container-color: #394353;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
}
.theme-amber .dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .theme-amber .dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted,
.theme-amber.dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected,
.theme-amber.dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #f59e0b;
  --mdc-chip-elevated-disabled-container-color: #f59e0b;
  --mdc-chip-label-text-color: #78350f;
  --mdc-chip-disabled-label-text-color: #78350f;
  --mdc-chip-with-icon-icon-color: #78350f;
  --mdc-chip-with-icon-disabled-icon-color: #78350f;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #78350f;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #78350f;
  --mdc-chip-with-icon-selected-icon-color: #78350f;
}
.theme-amber .dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .theme-amber .dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted,
.theme-amber.dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected,
.theme-amber.dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-amber .dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .theme-amber .dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted,
.theme-amber.dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected,
.theme-amber.dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-amber .dark .mat-mdc-chip-focus-overlay,
.theme-amber.dark .mat-mdc-chip-focus-overlay {
  background: #FFFFFF;
}
.theme-amber .dark .mat-mdc-slide-toggle,
.theme-amber.dark .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-unselected-icon-color: #212121;
}
.theme-amber .dark .mat-mdc-slide-toggle .mdc-form-field,
.theme-amber.dark .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-amber .dark .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.theme-amber.dark .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: #475569;
}
.theme-amber .dark .mat-mdc-slide-toggle.mat-primary,
.theme-amber.dark .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #fcd34d;
  --mdc-switch-selected-handle-color: #fcd34d;
  --mdc-switch-selected-hover-state-layer-color: #fcd34d;
  --mdc-switch-selected-pressed-state-layer-color: #fcd34d;
  --mdc-switch-selected-focus-handle-color: #fde68a;
  --mdc-switch-selected-hover-handle-color: #fde68a;
  --mdc-switch-selected-pressed-handle-color: #fde68a;
  --mdc-switch-selected-focus-track-color: #d97706;
  --mdc-switch-selected-hover-track-color: #d97706;
  --mdc-switch-selected-pressed-track-color: #d97706;
  --mdc-switch-selected-track-color: #d97706;
}
.theme-amber .dark .mat-mdc-slide-toggle.mat-accent,
.theme-amber.dark .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #cbd5e1;
  --mdc-switch-selected-handle-color: #cbd5e1;
  --mdc-switch-selected-hover-state-layer-color: #cbd5e1;
  --mdc-switch-selected-pressed-state-layer-color: #cbd5e1;
  --mdc-switch-selected-focus-handle-color: #e2e8f0;
  --mdc-switch-selected-hover-handle-color: #e2e8f0;
  --mdc-switch-selected-pressed-handle-color: #e2e8f0;
  --mdc-switch-selected-focus-track-color: #475569;
  --mdc-switch-selected-hover-track-color: #475569;
  --mdc-switch-selected-pressed-track-color: #475569;
  --mdc-switch-selected-track-color: #475569;
}
.theme-amber .dark .mat-mdc-slide-toggle.mat-warn,
.theme-amber.dark .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #fca5a5;
  --mdc-switch-selected-handle-color: #fca5a5;
  --mdc-switch-selected-hover-state-layer-color: #fca5a5;
  --mdc-switch-selected-pressed-state-layer-color: #fca5a5;
  --mdc-switch-selected-focus-handle-color: #fecaca;
  --mdc-switch-selected-hover-handle-color: #fecaca;
  --mdc-switch-selected-pressed-handle-color: #fecaca;
  --mdc-switch-selected-focus-track-color: #dc2626;
  --mdc-switch-selected-hover-track-color: #dc2626;
  --mdc-switch-selected-pressed-track-color: #dc2626;
  --mdc-switch-selected-track-color: #dc2626;
}
.theme-amber .dark .mat-mdc-radio-button,
.theme-amber.dark .mat-mdc-radio-button {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mat-mdc-radio-ripple-color: #fff;
}
.theme-amber .dark .mat-mdc-radio-button .mdc-form-field,
.theme-amber.dark .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-amber .dark .mat-mdc-radio-button .mdc-radio--disabled + label,
.theme-amber.dark .mat-mdc-radio-button .mdc-radio--disabled + label {
  color: #475569;
}
.theme-amber .dark .mat-mdc-radio-button.mat-primary,
.theme-amber.dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
  --mat-mdc-radio-checked-ripple-color: #f59e0b;
}
.theme-amber .dark .mat-mdc-radio-button.mat-accent,
.theme-amber.dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-mdc-radio-checked-ripple-color: #1e293b;
}
.theme-amber .dark .mat-mdc-radio-button.mat-warn,
.theme-amber.dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-mdc-radio-checked-ripple-color: #dc2626;
}
.theme-amber .dark .mat-mdc-slider,
.theme-amber.dark .mat-mdc-slider {
  --mdc-slider-label-container-color: white;
  --mdc-slider-label-label-text-color: black;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mat-mdc-slider-value-indicator-opacity: 0.9;
}
.theme-amber .dark .mat-mdc-slider.mat-primary,
.theme-amber.dark .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #f59e0b;
  --mdc-slider-focus-handle-color: #f59e0b;
  --mdc-slider-hover-handle-color: #f59e0b;
  --mdc-slider-active-track-color: #f59e0b;
  --mdc-slider-inactive-track-color: #f59e0b;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #f59e0b;
  --mat-mdc-slider-ripple-color: #f59e0b;
  --mat-mdc-slider-hover-ripple-color: rgba(245, 158, 11, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(245, 158, 11, 0.2);
}
.theme-amber .dark .mat-mdc-slider.mat-accent,
.theme-amber.dark .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mat-mdc-slider-ripple-color: #1e293b;
  --mat-mdc-slider-hover-ripple-color: rgba(30, 41, 59, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(30, 41, 59, 0.2);
}
.theme-amber .dark .mat-mdc-slider.mat-warn,
.theme-amber.dark .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mat-mdc-slider-ripple-color: #dc2626;
  --mat-mdc-slider-hover-ripple-color: rgba(220, 38, 38, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(220, 38, 38, 0.2);
}
.theme-amber .dark .mdc-menu-surface,
.theme-amber.dark .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #1e293b);
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mdc-list-item__primary-text,
.theme-amber.dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-amber .dark .mdc-list-item__secondary-text,
.theme-amber.dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.theme-amber .dark .mdc-list-item__overline-text,
.theme-amber.dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-amber .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-amber.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-amber .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-amber.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.theme-amber .dark .mdc-list-item__end,
.theme-amber.dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-amber .dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-amber .dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-amber .dark .mdc-list-item--disabled .mdc-list-item__end,
.theme-amber.dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-amber.dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-amber.dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-amber .dark .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-amber.dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-amber.dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-amber.dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-amber.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-amber.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-amber .dark .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-amber.dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-amber.dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #f59e0b);
}
.theme-amber .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #f59e0b);
}
.theme-amber .dark .mdc-deprecated-list-group__subheader,
.theme-amber.dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-amber .dark .mdc-list-divider::after,
.theme-amber.dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-amber .dark .mdc-list-divider,
.theme-amber.dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-amber .dark .mat-mdc-menu-item[disabled],
.theme-amber .dark .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.theme-amber .dark .mat-mdc-menu-item[disabled] .mat-icon-no-color,
.theme-amber.dark .mat-mdc-menu-item[disabled],
.theme-amber.dark .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.theme-amber.dark .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(255, 255, 255, 0.5));
}
.theme-amber .dark .mat-mdc-menu-item .mat-icon-no-color,
.theme-amber .dark .mat-mdc-menu-submenu-icon,
.theme-amber.dark .mat-mdc-menu-item .mat-icon-no-color,
.theme-amber.dark .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-amber .dark .mat-mdc-menu-item:hover:not([disabled]),
.theme-amber .dark .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.theme-amber .dark .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-amber .dark .mat-mdc-menu-item-highlighted:not([disabled]),
.theme-amber.dark .mat-mdc-menu-item:hover:not([disabled]),
.theme-amber.dark .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.theme-amber.dark .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-amber.dark .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.08);
}
.theme-amber .dark .mat-mdc-list-item-interactive::before,
.theme-amber.dark .mat-mdc-list-item-interactive::before {
  background: white;
}
.theme-amber .dark .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before,
.theme-amber.dark .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.08;
}
.theme-amber .dark .mat-mdc-list-item-interactive.mdc-list-item--selected::before,
.theme-amber.dark .mat-mdc-list-item-interactive.mdc-list-item--selected::before {
  background: #f59e0b;
  opacity: 0.16;
}
.theme-amber .dark .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before,
.theme-amber.dark .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.24;
}
.theme-amber .dark .mat-mdc-list-item-interactive:focus::before,
.theme-amber.dark .mat-mdc-list-item-interactive:focus::before {
  opacity: 0.24;
}
.theme-amber .dark .mdc-list-item__primary-text,
.theme-amber.dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-amber .dark .mdc-list-item__secondary-text,
.theme-amber.dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.theme-amber .dark .mdc-list-item__overline-text,
.theme-amber.dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-amber .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-amber.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-amber .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-amber.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.theme-amber .dark .mdc-list-item__end,
.theme-amber.dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.theme-amber .dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-amber .dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-amber .dark .mdc-list-item--disabled .mdc-list-item__end,
.theme-amber.dark .mdc-list-item--disabled .mdc-list-item__start,
.theme-amber.dark .mdc-list-item--disabled .mdc-list-item__content,
.theme-amber.dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-amber .dark .mdc-list-item--disabled .mdc-list-item__primary-text,
.theme-amber.dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
.theme-amber.dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mdc-list-item--disabled .mdc-list-item__overline-text,
.theme-amber.dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.theme-amber.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
.theme-amber.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-amber .dark .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-amber.dark .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-amber.dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #f59e0b);
}
.theme-amber .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #f59e0b);
}
.theme-amber .dark .mdc-deprecated-list-group__subheader,
.theme-amber.dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-amber .dark .mdc-list-divider::after,
.theme-amber.dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-amber .dark .mdc-list-divider,
.theme-amber.dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-amber .dark .mat-mdc-list-option .mdc-list-item__start, .theme-amber .dark .mat-mdc-list-option .mdc-list-item__end,
.theme-amber.dark .mat-mdc-list-option .mdc-list-item__start,
.theme-amber.dark .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-amber .dark .mat-mdc-list-option.mat-accent .mdc-list-item__start, .theme-amber .dark .mat-mdc-list-option.mat-accent .mdc-list-item__end,
.theme-amber.dark .mat-mdc-list-option.mat-accent .mdc-list-item__start,
.theme-amber.dark .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-amber .dark .mat-mdc-list-option.mat-warn .mdc-list-item__start, .theme-amber .dark .mat-mdc-list-option.mat-warn .mdc-list-item__end,
.theme-amber.dark .mat-mdc-list-option.mat-warn .mdc-list-item__start,
.theme-amber.dark .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-amber .dark .mat-mdc-paginator,
.theme-amber.dark .mat-mdc-paginator {
  background: #1e293b;
  color: rgba(255, 255, 255, 0.87);
}
.theme-amber .dark .mat-mdc-paginator-icon,
.theme-amber.dark .mat-mdc-paginator-icon {
  fill: rgba(255, 255, 255, 0.54);
}
.theme-amber .dark .mat-mdc-paginator-decrement,
.theme-amber .dark .mat-mdc-paginator-increment,
.theme-amber.dark .mat-mdc-paginator-decrement,
.theme-amber.dark .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(255, 255, 255, 0.54);
  border-right: 2px solid rgba(255, 255, 255, 0.54);
}
.theme-amber .dark .mat-mdc-paginator-first,
.theme-amber .dark .mat-mdc-paginator-last,
.theme-amber.dark .mat-mdc-paginator-first,
.theme-amber.dark .mat-mdc-paginator-last {
  border-top: 2px solid rgba(255, 255, 255, 0.54);
}
.theme-amber .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.theme-amber .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.theme-amber .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.theme-amber .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last,
.theme-amber.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.theme-amber.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.theme-amber.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.theme-amber.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-amber .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon,
.theme-amber.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(255, 255, 255, 0.12);
}
.theme-amber .dark .mat-mdc-tab, .theme-amber .dark .mat-mdc-tab-link,
.theme-amber.dark .mat-mdc-tab,
.theme-amber.dark .mat-mdc-tab-link {
  background-color: transparent;
}
.theme-amber .dark .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label, .theme-amber .dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.theme-amber.dark .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.theme-amber.dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  color: rgba(255, 255, 255, 0.6);
}
.theme-amber .dark .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-amber .dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-amber.dark .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-amber.dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #f59e0b;
}
.theme-amber .dark .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-amber .dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-amber.dark .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-amber.dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #f59e0b;
}
.theme-amber .dark .mdc-tab__ripple::before,
.theme-amber .dark .mat-mdc-tab .mat-ripple-element,
.theme-amber .dark .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-amber .dark .mat-mdc-tab-link .mat-ripple-element,
.theme-amber.dark .mdc-tab__ripple::before,
.theme-amber.dark .mat-mdc-tab .mat-ripple-element,
.theme-amber.dark .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-amber.dark .mat-mdc-tab-link .mat-ripple-element {
  background-color: #f59e0b;
}
.theme-amber .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-amber .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-amber .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-amber .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-amber.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-amber.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-amber.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-amber.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #1e293b;
}
.theme-amber .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-amber .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-amber .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-amber .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-amber.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-amber.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-amber.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-amber.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #1e293b;
}
.theme-amber .dark .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.theme-amber .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-amber .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-amber .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .theme-amber .dark .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.theme-amber .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-amber .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-amber .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element,
.theme-amber.dark .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.theme-amber.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-amber.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-amber.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element,
.theme-amber.dark .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.theme-amber.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-amber.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-amber.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #1e293b;
}
.theme-amber .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-amber .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-amber .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-amber .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-amber.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-amber.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-amber.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.theme-amber.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #dc2626;
}
.theme-amber .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-amber .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-amber .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-amber .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-amber.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-amber.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-amber.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.theme-amber.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #dc2626;
}
.theme-amber .dark .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.theme-amber .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-amber .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-amber .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .theme-amber .dark .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.theme-amber .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-amber .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-amber .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element,
.theme-amber.dark .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.theme-amber.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-amber.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-amber.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element,
.theme-amber.dark .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.theme-amber.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-amber.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-amber.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #dc2626;
}
.theme-amber .dark .mat-mdc-tab-group.mat-background-primary, .theme-amber .dark .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-amber.dark .mat-mdc-tab-group.mat-background-primary,
.theme-amber.dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #f59e0b;
  --mat-mdc-tab-header-with-background-foreground-color: #000;
}
.theme-amber .dark .mat-mdc-tab-group.mat-background-accent, .theme-amber .dark .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-amber.dark .mat-mdc-tab-group.mat-background-accent,
.theme-amber.dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #1e293b;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-amber .dark .mat-mdc-tab-group.mat-background-warn, .theme-amber .dark .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-amber.dark .mat-mdc-tab-group.mat-background-warn,
.theme-amber.dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #dc2626;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-amber .dark .mat-mdc-tab-header-pagination-chevron,
.theme-amber.dark .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #fff);
}
.theme-amber .dark .mat-mdc-checkbox .mdc-form-field,
.theme-amber.dark .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-amber .dark .mat-mdc-checkbox .mat-ripple-element,
.theme-amber.dark .mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .dark .mat-mdc-checkbox .mdc-checkbox__ripple,
.theme-amber.dark .mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #fff;
}
.theme-amber .dark .mat-mdc-checkbox.mat-primary,
.theme-amber.dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-amber .dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-amber.dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(245, 158, 11, 0.1);
}
.theme-amber .dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-amber.dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #f59e0b;
}
.theme-amber .dark .mat-mdc-checkbox.mat-accent,
.theme-amber.dark .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-amber .dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-amber.dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(30, 41, 59, 0.1);
}
.theme-amber .dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-amber.dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #1e293b;
}
.theme-amber .dark .mat-mdc-checkbox.mat-warn,
.theme-amber.dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.theme-amber .dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
.theme-amber.dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(220, 38, 38, 0.1);
}
.theme-amber .dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
.theme-amber.dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #dc2626;
}
.theme-amber .dark .mat-mdc-checkbox-disabled label,
.theme-amber.dark .mat-mdc-checkbox-disabled label {
  color: #475569;
}
.theme-amber .dark .mat-mdc-button.mat-unthemed,
.theme-amber.dark .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #fff;
}
.theme-amber .dark .mat-mdc-button.mat-primary,
.theme-amber.dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #f59e0b;
}
.theme-amber .dark .mat-mdc-button.mat-accent,
.theme-amber.dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
}
.theme-amber .dark .mat-mdc-button.mat-warn,
.theme-amber.dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
}
.theme-amber .dark .mat-mdc-button[disabled][disabled],
.theme-amber.dark .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-text-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.theme-amber .dark .mat-mdc-unelevated-button.mat-unthemed,
.theme-amber.dark .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-amber .dark .mat-mdc-unelevated-button.mat-primary,
.theme-amber.dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #f59e0b;
  --mdc-filled-button-label-text-color: #000;
}
.theme-amber .dark .mat-mdc-unelevated-button.mat-accent,
.theme-amber.dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-amber .dark .mat-mdc-unelevated-button.mat-warn,
.theme-amber.dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-amber .dark .mat-mdc-unelevated-button[disabled][disabled],
.theme-amber.dark .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-filled-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.theme-amber .dark .mat-mdc-raised-button.mat-unthemed,
.theme-amber.dark .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-amber .dark .mat-mdc-raised-button.mat-primary,
.theme-amber.dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #f59e0b;
  --mdc-protected-button-label-text-color: #000;
}
.theme-amber .dark .mat-mdc-raised-button.mat-accent,
.theme-amber.dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-amber .dark .mat-mdc-raised-button.mat-warn,
.theme-amber.dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-amber .dark .mat-mdc-raised-button[disabled][disabled],
.theme-amber.dark .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-elevation: 0;
}
.theme-amber .dark .mat-mdc-outlined-button,
.theme-amber.dark .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.theme-amber .dark .mat-mdc-outlined-button.mat-unthemed,
.theme-amber.dark .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #fff;
}
.theme-amber .dark .mat-mdc-outlined-button.mat-primary,
.theme-amber.dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #f59e0b;
}
.theme-amber .dark .mat-mdc-outlined-button.mat-accent,
.theme-amber.dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
}
.theme-amber .dark .mat-mdc-outlined-button.mat-warn,
.theme-amber.dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
}
.theme-amber .dark .mat-mdc-outlined-button[disabled][disabled],
.theme-amber.dark .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
}
.theme-amber .dark .mat-mdc-button, .theme-amber .dark .mat-mdc-outlined-button,
.theme-amber.dark .mat-mdc-button,
.theme-amber.dark .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .theme-amber .dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-amber .dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-amber .dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-amber .dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-amber .dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-amber .dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .theme-amber .dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-amber .dark .mat-mdc-button.mat-primary, .theme-amber .dark .mat-mdc-outlined-button.mat-primary,
.theme-amber.dark .mat-mdc-button.mat-primary,
.theme-amber.dark .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.theme-amber .dark .mat-mdc-button.mat-accent, .theme-amber .dark .mat-mdc-outlined-button.mat-accent,
.theme-amber.dark .mat-mdc-button.mat-accent,
.theme-amber.dark .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #1e293b;
  --mat-mdc-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-amber .dark .mat-mdc-button.mat-warn, .theme-amber .dark .mat-mdc-outlined-button.mat-warn,
.theme-amber.dark .mat-mdc-button.mat-warn,
.theme-amber.dark .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #dc2626;
  --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-amber .dark .mat-mdc-raised-button, .theme-amber .dark .mat-mdc-unelevated-button,
.theme-amber.dark .mat-mdc-raised-button,
.theme-amber.dark .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .theme-amber .dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-amber .dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-amber .dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-amber .dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-amber .dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-amber .dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .theme-amber .dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-amber .dark .mat-mdc-raised-button.mat-primary, .theme-amber .dark .mat-mdc-unelevated-button.mat-primary,
.theme-amber.dark .mat-mdc-raised-button.mat-primary,
.theme-amber.dark .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-amber .dark .mat-mdc-raised-button.mat-accent, .theme-amber .dark .mat-mdc-unelevated-button.mat-accent,
.theme-amber.dark .mat-mdc-raised-button.mat-accent,
.theme-amber.dark .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .dark .mat-mdc-raised-button.mat-warn, .theme-amber .dark .mat-mdc-unelevated-button.mat-warn,
.theme-amber.dark .mat-mdc-raised-button.mat-warn,
.theme-amber.dark .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .dark .mat-mdc-icon-button,
.theme-amber.dark .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-amber .dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-amber .dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-amber .dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-amber .dark .mat-mdc-icon-button.mat-primary,
.theme-amber.dark .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.theme-amber .dark .mat-mdc-icon-button.mat-accent,
.theme-amber.dark .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #1e293b;
  --mat-mdc-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-amber .dark .mat-mdc-icon-button.mat-warn,
.theme-amber.dark .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #dc2626;
  --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-amber .dark .mat-mdc-icon-button.mat-primary,
.theme-amber.dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #f59e0b;
}
.theme-amber .dark .mat-mdc-icon-button.mat-accent,
.theme-amber.dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
}
.theme-amber .dark .mat-mdc-icon-button.mat-warn,
.theme-amber.dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
}
.theme-amber .dark .mat-mdc-icon-button[disabled][disabled],
.theme-amber.dark .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
}
.theme-amber .dark .mat-mdc-fab, .theme-amber .dark .mat-mdc-mini-fab,
.theme-amber.dark .mat-mdc-fab,
.theme-amber.dark .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .theme-amber .dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-amber .dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-amber .dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-amber .dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-amber .dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-amber .dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .theme-amber .dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.theme-amber.dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-amber .dark .mat-mdc-fab.mat-primary, .theme-amber .dark .mat-mdc-mini-fab.mat-primary,
.theme-amber.dark .mat-mdc-fab.mat-primary,
.theme-amber.dark .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-amber .dark .mat-mdc-fab.mat-accent, .theme-amber .dark .mat-mdc-mini-fab.mat-accent,
.theme-amber.dark .mat-mdc-fab.mat-accent,
.theme-amber.dark .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .dark .mat-mdc-fab.mat-warn, .theme-amber .dark .mat-mdc-mini-fab.mat-warn,
.theme-amber.dark .mat-mdc-fab.mat-warn,
.theme-amber.dark .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .dark .mat-mdc-fab.mat-unthemed, .theme-amber .dark .mat-mdc-mini-fab.mat-unthemed,
.theme-amber.dark .mat-mdc-fab.mat-unthemed,
.theme-amber.dark .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-amber .dark .mat-mdc-fab.mat-primary, .theme-amber .dark .mat-mdc-mini-fab.mat-primary,
.theme-amber.dark .mat-mdc-fab.mat-primary,
.theme-amber.dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #f59e0b;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.theme-amber .dark .mat-mdc-fab.mat-accent, .theme-amber .dark .mat-mdc-mini-fab.mat-accent,
.theme-amber.dark .mat-mdc-fab.mat-accent,
.theme-amber.dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-amber .dark .mat-mdc-fab.mat-warn, .theme-amber .dark .mat-mdc-mini-fab.mat-warn,
.theme-amber.dark .mat-mdc-fab.mat-warn,
.theme-amber.dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-amber .dark .mat-mdc-fab[disabled][disabled], .theme-amber .dark .mat-mdc-mini-fab[disabled][disabled],
.theme-amber.dark .mat-mdc-fab[disabled][disabled],
.theme-amber.dark .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(255, 255, 255, 0.12);
  --mdc-fab-icon-color: rgba(255, 255, 255, 0.5);
  --mat-mdc-fab-color: rgba(255, 255, 255, 0.5);
}
.theme-amber .dark .mat-mdc-snack-bar-container,
.theme-amber.dark .mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: currentColor;
  --mdc-snackbar-container-color: #d2d4d8;
  --mdc-snackbar-supporting-text-color: rgba(30, 41, 59, 0.87);
}
.theme-amber .dark .mdc-data-table,
.theme-amber.dark .mdc-data-table {
  background-color: var(--mdc-theme-surface, #1e293b);
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-amber .dark .mdc-data-table__row,
.theme-amber.dark .mdc-data-table__row {
  background-color: inherit;
}
.theme-amber .dark .mdc-data-table__header-cell,
.theme-amber.dark .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #1e293b);
}
.theme-amber .dark .mdc-data-table__row--selected,
.theme-amber.dark .mdc-data-table__row--selected {
  background-color: rgba(245, 158, 11, 0.04);
}
.theme-amber .dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.theme-amber .dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.theme-amber .dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing,
.theme-amber.dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.theme-amber.dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.theme-amber.dark .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-amber .dark .mdc-data-table__cell,
.theme-amber .dark .mdc-data-table__header-cell,
.theme-amber.dark .mdc-data-table__cell,
.theme-amber.dark .mdc-data-table__header-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.theme-amber .dark .mdc-data-table__pagination,
.theme-amber.dark .mdc-data-table__pagination {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-amber .dark .mdc-data-table__row:not(.mdc-data-table__row--selected):hover,
.theme-amber.dark .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(255, 255, 255, 0.04);
}
.theme-amber .dark .mdc-data-table__header-cell,
.theme-amber.dark .mdc-data-table__header-cell {
  color: rgba(255, 255, 255, 0.87);
}
.theme-amber .dark .mdc-data-table__pagination-total,
.theme-amber .dark .mdc-data-table__pagination-rows-per-page-label,
.theme-amber .dark .mdc-data-table__cell,
.theme-amber.dark .mdc-data-table__pagination-total,
.theme-amber.dark .mdc-data-table__pagination-rows-per-page-label,
.theme-amber.dark .mdc-data-table__cell {
  color: rgba(255, 255, 255, 0.87);
}
[dir=rtl] .theme-amber .dark .mdc-data-table__pagination-button .mdc-button__icon, [dir=rtl] .theme-amber.dark .mdc-data-table__pagination-button .mdc-button__icon, .theme-amber .dark .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl], .theme-amber.dark .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-amber .dark .mat-mdc-table,
.theme-amber.dark .mat-mdc-table {
  background: #1e293b;
}
.theme-amber .dark .mat-mdc-progress-spinner,
.theme-amber.dark .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #f59e0b;
}
.theme-amber .dark .mat-mdc-progress-spinner.mat-accent,
.theme-amber.dark .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-amber .dark .mat-mdc-progress-spinner.mat-warn,
.theme-amber.dark .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-amber .dark .mat-badge-content,
.theme-amber.dark .mat-badge-content {
  color: #78350f;
  background: #f59e0b;
}
.cdk-high-contrast-active .theme-amber .dark .mat-badge-content, .cdk-high-contrast-active .theme-amber.dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.theme-amber .dark .mat-badge-accent .mat-badge-content,
.theme-amber.dark .mat-badge-accent .mat-badge-content {
  background: #1e293b;
  color: white;
}
.theme-amber .dark .mat-badge-warn .mat-badge-content,
.theme-amber.dark .mat-badge-warn .mat-badge-content {
  color: white;
  background: #dc2626;
}
.theme-amber .dark .mat-badge-disabled .mat-badge-content,
.theme-amber.dark .mat-badge-disabled .mat-badge-content {
  background: #1e293b;
  color: #475569;
}
.theme-amber .dark .mat-bottom-sheet-container,
.theme-amber.dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #1e293b;
  color: white;
}
.theme-amber .dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-amber .dark .mat-button-toggle-group:not([class*=mat-elevation-z]),
.theme-amber.dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-amber.dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-amber .dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]),
.theme-amber.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-amber.dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.theme-amber .dark .mat-button-toggle,
.theme-amber.dark .mat-button-toggle {
  color: #64748b;
}
.theme-amber .dark .mat-button-toggle .mat-button-toggle-focus-overlay,
.theme-amber.dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: #e2e8f0;
}
.theme-amber .dark .mat-button-toggle-appearance-standard,
.theme-amber.dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #1e293b;
}
.theme-amber .dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay,
.theme-amber.dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: #e2e8f0;
}
.theme-amber .dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
.theme-amber.dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #374152;
}
.theme-amber .dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
.theme-amber.dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #374152;
}
.theme-amber .dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle,
.theme-amber.dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #374152;
}
.theme-amber .dark .mat-button-toggle-checked,
.theme-amber.dark .mat-button-toggle-checked {
  background-color: rgba(255, 255, 255, 0.05);
  color: #94a3b8;
}
.theme-amber .dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard,
.theme-amber.dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.theme-amber .dark .mat-button-toggle-disabled,
.theme-amber.dark .mat-button-toggle-disabled {
  color: #1e293b;
  background-color: #0f172a;
}
.theme-amber .dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard,
.theme-amber.dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #1e293b;
}
.theme-amber .dark .mat-button-toggle-disabled.mat-button-toggle-checked,
.theme-amber.dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #1e293b;
}
.theme-amber .dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-amber .dark .mat-button-toggle-group-appearance-standard,
.theme-amber.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-amber.dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #374152;
}
.theme-amber .dark .mat-calendar-arrow,
.theme-amber.dark .mat-calendar-arrow {
  fill: #f1f5f9;
}
.theme-amber .dark .mat-datepicker-toggle,
.theme-amber .dark .mat-datepicker-content .mat-calendar-next-button,
.theme-amber .dark .mat-datepicker-content .mat-calendar-previous-button,
.theme-amber.dark .mat-datepicker-toggle,
.theme-amber.dark .mat-datepicker-content .mat-calendar-next-button,
.theme-amber.dark .mat-datepicker-content .mat-calendar-previous-button {
  color: #f1f5f9;
}
.theme-amber .dark .mat-calendar-table-header-divider::after,
.theme-amber.dark .mat-calendar-table-header-divider::after {
  background: rgba(241, 245, 249, 0.12);
}
.theme-amber .dark .mat-calendar-table-header,
.theme-amber .dark .mat-calendar-body-label,
.theme-amber.dark .mat-calendar-table-header,
.theme-amber.dark .mat-calendar-body-label {
  color: #94a3b8;
}
.theme-amber .dark .mat-calendar-body-cell-content,
.theme-amber .dark .mat-date-range-input-separator,
.theme-amber.dark .mat-calendar-body-cell-content,
.theme-amber.dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.theme-amber .dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-amber.dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: #475569;
}
.theme-amber .dark .mat-form-field-disabled .mat-date-range-input-separator,
.theme-amber.dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: #475569;
}
.theme-amber .dark .mat-calendar-body-in-preview,
.theme-amber.dark .mat-calendar-body-in-preview {
  color: rgba(241, 245, 249, 0.24);
}
.theme-amber .dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-amber.dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #64748b;
}
.theme-amber .dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-amber.dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(100, 116, 139, 0.8);
}
.theme-amber .dark .mat-calendar-body-in-range::before,
.theme-amber.dark .mat-calendar-body-in-range::before {
  background: rgba(245, 158, 11, 0.2);
}
.theme-amber .dark .mat-calendar-body-comparison-identical,
.theme-amber .dark .mat-calendar-body-in-comparison-range::before,
.theme-amber.dark .mat-calendar-body-comparison-identical,
.theme-amber.dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-amber .dark .mat-calendar-body-comparison-bridge-start::before,
.theme-amber .dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-amber.dark .mat-calendar-body-comparison-bridge-start::before,
.theme-amber.dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(245, 158, 11, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-amber .dark .mat-calendar-body-comparison-bridge-end::before,
.theme-amber .dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-amber.dark .mat-calendar-body-comparison-bridge-end::before,
.theme-amber.dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(245, 158, 11, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-amber .dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-amber .dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-amber.dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-amber.dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-amber .dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-amber .dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-amber.dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-amber.dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-amber .dark .mat-calendar-body-selected,
.theme-amber.dark .mat-calendar-body-selected {
  background-color: #f59e0b;
  color: #78350f;
}
.theme-amber .dark .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-amber.dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(245, 158, 11, 0.4);
}
.theme-amber .dark .mat-calendar-body-today.mat-calendar-body-selected,
.theme-amber.dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #78350f;
}
.theme-amber .dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-amber .dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-amber.dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-amber.dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(245, 158, 11, 0.3);
}
@media (hover: hover) {
  .theme-amber .dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-amber.dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(245, 158, 11, 0.3);
  }
}
.theme-amber .dark .mat-datepicker-content,
.theme-amber.dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #1e293b;
  color: white;
}
.theme-amber .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before,
.theme-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(30, 41, 59, 0.2);
}
.theme-amber .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-amber .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before,
.theme-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-amber .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-amber .dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-amber.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(30, 41, 59, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-amber .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-amber .dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-amber.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(30, 41, 59, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-amber .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-amber .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-amber .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-amber .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-amber .dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected,
.theme-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #1e293b;
  color: white;
}
.theme-amber .dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(30, 41, 59, 0.4);
}
.theme-amber .dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected,
.theme-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-amber .dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-amber .dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-amber.dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-amber.dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(30, 41, 59, 0.3);
}
@media (hover: hover) {
  .theme-amber .dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(30, 41, 59, 0.3);
  }
}
.theme-amber .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before,
.theme-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(220, 38, 38, 0.2);
}
.theme-amber .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-amber .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before,
.theme-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-amber .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-amber .dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
.theme-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-amber.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(220, 38, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-amber .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-amber .dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
.theme-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-amber.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(220, 38, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-amber .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-amber .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
.theme-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-amber .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-amber .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
.theme-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-amber .dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected,
.theme-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #dc2626;
  color: white;
}
.theme-amber .dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected,
.theme-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(220, 38, 38, 0.4);
}
.theme-amber .dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected,
.theme-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-amber .dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-amber .dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-amber.dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-amber.dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(220, 38, 38, 0.3);
}
@media (hover: hover) {
  .theme-amber .dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .theme-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(220, 38, 38, 0.3);
  }
}
.theme-amber .dark .mat-datepicker-content-touch,
.theme-amber.dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-datepicker-toggle-active,
.theme-amber.dark .mat-datepicker-toggle-active {
  color: #f59e0b;
}
.theme-amber .dark .mat-datepicker-toggle-active.mat-accent,
.theme-amber.dark .mat-datepicker-toggle-active.mat-accent {
  color: #1e293b;
}
.theme-amber .dark .mat-datepicker-toggle-active.mat-warn,
.theme-amber.dark .mat-datepicker-toggle-active.mat-warn {
  color: #dc2626;
}
.theme-amber .dark .mat-date-range-input-inner[disabled],
.theme-amber.dark .mat-date-range-input-inner[disabled] {
  color: #475569;
}
.theme-amber .dark .mat-divider,
.theme-amber.dark .mat-divider {
  border-top-color: rgba(241, 245, 249, 0.12);
}
.theme-amber .dark .mat-divider-vertical,
.theme-amber.dark .mat-divider-vertical {
  border-right-color: rgba(241, 245, 249, 0.12);
}
.theme-amber .dark .mat-expansion-panel,
.theme-amber.dark .mat-expansion-panel {
  background: #1e293b;
  color: white;
}
.theme-amber .dark .mat-expansion-panel:not([class*=mat-elevation-z]),
.theme-amber.dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-action-row,
.theme-amber.dark .mat-action-row {
  border-top-color: rgba(241, 245, 249, 0.12);
}
.theme-amber .dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .theme-amber .dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .theme-amber .dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]),
.theme-amber.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
.theme-amber.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
.theme-amber.dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.05);
}
@media (hover: none) {
  .theme-amber .dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover,
  .theme-amber.dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #1e293b;
  }
}
.theme-amber .dark .mat-expansion-panel-header-title,
.theme-amber.dark .mat-expansion-panel-header-title {
  color: white;
}
.theme-amber .dark .mat-expansion-panel-header-description,
.theme-amber .dark .mat-expansion-indicator::after,
.theme-amber.dark .mat-expansion-panel-header-description,
.theme-amber.dark .mat-expansion-indicator::after {
  color: #94a3b8;
}
.theme-amber .dark .mat-expansion-panel-header[aria-disabled=true],
.theme-amber.dark .mat-expansion-panel-header[aria-disabled=true] {
  color: #1e293b;
}
.theme-amber .dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-amber .dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description,
.theme-amber.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-amber.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.theme-amber .dark .mat-icon.mat-primary,
.theme-amber.dark .mat-icon.mat-primary {
  color: #f59e0b;
}
.theme-amber .dark .mat-icon.mat-accent,
.theme-amber.dark .mat-icon.mat-accent {
  color: #1e293b;
}
.theme-amber .dark .mat-icon.mat-warn,
.theme-amber.dark .mat-icon.mat-warn {
  color: #dc2626;
}
.theme-amber .dark .mat-drawer-container,
.theme-amber.dark .mat-drawer-container {
  background-color: #0f172a;
  color: white;
}
.theme-amber .dark .mat-drawer,
.theme-amber.dark .mat-drawer {
  background-color: #1e293b;
  color: white;
}
.theme-amber .dark .mat-drawer.mat-drawer-push,
.theme-amber.dark .mat-drawer.mat-drawer-push {
  background-color: #1e293b;
}
.theme-amber .dark .mat-drawer:not(.mat-drawer-side),
.theme-amber.dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-drawer-side,
.theme-amber.dark .mat-drawer-side {
  border-right: solid 1px rgba(241, 245, 249, 0.12);
}
.theme-amber .dark .mat-drawer-side.mat-drawer-end,
.theme-amber.dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(241, 245, 249, 0.12);
  border-right: none;
}
.theme-amber .dark [dir=rtl] .mat-drawer-side,
.theme-amber.dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(241, 245, 249, 0.12);
  border-right: none;
}
.theme-amber .dark [dir=rtl] .mat-drawer-side.mat-drawer-end,
.theme-amber.dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(241, 245, 249, 0.12);
}
.theme-amber .dark .mat-drawer-backdrop.mat-drawer-shown,
.theme-amber.dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(225, 214, 196, 0.6);
}
.theme-amber .dark .mat-step-header.cdk-keyboard-focused, .theme-amber .dark .mat-step-header.cdk-program-focused, .theme-amber .dark .mat-step-header:hover:not([aria-disabled]), .theme-amber .dark .mat-step-header:hover[aria-disabled=false],
.theme-amber.dark .mat-step-header.cdk-keyboard-focused,
.theme-amber.dark .mat-step-header.cdk-program-focused,
.theme-amber.dark .mat-step-header:hover:not([aria-disabled]),
.theme-amber.dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.05);
}
.theme-amber .dark .mat-step-header:hover[aria-disabled=true],
.theme-amber.dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .theme-amber .dark .mat-step-header:hover,
  .theme-amber.dark .mat-step-header:hover {
    background: none;
  }
}
.theme-amber .dark .mat-step-header .mat-step-label,
.theme-amber .dark .mat-step-header .mat-step-optional,
.theme-amber.dark .mat-step-header .mat-step-label,
.theme-amber.dark .mat-step-header .mat-step-optional {
  color: #94a3b8;
}
.theme-amber .dark .mat-step-header .mat-step-icon,
.theme-amber.dark .mat-step-header .mat-step-icon {
  background-color: #94a3b8;
  color: #78350f;
}
.theme-amber .dark .mat-step-header .mat-step-icon-selected,
.theme-amber .dark .mat-step-header .mat-step-icon-state-done,
.theme-amber .dark .mat-step-header .mat-step-icon-state-edit,
.theme-amber.dark .mat-step-header .mat-step-icon-selected,
.theme-amber.dark .mat-step-header .mat-step-icon-state-done,
.theme-amber.dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #f59e0b;
  color: #78350f;
}
.theme-amber .dark .mat-step-header.mat-accent .mat-step-icon,
.theme-amber.dark .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.theme-amber .dark .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-amber .dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-amber .dark .mat-step-header.mat-accent .mat-step-icon-state-edit,
.theme-amber.dark .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-amber.dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-amber.dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #1e293b;
  color: white;
}
.theme-amber .dark .mat-step-header.mat-warn .mat-step-icon,
.theme-amber.dark .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.theme-amber .dark .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-amber .dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-amber .dark .mat-step-header.mat-warn .mat-step-icon-state-edit,
.theme-amber.dark .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-amber.dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-amber.dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #dc2626;
  color: white;
}
.theme-amber .dark .mat-step-header .mat-step-icon-state-error,
.theme-amber.dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #dc2626;
}
.theme-amber .dark .mat-step-header .mat-step-label.mat-step-label-active,
.theme-amber.dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.theme-amber .dark .mat-step-header .mat-step-label.mat-step-label-error,
.theme-amber.dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #dc2626;
}
.theme-amber .dark .mat-stepper-horizontal, .theme-amber .dark .mat-stepper-vertical,
.theme-amber.dark .mat-stepper-horizontal,
.theme-amber.dark .mat-stepper-vertical {
  background-color: #1e293b;
}
.theme-amber .dark .mat-stepper-vertical-line::before,
.theme-amber.dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(241, 245, 249, 0.12);
}
.theme-amber .dark .mat-horizontal-stepper-header::before,
.theme-amber .dark .mat-horizontal-stepper-header::after,
.theme-amber .dark .mat-stepper-horizontal-line,
.theme-amber.dark .mat-horizontal-stepper-header::before,
.theme-amber.dark .mat-horizontal-stepper-header::after,
.theme-amber.dark .mat-stepper-horizontal-line {
  border-top-color: rgba(241, 245, 249, 0.12);
}
.theme-amber .dark .mat-sort-header-arrow,
.theme-amber.dark .mat-sort-header-arrow {
  color: #94a3b8;
}
.theme-amber .dark .mat-toolbar,
.theme-amber.dark .mat-toolbar {
  background: #0f172a;
  color: white;
}
.theme-amber .dark .mat-toolbar.mat-primary,
.theme-amber.dark .mat-toolbar.mat-primary {
  background: #f59e0b;
  color: #78350f;
}
.theme-amber .dark .mat-toolbar.mat-accent,
.theme-amber.dark .mat-toolbar.mat-accent {
  background: #1e293b;
  color: white;
}
.theme-amber .dark .mat-toolbar.mat-warn,
.theme-amber.dark .mat-toolbar.mat-warn {
  background: #dc2626;
  color: white;
}
.theme-amber .dark .mat-toolbar .mat-form-field-underline,
.theme-amber .dark .mat-toolbar .mat-form-field-ripple,
.theme-amber .dark .mat-toolbar .mat-focused .mat-form-field-ripple,
.theme-amber.dark .mat-toolbar .mat-form-field-underline,
.theme-amber.dark .mat-toolbar .mat-form-field-ripple,
.theme-amber.dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.theme-amber .dark .mat-toolbar .mat-form-field-label,
.theme-amber .dark .mat-toolbar .mat-focused .mat-form-field-label,
.theme-amber .dark .mat-toolbar .mat-select-value,
.theme-amber .dark .mat-toolbar .mat-select-arrow,
.theme-amber .dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow,
.theme-amber.dark .mat-toolbar .mat-form-field-label,
.theme-amber.dark .mat-toolbar .mat-focused .mat-form-field-label,
.theme-amber.dark .mat-toolbar .mat-select-value,
.theme-amber.dark .mat-toolbar .mat-select-arrow,
.theme-amber.dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.theme-amber .dark .mat-toolbar .mat-input-element,
.theme-amber.dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.theme-amber .dark .mat-tree,
.theme-amber.dark .mat-tree {
  background: #1e293b;
}
.theme-amber .dark .mat-tree-node,
.theme-amber .dark .mat-nested-tree-node,
.theme-amber.dark .mat-tree-node,
.theme-amber.dark .mat-nested-tree-node {
  color: white;
}