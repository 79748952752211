/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
.mat-grid-tile-content {
  padding: 10px !important;
}

.background-with-mascot {
  background-image: url(../assets/graphics/placeholder-documents.svg);
  background-repeat: no-repeat;
  background-position: right bottom;
}

.mat-expansion-indicator::after {
  height: 12px;
  width: 12px;
}